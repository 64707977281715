import axios from 'axios';
import config from '../../config/environmentConfig';
import { COMMON_ACTIONS, SCHOOL_ACTIONS, STUDENT_REGISTRATION_ACTION_LIST, LOCAL_STORAGE_KEYS } from '../../static/constants'
import { showToasterMessage, addToLocalStorage } from '../../utils';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import * as find from 'lodash.find';
const { lang } = require('../../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');




// export const fetchRelationshipByDomainName = (payload, props) => {
//   return dispatch => {
//     dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
//     return axios
//       .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/alpideClientURL/getRelationshipId?name=${payload.domainName}`)
//       .then(res => {
//         const { data } = res
//         const load = { relationshipId: data.relationshipId, registrationFormSettingId: payload.registrationFormSettingId }
//         props.fetchRegistrationFormSetting(load);
//         props.fetchCountries(load);
//         props.fetchPaymentTerms(load);
//         props.fetchRelationshipDataByRID(load);
//         props.getTotalStudentcCount(load);
//         props.getTotalStudentcCountByGrade(load);
//       })
//       .catch((err) => {
//         //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
//         if (!err.__isRetryRequest) {
//           showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
//         } else {
//         }
//       })
//   }
// }

export const fetchStudentFatherEmail = (payload, props) => {
  // let FY = getFinancialYear(payload.companyInfo);
  // let url = payload.type === 'fatherEmail' ? `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByFatherEmail?fatherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}` :
  //   `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByMotherEmail?motherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${props.registrationFormSettingId}`;
  
  // let url = payload.type === 'fatherEmail' ? `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByFatherEmail?fatherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}` :
  //   `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getRegistrationDataByMotherEmail?motherEmail=${payload.email}&relationshipId=${props.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`;
  let url = `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgRegistration/getByOrgRegId?relationshipId=${props.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}&orgRegistrationId=${payload.orgRegistrationId}&customerId=${payload.customerId}`;
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(url)
      .then(res => {
        if (res?.data?.customerId) {
          //let orgRegistration = res.data.orgRegistrationList[0] || {};
          let orgRegistration = {};
          if (payload.orgRegistrationId) {
            orgRegistration = find(res.data.orgRegistrationList || [], { orgRegistrationId: payload.orgRegistrationId }) || {}
          }
          
         const primayContact = find(res.data.boContactList || [], {isPrimaryContact: 1 }) || {};
          let contact = res.data.boContactList[0] || {};
          let location = res.data.boLocationList[0] || {};
          let studentList = [{...primayContact, dob: primayContact.dateOfBirth}];
          if ((location.countryName === 'United States' || location.countryName === 'United States (USA)' || location.countryName === 'India') ) {
            props.fetchStates && props.fetchStates(location.countryId);
          }
          if (orgRegistration.orgMembershipDetailsList && orgRegistration.orgMembershipDetailsList.length) {
             (orgRegistration.orgMembershipDetailsList || []).map((e) => {
              // const childContact = find(contact.boContactChildList || [], { contactChildId: e.contactChildId }) || {};
              // e.firstName = childContact.firstName;
              // e.lastName = childContact.lastName;
              // e.gender = childContact.gender;
              // e.allergies = childContact.allergies;
              // e.dob = childContact.dob ? new Date(childContact.dob) : '';
              // e.grade = e.grade;
              e.gradefee = e.courseFee ? Number(e.courseFee) : null;
              studentList.push(e)
              return e;
              //e.gradefee = (props.formSetting || {}).gradeFees[e.grade];
            })

          } 
          // else if (contact.boContactChildList && contact.boContactChildList.length) {
          //   studentList = (contact.boContactChildList || []).map((childContact) => {
          //     childContact.dob = childContact.dob ? new Date(childContact.dob) : '';
          //     return childContact;
          //   })
          // }

          props.updateState({
            ...orgRegistration,
            familyDoctorPhone: orgRegistration.familyDoctorPhNumber,
           // primaryContact: orgRegistration.primaryContact,
            customerId: res.data.customerId,
            contactId: contact.contactId,
            contactVersion: contact.version,
            fatherFirstName: res.data.fatherFirstName,
            fatherLastName: res.data.fatherLastName,
            fatherMobile: res.data.fatherMobile,
            fatherEmail: res.data.fatherEmail,
            motherFirstName: res.data.motherFirstName,
            motherLastName: res.data.motherLastName,
            motherMobile: res.data.motherMobile,
            motherEmail: res.data.motherEmail,

            fatherDOB: res.data.fatherDateOfBirth? new Date(res.data.fatherDateOfBirth) : '',
            motherDOB: res.data.motherDateOfBirth? new Date(res.data.motherDateOfBirth) : '',

            studentList: studentList,

            streetAddress1: location.streetAddress1,
            streetAddress2: location.streetAddress2,
            cityName: location.cityName,
            countryId: location.countryId,
            countryName: location.countryName,
            stateName: location.stateName,
            zipCode: location.zipCode,
            locationId: location.locationId,
            locationVersion: location.version,
            isFinancialAssistanceNeeded: orgRegistration.isFinancialAssistanceNeeded? 'yes': 'no',
            isTeacher: orgRegistration.isTeacher? 'yes': 'no',
            isVolunteer: orgRegistration.isVolunteer? 'yes': 'no',
            selectedPlan: JSON.parse(orgRegistration.selectedPlan || '{}'), 
            primaryContact: res.data.primaryContact,
            primayContactObj: primayContact,
            address: location,
            questionList: orgRegistration.questionList ? JSON.parse(orgRegistration.questionList): []
          })
        }
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || ((err.response || {}).data || {}).message })
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}



export const fetchRegistrationFormSetting = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    dispatch({ type: SCHOOL_ACTIONS.RESET_REGISTRATION_FORM_SETTING });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/registrationFormSetting/getFormSetting?relationshipId=${payload.relationshipId}&registrationFormSettingId=${payload.registrationFormSettingId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: SCHOOL_ACTIONS.REGISTRATION_FORM_SETTING, data });
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          //showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = SCHOOL_ACTIONS.REGISTRATION_FORM_SETTING;
        }
      })
  }
}

export const saveRegistration = (requestObj, props, amount) => {

  let formData = new FormData();
  formData.append('customer', JSON.stringify(requestObj, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgMembership/register`,
        formData,
        { headers: { 'content-type': 'multipart/form-data' } }
      )
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (requestObj.orgRegistrationList[0] && requestObj.orgRegistrationList[0].amount) {
          //window.location = (res.data || {}).redirectUrl+`&callback_uri=${window.location.origin}/app/register&formId=${(props.formSetting) || {}.registrationFormSettingId}`;
        }
        
        const { data } = res;
        if (data?.customerId) {
          dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: data });
          addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(data));
        }
        // if(props && props.updateState){
        //   props.updateState({
        //     isFormSaved: true
        //   })
        // }
        //showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'common.saved.success'] })
      })
      .catch(err => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const saveRegistrationFound = (requestObj, props) => {
  //formData.append('customer', JSON.stringify(requestObj, true));
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .post(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/orgMembership/register`,
        requestObj,

      )
      .then(res => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        const { formSetting = {} } = props;
        const { paymentGateway = {} } = formSetting;
        const { data } = res;
        if (data?.customerId) {
          dispatch({ type: COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO, data: data });
          addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(data));
        }
        if (requestObj.orgMembershipList && requestObj.orgMembershipList[0] && requestObj.orgMembershipList[0].amount && formSetting.collectOnlinePayment) {
          // window.location = (res.data || {}).redirectUrl+`&callback_uri=${window.location.origin}/app/register?studentRegistrationId=${(res.data || {}).studentRegistrationId}&formId=${props.registrationFormSettingId}`;
          let fName = '';
          let lName = '';
          if (formSetting.primaryContactForInvoicing === 'mother') { fName = props.motherFirstName || ''; lName = props.motherLastName || ''; };
          if (formSetting.primaryContactForInvoicing === 'father') { fName = props.fatherFirstName || ''; lName = props.fatherLastName || ''; };
          let params = `${res.data.customerId}_${res.data.studentRegistrationId}_${formSetting.primaryContactForInvoicing === 'mother' ? res.data.motherEmail : res.data.fatherEmail}_${res.data.registrationFormSettingId || 0}`
          let url = `${config.UI_BASE_URL}/payment-setup?t=${requestObj.relationshipId}_${paymentGateway.alpidePaymentGatewayId || 0}_4_${props.projectMasterId}_${props.ledgerAccountId || 0}_${requestObj.orgRegistrationList[0].amount || 0}&r=${params}&cName=${fName + '_' + lName}`
          window.open(url, '_self');
        } else {
          if (props && props.updateState) {
            props.updateState({
              isFormSaved: true
            })
          }
        }
        //showToasterMessage({ messageType: 'success', description: lang[res.data.message || 'common.saved.success'] })
      })
      .catch(err => {
		dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        showToasterMessage({ description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
      })
  }
}

export const getTotalStudentcCount = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/student/countStudent?relationshipId=${payload.relationshipId}&formId=${payload.registrationFormSettingId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT;
        }
      })
  }
}

export const getTotalStudentcCountByGrade = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/student/countStudentByGrade?relationshipId=${payload.relationshipId}&formId=${payload.registrationFormSettingId}`)
      .then(res => {
        const { data } = res
        dispatch({ type: STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE, data });
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch((err) => {
        dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        if (!err.__isRetryRequest) {
          // showToasterMessage({ messageType: 'error', description: lang[((err.response || {}).data || {}).message] || 'Some error occurred' })
        } else {
          err.actionToCall = STUDENT_REGISTRATION_ACTION_LIST.TOTAL_STUDENT_COUNT_BY_GRADE;
        }
      })
  }
}