import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TableViewComp from "../../components/TimeTable/tableView";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions';
import { getClassSectionList } from "./action";

class TableView extends Component {
  constructor(props) {
    super(props);
    console.log(`props`, props)
  }
    
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId,
      classId: this.props.studentData.classId,
      // sectionId: (this.props.classSectionList || []).find(val => console.log(val.classId,this.props.studentData.classId,"helloo"))
      sectionId:this.props.studentSectionId
    };
    // this.props.getTimeTableByClassSection(payload)
  }


  render() {
    return (
      <TableViewComp
        {...this.props}
        {...this.state}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerInfo: state.common.customerInfo,
    timeTableList: state.school.timeTableList,
    classSectionList: state.school.classSectionList,
    allStudentList: state.dashboard.allStudentList,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getClassSectionList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TableView);
