import React, { useState ,useEffect} from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Calendar, Skeleton } from "antd";
import { formatDateForBackend, getCurrentDateForBackend, getMomentDateForUI } from "../../utils";
import * as find from 'lodash.find';
import PageBreadcrumb from "../PageBreadcrumb";
import { Tabs } from 'antd';
// import AttendanceCon from "../../containers/Attendence2";
import ReactApexChart from "react-apexcharts";
import moment from "moment-timezone";

const { TabPane } = Tabs;


const School = (props) => {
  const [viewMode, setViewMode] = useState("Standard View");
 
  const { allStudentList, studentAttendance = {} } = props;
  const attendenceCount = (attendanceType) => {
    const statusList = Object.values(studentAttendance || {}) || [];
    let count = 0;
    if (statusList.length) {

      statusList.forEach(status => {
        switch (status) {
          case attendanceType:
            count++;
            break;
          default:
            break;
        }
      });
    }
    return count;
  }

  const presentCount = attendenceCount("Present");
  const absentCount = attendenceCount("Absent");
  const leaveCount = attendenceCount("Leave");
  const holidayCount = attendenceCount("Holiday");


  const chartState = {
    series: [presentCount, absentCount, leaveCount, holidayCount],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: 'gradient',
      },
      legend: {
        formatter: function (val, opts) {
          return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
        },
        position: 'bottom',
        horizontalAlign: 'left',
      },
      title: {
        text: 'Attendance Summary',
        align: 'center',
        margin: 40,
      },
      labels: ['Present', 'Absent', 'Leave', 'Holiday'],
      colors: ['#18CB8A', '#F85353', '#ffaa00', '#6385FF'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  const breadCrumbList = [
    {
      name: <FormattedMessage id='breadcrum.dashboard' defaultMessage='' />,
    },
    {
      name: <FormattedMessage id='attendance.text' defaultMessage='' />,
    },
    {
      name: <FormattedMessage id='list.text' defaultMessage='' />,
    },
  ];

  const handleTabChange = (studentId) => {
    const currentDate = new Date();

    const payload = {
      relationshipId: props.companyInfo.relationshipId,
      studentId: Number(studentId),
      startDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
      endDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)),
      pageNumber: 1,
      pageSize: 100,
    }
    props.getSchoolAttendanceByStudentId(payload);
    // const selectedStudent = (allStudentList || []).find(obj => obj.schoolStudentId === Number(studentId));
    // const attendenceList = (props.attendenceList[pageNumber] || []).filter(obj => obj.classSectionId === (selectedStudent?.schoolStudentClassRefList[0] || []).classSectionId);
    props.updateState({
      selectedStudent: (allStudentList || []).find(obj => obj.schoolStudentId === Number(studentId)),
      activeAttendKey: studentId
    })
  };


  const onDateChange = (pDate) =>{
    let y = pDate.format('YYYY');
    let m = pDate.format('MM');
    props.updateState({currentMonth: m})

    const payload = {
      relationshipId: props.companyInfo?.relationshipId,
      classId:props.selectedStudent.classId,
      startDate: formatDateForBackend(new Date(y, m-1, 1)),
      endDate: formatDateForBackend(new Date(y, m, 0)),
      studentId: Number(props.selectedStudent.schoolStudentId || 209),
    };

    props.getSchoolAttendanceByStudentId(payload);
  }

  const getAttendanceStatus = (date) => {
    switch (studentAttendance[date]) {
      case "Present":
        return <div style={{ color: '#18CB8A' }}>P</div>
      case "Absent":
        return <div style={{ color: '#F85353' }}>A</div>
      case "Leave":
        return <div style={{ color: '#ffaa00' }}>L</div>
      case "Holiday":
        return <div style={{ color: '#6385FF' }}>H</div>
      default:
        return <div style={{ color: 'transparent' }}>T</div>
    }
  }

  return (
    <>
      <div className="view-top-bar">
        <div className="top-view-left">
          <div className="page-heading">
            <FormattedMessage id='attendance.text' defaultMessage='' />
          </div>
          <div className="vertical-sep" />
          <PageBreadcrumb breadCrumbList={breadCrumbList} />
        </div>
        <div className="top-view-right">
        </div>
      </div>

      <div className="view-container">
        <Tabs
          tabBarGutter={8}
          activeKey={props.activeAttendKey || ''}
          onChange={handleTabChange}
        >
          {(allStudentList || []).length ? allStudentList.map((item, i) => {
            return <TabPane
              tab={`${item.firstName || `Student ${i}`} ${item.lastName || ''}`}
              key={item.schoolStudentId.toString()}
            >
                <Skeleton loading={props.isLoading}>
                  <div className="attendance_view">
                    <div className="div_p1">
                      {/* render attendance status for current month only */}
                     
                       <Calendar 
                    dateFullCellRender={(e)=>{
                      return <div className="p5" style={{backgroundColor: e.format('dddd') === 'Sunday' || e.format('dddd') === 'Saturday' ? 'aliceblue':''}}>
                      <div>{e.date()}</div>
                      {props.currentMonth? 
                          <div style={{color : studentAttendance[e.date()] ? '':'transparent'}}>{getAttendanceStatus(e.date()) || 'x'}</div>
                          :                    
                          <div>{getAttendanceStatus(100) || 'x'}</div>
                      }
                      </div>
                    }}
                    
                    onPanelChange={onDateChange}
                  />
 

                    </div>
                    <div className="div_p2">
                      <ReactApexChart options={chartState.options} series={chartState.series} type="pie" width={480} />
                      <div className="label">
                        <div className="attendence-label blue">{(presentCount + absentCount + leaveCount) > 0 ? ((presentCount / (presentCount + absentCount + leaveCount)) * 100).toFixed(2) : '0.00'}% Attendence</div>
                        <div className={`attendence-label ${studentAttendance[Number(moment().format('DD'))]}`}>{studentAttendance[Number(moment().format('DD'))]} Today</div>
                      </div>
                    </div>
                  </div>
                </Skeleton>
              
            </TabPane>
          })
            :
            ''
          }
        </Tabs>

      </div>
    </>
  );


  // return (
  //   <Fragment>

  //     <div className="view-top-bar">
  //       <div className="top-view-left">
  //         <div className="page-heading">
  //           <FormattedMessage id='attendance.text' defaultMessage='' />
  //         </div>
  //         <div className="vertical-sep" />
  //         <PageBreadcrumb breadCrumbList={breadCrumbList} />
  //       </div>
  //       <div className="top-view-right">
  //       </div>
  //     </div>

  //     <div className="view-container">
  //       <Tabs
  //         tabBarGutter={8}
  //         activeKey={props.activeAttendKey || ''}
  //         onChange={handleTabChange}
  //       >
  //         {(allStudentList || []).length ? allStudentList.map((item, i) => {
  //           return <TabPane
  //             tab={`${item.firstName} ${item.lastName}`}
  //             key={item.schoolStudentId.toString()}
  //           >
  //             <div className="view-container-actions">
  //               <div className="left-actions">
  //                 <div className="table-heading">
  //                   Attendance
  //                 </div>
  //               </div>
  //               <div className="right-actions">

  //                 <DropdownAction overlay={viewMenu()} trigger={['click']}>
  //                   <span>
  //                     <i class="fi fi-rr-eye mr5" style={{ marginTop: "5px" }}></i>
  //                     <div className="view-mode">{viewMode}</div>
  //                   </span>
  //                 </DropdownAction>

  //                 <DatePicker
  //                   defaultValue={getMomentDateForUI({ date: props.selectedDate || new Date(), format: 'YYYY-MM' })}
  //                   onChange={(selectedDate) => {
  //                     const companyInfo = props.companyInfo || {};
  //                     const obj = find(props.studentClassList || [], { studentClassRefId: props.studentClassRefId }) || {};
  //                     let date = new Date(selectedDate);
  //                     props.updateState({ selectedDate });
  //                     let y = date.getFullYear();
  //                     let m = date.getMonth();
  //                     const payload = {
  //                       relationshipId: companyInfo.relationshipId,
  //                       classId: props.classId,
  //                       startDate: formatDateForBackend(new Date(y, m, 1)),
  //                       endDate: formatDateForBackend(new Date(y, m + 1, 0)),
  //                       schoolStudentIdList: [obj.schoolStudentId]
  //                     };

  //                     props.getSchoolAttendanceByClassId(payload);

  //                   }}
  //                   picker="month"
  //                 />

  //                 <DropdownAction overlay={downloadMenu()} trigger={['click']}>
  //                   <span><DownloadOutlined /> &nbsp;
  //                     <div className="view-mode">Download</div>
  //                   </span>
  //                 </DropdownAction>

  //               </div>
  //             </div>

  //             {viewMode === 'Standard View' ?
  //               <div className='table-container'>
  //                 <table>
  //                   <thead>
  //                     <tr>

  //                     <th>Student Name</th>
  //                       {(getDateList(props.selectedDate) || []).map((date, i) => {
  //                         return (
  //                           <th key={date}>
  //                             {MONTH_NAME_CONSTANT[currentMonth]} {date}
  //                           </th>
  //                         );
  //                       })}
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                     {console.log(`vv`, Object.keys(props.studentAttendance || {}).length)}
  //                     {Object.keys(props.studentAttendance || {}).length ? 
  //                       <tr key={`standard`}>

  //                         <td>

  //                            {props.studentAttendance.studentId}
  //                         </td>
  //                         {(getDateList(props.selectedDate) || []).map((dateKey, k) => {                           
  //                             return <td
  //                               key={"att" + k}
  //                               style={{ width: "40px" }}
  //                             >
  //                               {studentAttendance[dateKey] === "Present" ? <div className="essential">P</div> : ''}
  //                               {studentAttendance[dateKey] === "Absent" ? <div className="required">A</div> : ''}
  //                               {studentAttendance[dateKey] === "Tardy" ? "T" : ""}
  //                               {studentAttendance[dateKey] === "Holiday" ? "H" : ""}
  //                               {studentAttendance[dateKey] === "Excuse Leave" ? "L" : ""}
  //                             </td>
  //                           })
  //                         }
  //                       </tr>
  //                       :
  //                       ''
  //                     }
  //                   </tbody>
  //                 </table>
  //               </div>
  //               :
  //               <div className='table-container'>
  //                 <table>
  //                   <thead>
  //                     <tr>
  //                       <th><FormattedMessage id='pagination.text.total' defaultMessage='' /></th>
  //                       <th><FormattedMessage id='modal.education.present' defaultMessage='' /></th>
  //                       <th><FormattedMessage id='modal.education.absent' defaultMessage='' /></th>

  //                       <th><FormattedMessage id='modal.education.holiday' defaultMessage='' /></th>
  //                       <th><FormattedMessage id='modal.education.sunday' defaultMessage='' /></th>
  //                     </tr>
  //                   </thead>
  //                   <tbody>
  //                   {Object.keys(props.studentAttendance || {}).length ? 

  //                       <tr key={`summary`}>
  //                       <td>{parseInt(attendenceCount("Present")) + parseInt(attendenceCount("Absent")) + parseInt(attendenceCount("Holiday"))}</td>
  //                       <td style={{color:"#1CB980"}}>{attendenceCount("Present")}</td>
  //                       <td style={{color:"#F85353"}}>{attendenceCount("Absent")}</td>
  //                       <td style={{color:"#F7AD01"}}>{attendenceCount("Holiday")}</td>
  //                       <td>{attendenceCount("Sunday")}</td>

  //                       </tr>
  //                       :
  //                       ''
  //         }                       

  //                   </tbody>
  //                 </table>
  //               </div>
  //             }

  //           </TabPane>
  //         })
  //           :
  //           ''
  //         }

  //       </Tabs>

  //     </div>
  //   </Fragment>
  // );

};

export default injectIntl(School);
// title={props.attendanceList && props.attendanceList[dateKey] ? props.attendanceList[dateKey]['1'] : ''}
