import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FeeListComp from "../../components/FeeList";
import { getFeesList, getFeesCount, resetPaginatedData, getInvoiceProforma,getInvoiceByProformaId } from "./action";
import { getAllStudentForm } from '../OpenRegistration/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from './../../actions/commonActions';
import { generatePDF, printPDF } from "../../actions/downloadFileAction";

class StudentFeeList extends Component {
  constructor(props) {
    super(props);
    const linkProps = (this.props.location && this.props.location.state) || {};
    this.state = {
      templateType: linkProps.templateType,
      pageNumber: 1,
      pageSize: 25,
      activeTab: props.activeTab,
      isFeesLoaded: false
    };
  }


  componentDidMount() {
    this.props.updateHeaderState({headerHeading: 'Fees'})
    const companyInfo = this.props.companyInfo || {};
    const customerInfo = this.props.customerInfo || {};
    const payload = {
      companyInfo,
      relationshipId: companyInfo.relationshipId,
      pageNumber: 1, pageSize: 25,
      customerId: customerInfo.customerId,
      formId: 0,
      proformaId:this.props.proformaId
    };
    this.props.getFeesList(payload);
    this.props.getFeesCount(payload);

  }
  
  componentWillReceiveProps(props){
   

    if(!this.state.isFeesLoaded && props.allStudentList?.length){
      const student = props.allStudentList[0]
      this.props.getInvoiceProforma({
        relationshipId: student?.relationshipId,
        customerId: student?.customerId,
        orgRegistrationId: student?.orgRegistrationId
      });
    
      this.setState({isFeesLoaded: true,
        activeTab: (props.allStudentList[0] || [])?.schoolStudentId.toString(),
      });
    }

  }

  
  render() {
    return <FeeListComp  
              {...this.props}
              {...this.state}
              updateState={(data) => {
                this.setState(data);
              }} 
            />;
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    relationshipId: state.common.companyInfo.relationshipId,
    customerInfo: state.common.customerInfo,
    feeList: state.school.feeList,
    feeCount: state.school.feeCount,
    invoiceProformaList: state.school.invoiceProformaList,
    allStudentList: state.dashboard.allStudentList,
    invoiceDetails:state.school.invoiceDetails
    // defaultSchoolForm: state.common.defaultSchoolForm,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getFeesList,
      getFeesCount,
      resetPaginatedData,
      getAllStudentForm,
      getInvoiceProforma,
      printPDF,
      generatePDF,
      getInvoiceByProformaId,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(StudentFeeList);
