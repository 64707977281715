export const lang = {
  'phone': 'Ph',
  'email': 'Email',
  'gstIn': 'GSTIN', 
  'pan': 'PAN',
  'ok': 'OK',
  'company': 'Organization',
  'supplier': 'Supplier',
  'contact': 'Contact',
  'billTo': 'Bill To',
  'shipTo': 'Ship To',
  'download': 'Download',
  'button.create.label': 'Create',
  'button.save.label': 'Save',
  'draft.text': 'Draft',
  'button.draft.label': 'Save as Draft',
  'button.cancel.label': 'Cancel',
  'button.close.label': 'Close',
  'pagination.text.total': 'Total',
  'pagination.text.items': 'items',
  'login.text': 'Sign in using your credentials',
  'login.or': 'Or',
  'login.text-signup': 'Free Sign Up',
  'login.submit': 'Sign In',
  'login.email.placeholder': 'Email',
  'login.email.invalidEmail': 'Incorrect Email',
  'login.email.requiredEmail': 'Email is required',
  'login.email.requiredPassword': 'Password is required',
  'login.forgot': 'Forgot Password?',
  'login.password.placeholder': 'Password',
  'login.remember': 'Remember Me',
  'login.capsLockIsOn': 'Caps lock is  on',
  'login.notification.lable': 'Notification',
  'login.notification.message': 'We will be performing routine maintenance on Oct 29th 2021. Thank you for your patience while we make these changes for you.',
  'login.text1': 'Your Business at ',
  'login.text2': 'Your Fingertips',
  'login.text3': ' Alpide ERP  allows total access to every important process in your business by making data easily accessible to you.',
  'signup.text': 'Sign Up',
  'signup.tab.contact': 'Contact',
  'signup.tab.address': 'Address',
  'signup.email.text': 'Email',
  'signup.currencyCode.text': 'Currency Code',
  'signup.country.text': 'Country',
  'signup.timezone.text': 'Timezone',
  'signup.timezone.placeholder': 'Select Timezone',
  'signup.password.text': 'Password',
  'signup.companyName.text': 'Organization Name',
  'signup.companyName.placeholder': 'Organization Name',
  'signup.country.placeholder': 'Country Name',
  'signup.passwordRepeat.text': 'Confirm Password',
  'signup.repeatPassword.placeholder': 'Confirm Password',
  'signup.firstname.placeholder': 'First Name',
  'signup.lastname.placeholder': 'Last Name',
  'signup.streetname.placeholder': 'Street Name',
  'signup.street(contd).placeholder': 'Street (Contd)',
  'signup.city.placeholder': 'City',
  'signup.postal/zipcode.placeholder': 'Postal/Zip Code',
  'signup.statename.placeholder': 'State/Province/Region Name',
  'signup.btn.cancel': 'Cancel',
  'signup.btn.create': 'Create Account',
  'signup.workPhone.placeholder': 'Work Phone',
  'signup.validation.email': 'Email is required.',
  'signup.validation.password': 'Password is required.',
  'signup.validation.confirmPassword': 'Confirm password is required',
  'signup.validation.passwordMismatch': 'Password does not match',
  'signup.validation.company': 'Company name is required',
  'signup.validation.country': 'Country name is required',
  'forgot.headingText': 'Forgot Password',
  'forgot.text': 'Enter your email address that you used to register your account. We will send you an email with your username and password.',
  'forgot.btn.save': 'Save',
  'forgot.btn.back': 'Go Back',
  'forgot.btn.submit': 'Send Email',
  'forgot.btn.addComment': 'Save Audit Trail',
  'forgot.email.placeholder': 'Enter your registered email',
  'confirmation.submit': 'Submit',
  'confirmation.sendNow': 'Send Now',
  'confirmation.cancel': 'Cancel',
  'signup.confirmation.country': 'Your country is - <strong>{value}</strong>',
  'signup.confirmation.text': 'Are you sure you want to create account?',
  'signup.confirmation.title': 'Confirmation',
  'signup.gstNumber.text': 'GSTIN',
  'signup.gstNumber.placeholder': 'GSTIN',
  'signup.vatNumber.text': 'VAT Number',
  'signup.vatNumber.placeholder': 'VAT Number',
  'signup.phoneNumber.text': 'Phone Number',
  'signup.phoneNumber.placeholder': 'Phone Number',
  'signup.faxNumber.text': 'Fax Number',
  'signup.faxNumber.placeholder': 'Fax Number',
  'signup.website.text': 'Website',
  'signup.website.placeholder': 'Website',

  'drawer.ledger.salesDiscount': 'Discount (Cr)',
  'drawer.ledger.sales': 'Sales (Cr)',
  'drawer.ledger.purchase': 'Purchase (Dr)',
  'drawer.ledger.salesOutput': 'Tax (Cr)',
  'drawer.ledger.generalIncome': 'Expense (Cr)',
  'drawer.ledger.debtors': 'Debtor (Dr)',
  'drawer.ledger.creditors': 'Creditor (Dr)',
  'drawer.ledger.creditorsCr': 'Creditor (Cr)',

  'drawer.inventory.title': 'Inventory Information',
  'drawer.inventory.tabNames.productInfo': 'Product Info',
  'drawer.inventory.tabNames.productDetails': 'Product Details',
  'drawer.inventory.tabNames.finance': 'Tax',
  'drawer.inventory.tabNames.warehouse': 'Warehouse',
  'drawer.inventory.tabNames.alerts': 'Alerts',
  'drawer.inventory.tabNames.image': 'Image',


  'modal.common.taxIdentification.taxType': 'Tax Type',
  'modal.common.taxIdentification.number': 'Number',
  'modal.common.taxIdentification.populateOnTransaction': 'Populate on Transaction',
  'modal.common.taxIdentification.populateOnTransaction.tooltip': 'If selected then tax identification will be displayed on pdf document',

  'drawer.inventory.productName': 'Product Name',
  'drawer.inventory.description': 'Description',
  'drawer.inventory.category': 'Category',
  'drawer.inventory.brand': 'Brand',
  'drawer.inventory.partNo': 'Part #',
  'drawer.inventory.arcticleNumber': 'Arcticle #',
  'drawer.inventory.manufacturer': 'Manufacturer',
  'drawer.inventory.sku': 'SKU',
  'drawer.inventory.systemSku': 'Use System SKU',
  'drawer.inventory.customerStockNo': 'Stock #',
  'drawer.inventory.salesTarget': 'Sales Target',
  'drawer.inventory.publishToB2BSite': 'Publish to B2B Site',
  'drawer.inventory.variantSwitch': 'Add Variants to Product',
  'drawer.inventory.purchasedPrice': 'Purchased Price',
  'drawer.inventory.wholeSalePrice': 'Wholesale Price',
  'drawer.inventory.retailPrice': 'Retail Price',
  'drawer.inventory.barCode': 'Bar Code',
  'drawer.inventory.currentStock': 'Current Stock',
  'drawer.inventory.uom': 'UOM',
  'drawer.inventory.attribute': 'Attribute',
  'drawer.inventory.attributeValue': 'Value',

  'drawer.inventory.upc': 'UPC',
  'drawer.inventory.ean': 'EAN',
  'drawer.inventory.isbn': 'ISBN',

  'drawer.inventory.upc.placeholder': 'Universal Product Code',
  'drawer.inventory.ean.placeholder': 'International Article Number',
  'drawer.inventory.isbn.placeholder': 'Internaltional Standard Book Number',

  'drawer.inventory.warehouse': 'Warehouse',
  'drawer.inventory.warehouse.placeholder': 'Please Select Warehouse',

  'drawer.inventory.productName.placeholder': 'Product Name',
  'drawer.inventory.description.placeholder': 'Product Description',
  'drawer.inventory.category.placeholder': 'Select Product Category',
  'drawer.inventory.brand.placeholder': 'Select Brand',
  'drawer.inventory.partNo.placeholder': 'Enter Part #',
  'drawer.inventory.arcticleNumber.placeholder': 'Enter Arcticle #',
  'drawer.inventory.manufacturer.placeholder': 'Select Manufacturer',
  'drawer.inventory.sku.placeholder': 'Enter SKU',
  'drawer.inventory.customerStockNo.placeholder': 'Customer Stock #',
  'drawer.inventory.salesTarget.placeholder': 'Sales Target',
  'drawer.inventory.purchasedPrice.placeholder': 'Enter Purchased Price',
  'drawer.inventory.wholeSalePrice.placeholder': 'Enter Wholesale Price',
  'drawer.inventory.retailPrice.placeholder': 'Enter Retail Price',
  'drawer.inventory.barCode.placeholder': 'Enter the bar code associated with this item',
  'drawer.inventory.currentStock.placeholder': 'Enter Current Stock',
  'drawer.inventory.uom.placeholder': 'Select UOM',
  'drawer.inventory.attribute.placeholder': 'Select a attribute',
  'drawer.inventory.attributeValue.placeholder': 'Enter attribute value',

  'drawer.inventory.productDetails.addVariantToProduct.button': 'Add Variant to Product',
  'drawer.inventory.productDetails.reset.button': 'Reset Variant',
  'drawer.inventory.productDetails.gridHeader.variant': 'Variant',
  'drawer.inventory.productDetails.gridHeader.purchasedPrice': 'Purchased Price',
  'drawer.inventory.productDetails.gridHeader.retailPrice': 'Retail Price',
  'drawer.inventory.productDetails.gridHeader.wholeSalePrice': 'Wholesale Price',
  'drawer.inventory.productDetails.gridHeader.currentStock': 'Current Stock',
  'drawer.inventory.productDetails.gridHeader.uom': 'UOM',
  'drawer.inventory.productDetails.gridHeader.sku': 'SKU',

  'drawer.inventory.finance.popuplateTaxOnTransactions': 'Populate Tax on Transactions',
  'drawer.inventory.finance.popuplateTaxOnTransactions.desc': 'When checked Tax rates will be populated on SO, Invoice, PO etc',
  'drawer.inventory.finance.HSNCode': 'HSN Code',
  'drawer.inventory.finance.HSNCode.placeholder': 'Select a HSN Code',
  'drawer.inventory.finance.purchaseLedgerAccount': 'Purchase Ledger Account',
  'drawer.inventory.finance.purchaseLedgerAccount.placeholder': 'Select Purchase Ledger Account',
  'drawer.inventory.finance.salesLedgerAccount': 'Sales Ledger Account',
  'drawer.inventory.finance.salesLedgerAccount.placeholder': 'Select Sales Ledger Account',
  'drawer.inventory.finance.tax': 'Tax',
  'drawer.inventory.finance.tax.placeholder': 'Select Tax Rate',
  'drawer.inventory.image.productImg': 'Select Product Image',
  'drawer.inventory.image.productImg.upload': 'Upload',

  'drawer.inventory.alert.lowStockLevel': 'Low Stock Level',
  'drawer.inventory.alert.lowStockLevel.desc': 'Enter how many items left until you start receiving low stock alert',
  'drawer.inventory.alert.lowStockLevel.placeholder': 'Enter Low Stock Level',
  'drawer.inventory.alert.velocityAlert': 'Velocity Alert',
  'drawer.inventory.alert.velocityAlert.desc': 'Alert unexpected sales spikes',
  'drawer.inventory.alert.reorderLevel': 'Reorder Level',
  'drawer.inventory.alert.reorderLevel.desc': 'Enter how many items left until you are prompted to reorder',
  'drawer.inventory.alert.reorderLevel.placeholder': 'Enter Reorder Level',
  'drawer.inventory.alert.outOfStockAlert': 'Out of Stock Alert',
  'drawer.inventory.alert.outOfStockAlert.desc': 'Alert when the product is out of stock',
  'drawer.inventory.alert.aboveThresholdLevel': 'Above Threshold Level',
  'drawer.inventory.alert.aboveThresholdLevel.desc': 'Notify when projected stock is above the maximum stock level',
  'drawer.inventory.alert.aboveThresholdLevel.placeholder': 'Enter Above Threshold Level',

  'drawer.inventory.confirmation.title': 'Confirmation',
  'drawer.inventory.confirmation.text': 'Are you sure you want to create product?',
  'drawer.inventory.validation.productName': 'Product Name is required',
  'drawer.inventory.validation.productNameLenght': 'Minimum 4 chars required',
  'drawer.inventory.validation.duplicateAttribute': 'Attribute is already selected',
  'drawer.inventory.validation.defaultHSN': 'Please select HSN code before Populate Tax on Transactions',
  'drawer.inventory.validation.defaultTax': 'Please select tax before checking Populate Tax on Transactions',
  'drawer.inventory.validation.purchasePrice': 'Purchase price for current stock is required for inventory valuation',

  'drawer.customer.title': 'Customer Information',
  'drawer.customer.companyName': 'Organization Name',
  'drawer.customer.companyName.required': 'Company name is required',
  'drawer.customer.companyName.placeholder': 'Enter Company Name',
  'drawer.customer.paymentTerm': 'Payment Term',
  'drawer.customer.paymentTerm.placeholder': 'Select Payment Term',
  'drawer.customer.customerType': 'Customer Type',
  'drawer.customer.customerType.placeholder': 'Select Customer Type',
  'drawer.customer.websiteUrl': 'Website Url',
  'drawer.customer.websiteUrl.placeholder': 'Enter Website Url',
  'drawer.customer.customerSource': 'Customer Source',
  'drawer.customer.customerSource.placeholder': 'Select Customer Source',
  'drawer.customer.rating': 'Rate Your Company',
  'drawer.customer.rating.placeholder': 'Select Company Rating',
  'drawer.customer.industry': 'Industry',
  'drawer.customer.industry.placeholder': 'Select Industry',
  'drawer.customer.relationshipSince': 'Relationship Since',
  'drawer.customer.relationshipSince.placeholder': 'Relationship Since Date',
  'drawer.customer.address.streetName.placeholder': 'Street Name',
  'drawer.customer.address.streetContd.placeholder': 'Street (contd)',
  'drawer.customer.address.city.placeholder': 'City',
  'drawer.customer.address.state.placeholder': 'State/Province/Region',
  'drawer.customer.address.country.placeholder': 'Select Country',
  'drawer.customer.address.zipCode.placeholder': 'Zip/Postal Code',
  'drawer.customer.billingAddress': 'Billing Address',
  'drawer.customer.shippingAndBillingAddressSame': 'Shipping address same as billing',
  'drawer.customer.contact.title.placeholder': 'Select Title',
  'drawer.customer.contact.firstName.placeholder': 'First Name',
  'drawer.customer.contact.middleName.placeholder': 'Middle Name',
  'drawer.customer.contact.lastName.placeholder': 'Last Name',
  'drawer.customer.contact.callName.placeholder': 'Call Name',
  'drawer.customer.contact.phone.placeholder': 'Phone',
  'drawer.customer.contact.mobile.placeholder': 'Mobile',
  'drawer.customer.contact.faxNumber.placeholder': 'Fax #',
  'drawer.customer.contact.email.placeholder': 'Email',
  'drawer.customer.termName': 'Term Name',
  'drawer.customer.days': 'Days',
  'drawer.customer.description': 'Description',
  'drawer.customer.defaultPayment': 'Default Payment',
  'drawer.customer.taxInfo.gstMechanism': 'GST Mechanism',
  'drawer.customer.taxInfo.gstMechanism.placeholder': 'Select GST Mechanism',
  'drawer.customer.taxInfo.sez': 'Located in SEZ',
  'drawer.customer.taxInfo.companyLogo': 'Select Organization Logo',
  'drawer.customer.taxInfo.companyLogo.upload': ' Upload',
  'drawer.customer.taxInfo.taxType': ' Tax Type',
  'drawer.customer.taxInfo.taxNumber': 'Number',
  'drawer.customer.taxInfo.populateOnTransaction': 'Populate On Transaction',
  'drawer.customer.confirmation.title': 'Confirmation',
  'drawer.customer.confirmation.text': 'Are you sure you want to create customer?',
  'drawer.customer.validation.companyName': 'Company Name is required',
  'drawer.customer.validation.billingStreet1Name': 'Street Name is required',
  'drawer.customer.validation.billingCityName': 'City Name is required',
  'drawer.customer.validation.billingStateName': 'State/Province/Region Name is required',
  'drawer.customer.validation.shippingStreet1Name': 'Street Name is required',
  'drawer.customer.validation.shippingCityName': 'City Name is required',
  'drawer.customer.validation.paymentTerm': 'Payment Term is required',
  'drawer.customer.validation.shippingStateName': 'State/Province/Region Name is required',
  'drawer.supplier.title': 'Supplier Information',
  'drawer.supplier.companyName': 'Supplier Name',
  'drawer.supplier.companyName.placeholder': 'Enter Supplier Name',
  'drawer.supplier.paymentTerm': 'Payment Term',
  'drawer.supplier.paymentTerm.placeholder': 'Select Payment Term',
  'drawer.supplier.supplierType': 'Supplier Type',
  'drawer.supplier.supplierType.placeholder': 'Select Supplier Type',
  'drawer.supplier.websiteUrl': 'Website Url',
  'drawer.supplier.websiteUrl.placeholder': 'Enter Website Url',
  'drawer.supplier.supplierSource': 'Supplier Source',
  'drawer.supplier.supplierSource.placeholder': 'Select Supplier Source',
  'drawer.supplier.rating.placeholder': 'Select Company Rating',
  'drawer.supplier.industry': 'Industry',
  'drawer.supplier.industry.placeholder': 'Select Industry',
  'drawer.supplier.relationshipSince': 'Relationship Since',
  'drawer.supplier.relationshipSince.placeholder': 'Relationship Since Date',
  'drawer.supplier.address.streetName.placeholder': 'Street Name',
  'drawer.supplier.address.streetContd.placeholder': 'Street (contd)',
  'drawer.supplier.address.city.placeholder': 'City',
  'drawer.supplier.address.state.placeholder': 'State/Province/Region',
  'drawer.supplier.address.country.placeholder': 'Select Country',
  'drawer.supplier.address.zipCode.placeholder': 'Zip/Postal Code',
  'drawer.supplier.billingAddress': 'Billing Address',
  'drawer.supplier.shippingAndBillingAddressSame': 'Shipping address same as billing',
  'drawer.supplier.contact.title.placeholder': 'Select Title',
  'drawer.supplier.contact.firstName.placeholder': 'First Name',
  'drawer.supplier.contact.middleName.placeholder': 'Middle Name',
  'drawer.supplier.contact.lastName.placeholder': 'Last Name',
  'drawer.supplier.contact.callName.placeholder': 'Call Name',
  'drawer.supplier.contact.phone.placeholder': 'Phone',
  'drawer.supplier.contact.mobile.placeholder': 'Mobile',
  'drawer.supplier.contact.faxNumber.placeholder': 'Fax #',
  'drawer.supplier.contact.email.placeholder': 'Email',
  'drawer.supplier.taxInfo.gstMechanism': 'GST Mechanism',
  'drawer.supplier.taxInfo.gstMechanism.placeholder': 'Select GST Mechanism',
  'drawer.supplier.taxInfo.sez': 'Located in SEZ',
  'drawer.supplier.taxInfo.taxType': ' Tax Type',
  'drawer.supplier.taxInfo.taxNumber': 'Number',
  'drawer.supplier.taxInfo.populateOnTransaction': 'Populate On Transaction',
  'drawer.supplier.taxInfo.companyLogo': 'Select Company Logo',
  'drawer.supplier.taxInfo.companyLogo.upload': ' Upload',
  'drawer.supplier.confirmation.title': 'Confirmation',
  'drawer.supplier.confirmation.text': 'Are you sure you want to create supplier?',
  'drawer.supplier.validation.companyName': 'Company Name is required',
  'drawer.supplier.validation.billingStreet1Name': 'Street Name is required',
  'drawer.supplier.validation.billingCityName': 'City Name is required',
  'drawer.supplier.validation.billingStateName': 'State/Province/Region Name is required',
  'drawer.supplier.validation.shippingStreet1Name': 'Street Name is required',
  'drawer.supplier.validation.shippingCityName': 'City Name is required',
  'drawer.supplier.validation.shippingStateName': 'State/Province/Region Name is required',
  'drawer.ledger.title': 'Ledger Information',
  'add': 'Add',
  'yes': 'Yes',
  'no': 'No',
  'addRow': 'Add Row',
  'addUpdateData': 'Are you sure you want to save?',
  'createSalesOrder': 'Are you sure you want to save?',
  'customer.address.delete.confirmation': 'Are you sure you want to delete this address?',
  'supplier.address.delete.confirmation': 'Are you sure you want to delete this address?',
  'deleteItem.confirmation': 'Are you sure you want to delete {value}?',
  'updateItem.confirmation': 'Are you sure you want to update {value}?',
  'addItem.confirmation': 'Are you sure you want to save {value} ',
  'setItem.confirmation': 'Are you sure you want to set {value} ',
  'addItem.validation': '{value} is required',
  'addItem.text.paymentTerm': 'Payment Terms',
  'addItem.text.customerSource': 'Customer Source',
  'addItem.text.customerType': 'Customer Type',
  'addItem.text.industry': 'Industry',
  'addItem.text.supplierSource': 'Supplier Source',
  'addItem.text.supplierType': 'Supplier Type',
  'addItem.text.soSource': 'SO Source',
  'addItem.text.productCategory': 'Product Category',
  'addItem.text.productBrand': 'Product Brand',
  'addItem.text.productManufacturer': 'Product Manufacturer',
  'addItem.text.uom': 'UOM',
  'addItem.text.department': 'Department',
  'addItem.text.warehouse': 'Warehouse',
  'addItem.text.project': 'Project',
  'addItem.text.billingAddress': 'Billing Address',
  'addItem.text.shippingAddress': 'Shipping Address',
  'addItem.text.relationshipBillingAddress': 'Billing Address',
  'addItem.text.relationshipShippingAddress': 'Shipping Address',
  'addItem.text.employee': 'Employee',
  'addItem.text.contact': 'Contact',
  'addItem.text.priceList': 'Price List',
  'addItem.text.documentName': 'Document Name',
  'addItem.text.newTax': 'New Tax',
  'addItem.text.attribute': 'Attribute',
  'addItem.text.rfqStatus': 'RFQ Status',
  'addItem.text.rfqPriceCode': 'RFQ Price Code',
  'addItem.text.salutation': 'Salutation',
  'addItem.text.activityStatus': 'Activity Status',
  'addItem.text.activityPriority': 'Activity Priority',
  'addItem.text.activityType': 'Activity Type',
  'addItem.text.carrierName': 'Carrier Name',
  'addItem.text.freightType': 'Freight Type',
  'addItem.text.expenseType': 'Expense Type',
  'addItem.text.shippingInstruction': 'Shipping Instruction',
  'addItem.text.jobTitle': 'Job Title',
  'addItem.text.taxIdentification': 'Tax Identification',
  'addItem.text.qualityCheck': 'Quality Check',
  'addItem.text.userStatus': 'User Status',
  'addItem.text.actualDeliveryDate': 'Actual Delivery Date',
  'addItem.text.incoterm': 'Incoterm',
  'addItem.text.salesComplaintType': 'Sales Complaint Type',
  'addItem.text.statusName': 'Status Name',
  'salesInvoice.cashSale.checkbox.text': ' Cash Sale',
  'salesInvoice.pricelist.placeholder': 'Select Price List',
  'salesInvoice.retailPrice.placeholder': 'Apply Retail Price',
  'salesInvoice.priceType.placeholder': 'Select Price Type',
  'salesInvoice.customer.placeholder': 'Select Customer',
  'salesInvoice.billingAddress.placeholder': 'Select Billing Address',
  'salesInvoice.currenctFormat.placeholder': 'Select Currency Format',
  'salesInvoice.project.placeholder': 'Select Projects',
  'salesInvoice.taxName.placeholder': 'Select Tax Name',
  'salesInvoice.expense.placeholder': 'Select Expense',
  'drawer.invoice.dropdown.placeholder': 'Select Price List',
  'drawer.invoice.cashPurchase': 'Cash Purchase',
  'drawer.invoice.invoiceDate': 'Invoice Date',
  'drawer.invoice.invoiceDueDate': 'Invoice Due Date',
  'drawer.invoice.projectDropdown': 'Project Ref',
  'drawer.invoice.dropdown.taxName': 'Tax Name',
  'drawer.invoice.dropdown.expense': 'Expense',
  'warehouse.dropdown.placeholder': 'Select Warehouse',
  'project.label': 'Project',
  'salesOrder.creation.date': 'SO Date',
  'sidebar.menuItem.dashboard': 'Dashboard',
  'sidebar.menuItem.inboundDelivery': 'Goods Receipt Note (GRN)',
  'sidebar.menuItem.purchase': 'Purchase',
  'sidebar.menuItem.purchaseRequest': 'Purchase Request',
  'sidebar.menuItem.RFQ': 'RFQ',
  'sidebar.menuItem.purchaseOrder': 'Purchase Order',
  'sidebar.menuItem.purchaseInvoice': 'Purchase Invoice',
  'sidebar.menuItem.payment': 'Payment',
  'sidebar.menuItem.debitMemo': 'Debit Memo',
  'sidebar.menuItem.expense': 'Expense',
  'sidebar.menuItem.sales': 'Sales',
  'sidebar.menuItem.salesQuote': 'Sales Quote',
  'sidebar.menuItem.salesOrder': 'Sales Order',
  'sidebar.menuItem.salesInvoice': 'Sales Invoice',
  'sidebar.menuItem.inquiry': 'Inquiry',
  'sidebar.menuItem.customerPayment': 'Payment',
  'sidebar.menuItem.creditMemo': 'Credit Memo',
  'sidebar.menuItem.salesSupport': 'Sales Support',
  'sidebar.menuItem.package': 'Package',
  'sidebar.menuItem.shipment': 'Shipment',
  'sidebar.menuItem.customerExpense': 'Expense',
  'sidebar.menuItem.customers': 'Customers',
  'sidebar.menuItem.suppliers': 'Suppliers',
  'sidebar.menuItem.inventory': 'Inventory',
  'sidebar.menuItem.products': 'Products',
  'sidebar.menuItem.warehouse': 'Warehouse',
  'sidebar.menuItem.myWarehouse': 'My Warehouse',
  'sidebar.menuItem.stockSummary': 'Stock Summary',
  'sidebar.menuItem.stockAdjustment': 'Stock Adjustment',
  'sidebar.menuItem.agedStock': 'Aged Stock',
  'sidebar.menuItem.productPrice': 'Product Price',
  'sidebar.menuItem.priceList': 'Price List',
  'sidebar.menuItem.extras': 'Extras',
  'sidebar.menuItem.category': 'Category',
  'sidebar.menuItem.manufactures': 'Manufactures',
  'sidebar.menuItem.brands': 'Brands',
  'sidebar.menuItem.uom': 'UOM',
  'sidebar.menuItem.finance': 'Finance',
  'sidebar.menuItem.leadgerAccounts': 'Ledger Accounts',
  'sidebar.menuItem.balanceSheet': 'Balance Sheet',
  'sidebar.menuItem.profitLoss': 'Profit & Loss',
  'sidebar.menuItem.trialBalance': 'Trial Balance',
  'sidebar.menuItem.openingBalance': 'Opening Balance',
  'sidebar.menuItem.journals': 'Journals',
  'sidebar.menuItem.businessExpense': 'Business Expense',
  'sidebar.menuItem.bankReconciliation': 'Bank Reconciliation',
  'sidebar.menuItem.taxes': 'Taxes',
  'sidebar.menuItem.company': 'Organization',
  'sidebar.menuItem.companyHierarcy': 'Organization Heirarchy',
  'sidebar.menuItem.employee': 'Employee',
  'sidebar.menuItem.financialYears': 'Financial Years',
  'sidebar.menuItem.costCenter': 'Cost Center',
  'sidebar.menuItem.reports': 'Reports',
  'sidebar.menuItem.settings': 'Settings',
  'sidebar.menuItem.lockScreen': 'Lock Screen',
  'sidebar.menuItem.integrations': 'Integrations',
  'sidebar.menuItem.salesChannels': 'Sales Channel',
  'sidebar.menuItem.publicProducts': 'Public Products',
  'sidebar.menuItem.payments': 'Payments',
  'sidebar.menuItem.shipping': 'Carriar',
  'sidebar.menuItem.accounting': 'Accounting',
  'header.menuItem.salesHead.label': 'Sales',
  'header.menuItem.customer.label': 'Customer',
  'header.menuItem.salesQuote.label': 'Sales Quote',
  'header.menuItem.salesInquiry.label': 'Inquiry',
  'header.menuItem.salesOrder.label': 'Sales Order',
  'header.menuItem.salesInvoice.label': 'Sales Invoice',
  'header.menuItem.creditMemo.label': 'Credit Memo',
  'header.menuItem.receivePayment.label': 'Receive Payment',
  'header.menuItem.purchaseHead.label': 'Purchase',
  'header.menuItem.supplier.label': 'Supplier',
  'header.menuItem.rfq.label': 'RFQ',
  'header.menuItem.purchaseRequest.label': 'Purchase Request',
  'header.menuItem.purchaseOrder.label': 'Purchase Order',
  'header.menuItem.purchaseInvoice.label': 'Purchase Invoice',
  'header.menuItem.debitMemo.label': 'Debit Memo',
  'header.menuItem.payInvoice.label': 'Pay Invoice',
  'header.menuItem.inventoryHead.label': 'Inventory',
  'header.menuItem.inventory.label': 'Inventory Item',
  'header.menuItem.priceList.label': 'Price List',
  'header.menuItem.category.label': 'Category',
  'header.menuItem.uom.label': 'UOM',
  'header.menuItem.brands.label': 'Brands',
  'header.menuItem.manufacturer.label': 'Manufacturer',
  'header.menuItem.warehouse.label': 'Warehouse',
  'header.menuItem.financeHead.label': 'Finance',
  'header.menuItem.ledgerAccount.label': 'Ledger Account',
  'header.menuItem.costCenter.label': 'Cost Center',
  'header.menuItem.tax.label': 'Tax',
  'header.menuItem.journal.label': 'Journal',

  'customer.listing.tiles.tatalOrders.label': 'TOTAL ORDERS',
  'customer.listing.tiles.openSalesOrder.label': 'OPEN SALES ORDER',
  'customer.listing.tiles.unpaidBalance.label': 'UNPAID BALANCE',
  'customer.listing.tiles.salesRevenue.label': 'SALES REVENUE',
  'customer.listing.header.customerDetails': 'Customer Details',
  'customer.listing.header.contact': 'Contact',
  'customer.listing.header.dueAmount': 'Due Amount',
  'customer.listing.header.openOrder': 'Open Order',
  'customer.listing.header.unpaidInvoices': 'Unpaid Invoices',
  'customer.listing.header.paidInvoices': 'Paid Invoices',
  'customer.listing.header.paymentTerm': 'Payment Term',
  'customer.listing.header.action': 'Action',
  'customer.listing.button.newCustomer.level': 'New Customer',
  'customer.listing.importCustomer.modal.title': 'Import Customer',
  'customer.listing.buttonMenu.importCustomer': 'Import Customer',

  'customer.listing.totalOrder.header.soNumber': 'SO #',
  'customer.listing.totalOrder.header.soDate': 'SO Date',
  'customer.listing.totalOrder.header.soAmount': 'SO Amount',
  // 'customer.listing.totalOrder.header.status': 'Status',
  'customer.listing.totalOrder.header.priority': 'Priority',
  'customer.listing.totalOrder.header.soPerson': 'SO Person',

  'customer.listing.openOrder.header.soNumber': 'SO #',
  'customer.listing.openOrder.header.soDate': 'SO Date',
  'customer.listing.openOrder.header.soAmount': 'SO Amount',
  // 'customer.listing.openOrder.header.status': 'Status',
  'customer.listing.openOrder.header.priority': 'Priority',
  'customer.listing.openOrder.header.soPerson': 'SO Person',

  'customer.listing.unPaidInvoice.header.invoiceNumber': 'Invoice #',
  'customer.listing.unPaidInvoice.header.amount': 'Invoice Amount',
  'customer.listing.unPaidInvoice.header.paymentAmount': 'Payment Amount',
  'customer.listing.unPaidInvoice.header.dueAmount': 'Due Amount',
  'customer.listing.unPaidInvoice.header.invoiceDate': 'Invoice Date',
  'customer.listing.unPaidInvoice.header.invoiceDueDate': 'Invoice Due Date',
  'customer.listing.unPaidInvoice.header.paymentTerm': 'Payment Term',
  // 'customer.listing.unPaidInvoice.header.status': 'Status',

  'customer.listing.paidInvoice.header.invoiceNumber': 'Invoice #',
  'customer.listing.paidInvoice.header.amount': 'Invoice Amount',
  'customer.listing.paidInvoice.header.paymentAmount': 'Payment Amount',
  'customer.listing.paidInvoice.header.invoiceDate': 'Invoice Date',
  // 'customer.listing.paidInvoice.header.status': 'Status',

  'customer.salesQuote.listing.header.sqNo': 'Quote #',
  'customer.salesQuote.listing.header.rfqNumber': 'RFQ #',
  'customer.salesQuote.listing.header.customer': 'Customer',
  'customer.salesQuote.listing.header.amount': 'Amount',
  'customer.salesQuote.listing.header.priority': 'Priority',
  'customer.salesQuote.listing.header.refNumber': 'Ref #',
  'customer.salesQuote.listing.header.date': 'Date',
  'customer.salesQuote.listing.header.systemStatus': 'System Status',
  'customer.salesQuote.listing.header.qtyOnSO': 'Qty on SO',
  'customer.salesQuote.listing.header.rfqExpirationDate': 'Bid Closing Date',
  'customer.salesQuote.listing.header.isInquiryConversion': 'Inquiry Conversion',
  'customer.salesQuote.listing.header.remarksInternal': 'Remarks (Internal)',
  'customer.salesQuote.listing.header.remarksCustomer': 'Remarks',

  'customer.salesOrder.listing.header.soNo': 'SO #',
  'customer.salesOrder.listing.header.customer': 'Customer',
  'customer.salesOrder.listing.header.quoteNumber': 'Quote #',
  'customer.salesOrder.listing.header.rfqNumber': 'RFQ #',
  'customer.salesOrder.listing.header.systemStatus': 'System Status',
  'customer.salesOrder.listing.header.amount': 'Amount',
  'customer.salesOrder.listing.header.refNumber': 'Ref #',
  'customer.salesOrder.listing.header.date': 'Date',
  'customer.salesOrder.listing.header.qtyInvoiced': 'Invoiced',
  'customer.salesOrder.listing.header.qtyPacked': 'Packed',
  'customer.salesOrder.listing.header.qtyShipped': 'Shipped',
  'customer.salesOrder.listing.header.qtyOnPR': 'Backorders',
  'customer.salesOrder.listing.header.qtyOnQuote': 'Qty on Quote',
  'customer.salesOrder.listing.header.deliveryDate': 'Delivery Date',
  // 'customer.salesOrder.listing.header.status': 'Status',
  'customer.salesOrder.listing.header.paymentTerm': 'Payment Term',

  'customer.package.listing.header.packageNo': 'Package #',
  'customer.package.listing.header.soNo': 'SO #',
  'customer.package.listing.header.customer': 'Customer',
  'customer.package.listing.header.date': 'Package Date',
  'customer.package.listing.header.qtyPacked': 'Qty Packed',
  'customer.package.listing.header.systemStatus': 'System Status',
  'customer.package.listing.header.qcStatus': 'QC Status',

  'customer.shipment.listing.header.shipmentNo': 'Shipment #',
  'customer.shipment.listing.header.carrierName': 'Carrier Name',
  'customer.shipment.listing.header.trackingNumber': 'Tracking Number',
  'customer.shipment.listing.header.shipmentDate': 'Shipment Date',
  'customer.shipment.listing.header.expectedDeliveryDate': 'Expected Delivery',
  'customer.shipment.listing.header.systemStatus': 'System Status',
  'customer.shipment.listing.header.qtyShipped': 'QTY Shipped',
  'customer.shipment.listing.header.stockUpdated': 'Stock Updated',
  'customer.shipment.listing.header.totalPackage': 'Total Package(s)',
  'customer.shipment.listing.header.comments': 'Comments',
  'customer.shipment.listing.header.consigneeName': 'Consignee Name',
  'customer.shipment.listing.header.actualDeliveyDate': 'Actual Delivery Date',
  'customer.shipment.listing.header.customerRemarks': 'Customer Remarks',
  'customer.shipment.listing.header.freightType': 'Freight Type',

  'customer.salesInvoice.listing.header.invoiceNo': 'Invoice #',
  'customer.salesInvoice.listing.header.soNo': 'SO #',
  'customer.salesInvoice.listing.header.customer': 'Customer',
  'customer.salesInvoice.listing.header.quoteNumber': 'Quote #',
  'customer.salesInvoice.listing.header.rfqNumber': 'RFQ #',
  'customer.salesInvoice.listing.header.invoiceAmount': 'Invoice Amount',
  'customer.salesInvoice.listing.header.paymentAmount': 'Paid',
  'customer.salesInvoice.listing.header.dueAmount': 'Due Amount',
  'customer.salesInvoice.listing.header.paymentTerm': 'Payment Term',
  'customer.salesInvoice.listing.header.invoiceDate': 'Invoice Date',
  'customer.salesInvoice.listing.header.invoiceDueDate': 'Due Date',
  'customer.salesInvoice.listing.header.systemStatus': 'System Status',
  'customer.salesInvoice.listing.header.action': 'Action',
  'customer.salesInvoice.listing.header.isCashInvoice': 'Cash Invoice',
  'customer.salesInvoice.listing.header.isProformaConversion': 'Invoice Converted',

  'customer.customerExpense.listing.header.expenseNo': 'Expense #',
  'customer.customerExpense.listing.header.customer': 'Customer',
  'customer.customerExpense.listing.header.refNumber': 'Ref #',
  'customer.customerExpense.listing.header.expenseDate': 'Expense Date',
  'customer.customerExpense.listing.header.amount': 'Amount',
  'customer.customerExpense.listing.header.systemStatus': 'System Status',

  'customer.creditMemo.listing.header.creditMemoNo': 'Credit Memo #',
  'customer.creditMemo.listing.header.customer': 'Customer',
  'customer.creditMemo.listing.header.transactionDate': 'Transaction Date',
  'customer.creditMemo.listing.header.amount': 'Amount',
  'customer.creditMemo.listing.header.systemStatus': 'System Status',

  'customer.customerPayment.listing.header.customer': 'Customer',
  'customer.customerPayment.listing.header.paymentNo': 'Payment #',
  'customer.customerPayment.listing.header.invoiceNo': 'Invoice #',
  'customer.customerPayment.listing.header.soNo': 'SO #',
  'customer.customerPayment.listing.header.quoteNumber': 'Quote #',
  'customer.customerPayment.listing.header.rfqNumber': 'RFQ #',
  'customer.customerPayment.listing.header.paymentAmount': 'Payment Amount',
  'customer.customerPayment.listing.header.paymentDate': 'Payment Date',
  'customer.customerPayment.listing.header.systemStatus': 'System Status',

  'supplier.listing.openOrder.header.poNumber': 'Purchase Order #',
  // 'supplier.listing.openOrder.header.status': 'Status',
  'supplier.listing.openOrder.header.poAmount': 'Purchase Order Amount',
  'supplier.listing.openOrder.header.poDate': 'Purchase Order Date',

  'supplier.listing.totalOrder.header.poNumber': 'Purchase Order #',
  // 'supplier.listing.totalOrder.header.status': 'Status',
  'supplier.listing.totalOrder.header.poAmount': 'Purchase Order Amount',
  'supplier.listing.totalOrder.header.poDate': 'Purchase Order Date',

  'supplier.listing.unPaidInvoice.header.invoiceNumber': 'Invoice #',
  'supplier.listing.unPaidInvoice.header.amount': 'Invoice Amount',
  'supplier.listing.unPaidInvoice.header.paymentAmount': 'Payment Amount',
  'supplier.listing.unPaidInvoice.header.dueAmount': 'Due Amount',
  'supplier.listing.unPaidInvoice.header.invoiceDate': 'Invoice Date',
  'supplier.listing.unPaidInvoice.header.invoiceDueDate': 'Invoice Due Date',
  'supplier.listing.unPaidInvoice.header.paymentTerm': 'Payment Term',
  // 'supplier.listing.unPaidInvoice.header.status': 'Status',

  'supplier.listing.paidInvoice.header.invoiceNumber': 'Invoice #',
  'supplier.listing.paidInvoice.header.amount': 'Invoice Amount',
  'supplier.listing.paidInvoice.header.paymentAmount': 'Payment Amount',
  'supplier.listing.paidInvoice.header.dueAmount': 'Due Amount',
  'supplier.listing.paidInvoice.header.invoiceDate': 'Invoice Date',
  'supplier.listing.paidInvoice.header.invoiceDueDate': 'Invoice Due Date',
  'supplier.listing.paidInvoice.header.paymentTerm': 'Payment Term',
  // 'supplier.listing.paidInvoice.header.status': 'Status',

  'supplier.debitMemo.listing.header.debitMemoNo': 'Debit Memo #',
  'supplier.debitMemo.listing.header.supplier': 'Supplier',
  'supplier.debitMemo.listing.header.date': 'Date',
  'supplier.debitMemo.listing.header.amount': 'Amount',
  'supplier.debitMemo.listing.header.systemStatus': 'System Status',

  'supplier.supplierExpense.listing.header.expenseNo': 'Expense #',
  'supplier.supplierExpense.listing.header.supplier': 'Supplier',
  'supplier.supplierExpense.listing.header.refNumber': 'Ref #',
  'supplier.supplierExpense.listing.header.expenseDate': 'Expense Date',
  'supplier.supplierExpense.listing.header.amount': 'Amount',
  'supplier.supplierExpense.listing.header.systemStatus': 'System Status',

  'supplier.purchaseInvoice.listing.header.invoiceNo': 'Invoice #',
  'supplier.purchaseInvoice.listing.header.poNo': 'PO #',
  'customer.purchaseInvoice.listing.header.rfqNumber': 'RFQ #',
  'supplier.purchaseInvoice.listing.header.supplier': 'Supplier',
  'supplier.purchaseInvoice.listing.header.invoiceAmount': 'Invoice Amount',
  'supplier.purchaseInvoice.listing.header.paymentAmount': 'Paid',
  'supplier.purchaseInvoice.listing.header.dueAmount': 'Due Amount',
  'supplier.purchaseInvoice.listing.header.paymentTerm': 'Payment Term',
  'supplier.purchaseInvoice.listing.header.invoiceDate': 'Invoice Date',
  'supplier.purchaseInvoice.listing.header.invoiceDueDate': 'Due Date',
  'supplier.purchaseInvoice.listing.header.systemStatus': 'System Status',
  'supplier.purchaseInvoice.listing.header.action': 'Action',

  'supplier.listing.tiles.tatalOrders.label': 'TOTAL ORDERS',
  'supplier.listing.tiles.openSalesOrder.label': 'OPEN SALES ORDER',
  'supplier.listing.tiles.unpaidBalance.label': 'UNPAID BALANCE',
  'supplier.listing.tiles.salesRevenue.label': 'SALES REVENUE',
  'supplier.listing.header.supplierDetails': 'Supplier Details',
  'supplier.listing.header.contact': 'Contact',
  'supplier.listing.header.dueAmount': 'Due Amount',
  'supplier.listing.header.openOrder': 'Open Order',
  'supplier.listing.header.unpaidInvoices': 'Unpaid Invoices',
  'supplier.listing.header.paidInvoices': 'Paid Invoices',
  'supplier.listing.header.paymentTerm': 'Payment Term',
  'supplier.listing.header.action': 'Action',
  'supplier.listing.button.newSupplier.level': 'New Supplier',
  'supplier.listing.buttonMenu.importSuppliers': 'Import Suppliers',
  'supplier.listing.importSuppliers.modal.title': 'Import Suppliers',

  'supplier.supplierPayment.listing.header.supplier': 'Supplier',
  'supplier.supplierPayment.listing.header.paymentNo': 'Payment #',
  'supplier.supplierPayment.listing.header.invoiceNo': 'Invoice #',
  'supplier.supplierPayment.listing.header.poNo': 'PO #',
  'customer.supplierPayment.listing.header.rfqNumber': 'RFQ #',
  'supplier.supplierPayment.listing.header.paymentAmount': 'paymentAmount',
  'supplier.supplierPayment.listing.header.invoiceAmount': 'Invoice Amount',
  'supplier.supplierPayment.listing.header.paymentDate': 'Payment Date',
  'supplier.supplierPayment.listing.header.systemStatus': 'System Status',

  'supplier.purchaseOrder.listing.header.poNo': 'PO #',
  'supplier.purchaseOrder.listing.header.qtyRecieved': 'Received',
  'supplier.purchaseOrder.listing.header.supplier': 'Supplier',
  'supplier.purchaseOrder.listing.header.amount': 'Amount',
  'supplier.purchaseOrder.listing.header.refNumber': 'Ref #',
  'supplier.purchaseOrder.listing.header.projectName': 'Project Name',
  'supplier.purchaseOrder.listing.header.rfqConversion': 'Is RFQ Conversion',
  'supplier.purchaseOrder.listing.header.rfqNumber': 'RFQ #',
  'supplier.purchaseOrder.listing.header.expectedDeliveryDate': 'Expected Delivery',
  'supplier.purchaseOrder.listing.header.date': 'Date',
  'supplier.purchaseOrder.listing.header.paymentTerm': 'Payment Term',
  'supplier.purchaseOrder.listing.header.systemStatus': 'System Status',
  'supplier.purchaseOrder.listing.header.qtyInvoiced': 'Invoiced',

  'supplier.purchaseRequest.listing.header.purchaseRequestNo': 'Purchase Request #',
  'supplier.purchaseRequest.listing.header.requester': 'Requester',
  'supplier.purchaseRequest.listing.header.orderPriority': 'Priority',
  'supplier.purchaseRequest.listing.header.department': 'Department',
  'supplier.purchaseRequest.listing.header.date': 'Date',
  'supplier.purchaseRequest.listing.header.expirationDate': 'Expiration Date',
  'supplier.purchaseRequest.listing.header.reason': 'Reason',
  'supplier.purchaseRequest.listing.header.owner': 'Owner',
  'supplier.purchaseRequest.listing.header.systemStatus': 'System Status',
  'supplier.purchaseRequest.listing.header.qtyOnRFQ': 'Qty on RFQ',
  'supplier.purchaseRequest.listing.header.qtyOnPO': 'Qty on PO',

  'supplier.rfq.listing.header.rfqNo': 'RFQ #',
  'supplier.rfq.listing.header.rfqDate': 'RFQ Date',
  'supplier.rfq.listing.header.supplier': 'Supplier',
  'supplier.rfq.listing.header.priority': 'Priority',
  'supplier.rfq.listing.header.prNumber': 'PR #',
  'supplier.rfq.listing.header.prDate': 'PR Date',
  'supplier.rfq.listing.header.prExpirationDate': 'PR Expiration Date',
  'supplier.rfq.listing.header.requestingDepartment': 'Requesting Department',
  'supplier.rfq.listing.header.project': 'Project',
  'supplier.rfq.listing.header.systemStatus': 'System Status',

  'modal.common.warehouse.warehouseName': 'Warehouse Name',

  'modal.customer.payInvoice.paymentNumber': 'Payment #',
  'modal.customer.payInvoice.selectCustomer': 'Customer',
  'modal.customer.payInvoice.customerName': 'Customer Name',
  'modal.customer.payInvoice.paymentDate': 'Payment Date',
  'modal.customer.payInvoice.invoiceNumber': 'Invoice #',
  'modal.customer.payInvoice.paymentMode': 'Payment Mode',
  'modal.customer.payInvoice.paymentModeDetails': 'Payment Mode Details',
  'modal.customer.payInvoice.bankAccount': 'Bank Account',
  'modal.customer.payInvoice.amount': 'Amount',
  'modal.customer.payInvoice.customerValidation': 'Customer is required',
  'modal.customer.payInvoice.paymentDateValidation': 'Payment date is required',
  'modal.customer.payInvoice.invoiceValidation': 'Invoice is required',
  'modal.customer.payInvoice.amountValidation': 'Payment amount is required',
  'modal.customer.payInvoice.amountOverValidation': 'Payment amount can not be more than the due amount',
  'modal.customer.payInvoice.invoiceAmount': 'Invoice Amount',
  'modal.customer.payInvoice.invoiceAmountDue': 'Invoice Amount Due',
  'modal.customer.payInvoice.remarks': 'Remarks',
  'modal.customer.payInvoice.project': 'Project',
  'modal.customer.payInvoice.saveConfirmation': 'Are you sure you want to save payment?',

  'modal.customer.payInvoice.selectCustomer.placeholder': 'Select Customer',
  'modal.customer.payInvoice.paymentDate.placeholder': 'Payment Date',
  'modal.customer.payInvoice.invoiceNumber.placeholder': 'Select Invoice',
  'modal.customer.payInvoice.paymentMode.placeholder': 'Select Payment Mode',
  'modal.customer.payInvoice.paymentModeDetails.placeholder': 'Enter Payment Mode Details',
  'modal.customer.payInvoice.placeholder': 'Enter Amount',
  'modal.customer.payInvoice.remarks.placeholder': 'Remarks',
  'modal.customer.payInvoice.bankAccount.placeholder': 'Select Bank Account',

  'modal.supplier.payInvoice.paymentNumber': 'Payment #',
  'modal.supplier.payInvoice.selectSupplier': 'Select Supplier',
  'modal.supplier.payInvoice.supplierName': 'Supplier Name',
  'modal.supplier.payInvoice.paymentDate': 'Payment Date',
  'modal.supplier.payInvoice.invoiceNumber': 'Invoice #',
  'modal.supplier.payInvoice.paymentMode': 'Payment Mode',
  'modal.supplier.payInvoice.paymentModeDetails': 'Payment Mode Details',
  'modal.supplier.payInvoice.bankAccount': 'Bank Account',
  'modal.supplier.payInvoice.amount': 'Amount',
  'modal.supplier.payInvoice.invoiceAmount': 'Invoice Amount',
  'modal.supplier.payInvoice.invoiceAmountDue': 'Invoice Amount Due',
  'modal.supplier.payInvoice.remarks': 'Remarks',
  'modal.supplier.payInvoice.project': 'Project',
  'modal.supplier.payInvoice.saveConfirmation': 'Are you sure you want to save payment?',

  'modal.supplier.payInvoice.selectSupplier.placeholder': 'Select Supplier',
  'modal.supplier.payInvoice.paymentDate.placeholder': 'Payment Date',
  'modal.supplier.payInvoice.invoiceNumber.placeholder': 'Select Invoice',
  'modal.supplier.payInvoice.paymentMode.placeholder': 'Select Payment Mode',
  'modal.supplier.payInvoice.paymentModeDetails.placeholder': 'Enter Payment Mode Details',
  'modal.supplier.payInvoice.placeholder': 'Enter Amount',
  'modal.supplier.payInvoice.remarks.placeholder': 'Remarks',
  'modal.supplier.payInvoice.bankAccount.placeholder': 'Select Bank Account',
  'modal.supplier.payInvoice.supplierValidation': 'Supplier is required',
  'modal.supplier.payInvoice.paymentDateValidation': 'Payment date is required',
  'modal.supplier.payInvoice.invoiceValidation': 'Invoice is required',
  'modal.supplier.payInvoice.amountValidation': 'Payment amount is required',
  'modal.supplier.payInvoice.amountOverValidation': 'Payment amount can not be more than the due amount',

  'modal.ImportSupplier.industry.placeholder': 'Select Industry',
  'modal.ImportSupplier.supplierType.placeholder': 'Select Supplier Type',
  'modal.ImportSupplier.supplierSources.placeholder': 'Select Supplier Source',
  'modal.ImportSupplier.paymentTerm.placeholder': 'Select Payment Term',

  'product.listing.outOfStock.header.productDetails': 'Product Details',
  'product.listing.outOfStock.header.variant': 'Variant',
  'product.listing.outOfStock.header.sku': 'SKU',
  'product.listing.outOfStock.header.category': 'Category',
  'product.listing.outOfStock.header.warehouse': 'Warehouse',
  'product.listing.outOfStock.header.currentStock': 'Current Stock',

  'product.listing.reorderStock.header.productDetails': 'Product Details',
  'product.listing.reorderStock.header.variant': 'Variant',
  'product.listing.reorderStock.header.sku': 'SKU',
  'product.listing.reorderStock.header.category': 'Category',
  'product.listing.reorderStock.header.warehouse': 'Warehouse',
  'product.listing.reorderStock.header.currentStock': 'Current Stock',
  'product.listing.reorderStock.header.reorderStock': 'Reorder Stock',

  'product.listing.lowStock.header.productDetails': 'Product Details',
  'product.listing.lowStock.header.variant': 'Variant',
  'product.listing.lowStock.header.sku': 'SKU',
  'product.listing.lowStock.header.category': 'Category',
  'product.listing.lowStock.header.warehouse': 'Warehouse',
  'product.listing.lowStock.header.currentStock': 'Current Stock',
  'product.listing.lowStock.header.lowStock': 'Low Stock',

  'product.listing.buttonMenu.importProducts': 'Import Products',

  'package.packageDetail.soNo': 'SO #',
  'package.packageDetail.poNo': 'PO #',
  'package.packageDetail.refNo': 'Ref #',
  'package.packageDetail.incoterm': 'Incoterm',
  'package.packageDetail.packedBy': 'Packed By',
  'package.packageDetail.packageDate': 'Package Date',
  'package.productDetail.product': 'Product',
  'package.productDetail.variant': 'Variant',
  'package.productDetail.description': 'Description',
  'package.productDetail.uom': 'UOM',
  'package.productDetail.qtyOrdered': 'Qty Ordered',
  'package.productDetail.qtyPacked': 'Qty Packed',
  'package.productDetail.qtyToPack': 'Qty to Pack',
  'package.productDetail.action': 'Action',
  'package.productDetail.length': 'length',
  'package.productDetail.width': 'Width',
  'package.productDetail.height': 'Height',
  'package.productDetail.weight': 'Weight',

  'supplier.debitMemo.form.confirmation': 'Are you sure you want to create debit memo?',
  'supplier.debitMemo.form.gridHeader.product': 'Product',
  'supplier.debitMemo.form.gridHeader.description': 'Description',
  'supplier.debitMemo.form.gridHeader.qty': 'QTY',
  'supplier.debitMemo.form.gridHeader.uom': 'UOM',
  'supplier.debitMemo.form.gridHeader.hsn': 'HSN/SAC',
  'supplier.debitMemo.form.gridHeader.rate': 'Rate',
  'supplier.debitMemo.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'supplier.debitMemo.form.gridHeader.discount': 'Discount (%)',
  'supplier.debitMemo.form.gridHeader.tax': 'Tax (%)',
  'supplier.debitMemo.form.gridHeader.amount': 'Amount ',
  'supplier.debitMemo.form.gridHeader.action': 'Action',
  'supplier.debitMemo.form.button.addLine': 'Add Line',
  'supplier.debitMemo.form.button.clearAllLines': 'Clear All Lines',
  'supplier.debitMemo.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'supplier.debitMemo.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.debitMemo.form.supplierRemarks.label': 'Supplier Remarks',
  'supplier.debitMemo.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.debitMemo.form.checkbox.discount': 'Discount',
  'supplier.debitMemo.form.checkbox.tax': 'Tax',
  'supplier.debitMemo.form.checkbox.expense': 'Expense',
  'supplier.debitMemo.form.totalDiscount.label': 'Discount',
  'supplier.debitMemo.form.totalTax.label': 'Tax',
  'supplier.debitMemo.form.totalExpense.label': 'Expense',
  'supplier.debitMemo.form.totalExpenseAmount.label': 'Expense Amount',
  'supplier.debitMemo.form.subTotal.label': 'Sub Total',
  'supplier.debitMemo.form.totalAmount.label': 'Total Amount',
  'supplier.debitMemo.form.supplier.label': 'Supplier',
  'supplier.debitMemo.form.supplier.placeholder': 'Select Supplier',
  'supplier.debitMemo.form.supplierName.label': 'Supplier Name',
  'supplier.debitMemo.form.gstnIn.label': 'GSTIN',
  'supplier.debitMemo.form.pan.label': 'PAN',
  'supplier.debitMemo.form.phone.label': 'Ph',
  'supplier.debitMemo.form.email.label': 'Email',
  'supplier.debitMemo.form.debitMemoNumber.label': 'Debit Memo #',
  'supplier.debitMemo.form.debitMemoDate.label': 'Debit Memo Date',
  'supplier.debitMemo.form.updateInventory.label': 'Update Inventory',

  'supplier.purchaseOrder.form.confirmation': 'Are you sure you want to create purchase order?',
  'supplier.purchaseOrder.form.priceListDropdown.placeholder': 'Select Price List',
  'supplier.purchaseOrder.form.gridHeader.placeOfSupply': 'Place of Supply',
  'supplier.purchaseOrder.form.gridHeader.refNumber': 'Ref #',
  'supplier.purchaseOrder.form.gridHeader.shippingAddress': 'Shipping Address',
  'supplier.purchaseOrder.form.gridHeader.paymentTerms': 'Payment Terms',
  'supplier.purchaseOrder.form.gridHeader.product': 'Product',
  'supplier.purchaseOrder.form.gridHeader.description': 'Description',
  'supplier.purchaseOrder.form.gridHeader.qty': 'QTY',
  'supplier.purchaseOrder.form.gridHeader.uom': 'UOM',
  'supplier.purchaseOrder.form.gridHeader.hsn': 'HSN/SAC',
  'supplier.purchaseOrder.form.gridHeader.rate': 'Rate',
  'supplier.purchaseOrder.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'supplier.purchaseOrder.form.gridHeader.discount': 'Discount (%)',
  'supplier.purchaseOrder.form.gridHeader.tax': 'Tax (%)',
  'supplier.purchaseOrder.form.gridHeader.amount': 'Amount ',
  'supplier.purchaseOrder.form.gridHeader.action': 'Action',
  'supplier.purchaseOrder.form.button.addLine': 'Add Line',
  'supplier.purchaseOrder.form.button.clearAllLines': 'Clear All Lines',
  'supplier.purchaseOrder.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'supplier.purchaseOrder.form.shippingInstructions.label': 'Shipping Instructions',
  'supplier.purchaseOrder.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseOrder.form.supplierRemarks.label': 'Supplier Remarks',
  'supplier.purchaseOrder.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseOrder.form.checkbox.discount': 'Discount',
  'supplier.purchaseOrder.form.checkbox.tax': 'Tax',
  'supplier.purchaseOrder.form.checkbox.expense': 'Expense',
  'supplier.purchaseOrder.form.totalDiscount.label': 'Discount',
  'supplier.purchaseOrder.form.totalTax.label': 'Tax',
  'supplier.purchaseOrder.form.totalExpense.label': 'Expense',
  'supplier.purchaseOrder.form.totalExpenseAmount.label': 'Expense Amount',
  'supplier.purchaseOrder.form.subTotal.label': 'Sub Total',
  'supplier.purchaseOrder.form.totalAmount.label': 'Total Amount',
  'supplier.purchaseOrder.form.supplier.label': 'Supplier',
  'supplier.purchaseOrder.form.supplierName.label': 'Supplier Name',
  'supplier.purchaseOrder.form.supplierName.placeholder': 'Select Supplier',
  'supplier.purchaseOrder.form.gstnIn.label': 'GSTIN',
  'supplier.purchaseOrder.form.pan.label': 'PAN',
  'supplier.purchaseOrder.form.phone.label': 'Ph',
  'supplier.purchaseOrder.form.email.label': 'Email',
  'supplier.purchaseOrder.form.poNumber.label': 'PO #',
  'supplier.purchaseOrder.form.rfqNumber.label': 'RFQ #',
  'supplier.purchaseOrder.form.poDate.label': 'PO Date',
  'supplier.purchaseOrder.form.poDueDate.label': 'PO Due Date',
  'supplier.purchaseOrder.form.expectedDeliveryDate.label': 'Expected Delivery',
  'supplier.purchaseOrder.form.projectDropdown.label': 'Project',

  'supplier.inboundDelivery.form.confirmation': 'Are you sure you want to create Goods Receipt Note?',

  'supplier.rfq.form.confirmation': 'Are you sure you want to create RFQ?',
  'supplier.rfq.form.customer.label': 'Customer',
  'supplier.rfq.form.customer.placeholder': 'Select Customer',
  'supplier.rfq.form.rfqNumber.label': 'RFQ #',
  'supplier.rfq.form.prNumber.label': 'PR #',
  'supplier.rfq.form.gridHeader.supplierQuoteNumber': 'Supplier Quote #',
  'supplier.rfq.form.gridHeader.customerRFQNumber': 'Customer RFQ #',
  'supplier.rfq.form.gridHeader.refNumber': 'Ref #',
  'supplier.rfq.form.gridHeader.requestingDepartment': 'Requesting Department',
  'supplier.rfq.form.gridHeader.project': 'Project',
  'supplier.rfq.form.gridHeader.purchaseType': 'Purchase Type',
  'supplier.rfq.form.gridHeader.prRequestedBy': 'Requester',
  'supplier.rfq.form.gridHeader.salesPerson': 'Sales Person',
  'supplier.rfq.form.gridHeader.priority': 'Priority',
  'supplier.rfq.form.gridHeader.product': 'Product',
  'supplier.rfq.form.gridHeader.description': 'Description',
  'supplier.rfq.form.gridHeader.qty': 'QTY',
  'supplier.rfq.form.gridHeader.uom': 'UOM',
  'supplier.rfq.form.gridHeader.action': 'Action',
  'supplier.rfq.form.button.addLine': 'Add Line',
  'supplier.rfq.form.button.clearAllLines': 'Clear All Lines',
  'supplier.rfq.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'supplier.rfq.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.rfq.form.supplierRemarks.label': 'Remarks',
  'supplier.rfq.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.rfq.form.checkbox.discount': 'Discount',
  'supplier.rfq.form.checkbox.tax': 'Tax',
  'supplier.rfq.form.checkbox.expense': 'Expense',
  'supplier.rfq.form.totalDiscount.label': 'Discount',
  'supplier.rfq.form.totalTax.label': 'Tax',
  'supplier.rfq.form.totalExpense.label': 'Expense',
  'supplier.rfq.form.totalExpenseAmount.label': 'Expense Amount',
  'supplier.rfq.form.expenseAmountDue.label': 'Expense Amount Due',
  'supplier.rfq.form.subTotal.label': 'Sub Total',
  'supplier.rfq.form.totalAmount.label': 'Total Amount',
  'supplier.rfq.form.supplier.label': 'Supplier',
  'supplier.rfq.form.supplierName.label': 'Supplier Name',
  'supplier.rfq.form.supplierName.placeholder': 'Select Supplier',
  'supplier.rfq.form.gstnIn.label': 'GSTIN',
  'supplier.rfq.form.pan.label': 'PAN',
  'supplier.rfq.form.companyName.label': 'Organization',
  'supplier.rfq.form.contact.label': 'Contact',
  'supplier.rfq.form.billTo.label': 'Bill To',


  'customer.salesEnquiry.form.confirmation': 'Are you sure you want to create Inquiry?',
  'customer.salesEnquiry.form.customer.label': 'Customer',
  'customer.salesEnquiry.form.customer.placeholder': 'Select Customer',
  'customer.salesEnquiry.form.salesEnquiryNumber.label': 'Inquiry #',
  'customer.salesEnquiry.form.prNumber.label': 'PR #',
  'customer.salesEnquiry.form.gridHeader.customerSalesEnquiryNumber': 'Customer Inquiry #',
  'customer.salesEnquiry.form.gridHeader.refNumber': 'Ref #',
  'customer.salesEnquiry.form.gridHeader.requestingDepartment': 'Requesting Department',
  'customer.salesEnquiry.form.gridHeader.project': 'Project',
  'customer.salesEnquiry.form.gridHeader.purchaseType': 'Purchase Type',
  'customer.salesEnquiry.form.gridHeader.prRequestedBy': 'Requester',
  'customer.salesEnquiry.form.gridHeader.salesPerson': 'Sales Person',
  'customer.salesEnquiry.form.gridHeader.priority': 'Priority',
  'customer.salesEnquiry.form.gridHeader.product': 'Product',
  'customer.salesEnquiry.form.gridHeader.description': 'Description',
  'customer.salesEnquiry.form.gridHeader.qty': 'QTY',
  'customer.salesEnquiry.form.gridHeader.uom': 'UOM',
  'customer.salesEnquiry.form.gridHeader.action': 'Action',
  'customer.salesEnquiry.form.button.addLine': 'Add Line',
  'customer.salesEnquiry.form.button.clearAllLines': 'Clear All Lines',
  'customer.salesEnquiry.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.salesEnquiry.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'customer.salesEnquiry.form.supplierRemarks.label': 'Remarks',
  'customer.salesEnquiry.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'customer.salesEnquiry.form.checkbox.discount': 'Discount',
  'customer.salesEnquiry.form.checkbox.tax': 'Tax',
  'customer.salesEnquiry.form.checkbox.expense': 'Expense',
  'customer.salesEnquiry.form.totalDiscount.label': 'Discount',
  'customer.salesEnquiry.form.totalTax.label': 'Tax',
  'customer.salesEnquiry.form.totalExpense.label': 'Expense',
  'customer.salesEnquiry.form.totalExpenseAmount.label': 'Expense Amount',
  'customer.salesEnquiry.form.subTotal.label': 'Sub Total',
  'customer.salesEnquiry.form.totalAmount.label': 'Total Amount',
  'customer.salesEnquiry.form.supplier.label': 'Supplier',
  'customer.salesEnquiry.form.supplierName.label': 'Supplier Name',
  'customer.salesEnquiry.form.supplierName.placeholder': 'Select Supplier',
  'customer.salesEnquiry.form.gstnIn.label': 'GSTIN',
  'customer.salesEnquiry.form.pan.label': 'PAN',
  'customer.salesEnquiry.form.companyName.label': 'Organization',
  'customer.salesEnquiry.form.contact.label': 'Contact',
  'customer.salesEnquiry.form.billTo.label': 'Bill To',
  'customer.form.taxIdentification.label': 'Tax Id',

  'supplier.purchaseRequest.form.confirmation': 'Are you sure you want to create purchase request?',
  'supplier.purchaseRequest.form.gridHeader.product': 'Product',
  'supplier.purchaseRequest.form.gridHeader.description': 'Description',
  'supplier.purchaseRequest.form.gridHeader.qty': 'QTY',
  'supplier.purchaseRequest.form.gridHeader.uom': 'UOM',
  'supplier.purchaseRequest.form.gridHeader.action': 'Action',
  'supplier.purchaseRequest.form.button.addLine': 'Add Line',
  'supplier.purchaseRequest.form.button.clearAllLines': 'Clear All Lines',
  'supplier.purchaseRequest.form.remarks.label': 'Remarks (For Internal Use)',
  'supplier.purchaseRequest.form.remarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseRequest.form.requestor.label': 'Requestor',
  'supplier.purchaseRequest.form.department.label': 'Department',
  // 'supplier.purchaseRequest.form.status.label': 'Status',
  'supplier.purchaseRequest.form.owner.label': 'Owner',
  'supplier.purchaseRequest.form.purchaseRequestNumber.label': 'Purchase Request #',
  'supplier.purchaseRequest.form.purchaseRequestDate.label': 'Purchase Request Date',
  'supplier.purchaseRequest.form.expirationDate.label': 'Expiration Date',
  'supplier.purchaseRequest.form.sendEmail.label': 'Email if RFQ/PO is Created',
  'supplier.purchaseRequest.form.reason.label': 'Reason',
  'supplier.purchaseRequest.form.priority.label': 'Priority',
  'supplier.purchaseRequest.form.refNumber.label': 'Ref #',
  'supplier.purchaseRequest.form.refNumber.placeholder': 'Enter Ref #',
  'supplier.purchaseRequest.form.projectDropdown.label': 'Project',
  'supplier.purchaseRequest.form.projectDropdown.placeholder': 'Select Project',

  'supplier.purchaseinvoice.form.confirmation': 'Are you sure you want to create purchase invoice?',
  'supplier.purchaseinvoice.form.cashPurchase.label': 'Cash Purchase',
  'supplier.purchaseinvoice.form.priceListDropdown.placeholder': 'Select Price List',
  'supplier.purchaseinvoice.form.gridHeader.placeOfSupply': 'Place of Supply',
  'supplier.purchaseOrder.form.gridHeader.supplierInvoiceNumber': 'Supplier Invoice #',
  'supplier.purchaseinvoice.form.gridHeader.refNumber': 'Ref #',
  'supplier.purchaseinvoice.form.gridHeader.shippingAddress': 'Shipping Address',
  'supplier.purchaseinvoice.form.gridHeader.paymentTerms': 'Payment Terms',
  'supplier.purchaseinvoice.form.gridHeader.product': 'Product',
  'supplier.purchaseinvoice.form.gridHeader.description': 'Description',
  'supplier.purchaseinvoice.form.gridHeader.qty': 'QTY',
  'supplier.purchaseinvoice.form.gridHeader.uom': 'UOM',
  'supplier.purchaseinvoice.form.gridHeader.hsn': 'HSN/SAC',
  'supplier.purchaseinvoice.form.gridHeader.rate': 'Rate',
  'supplier.purchaseinvoice.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'supplier.purchaseinvoice.form.gridHeader.discount': 'Discount (%)',
  'supplier.purchaseinvoice.form.gridHeader.tax': 'Tax (%)',
  'supplier.purchaseinvoice.form.gridHeader.amount': 'Amount ',
  'supplier.purchaseinvoice.form.gridHeader.action': 'Action',
  'supplier.purchaseinvoice.form.button.addLine': 'Add Line',
  'supplier.purchaseinvoice.form.button.clearAllLines': 'Clear All Lines',
  'supplier.purchaseinvoice.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'supplier.purchaseinvoice.form.internalRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseinvoice.form.supplierRemarks.label': 'Remarks',
  'supplier.purchaseinvoice.form.supplierRemarks.placeholder': 'Will not be visible to the supplier. For internal purpose only.',
  'supplier.purchaseinvoice.form.checkbox.discount': 'Discount',
  'supplier.purchaseinvoice.form.checkbox.tax': 'Tax',
  'supplier.purchaseinvoice.form.checkbox.expense': 'Expense',
  'supplier.purchaseinvoice.form.totalDiscount.label': 'Discount',
  'supplier.purchaseinvoice.form.totalTax.label': 'Tax',
  'supplier.purchaseinvoice.form.totalExpense.label': 'Expense',
  'supplier.purchaseinvoice.form.totalExpenseAmount.label': 'Expense Amount',
  'supplier.purchaseinvoice.form.subTotal.label': 'Sub Total',
  'supplier.purchaseinvoice.form.totalAmount.label': 'Total Amount',
  'supplier.purchaseinvoice.form.supplier.label': 'Supplier',
  'supplier.purchaseinvoice.form.supplierName.label': 'Supplier Name',
  'supplier.purchaseinvoice.form.supplierName.placeholder': 'Select Supplier',
  'supplier.purchaseinvoice.form.gstnIn.label': 'GSTIN',
  'supplier.purchaseinvoice.form.pan.label': 'PAN',
  'supplier.purchaseinvoice.form.phone.label': 'Ph',
  'supplier.purchaseinvoice.form.email.label': 'Email',
  'supplier.purchaseinvoice.form.invoiceNumber.label': 'Invoice #',
  'supplier.purchaseinvoice.form.poNumber.label': 'PO #',
  'supplier.purchaseinvoice.form.idNumber.label': 'GRN #',
  'supplier.purchaseinvoice.form.invoiceDate.label': 'Invoice Date',
  'supplier.purchaseinvoice.form.invoiceDueDate.label': 'Invoice Due Date',
  'supplier.purchaseinvoice.form.projectDropdown.label': 'Project',

  'customer.creditMemo.form.confirmation': 'Are you sure you want to create credit memo?',
  'customer.creditMemo.form.gridHeader.product': 'Product',
  'customer.creditMemo.form.gridHeader.description': 'Description',
  'customer.creditMemo.form.gridHeader.qty': 'QTY',
  'customer.creditMemo.form.gridHeader.uom': 'UOM',
  'customer.creditMemo.form.gridHeader.hsn': 'HSN/SAC',
  'customer.creditMemo.form.gridHeader.rate': 'Rate',
  'customer.creditMemo.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'customer.creditMemo.form.gridHeader.discount': 'Discount (%)',
  'customer.creditMemo.form.gridHeader.tax': 'Tax (%)',
  'customer.creditMemo.form.gridHeader.amount': 'Amount ',
  'customer.creditMemo.form.gridHeader.action': 'Action',
  'customer.creditMemo.form.button.addLine': 'Add Line',
  'customer.creditMemo.form.button.clearAllLines': 'Clear All Lines',
  'customer.creditMemo.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.creditMemo.form.internalRemarks.placeholder': 'Will not be visible to the customer. For internal purpose only.',
  'customer.creditMemo.form.customerRemarks.label': 'Customer Remarks',
  'customer.creditMemo.form.customerRemarks.placeholder': 'Thanks for your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.',
  'customer.creditMemo.form.checkbox.costCenter': 'Cost Center',
  'customer.creditMemo.form.totalDiscount.label': 'Discount',
  'customer.creditMemo.form.costCenter.label': 'Cost Center',
  'customer.creditMemo.form.addCostCenter.label': 'Add Cost Center',
  'customer.creditMemo.form.subTotal.label': 'Sub Total',
  'customer.creditMemo.form.totalAmount.label': 'Total Amount',
  'customer.creditMemo.form.customer.label': 'Customer',
  'customer.creditMemo.form.customerName.label': 'Customer Name',
  'customer.creditMemo.form.customer.placeholder': 'Select Customer',
  'customer.creditMemo.form.priceList.placeholder': 'Select Price List',
  'customer.creditMemo.form.gstnIn.label': 'GSTIN',
  'customer.creditMemo.form.pan.label': 'PAN',
  'customer.creditMemo.form.phone.label': 'Ph',
  'customer.creditMemo.form.email.label': 'Email',
  'customer.creditMemo.form.creditMemoNumber.label': 'Credit Memo #',
  'customer.creditMemo.form.creditMemoDate.label': 'Credit Memo Date',
  'customer.creditMemo.form.creditMemoExpiry.label': 'Credit Memo Expiry',
  'customer.creditMemo.form.updateInventory.label': 'Update Inventory',

  'customer.salesInvoice.form.confirmation': 'Are you sure you want to create sales Invoice?',
  'customer.salesInvoice.form.priceListDropdown.placeholder': 'Select Price List',
  'customer.salesInvoice.form.gridHeader.placeOfSupply': 'Place of Supply',
  'customer.salesInvoice.form.gridHeader.customerPO': 'Customer PO #',
  'customer.salesInvoice.form.gridHeader.refNumber': 'Ref #',
  'customer.salesInvoice.form.gridHeader.billingAddress': 'Billing Address',
  'customer.salesInvoice.form.gridHeader.shippingAddress': 'Shipping Address',
  'customer.salesInvoice.form.gridHeader.paymentTerms': 'Payment Terms',
  'customer.salesInvoice.form.gridHeader.product': 'Product',
  'customer.salesInvoice.form.gridHeader.description': 'Description',
  'customer.salesInvoice.form.gridHeader.qty': 'QTY',
  'customer.salesInvoice.form.gridHeader.qtyOrdered': 'Qty Ordered',
  'customer.salesInvoice.form.gridHeader.qtyInvoiced': 'Qty Invoiced',
  'customer.salesInvoice.form.gridHeader.qtyToInvoice': 'Qty To Invoice',
  'customer.salesInvoice.form.gridHeader.uom': 'UOM',
  'customer.salesInvoice.form.gridHeader.hsn': 'HSN/SAC',
  'customer.salesInvoice.form.gridHeader.rate': 'Rate',
  'customer.salesInvoice.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'customer.salesInvoice.form.gridHeader.discount': 'Discount (%)',
  'customer.salesInvoice.form.gridHeader.discountAmt': 'Discount',
  'customer.salesInvoice.form.gridHeader.tax': 'Tax (%)',
  'customer.salesInvoice.form.gridHeader.amount': 'Amount ',
  'customer.salesInvoice.form.gridHeader.stockNumber': 'Stock #',
  'customer.salesInvoice.form.gridHeader.partNumber': 'Part #',
  'customer.salesInvoice.form.gridHeader.origin': 'Origin',
  'customer.salesInvoice.form.gridHeader.hsCode': 'HS Code',
  'customer.salesInvoice.form.gridHeader.comment': 'Warehouse',
  'customer.salesInvoice.form.gridHeader.action': 'Action',
  'customer.salesInvoice.form.button.addLine': 'Add Line',
  'customer.salesInvoice.form.button.clearAllLines': 'Clear All Lines',
  'customer.salesInvoice.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.salesInvoice.form.internalRemarks.placeholder': 'Will not be visible to the customer. For internal purpose only.',
  'customer.salesInvoice.form.customerRemarks.label': 'Customer Remarks',
  'customer.salesInvoice.form.customerRemarks.placeholder': 'Thanks for your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.',
  'customer.salesInvoice.form.checkbox.discount': 'Discount',
  'customer.salesInvoice.form.checkbox.tax': 'Tax',
  'customer.salesInvoice.form.checkbox.expense': 'Expense',
  'customer.salesInvoice.form.totalDiscount.label': 'Discount',
  'customer.salesInvoice.form.totalTax.label': 'Tax',
  'customer.salesInvoice.form.taxName.label': 'Tax Name',
  'customer.salesInvoice.form.totalExpense.label': 'Expense',
  'customer.salesInvoice.form.totalExpenseAmount.label': 'Expense Amount',
  'customer.salesInvoice.form.subTotal.label': 'Sub Total',
  'customer.salesInvoice.form.totalAmount.label': 'Total Amount',
  'customer.salesInvoice.form.customer.label': 'Customer',
  'customer.salesInvoice.form.customerName.label': 'Customer Name',
  'customer.salesInvoice.form.customer.placeholder': 'Select Customer',
  'customer.salesInvoice.form.priceList.placeholder': 'Select Price List',
  'customer.salesInvoice.form.priceType.placeholder': 'Apply Retail Price',
  'customer.salesInvoice.form.gstnIn.label': 'GSTIN',
  'customer.salesInvoice.form.pan.label': 'PAN',
  'customer.salesInvoice.form.phone.label': 'Ph',
  'customer.salesInvoice.form.email.label': 'Email',
  'customer.salesInvoice.form.loadFromShipment.label': 'Invoice from Shipment',
  'customer.salesInvoice.form.loadFromSo.label': 'Invoice from SO',
  'customer.salesInvoice.form.selectSalesOrder.label': 'Sales Order (s)',
  'customer.salesInvoice.form.selectShipment.label': 'Select Shipment',
  'customer.salesInvoice.form.invoiceNumber.label': 'Invoice #',
  'customer.salesInvoice.form.soNumber.label': 'SO #',
  'customer.salesInvoice.form.invoiceDate.label': 'Invoice Date',
  'customer.salesInvoice.form.invoiceDueDate.label': 'Invoice Due Date',
  'customer.salesInvoice.form.projectDropdown.label': 'Project',
  'customer.salesInvoice.form.transportData.label': 'Transport Data',
  'customer.salesInvoice.form.bankDetails.label': 'Bank Detail',

  'customer.salesOrder.form.confirmation': 'Are you sure you want to create sales order?',
  'customer.salesOrder.form.priceListDropdown.placeholder': 'Select Price List',
  'customer.salesOrder.form.gridHeader.placeOfSupply': 'Place of Supply',
  'customer.salesOrder.form.gridHeader.deliveryMethod': 'Delivery Method',
  'customer.salesOrder.form.gridHeader.soSource': 'SO Source',
  'customer.salesOrder.form.gridHeader.customerPO': 'Customer PO #',
  'customer.salesOrder.form.gridHeader.salesPerson': 'Sales Person',
  'customer.salesOrder.form.gridHeader.incoterm': 'Incoterm',
  'customer.salesOrder.form.gridHeader.refNumber': 'Ref #',
  'customer.salesOrder.form.gridHeader.priority': 'Priority',
  'customer.salesOrder.form.gridHeader.billingAddress': 'Billing Address',
  'customer.salesOrder.form.gridHeader.shippingAddress': 'Shipping Address',
  'customer.salesOrder.form.gridHeader.paymentTerms': 'Payment Terms',
  'customer.salesOrder.form.gridHeader.product': 'Product',
  'customer.salesOrder.form.gridHeader.description': 'Description',
  'customer.salesOrder.form.gridHeader.qty': 'QTY',
  'customer.salesOrder.form.gridHeader.qtyOrdered': 'Qty Ordered',
  'customer.salesOrder.form.gridHeader.qtyInvoiced': 'Qty Invoiced',
  'customer.salesOrder.form.gridHeader.qtyToInvoice': 'Qty To Invoice',
  'customer.salesOrder.form.gridHeader.uom': 'UOM',
  'customer.salesOrder.form.gridHeader.hsn': 'HSN/SAC',
  'customer.salesOrder.form.gridHeader.rate': 'Rate',
  'customer.salesOrder.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'customer.salesOrder.form.gridHeader.discount': 'Discount (%)',
  'customer.salesOrder.form.gridHeader.tax': 'Tax (%)',
  'customer.salesOrder.form.gridHeader.amount': 'Amount ',
  'customer.salesOrder.form.gridHeader.stockNumber': 'Stock #',
  'customer.salesOrder.form.gridHeader.partNumber': 'Part #',
  'customer.salesOrder.form.gridHeader.origin': 'Origin',
  'customer.salesOrder.form.gridHeader.hsCode': 'HS Code',
  'customer.salesOrder.form.gridHeader.comment': 'Warehouse',
  'customer.salesOrder.form.gridHeader.action': 'Action',
  'customer.salesOrder.form.button.addLine': 'Add Line',
  'customer.salesOrder.form.button.clearAllLines': 'Clear All Lines',
  'customer.salesOrder.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.salesOrder.form.internalRemarks.placeholder': 'Will not be visible to the customer. For internal purpose only.',
  'customer.salesOrder.form.customerRemarks.label': 'Customer Remarks',
  'customer.salesOrder.form.customerRemarks.placeholder': 'Thanks for your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.',
  'customer.salesOrder.form.checkbox.discount': 'Discount',
  'customer.salesOrder.form.checkbox.tax': 'Tax',
  'customer.salesOrder.form.checkbox.expense': 'Expense',
  'customer.salesOrder.form.totalDiscount.label': 'Discount',
  'customer.salesOrder.form.totalTax.label': 'Tax',
  'customer.salesOrder.form.taxName.label': 'Tax Name',
  'customer.salesOrder.form.totalExpense.label': 'Expense',
  'customer.salesOrder.form.totalExpenseAmount.label': 'Expense Amount',
  'customer.salesOrder.form.subTotal.label': 'Sub Total',
  'customer.salesOrder.form.totalAmount.label': 'Total Amount',
  'customer.salesOrder.form.warehouse.placeholder': 'Warehouse',
  'customer.salesOrder.form.customer.label': 'Customer',
  'customer.salesOrder.form.customerName.label': 'Customer Name',
  'customer.salesOrder.form.customer.placeholder': 'Select Customer',
  'customer.salesOrder.form.priceList.placeholder': 'Select Price List',
  'customer.salesOrder.form.priceType.placeholder': 'Apply Retail Price',
  'customer.salesOrder.form.gstnIn.label': 'GSTIN',
  'customer.salesOrder.form.pan.label': 'PAN',
  'customer.salesOrder.form.phone.label': 'Ph',
  'customer.salesOrder.form.email.label': 'Email',
  'customer.salesOrder.form.soNumber.label': 'SO #',
  'customer.salesOrder.form.deliveryDate.label': 'Delivery Date',
  'customer.salesOrder.form.quotationNumber.label': 'Quote #',
  'customer.salesOrder.form.soDate.label': 'SO Date',
  'customer.salesOrder.form.projectDropdown.label': 'Project',
  'customer.salesOrder.form.documentDropdown.label': 'Document Title',
  'customer.salesOrder.form.qualityCheck.label': 'Quality Check',
  'customer.salesOrder.form.updateStock.label': 'Update Stock',
  'customer.salesQuote.form.confirmation': 'Are you sure you want to create sales quote?',
  'customer.salesQuote.form.priceListDropdown.placeholder': 'Select Price List',
  'customer.salesQuote.form.gridHeader.customerRFQ': 'Customer RFQ #',
  'customer.salesQuote.form.gridHeader.refNumber': 'Ref #',
  'customer.salesQuote.form.gridHeader.salesPerson': 'Sales Person',
  'customer.salesQuote.form.gridHeader.priority': 'Priority',
  // 'customer.salesQuote.form.gridHeader.billingAddress': 'Billing Address',
  'customer.salesQuote.form.gridHeader.project': 'Project',
  'customer.salesQuote.form.gridHeader.product': 'Product',
  'customer.salesQuote.form.gridHeader.description': 'Description',
  'customer.salesQuote.form.gridHeader.qty': 'QTY',
  'customer.salesQuote.form.gridHeader.qtyOrdered': 'Qty Ordered',
  'customer.salesQuote.form.gridHeader.qtyInvoiced': 'Qty Invoiced',
  'customer.salesQuote.form.gridHeader.qtyToInvoice': 'Qty To Invoice',
  'customer.salesQuote.form.gridHeader.uom': 'UOM',
  'customer.salesQuote.form.gridHeader.hsn': 'HSN/SAC',
  'customer.salesQuote.form.gridHeader.rate': 'Rate',
  'customer.salesQuote.form.gridHeader.specialDiscount': 'Spl. Discount (%)',
  'customer.salesQuote.form.gridHeader.discount': 'Discount (%)',
  'customer.salesQuote.form.gridHeader.tax': 'Tax (%)',
  'customer.salesQuote.form.gridHeader.amount': 'Amount ',
  'customer.salesQuote.form.gridHeader.stockNumber': 'Stock #',
  'customer.salesQuote.form.gridHeader.partNumber': 'Part #',
  'customer.salesQuote.form.gridHeader.origin': 'Origin',
  'customer.salesQuote.form.gridHeader.hsCode': 'HS Code',
  'customer.salesQuote.form.gridHeader.comment': 'Warehouse',
  'customer.salesQuote.form.gridHeader.action': 'Action',
  'customer.salesQuote.form.button.addLine': 'Add Line',
  'customer.salesQuote.form.button.clearAllLines': 'Clear All Lines',
  'customer.salesQuote.form.internalRemarks.label': 'Remarks (For Internal Use)',
  'customer.salesQuote.form.internalRemarks.placeholder': 'Will not be visible to the customer. For internal purpose only.',
  'customer.salesQuote.form.customerRemarks.label': 'Customer Remarks',
  'customer.salesQuote.form.customerRemarks.placeholder': 'Thanks for your business We truly appreciate your business, and we are grateful for the trust you have placed in us. If you have any concerns or questions, please do not hesitate to contact us.',
  'customer.salesQuote.form.checkbox.discount': 'Discount',
  'customer.salesQuote.form.checkbox.tax': 'Tax',
  'customer.salesQuote.form.checkbox.expense': 'Expense',
  'customer.salesQuote.form.totalDiscount.label': 'Discount',
  'customer.salesQuote.form.totalTax.label': 'Tax',
  'customer.salesQuote.form.taxName.label': 'Tax Name',
  'customer.salesQuote.form.totalExpense.label': 'Expense',
  'customer.salesQuote.form.totalExpenseAmount.label': 'Expense Amount',
  'customer.salesQuote.form.subTotal.label': 'Sub Total',
  'customer.salesQuote.form.totalAmount.label': 'Total Amount',
  'customer.salesQuote.form.warehouse.placeholder': 'Warehouse',
  'customer.salesQuote.form.customer.label': 'Customer',
  'customer.salesQuote.form.customerName.label': 'Customer Name',
  'customer.salesQuote.form.rfqNumber.label': 'RFQ #',
  'customer.salesQuote.form.rfqDate.label': 'RFQ Date',
  'customer.salesQuote.form.rfqExpirationDate.label': 'RFQ Submit By',
  'customer.salesQuote.form.customer.placeholder': 'Select Customer',
  'customer.salesQuote.form.priceList.placeholder': 'Select Price List',
  'customer.salesQuote.form.priceType.placeholder': 'Apply Retail Price',
  'customer.salesQuote.form.gstnIn.label': 'GSTIN',
  'customer.salesQuote.form.pan.label': 'PAN',
  'customer.salesQuote.form.phone.label': 'Ph',
  'customer.salesQuote.form.email.label': 'Email',
  'customer.salesQuote.form.sqNumber.label': 'Qoute #',
  'customer.salesQuote.form.sqDate.label': 'Quote Date',
  'customer.salesQuote.form.projectDropdown.label': 'Project',

  'modal.warehouse.button.createWarehouse': 'Create Warehouse',
  'modal.warehouse.button.updateWarehouse': 'Update Warehouse',
  'modal.warehouse.button.cancel': 'Cancel',

  'modal.project.button.createProject': 'Create Project',
  'modal.project.button.cancel': 'Cancel',

  'modal.paymentTerm.button.create': 'Create',
  'modal.paymentTerm.button.cancel': 'Cancel',

  'modal.contact.button.createContact': 'Create',
  'modal.contact.button.cancel': 'Cancel',

  'modal.priceList.button.createPriceList': 'Create',
  'modal.priceList.button.cancel': 'Cancel',

  'modal.newTax.button.createTax': 'Create',
  'modal.newTax.button.cancel': 'Cancel',

  'modal.address.button.createAddress': 'Create',
  'modal.address.button.cancel': 'Cancel',
  'modal.address.button.ok': 'OK',
  'finance.journal.newJournal.button.name': 'New Journal',

  'supplier.rfq.biddingSuppliers.rfqDetail.rfqNumber': 'RFQ #',
  'supplier.rfq.biddingSuppliers.rfqDetail.customerName': 'Customer',
  'supplier.rfq.biddingSuppliers.rfqDetail.refNumber': 'Ref #',
  'supplier.rfq.biddingSuppliers.rfqDetail.bidClosingDate': 'Bid Closing Date',
  'supplier.rfq.biddingSuppliers.rfqDetail.rfqDate': 'RFQ Date',
  'supplier.rfq.biddingSuppliers.rfqDetail.priority': 'Priority',
  // 'supplier.rfq.biddingSuppliers.rfqDetail.status': 'Status',
  'supplier.rfq.biddingSuppliers.gridHeader.supplierName': 'Supplier Name',
  'supplier.rfq.biddingSuppliers.gridHeader.address': 'Address',
  'supplier.rfq.biddingSuppliers.gridHeader.contact': 'Contact',
  'supplier.rfq.biddingSuppliers.gridHeader.rfqEmailed': 'RFQ Emailed',
  'supplier.rfq.biddingSuppliers.gridHeader.documents': 'Documents',
  'supplier.rfq.biddingSuppliers.gridHeader.comments': 'Comments',
  'supplier.rfq.biddingSuppliers.gridHeader.priceCode': 'Price Code',
  // 'supplier.rfq.biddingSuppliers.gridHeader.status': 'Status',
  'supplier.rfq.biddingSuppliers.gridHeader.action': 'Action',

  'modal.settings.emailTemplate.transaction': 'Transaction',
  'modal.settings.emailTemplate.emailSubject': 'Email Subject',
  'modal.settings.emailTemplate.emailMessage': 'Email Message',

  'modal.settings.remarksTemplate.transaction': 'Transaction',
  'modal.settings.remarksTemplate.message': 'Message',
  'modal.settings.remarksTemplate.footer': 'Footer',

  'modal.settings.changePassword.currentPassword': 'Current Password',
  'modal.settings.changePassword.newPassword': 'Enter New Password',
  'modal.settings.changePassword.confirmPassword': 'Confirm Password',
  'modal.settings.changePassword.currentPassword.placeholder': 'Current Password',
  'modal.settings.changePassword.newPassword.placeholder': 'Enter New Password',
  'modal.settings.changePassword.confirmPassword.placeholder': 'Confirm Password',
  'modal.settings.changePassword.validation.currentPassword': 'Current Password is required',
  'modal.settings.changePassword.validation.newPassword': 'New Password is required',
  'modal.settings.changePassword.validation.confirmPassword': 'Confirm password does not match',

  'inventory.priceList.listing.header.name': 'Name',
  'inventory.priceList.listing.header.description': 'Description',
  'inventory.priceList.listing.header.percentage': 'Percentage',
  'inventory.priceList.listing.header.type': 'Type',
  'inventory.priceList.listing.header.startDate': 'Start Date',
  'inventory.priceList.listing.header.endDate': 'End Date',
  'inventory.priceList.listing.header.action': 'Action',
  'inventory.priceList.listing.header.priceList': 'Price List',
  'inventory.priceList.listing.header.newPriceList': 'New Price List',
  'inventory.productPrice.listing.header.productPrice': 'Product Price',

  'inventory.extras.category.listing.header.categoryName': 'Category Name',
  'inventory.extras.category.listing.header.categoryDescription': 'Category Description',
  'inventory.extras.category.listing.header.dateCreated': 'Date Created',
  'inventory.extras.category.listing.header.totalProducts': 'Total Products',
  'inventory.extras.category.listing.header.action': 'Action',
  'inventory.extras.category.listing.header.newCategory': 'New Category',
  'inventory.extras.category.listing.header.productCategories': 'Product Categories',
  'inventory.extras.category.listing.subHeader.text': 'Product Category helps to organize the products by their attributes. A particular group of related products can be categorized in one category.',

  'inventory.extras.brand.listing.header.brandName': 'Brand Name',
  'inventory.extras.brand.listing.header.brandDescription': 'Brand Description',
  'inventory.extras.brand.listing.header.dateCreated': 'Date Created',
  'inventory.extras.brand.listing.header.totalProducts': 'Total Products',
  'inventory.extras.brand.listing.header.action': 'Action',
  'inventory.extras.brand.listing.header.newBrand': 'New Brand',
  'inventory.extras.brand.listing.header.productBrands': 'Product Brands',
  'inventory.extras.brand.listing.subHeader.text': 'Brand is a term used to distinguish a product from one seller to another. A brand can be identified by logos, trade name, or trademark.',

  'inventory.extras.manufacturer.listing.header.manufacturerName': 'Manufacturer Name',
  'inventory.extras.manufacturer.listing.header.description': 'Manufacturer Description',
  'inventory.extras.manufacturer.listing.header.dateCreated': 'Date Created',
  'inventory.extras.manufacturer.listing.header.totalProducts': 'Total Products',
  'inventory.extras.manufacturer.listing.header.action': 'Action',
  'inventory.extras.manufacturer.listing.header.newManufacturer': 'New Manufacturer',
  'inventory.extras.manufacturer.listing.header.productManufacturer': 'Product Manufacturer',
  'inventory.extras.manufacturer.listing.subHeader.text': 'Manufacturer is an entity that manufactures goods to meet customer expectations.',


  'inventory.extras.uom.listing.header.uom': 'UOM',
  'inventory.extras.uom.listing.header.isDefault': 'Is Default',
  'inventory.extras.uom.listing.header.description': 'Description',
  'inventory.extras.uom.listing.header.dateCreated': 'Date Created',
  'inventory.extras.uom.listing.header.action': 'Action',
  'inventory.extras.uom.listing.header.newUOM': 'New UOM',
  'inventory.extras.uom.listing.subHeader.text': 'UOM is a term used for unit of measure of a product.',

  'inventory.warehouse.myWarehouse.listing.header.warehouseName': 'Warehouse Name',
  'inventory.warehouse.myWarehouse.listing.header.isPrimary': 'Primary Warehouse',
  'inventory.warehouse.myWarehouse.listing.header.totalItems': 'Total Items',
  'inventory.warehouse.myWarehouse.listing.header.warehouseAddress': 'Warehouse Address',
  'inventory.warehouse.myWarehouse.listing.header.dateCreated': 'Date Created',
  'inventory.warehouse.myWarehouse.listing.header.action': 'Action',
  'inventory.warehouse.myWarehouse.listing.header.warehouse': 'Warehouse',
  'inventory.warehouse.myWarehouse.listing.header.newWarehouse': 'New Warehouse',

  'inventory.warehouse.agedStock.listing.header.product': 'Product',
  'inventory.warehouse.agedStock.listing.header.variant': 'Variant',
  'inventory.warehouse.agedStock.listing.header.sku': 'SKU',
  'inventory.warehouse.agedStock.listing.header.days': 'Days',
  'inventory.warehouse.agedStock.listing.header.currentStock': 'Current Stock',
  'inventory.warehouse.agedStock.listing.header.lastPurchasePrice': 'Last Purchase Price',
  'inventory.warehouse.agedStock.listing.header.agedProducts': 'Aged Products',

  'inventory.warehouse.stockSummary.listing.header.product': 'Product',
  'inventory.warehouse.stockSummary.listing.header.sku': 'SKU',
  'inventory.warehouse.stockSummary.listing.header.category': 'Category',
  'inventory.warehouse.stockSummary.listing.header.currentStock': 'Current Stock',
  'inventory.warehouse.stockSummary.listing.header.qtyOnSO': 'Qty on SO',
  'inventory.warehouse.stockSummary.listing.header.qtyAvailable': 'Qty Available',
  'inventory.warehouse.stockSummary.listing.header.qtyOnPO': 'Qty on PO',
  'inventory.warehouse.stockSummary.listing.header.reorderLevel': 'Reorder Level',
  'inventory.warehouse.stockSummary.listing.header.lowStockLevel': 'Low Stock Level',
  'inventory.warehouse.stockSummary.listing.warehouseFilter.placeholder': 'Select Warehouse',
  'inventory.warehouse.stockAdjustment.listing.header.stockSummary': 'Stock Summary',

  'inventory.warehouse.stockAdjustment.listing.header.product': 'Product',
  'inventory.warehouse.stockAdjustment.listing.header.sku': 'SKU',
  'inventory.warehouse.stockAdjustment.listing.header.category': 'Category',
  'inventory.warehouse.stockAdjustment.listing.header.currentStock': 'Current Stock',
  'inventory.warehouse.stockAdjustment.listing.header.qtyOnSO': 'Qty on SO',
  'inventory.warehouse.stockAdjustment.listing.header.qtyAvailable': 'Qty Available',
  'inventory.warehouse.stockAdjustment.listing.header.qtyOnPO': 'Qty on PO',
  'inventory.warehouse.stockAdjustment.listing.header.action': 'Action',
  'inventory.warehouse.stockAdjustment.listing.header.stockAdjustment': 'Stock Adjustment',

  'inventory.warehouse.productPrice.listing.header.product': 'Product',
  'inventory.warehouse.productPrice.listing.header.sku': 'SKU',
  'inventory.warehouse.productPrice.listing.header.itemCode': 'Item Code',
  'inventory.warehouse.productPrice.listing.header.description': 'Description',
  'inventory.warehouse.productPrice.listing.header.category': 'Category',
  'inventory.warehouse.productPrice.listing.header.purchasedPrice': 'Purchased Price',
  'inventory.warehouse.productPrice.listing.header.wholesalePrice': 'Wholesale Price',
  'inventory.warehouse.productPrice.listing.header.retailPrice': 'Retail Price',
  'inventory.warehouse.productPrice.listing.header.action': 'Action',

  'company.companyHierarchy.message': 'Organization hierarchy is required',
  'company.hierarchy.button.newCompanyHierarchy': 'New Organization Hierarchy',
  'company.hierarchy.hierarchyName': ' Hierarchy Name',
  'company.hierarchy.hierarchyLevel': ' Hierarchy Level',
  'company.hierarchy.hierarchyCode': ' Hierarchy code',
  'company.hierarchy.hierarchyLevel.placeholder': 'Select Hierarchy Level',
  'company.hierarchy.hierarchyLevel.message': 'Hierarchy level code is required',
  'company.hierarchy.description': ' Description',
  'company.hierarchy.rootHierarchy': 'Root Hierarchy',
  'company.hierarchy.action': 'Action',
  'company.heirarchy.name.placeholder': 'Enter hierarchy name',
  'company.heirarchy.level.name.placeholder': 'Enter hierarchy level name',
  'company.heirarchy.name.messgae': 'Hierarchy name is required',
  'company.heirarchy.level.name.message': 'Hierarchy level name is required',

  'company.employee.name.placeholder': 'Enter 3 characters employee name',
  'company.employee.title.newEmployee': 'New Employee',
  'company.employee.firstName': 'First Name',
  'company.employee.lastName': 'Last Name',
  'company.employee.hierarchyName': 'Hierarchy Name',
  'company.employee.hierarchyCode': ' Hierarchy Code',
  'company.employee.jobTitle': 'Job Title',
  'company.employee.manager': 'Manager',
  'company.employee.companyHierarchy': 'Organization Hierarchy',
  'company.employee.hierarchyLevel': 'Hierarchy Level',
  'company.employee.phone': 'Phone',
  'company.employee.email': 'Email',
  'company.employee.department': 'Department',
  'common.addRemove': 'Add/Remove',
  'company.companySetup.accountingType': 'Accounting Type',
  'company.companySetup.financialYear': 'Setup Financial Year',
  'company.companySetup.businessDetail.title': 'Little more about your business',
  'company.companySetup.businessDetail.optionalFields': 'These are optional fields',
  'company.companySetup.businessDetail.companySize': 'Organization Size',
  'company.companySetup.companySize.placeholder': 'Select Organization Size',
  'company.companySetup.ledgerAccounts.title': 'Little more about your business',
  'company.companySetup.ledgerAccounts.defaultLedger': 'Default Ledger Accounts (Optional)',
  'company.companySetup.ledgerAccounts.sales': 'Sales',
  'company.companySetup.ledgerAccounts.salesLedger.placeholder': 'Select Sales Ledger',
  'company.companySetup.ledgerAccounts.purchaseLedger.placeholder': 'Select Purchase Ledger',
  'company.companySetup.ledgerAccounts.debtorsLedger.placeholder': 'Select Debtors Account',
  'company.companySetup.ledgerAccounts.creditorsLedger.placeholder': 'Select Creditors Account',
  'company.companySetup.ledgerAccounts.customerDiscountLedger.placeholder': 'Select Customer Discount Ledger',
  'company.companySetup.ledgerAccounts.supplierDiscountLedger.placeholder': 'Select Supplier Discount Ledger',
  'company.companySetup.ledgerAccounts.salesoutputTaxLedger.placeholder': 'Select Sales Output Tax Ledger',
  'company.companySetup.ledgerAccounts.purchaseInputTaxLedger.placeholder': 'Select Purchase Input Tax Ledger',
  'company.companySetup.ledgerAccounts.creditMemoTaxLedger.placeholder': 'Select Credit Memo Ledger',
  'company.companySetup.ledgerAccounts.debitMemoLedger.placeholder': 'Select Debit Memo Ledger',
  'company.companySetup.ledgerAccounts.customerExpenseLedger.placeholder': 'Select Customer Expense Ledger',
  'company.companySetup.ledgerAccounts.supplierExpenseLedger.placeholder': 'Select Supplier Expense',
  'company.companySetup.ledgerAccounts.paymentRecieveLedger.placeholder': 'Select Payment Recieved Ledger',
  'company.companySetup.ledgerAccounts.paymentMadeLedger.placeholder': 'Select Payment Made Ledger',
  'company.companySetup.ledgerAccounts.paymentMadeLedger.title': 'Payment Made',
  'company.companySetup.ledgerAccounts.paymentRecievedLedger.title': 'Payment Received',
  'company.companySetup.ledgerAccounts.supplierExpense.title': 'Supplier Expense',
  'company.companySetup.ledgerAccounts.customerExpense.title': 'Customer Expense',
  'company.companySetup.ledgerAccounts.debitMemo.title': 'Debit Memo',
  'company.companySetup.ledgerAccounts.creditMemo.title': 'Credit Memo',
  'company.companySetup.ledgerAccounts.purchaseInputTax.title': 'Purchase Input Tax',
  'company.companySetup.ledgerAccounts.salesOutputTax.title': 'Sales Output Tax',
  'company.companySetup.ledgerAccounts.salesDiscount.title': ' Supplier Discount',
  'company.companySetup.ledgerAccounts.customerDiscount.title': 'Customer Discount',
  'company.companySetup.ledgerAccounts.creditors.title': 'Creditors',
  'company.companySetup.ledgerAccounts.debtors.title': 'Debtors',
  'company.companySetup.ledgerAccounts.purchase.title': 'Purchase',
  'company.companySetup.setupFinancial.setupFinYear.title': 'Setup Financial Year',
  'company.companySetup.setupFinancial.finYearStartDate.title': 'Financial year start Date',
  'company.companySetup.setupFinancial.finYearEndDate.title': 'Financial year end Date',
  'company.companySetup.button.previous': 'Previous',
  'company.companySetup.button.next': 'Next',
  'company.companySetup.button.done': 'Done',
  'common.sales': 'Sales',
  'common.creditMemo': 'Credit Memo',
  'common.salesInvoice': 'Sales Invoice',
  'common.proformaInvoice': 'Proforma Invoice',
  'relationshipAddress.tooltip': 'This is your company billing address. Additional addresses can be managed from Setting > Organization Information > Address Page',
  'relationshiptaxIdentification.placeholder': 'Tax Identification(s)',
  'contact.placeholder': 'Please select contact name',
  'billTo.placeholder': 'Billing Address',
  'taxIdentification.placeholder': 'Tax Identification(s)',
  'taxIdentification.tooltip': 'Customer Tax Identification',
  'documentTitle.tooltip': 'Document title will appear on PDF and on Report etc',
  'uploadDocument.button': ' Upload Documents',
  'activity.activityType': 'Activity Type',
  'activity.assignedTo': 'Assigned To',
  'activity.dueDate': 'Due Date',
  'activity.closeDate': 'Closed Date',
  'common.dateCreated': 'Date Created',
  'common.date.text': 'Date',
  'activity.documents': 'Documents',
  'activity.title': 'New Activity',
  'activity.downloadPdf': 'Download Pdf',
  'activity.exportExcel': ' Export to Excel',
  'activity.email': 'Email',
  'address.addressType': 'Address Type',
  'address.streetName': 'Street Name',
  'address.streetNameContd': 'Street Address (contd.)',
  'address.city': 'City',
  'address.state': 'State/Province/Region',
  'address.zip': 'Zip/Postal Code',
  'common.contact': 'Contact',
  'address.title': 'Manage Address',
  'expense.customers': 'Customers',
  'expense.lable': 'Expense',
  'expense.list': 'List',
  'xero.tile': 'Xero Invoice Upload',
  'xero.invoice.slected.message': 'Selected invoice has already been uploaded. Please remove and try again',
  'xero.invoice.slected.before.message': 'Please select invoice before uploading',
  'updateInvoice.text': 'Update  Invoice',
  'update.text': 'Update',
  'clone.text': 'Clone',
  'delete.text': 'Delete',
  'common.delete.confirmation': 'Are you sure you want to delete this transaction?',
  'delete.invoice': 'Delete Invoice',
  'void.invoice': 'Void Invoice',
  'update.user.status': 'Update User Status',
  'template.standard.invoice': 'Standard Invoice Template',
  'template.standard.invoice.multicurrency': 'Multi Currency Invoice Template',
  'common.currency.placeholder': 'Select Currency',
  'common.confirmation.clear': 'Are you sure you want to clear?',
  'bankDetail.placeholder': 'Bank Details',
  'activity.confirmation': 'Are you sure you want to create activity?',
  'address.primary.message': 'When this is default then this address will be populated on all new transaction',
  'address.primary': 'Primary Address',
  'address.streetAddress1': 'Street Address1',
  'address.streetAddress2': 'Street Address2',
  'address.country': 'Country',
  'contact.primary.message': 'When checked, this contact will be populated on all transactions. You can change from the transaction page',
  'contact.primary': 'Primary Contact',
  'contact.title': 'Title',
  'contact.text': 'Contact',
  'manageContact.text': 'Manage Contact',
  'addContact.text': 'Add Contact',
  'addAddress.text': 'Add Address',
  'contact.firstName': 'First Name',
  'contact.firstName.placeholder': 'First Name',
  'contact.firstName.message': 'Name is required',
  'contact.middleName': 'Middle Name',
  'contact.phone': 'Phone',
  'contact.phone.placeholder': 'Phone',
  'contact.middleName.placeholder': 'Middle Name',
  'contact.lastName': 'Last Name',
  'contact.lastName.placeholder': 'Last Name',
  'contact.lastName.message': 'Last name is required',
  'contact.departments': 'Department',
  'contact.jobTitle': 'Job Title',
  'contact.jobTitle.placeholder': 'Select Job Title',
  'contact.jobTitle.message': 'Job title is required',
  'contact.manager': 'Manager',
  'contact.manager.placeholder': 'Select Manager',
  'contact.manager.message': 'Manager name is required',
  'contact.departments.placeholder': 'Select Department',
  'contact.callName': 'Call Name',
  'contact.callName.placeholder': 'Call Name',
  'contact.workPhone': 'Work Phone',
  'contact.mobile': 'Mobile',
  'contact.mobile.placeholder': 'Mobile Number ',
  'contact.faxNumber': 'Fax #',
  'contact.email': 'Email',
  'contact.email.placeholder': 'Email',
  'contact.email.message': 'Email is required',
  'contact.manageContact': 'Manage Contact',
  'contact.downloadPdf': 'Download Pdf',
  'contact.exportExcel': ' Export to Excel',
  'tiles.customerDue': 'CUSTOMER DUE',
  'common.error.swr': 'Something went wrong',
  'common.error.invalidPath': 'Invalid path',
  'tiles.supplierDue': 'SUPPLIER DUE',
  'tile.balanceSheet': 'BALANCE SHEET',
  'tiles.pAndL': 'PROFIT AND LOSS',
  'common.purchase': 'Purchase',
  'dashboard.topCustomerSales': 'Top Customers by Sales',
  'dashboard.topCustomerPurchase': 'Top Suppliers by Purchase',
  'dashboard.topSupplierPayment': ' Top Suppliers by Payment',
  'dashboard.topCustomerPayment': ' Top Customers by Payment',
  'dashboard.fasetesProduct': ' Fastest Moving Products',
  'company.companyHierarchy.title': 'Organization Hierarchy',
  'company.companyHierarchy.confirmation.message': 'Are you sure you want to create company hierarchy',
  'company.hierarchy.hierarchDescription': 'Hierarchy Description',
  'company.hierarchy.hierarchDescription.placeholder': 'Enter Hierarchy Description',
  'company.hierarchy.parentHierarchy': ' Parent Hierarchy',
  'company.hierarchy.parentHierarchy.placeholder': 'Select Parent hierarchy',
  'company.hierarchy.parentHierarchy.message': 'Parent Hierarchy name is required',
  'company.hierarchy.rootHierarchy.text': 'This is a root hierarchy',
  'company.employee.confitmation': 'Are you sure you want to create employee?',
  'company.employee.text': 'Employee',
  'common.title.placeholder': 'Select title',
  'title.text': "Title",
  'company.companyHierarchy.text': 'Organization Hierarchy',
  'company.companyHierarchy.placeholder': 'Select Organization Hierarchy',
  'breadcrum.finance.balanceSheet.text': 'Balance Sheet',
  'breadcrum.finance.text': 'Finance',
  'breadcrum.inquiry.text': 'Inquiry',
  'finance.balanceSheetAsOn.text': '  Balance Sheet as on',
  'particulars.text': 'Particulars',
  'notes.text': 'Notes',
  'asOn.text': 'As On',
  'equityAndLiabilities.text': 'EQUITY AND LIABILITIES',
  'a.text': 'A',
  'shareHolderFund.text': `Shareholder's Funds`,
  'shareCapital.text': 'Share Capital',
  'reserveAndSurplus.text': 'Reserve & Surplus',
  'moneyRecievedShareWarrants.text': 'Money Received against Share Warrants',
  'nonCurrentLibilities.text': 'Non Current Liabilities',
  'longTermBorrow.text': 'Long Term Borrowings',
  '(a).text': '(a)',
  '(b).text': '(b)',
  '(c).text': '(c)',
  '(d).text': '(d)',
  'b.text': 'B',
  'assets.text': 'ASSETS',
  'noncurrentAssets.text': 'Non - Current Assets',
  'fixedAssets.text': 'Fixed Assets',
  'tangibleAssets.text': '(i) Tangible Asset',
  'intangibleAssets.text': '(ii) Intangible Asset',
  'nonCurrentInvestMents.text': 'Non Current Investments',
  'longTermLoansAndAdvance': 'Long Term Loans & Advances',
  'otherNonCurrentAssets.text': 'Other Non Current Assets',
  'currentAssets.text': 'Current Assets',
  'currentInvestment.text': 'Current Investment',
  'inventories.text': 'Inventories',
  'tradeRecievable.text': 'Trade Receivables',
  'cashEquivalent.text': 'Cash & Cash Equivalents',
  'shortTermAdvance.text': 'Short Term Loans & Advances',
  'inboundDelivery.selectPO': 'Please select PO',
  '(e).text': '(e)',
  '(f).text': '(f)',
  'total.text': 'Total',
  'defferedLiablities.text': 'Deferred Tax Liabilities',
  'otherLongTermLiabilities.text': 'Other Long Term Liabilities',
  'longTermProvision.text': 'Long Term provisions',
  'currentLibilities.text': 'Current Liabilities',
  'shortTermBorrowing.text': 'Short Term Borrowings',
  'tradePayable.text': 'Trade Payables',
  'otherCurrentLiabilities.text': 'Other Current Liabilities',
  'gstnumber.text': 'GST Number',
  'panNumber.text': 'PAN Number',
  'expenseDate.text': 'Expense Date',
  'costCenter.text': '  Cost Center',
  'referenceNumber.text': 'Reference Number',
  'expenseType.text': 'Expense Type',
  'amountTaxInclusive.text': 'Amount(Tax Inclusive)',
  'remarks.text': 'Remarks',
  'remarks.placeholder': 'Remarks',
  'expense.text': 'Expense',
  'list.text': 'List',
  'card.text': 'Card',
  'newBusinessExpense.text': 'New Business Expense',
  'costCenterName.text': 'Cost Center Name',
  'costCenterNumber.text': 'Cost Center Number',
  'department.text': 'Department',
  'finance.text': 'Finance',
  'financeYear.text': 'Financial Years',
  'error.parseJson': 'An error occurred in converting the request',
  'common.error.processing.request': 'There is an issue processing the request. Please try again later',
  'aboutus.update.success': 'About Us content updated successfully',
  'returnPolicy.update.success': 'Return Policy content updated successfully',
  'mission.update.success': 'Mission Statement content updated successfully',
  'visionStatement.content.success': 'Vision Statement content updated successfully',
  'shippingContent.update.success': 'Free Shipping content updated successfully',
  'promotion.update.success': 'Promotions content updated successfully',
  'licence.updload.success': 'License uploaded successfully',
  'business.data.update.success': 'Business data updated successfully',
  'testData.upload.success': 'Test Data uploaded successfully',
  'user.already.assign.message': 'User is already assigned to this company',
  'company.access.successfully': 'Organization access granted successfully',
  'unable.revoke.access': 'Unable to revoke access',
  'access.revoked.success': 'Access revoked successfully',
  'sales.invoice.approoved.success': 'Sales Invoice approved successfully',
  'so.approoved.success': 'Sales Order approved successfully',
  'actualDeliveryDate.update.success': 'Actual Deliver Date updated successfully',
  'shipment.update.success': 'Shipment status updated successfully',
  'inventory.update.success': 'Inventory  updated successfully',
  'conformity.update.success': 'Certificate of conformity saved successfully',
  'paymentDetail.update.success': 'Payment details updated successfully',
  'account.create.trial.success': 'Your account has been created successfully. Your free trial is valid for 14 days',
  'so.delete.success': 'Sales order deleted successfully',
  'so.cancel.success': 'Sales order cancelled successfully',
  'so.reinitiate.success': 'Sales order reinstate successfully',
  'product.not.exist': 'Product does not exist ',
  'stock.transfer.success': 'Stock successfully transferred',
  'po.cancel.success': 'Purchase order cancelled successfully',
  'po.delete.success': 'Purchase order deleted successfully',
  'po.unableTo.delete': `Purchase Order can't be deleted because GRN document has been created`,
  'po.unableTo.delete.dueToInvoice': `Purchase Order can't be deleted because invoicing is completed`,
  'tooltip.originalRate': 'This is the original rate',
  'tooltip.taIdentification': 'This is your company Tax Identification',
  'tooltip.customerContact': 'Customer Contact',
  'tooltip.customerAddress': 'Customer Address',
  'tooltip.generateCreditMemo': 'System Generated Credit Memo Number',
  'tooltip.uploadToXero': 'Upload invoice to xero?',
  'tooltip.referenceNumberAssign': 'Reference number is an identifier assigned to any transaction. This number can be used to associate or refer to another transaction',
  'tooltip.companyBillingAddress': 'This is your company billing address. Additional addresses can be managed from Setting > Organization Information > Address Page',
  'tooltip.generateSI': 'System Generated Sales Invoice Number',
  'tooltip.documentAppearInPdf': 'Document title will appear on PDF and on Report etc',
  'tooltip.qualityChekSetting': 'Quality Check settings',
  'tooltip.warehouseDetail': 'This is customer warehouse details',
  'tooltip.generatePaymentNumber': 'System Generated Payment Number',
  'tooltip.enableToCreateShipment': 'Please Enable to Create Invoice from Shipment',
  'tooltip.transactionNumberRelevent': 'This could be transaction name or transaction number relevant to this activity',
  'tooltip.communicationType': 'Communication Type could be Email, Courier, Fax etc',
  'tooltip.briefDescription': 'Brief description about the communication',
  'tooltip.typeOfCommunication': 'It could be email address, fax number, full postal address',
  'tooltip.qualityChecklist': 'This is Quality Checklist Status',
  'tooltip.isInvoiceUploadedToXero': 'Invoice uploaded to Xero?',
  'tooltip.InqNumberToLocateInquiry': 'This is the Inquiry Number that you could locate from your Customer Inquiry',
  'tooltip.generateSalesInquiryNum': 'System Generated Sales Inquiry #',
  'tooltip.internationalCommercial': 'International Commercial Terms are a series of pre-defined commercial terms which define the responsibilities of sellers and buyers',
  'tooltip.generateSONumber': 'System Generated SO Number',
  'tooltip.qualityCheckList': 'Quality Check list',
  'tooltip.checkedPackage': 'When checked, packages from other Sales Orders will be displayed',
  'tooltip.consigneePerson': 'Consignee is the person who is responsbile for receiving the shipment',
  'tooltip.rfqNoToLocate': 'This is the RFQ Number that you could locate from your Customer RFQ request',
  'tooltip.generateSQNumber': 'System Generated Sales Quote Number',
  'tooltip.taxDisplayOnPdf': 'If selected then tax identification will be displayed by default on all new transaction but you remove too',
  'tooltip.searchHSN': 'Search is enabled on HSN Code',
  'tooltip.taxPopulateOnItemSelect': 'When checked Tax will be populated automatically on tx when this item is selected',
  'tooltip.showTooltipMessage': 'Here will show Tooltip Message',
  'tooltip.category': 'This is category',
  'tooltip.part': 'This is part #',
  'tooltip.manufacturer': 'This is manufacturer',
  'tooltip.sku': 'This is sku',
  'tooltip.stokNumber': 'This is stock #',
  'tooltip.article': 'This is article #',
  'tooltip.universalProduct': 'Universal Product Code is a 12 digits most popular bar code',
  'tooltip.internationalArticle': 'International Article Number is a 13 digit unique number bar code',
  'tooltip.internationalStandard': 'Internaltional Standard Book Number is a 13 digit bar code. Books often use this barcode format and compatible with EAN barcodes',
  'tooltip.recievingLowStock': 'Enter how many items left until you start receiving low stock alert',
  'tooltip.fyEndDate': 'FY End Date',
  'tooltip.department': 'Department',
  // 'tooltip.status': 'Status',
  'tooltip.journalNumber': 'Journal Number',
  'tooltip.narration': 'Narration',
  'tooltip.amount': 'Amount',
  'amount.text': 'Amount',
  'tooltip.date': 'Date',
  'tooltip.startAcNumber': 'Starting Account No',
  'tooltip.endAcNumber': 'Ending Account No',
  'tooltip.categoryGrp': 'Category Group',
  'tooltip.systemAccount': 'System Account?',
  'tooltip.inbuilt': 'Inbuilt?',
  'tooltip.accNNumber': 'Account No',
  'tooltip.ledgerAccNumber': 'This could be ledger account number from other accounting software such as QuickBook, Sage, Xero, SAP etc',
  'tooltip.ledgerNo': 'Ledger No',
  'tooltip.ledgerAccount': 'Ledger Account',
  'tooltip.totalAmount': 'Total Amount',
  'tooltip.openingBalance': 'Opening Balance',
  'tooltip.debitAmount': 'Debit Amount',
  'tooltip.creditAmount': 'Credit Amount',
  'tooltip.amountDifference': 'Amount Difference',
  'tooltip.nature': 'Nature',
  'tooltip.ledgerAccGrp': 'Ledger Account Group',
  'tooltip.accountEntry': 'Accounting Entry',
  'tooltip.txDate': 'Transaction Date',
  'tooltip.taxName': 'Tax Name',
  'tooltip.description': 'Description',
  'tooltip.rate': 'Rate',
  'tooltip.soTax': 'Sales Output Tax',
  'tooltip.poTax': 'Purchase Input Tax',
  'modalBody.deleteHierarchy': 'Are you sure you want to delete Hierarchy?',
  'breadcrum.dashboard': 'Dashboard',
  'breadcrum.dashboard.customer': 'Customer',
  'breadcrum.dashboard.customerDetail': 'Customer Detail',
  'breadcrum.company': 'Organization',
  'breadcrum.companyHierarchy': 'Organization Hierarchy',
  'modalBody.deleteEmployee': 'Are you sure you want to delete employee?',
  'breadcrum.employee': 'Employee',
  'industry.text': 'Industry',
  'industry.placeholder': 'Select Industry',
  'inventoryItemQty.text': 'Inventory Item Qty',
  'inventoryItemQty.placeholder': 'Select Inventory Item Qty',
  'averageMonthlyOrder.text': 'Average Monthly Orders',
  'averageMonthlyOrder.placeholder': 'Select Average Monthly Orders',
  'howDidYouHear.text': 'How Did You Hear?',
  'howDidYouHear.paceholder': 'Select source of reaching  us',
  'updateCM.text': 'Update Credit Memo',
  'deleteCm.text': 'Delete Credit Memo',
  'common.duplicateProduct.message': 'Duplicate product is selected',
  'common.type': 'Type',
  'communicationTo.text': 'Communicated to',
  'communicationDate.text': 'Communication Date',
  'transaction.text': 'Transaction',
  'origination.text': 'Origination',
  'communicatedBy.text': 'Communicated by',
  'newCommunication.text': 'New Communication',
  'common.downloadPdf': 'activity.dounloadPdf',
  'common.export': 'Export to Excel',
  'email.text': ' Email',
  'transactionNumber.text': 'Transaction #',
  'transactionAmount.text': 'Transaction Amount',
  'transactionDate.text': 'Transaction Date',
  'actions.text': 'Actions',
  'customerPoNumber.text': 'Customer PO Number',
  'priority.text': 'Priority',
  'communicationMode.required.message': 'Communication mode is required',
  'createInquiry.text': 'Create Inquiry',
  'createSalesQuote.text': 'Create Sales Quote',
  'createSalesOrder.text': 'Create Sales Order',
  'createInvoice.text': 'Create Invoice',
  'createCreditMemo.text': 'Create Credit Memo',
  'createShipment.text': 'Create Shipment',
  'viewShipment.text': 'View Shipment',
  'updatePackage.text': 'Update Package',
  'template.standardSalesQuote': 'Standard Sales Quote Template',
  'template.multicurrencySalesQuote': 'Multi Currency Sales Quote Template',
  'customerSalesEnquiryNumber.text': 'Customer Inquiry #',
  'customerRfqNumber.text': 'Customer RFQ #',
  'customerPONumber.text': 'Customer PO #',
  'package.text': 'Package',
  'newPackage.text': 'New Package',
  'xeroPaymentUpload.text': 'Xero Payment Upload',
  'paymentBeforeUpload.message': 'Please select payment before uploading',
  'salesSupport.text': 'Sales Support',
  'convertToSq.text': 'Convert To  SQ',
  'convertToPr.text': 'Convert To  PR',
  'updateInquiry.text': 'Update Inquiry',
  'cloneInquiry.text': 'Clone Inquiry',
  'deleteInquiry.text': 'Delete Inquiry',
  'inquiryNumber.text': 'Inquiry Number',
  'common.customer.text': 'Customer',
  'expirationDate.text': 'Expiration Date',
  'inquiryDate.text': 'Inquiry Date',
  'quoteConverted.text': 'Quote Converted',
  'salesPerson.text': 'Sales Person',
  'salesOrder.text': 'Sales Order',
  'salesQuote.text': 'Sales Quote',
  'selectCustomer.message': 'Please Select Customer',
  'multicurrencySalesQuote.text': 'MultiCurrency Sales Quote',
  'addAuditTrail.text': 'Add Audit Trail',
  'addAuditTrail.placeholder': 'Add audit trail here',
  'button.uploadCustomerPO.label': 'Upload Customer PO',
  'companyInfo.text': 'Organization Info',
  'signout.text': ' Sign out',
  'lastLogin.text': 'Last Login',
  'homeCurr.text': 'Home Currency',
  'language.text': 'Language',
  'timezon.text': 'TimeZone',
  'setting.text': 'Settings',
  'privacyPolicy.text': 'Privacy Policy',
  'termsOfService.text': 'Terms of Service',
  'xero.text': 'Xero',
  'createUpdateInvoice.text': 'Create Payment Transactions',
  'createUpdateNotes.text': 'Create Customer Profiles',
  'applyPayment.text': 'Create Subscriptions',

  'update': 'Update',
  'clone': 'Clone',
  'delete': 'Delete',
  'product.listing.buttonMenu.newProducts': 'New Products',
  'product.listing.validation.failed.label': 'Validation Failed',
  'product.listing.validation.failed.message.delete': 'No product selected. Please select product(s) before deleting.',
  'product.listing.validation.failed.message.download': 'No item selected. Please select item before downloading.',
  'product.listing.header.productDetails': 'Product Details',
  'product.listing.header.variant': 'Variant',
  'product.listing.header.sku': 'SKU',
  'product.listing.header.category': 'Category',
  'product.listing.header.hsnCode': 'HSN Code',
  'product.listing.header.currentStock': 'Current Stock',
  'product.listing.header.warehouse': 'Warehouse',
  'product.listing.header.valuation': 'Valuation',

  'product.detail.panel.header.label': 'Product Information',
  'product.detail.panel.productName.label': 'Product Name',
  'product.detail.panel.productCategory.label': 'Product Category',
  'product.detail.panel.firstProcurement.label': 'First Procurement',
  'product.detail.panel.manufacturer.label': 'Manufacturer',
  'product.detail.panel.brand.label': 'Brand',
  'product.detail.panel.articleNo.label': 'Article No',

  'product.detail.tab.header.outboundInventory': 'Outbound Inventory',
  'product.detail.tab.header.inboundInventory': 'Inbound Inventory',
  'product.detail.tab.inboundInventory.productName': 'Product Name',
  'product.detail.tab.inboundInventory.variant': 'Variant',
  'product.detail.tab.inboundInventory.sku': 'SKU',
  'product.detail.tab.inboundInventory.supplier': 'Supplier',
  'product.detail.tab.inboundInventory.invoiceNumber': 'Invoice #',
  'product.detail.tab.inboundInventory.quantity': 'Quantity',
  'product.detail.tab.inboundInventory.price': 'Price',
  'product.detail.tab.inboundInventory.txDate': 'Tx Date',

  'product.detail.tab.outboundInventory.productName': 'Product Name',
  'product.detail.tab.outboundInventory.variant': 'Variant',
  'product.detail.tab.outboundInventory.sku': 'SKU',
  'product.detail.tab.outboundInventory.customer': 'Customer',
  'product.detail.tab.outboundInventory.invoiceNumber': 'Invoice #',
  'product.detail.tab.outboundInventory.quantity': 'Quantity',
  'product.detail.tab.outboundInventory.price': 'Price',
  'product.detail.tab.outboundInventory.txDate': 'Tx Date',

  'setting.accountInformation.label': 'Account Information',
  'setting.companyInfo.label': 'Organization Info',
  'setting.companyInformation.label': 'Organization Information',
  'setting.companyInformation.message': 'Update your company profile, logo, address etc',
  'setting.changePassword.label': 'Change Password',
  'setting.changePassword.message': "We strongly recommend periodically changing your password.",
  'setting.administration.label': 'Administration',
  'setting.userManagement.label': 'User Management',
  'setting.passwordManagement.label': 'Password Management',
  'setting.documentSequence.label': 'Document Sequence',
  'setting.qualityCheck.label': 'Quality Check',
  'setting.qualityCheckSetting.label': 'Quality Check Setting',
  'setting.userManagement.message': 'You are powered with creating multiple users for your business. You can have granular control of what operations users can perform or what data users can see.',
  'setting.documentSequence.message': 'You can override the default system-generated numbers for your Purchase Order, Sales Order, Invoice, etc.',
  'setting.qualityCheck.message': 'You can enable quality check setting',
  'setting.customizations.label': 'Customizations',
  'setting.emailTemplates.label': 'Email Templates',
  'setting.emailTemplates.message': 'Not satisfied with your current plan. No worries, you can select the plan that best suits your business needs. You can set up billing frequency (Monthly, Quarterly, or Annually) for the subscription.',
  'setting.remarks.label': 'Remarks',
  'setting.remarks.message': 'Remarks that are printed on RFQ, Quotation, Invoice, Sales & Purchase Order and on other transactions.',
  'setting.documentName.label': 'Document Name',
  'setting.documentName.message': 'You can override the default system-generated document name for your Purchase Order, Sales Order, Invoice, etc.',
  'setting.documentApproval.label': 'Document Approval',
  'setting.documentApproval.message': 'You can override the default settings for document approval.',
  'setting.enableTestData.message': 'Enable Test Data',

  'setting.userManagement.updatePermissions.label': 'Update Permissions',
  'setting.userManagement.revokeAccess.label': 'Revoke Access',
  'setting.userManagement.listing.title': 'Title',
  'setting.userManagement.listing.firstName': 'First Name',
  'setting.userManagement.listing.middleName': 'Middle Name',
  'setting.userManagement.listing.lastName': 'Last Name',
  'setting.userManagement.listing.callName': 'Call Name',
  'setting.userManagement.listing.phone': 'Phone',
  'setting.userManagement.listing.mobile': 'Mobile',
  // 'setting.userManagement.listing.status': 'Status',
  'setting.userManagement.listing.email': 'Email',
  'setting.userManagement.listing.createdDate': 'Created Date',
  'setting.userManagement.addUser.label': 'Add User',
  'setting.userManagement.addEmployee.label': 'Add New Employee',

  'setting.marketplaceConfiguration.label': 'Online Store Setup',
  'setting.marketplaceConfiguration.taxes.label': 'Taxes',
  'setting.marketplaceConfiguration.taxes.msg': 'You can setup how you want to collect Tax from your customer. You can setup automatic tax calculation or manual setup.',
  'setting.marketplaceConfiguration.shipping.label': 'Shipping',
  'setting.marketplaceConfiguration.shipping.msg': 'You can setup various shipping options and define the shipping rates. You can configure carriers to provide realtime various rates.',

  'marketplaceConfiguration.shippingRule.form.name.freeShipping': 'Free Shipping',
  'marketplaceConfiguration.shippingRule.form.name.flatRate': 'Flat Rate Shipping',
  'marketplaceConfiguration.shippingRule.form.name.rateByWeight': 'Rate By Weight Shipping',
  'marketplaceConfiguration.shippingRule.form.name.rateByPrice': 'Rate By Price Shipping',

  'marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.level': 'Shipping Name to displayed at checkout',
  'marketplaceConfiguration.shippingRule.form.field.checkoutShippingName.placeholder': 'Shipping Name',
  'marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.level': 'Estimated Delivery ',
  'marketplaceConfiguration.shippingRule.form.field.estimatedDelivery.placeholder': 'Estimated Delivery',
  'marketplaceConfiguration.shippingRule.form.field.rate.level': 'Flat Rate',
  'marketplaceConfiguration.shippingRule.form.field.rate.placeholder': 'Rate',
  'marketplaceConfiguration.shippingRule.form.field.offerFreeShipping.level': 'Offer free shipping when a customer buys over a certain amount',
  'marketplaceConfiguration.shippingRule.form.field.addHandlingFee.level': 'Add a handling fee to every order',
  'marketplaceConfiguration.shippingRule.form.field.addRange': 'Add Range',
  'marketplaceConfiguration.shippingRule.form.field.weightRange': 'Weight Range',
  'marketplaceConfiguration.shippingRule.form.field.priceRange': 'Price Range',

  'setting.marketplaceConfiguration.taxSetting.yesConfirm.popup': 'Are you sure to change tax setting, you collected tax in selling price onwards?',
  'setting.marketplaceConfiguration.taxSetting.noConfirm.popup': 'Are you sure to change tax setting?',
  'setting.marketplaceConfiguration.taxes.listing.header.countryName': 'Country',
  'setting.marketplaceConfiguration.taxes.listing.header.isPartOfSellingprice': 'Tax Included in Price?',
  'setting.marketplaceConfiguration.taxes.listing.header.totalStates': 'Total States',
  'setting.marketplaceConfiguration.taxes.listing.header.minRate': 'Min Rate',
  'setting.marketplaceConfiguration.taxes.listing.header.maxRate': 'Max Rate',
  'setting.marketplaceConfiguration.taxes.listing.header.configureStates': 'Configure States',
  'setting.marketplaceConfiguration.taxes.listing.header.taxRate': 'Is Tax Automated?',
  'setting.marketplaceConfiguration.taxes.currentRate.manual.msg': 'Your current is Manual',
  'setting.marketplaceConfiguration.taxes.currentRate.automatic.msg': 'Your current is Automatic',
  'setting.marketplaceConfiguration.taxes.switch.manual.msg': 'Switch to Manual Tax Rate?',
  'setting.marketplaceConfiguration.taxes.switch.automatic.msg': 'Automate Tax Rate',
  'setting.marketplaceConfiguration.taxes.switch.manual.popup.msg': 'Do you want to switch manual tax rate?',
  'setting.marketplaceConfiguration.taxes.switch.automatic.popup.msg': 'Do you want to switch automatic tax rate?',
  'setting.marketplaceConfiguration.taxes.model.name': 'Add Country',
  'setting.marketplaceConfiguration.taxes.model.field.country': 'Select the country you want to collect tax',
  'setting.marketplaceConfiguration.shipping.model.field.country': 'Select the country you want to setup shipping',
  'setting.marketplaceConfiguration.taxes.model.field.state': 'In which State?',
  'setting.marketplaceConfiguration.taxes.model.field.taxRate': 'Tax Rate',
  'setting.marketplaceConfiguration.taxes.model.field.taxOnShipping': 'Tax on Shipping',
  'setting.marketplaceConfiguration.taxes.stateTaxes.listing.header.state': 'State',
  'setting.marketplaceConfiguration.taxes.stateTaxes.listing.header.taxRate': 'Tax Rate',
  'setting.marketplaceConfiguration.taxes.stateTaxes.listing.header.taxOnShipping': 'Tax On Shipping',
  'setting.marketplaceConfiguration.taxes.addMarketplaceState.model.name': 'Add Marketplcae State',
  'setting.marketplaceConfiguration.taxes.editMarketplaceState.model.name': 'Update Marketplcae State',

  'setting.marketplaceConfiguration.shipping.listing.header.country': 'Country',
  'setting.marketplaceConfiguration.shipping.listing.header.state': 'State/Province/Terrorties',
  'setting.marketplaceConfiguration.shipping.listing.header.shippingChoice': 'Shipping Choice',

  'sidebar.salesPayment.popup.title': 'Sales Payment',
  'sidebar.purchasePayment.popup.title': 'Purchase Payment',
  'sidebar.newChartOfAccount.popup.title': 'New Chart of Account',

  'reports.title.agingOfAccountReceivable': 'Aging of Account Receivable',
  'reports.title.agingOfAccountPayable': 'Aging of Account Payable',
  'reports.title.customerPartyLedger': 'Customer Party Ledger',
  'reports.title.supplierPartyLedger': 'Supplier Party Ledger',

  'reports.title.customer': 'Customer',
  'reports.customerByPayment.label': 'Customer by Payment',
  'reports.customerByPayment.message': 'This report lists all customers by payment',
  'reports.agedDebtors.label': 'Aged Debtors',
  'reports.agedDebtors.message': 'This report lists all customers by the due amount',
  'reports.partyLedger.label': 'Party Ledger',
  'reports.partyLedger.message': 'Show Party Ledger Report',

  'reports.title.supplier': 'Supplier',
  'reports.supplierByPayment.label': 'Supplier by Payment',
  'reports.supplierByPayment.message': 'This report lists all suppliers by payment',
  'reports.agedCreditors.label': 'Aged Creditors',
  'reports.agedCreditors.message': 'This report lists all suppliers by the due amount',

  'reports.title.salesReports.label': 'Sales Reports',
  'reports.salesByCustomer.label': 'Sales by Customer',
  'reports.salesByCustomer.message': 'This report lists customer sales activities',
  'reports.salesByProduct.label': 'Sales by Product',
  'reports.salesByProduct.message': 'This report lists all sales by the product',
  'reports.salesOrderByFulfillment.label': 'Sales Order by Fulfillment',
  'reports.salesOrderByFulfillment.message': 'This report lists all orders by their fulfillment status',

  'reports.title.purchaseReports.label': 'Purchase Reports',
  'reports.purchaseBySupplier.label': 'Purchase by Supplier',
  'reports.purchaseBySupplier.message': 'This report lists supplier purchase activities',
  'reports.purchaseByFulfillment.label': 'Purchase by Fulfillment',
  'reports.purchaseByFulfillment.message': 'This report lists all purchases by their fulfillment status',

  'reports.title.inventoryReports.label': 'Inventory Reports',
  'reports.agedInventory.label': 'Aged Inventory',
  'reports.agedInventory.message': 'The stock aging report lists all inventory items that are in the stock based on their incoming date',
  'reports.inventoryValuation.label': 'Inventory Valuation',
  'reports.inventoryValuation.message': 'The Inventory Valuation Summary report includes the total cost of stock across warehouses',
  'reports.lowStockReport.label': 'Low Stock Report',
  'reports.lowStockReport.message': 'Low Stock Report lists all items across warehouses that are low on inventory based on minimum stock levels defined against each item',
  'reports.fastestSellingProduct.label': 'Fastest Selling Product',
  'reports.fastestSellingProduct.message': 'This report lists all items that are selling fast. This report helps to plan stock purchase in advance',
  'reports.outOfStockReport.label': 'Out of Stock Report',
  'reports.outOfStockReport.message': 'Out of Stock report lists all items which are not available in stock across warehouses',

  'supplier.listing.actionMenu.title.newActivity': 'New Activity',
  'supplier.listing.actionMenu.newActivity': 'Log Activity',
  'supplier.listing.actionMenu.title.newCommunication': 'New Communication',
  'supplier.listing.actionMenu.newCommunication': 'Log Communication',
  'supplier.listing.actionMenu.downloadLedgerStatement': 'Download Ledger Statement',
  'supplier.listing.actionMenu.title.contact': 'Contact',
  'supplier.listing.actionMenu.contact': 'Show Contact',
  'supplier.listing.actionMenu.title.address': 'Address',
  'supplier.listing.actionMenu.address': 'Show Address',
  'supplier.listing.actionMenu.createPurchaseOrder': 'Create Purchase Order',
  'supplier.listing.actionMenu.bookPurchase': 'Book Purchase',
  'supplier.listing.actionMenu.createDebitMemo': 'Create Debit Memo',
  'supplier.listing.actionMenu.makePayment': 'Make Payment',
  'supplier.listing.actionMenu.makePayment.title': 'Purchase Payment',
  'supplier.listing.actionMenu.editSupplier': 'Edit Supplier',
  'supplier.listing.actionMenu.deleteSupplier': 'Delete Supplier',
  'supplier.listing.validation.failed.label': 'Validation Failed',
  'supplier.listing.validation.failed.message': 'No supplier selected. Please select supplier(s) before deleting',

  'supplier.listing.showContact.gridHeader.primaryContact': 'Primary Contact',
  'supplier.listing.showContact.gridHeader.title': 'Title',
  'supplier.listing.showContact.gridHeader.firstName': 'First Name',
  'supplier.listing.showContact.gridHeader.lastName': 'Last Name',
  'supplier.listing.showContact.gridHeader.callName': 'Call Name',
  'supplier.listing.showContact.gridHeader.workPhone': 'Work Phone',
  'supplier.listing.showContact.gridHeader.mobile': 'Mobile',
  'supplier.listing.showContact.gridHeader.faxNumber': 'Fax Number',
  'supplier.listing.showContact.gridHeader.email': 'Email',

  'supplier.listing.showAddress.gridHeader.primaryAddress': 'Primary Address',
  'supplier.listing.showAddress.gridHeader.streetAddress1': 'Street Address1',
  'supplier.listing.showAddress.gridHeader.streetAddress2': 'Street Address2',
  'supplier.listing.showAddress.gridHeader.city': 'City',
  'supplier.listing.showAddress.gridHeader.country': 'Country',
  'supplier.listing.showAddress.gridHeader.state': 'State/Province/Region',
  'supplier.listing.showAddress.gridHeader.postalCode': 'Zip/Postal',

  'supplier.listing.logCommunication.communicationType.label': 'Communication Type',
  'supplier.listing.logCommunication.communicationType.tooltip': 'Communication Type could be Email, Courier, Fax etc',
  'supplier.listing.logCommunication.communicationType.validationMessage': 'Communication mode is required',
  'supplier.listing.logCommunication.description.label': 'Description',
  'supplier.listing.logCommunication.description.tooltip': 'Brief description about the communication',
  'supplier.listing.logCommunication.communicatedTo.label': 'Communicated to',
  'supplier.listing.logCommunication.communicatedTo.tooltip': 'It could be email address, fax number, full postal address',
  'supplier.listing.logCommunication.communicatedBy.label': 'Communicated by',
  'communication.listing.heading.communicationEmail' : " Communication Email",
  'communication.listing.heading.CommunicationEmailList' : "Email List",
  'communication.listing.heading.commination' : "Communication",

  

  'supplier.listing.logActivity.activityType.label': 'Activity Type',
  'supplier.listing.logActivity.description.label': 'Description',
  'supplier.listing.logActivity.description.placeholder': 'Brief description about the activity',
  'supplier.listing.logActivity.refNumber.label': 'Ref #',
  'supplier.listing.logActivity.refNumber.tooltip': 'This could be transaction name or transaction number relevant to this activity',
  'supplier.listing.logActivity.refNumber.placeholder': 'Ref Number',
  // 'supplier.listing.logActivity.status.label': 'Status',
  'supplier.listing.logActivity.priority.label': 'Priority',
  'supplier.listing.logActivity.assignTo.label': 'Assign To',
  'supplier.listing.logActivity.activityDueDate.label': 'Activity Due Date',
  'supplier.listing.logActivity.ActivityClosedDate.label': 'Activity Closed Date',

  'supplier.detail.activity.gridHeader.activityType': 'Activity Type',
  'supplier.detail.activity.gridHeader.description': 'Description',
  'supplier.detail.activity.gridHeader.refNumber': 'Ref #',
  // 'supplier.detail.activity.gridHeader.status': 'Status',
  'supplier.detail.activity.gridHeader.priority': 'Priority',
  'supplier.detail.activity.gridHeader.assignedTo': 'Assigned To',
  'supplier.detail.activity.gridHeader.dueDate': 'Due Date',
  'supplier.detail.activity.gridHeader.closedDate': 'Closed Date',
  'supplier.detail.activity.gridHeader.dateCreated': 'Date Created',
  'supplier.detail.activity.gridHeader.documents': 'Documents',

  'supplier.detail.communication.gridHeader.type': 'Type',
  'supplier.detail.communication.gridHeader.description': 'Description',
  'supplier.detail.communication.gridHeader.communicatedTo': 'Communicated to',
  'supplier.detail.communication.gridHeader.transaction': 'Transaction',
  'supplier.detail.communication.gridHeader.date': 'Date',
  'supplier.detail.communication.gridHeader.origination': 'Origination',
  'supplier.detail.communication.gridHeader.communicatedBy': 'Communicated by',

  'supplier.detail.transaction.gridHeader.transaction': 'Transaction',
  'supplier.detail.transaction.gridHeader.transactionNumber': 'Transaction #',
  'supplier.detail.transaction.gridHeader.transactionAmount': 'Transaction Amount',
  'supplier.detail.transaction.gridHeader.transactionDate': 'Transaction Date',
  // 'supplier.detail.transaction.gridHeader.status': 'Status',
  'supplier.detail.transaction.gridHeader.invoiceNumber': 'Invoice Number',
  'supplier.detail.transaction.gridHeader.poNumber': 'PO Number',
  'supplier.detail.transaction.gridHeader.amount': 'Amount',
  'supplier.detail.transaction.gridHeader.paymentDueDate': 'Payment Due Date',
  'supplier.detail.transaction.gridHeader.paymentTerm': 'Payment Term',
  'supplier.detail.transaction.gridHeader.date': 'Date',
  'supplier.detail.transaction.gridHeader.paymentNumber': 'Payment Number',
  'supplier.detail.transaction.gridHeader.priority': 'Priority',


  'communication.account.addSubAccount.model.name': 'Add Account',
  'communication.account.addSubAccount.model.field.accountName': 'Account Name',


  'modal.settings.companyInfo.aboutCompany.companySize': 'Organization Size',
  'modal.settings.companyInfo.aboutCompany.companySize.placeholder': 'Select Organization Size',
  'modal.settings.companyInfo.aboutCompany.industry': 'Industry',
  'modal.settings.companyInfo.aboutCompany.industry.placeholder': 'Select Industry',
  'modal.settings.companyInfo.aboutCompany.inventoryItemQty': 'Inventory Item Qty',
  'modal.settings.companyInfo.aboutCompany.inventoryItemQty.placeholder': 'Select Inventory Item Qty',
  'modal.settings.companyInfo.aboutCompany.averageMonthlyOrders': 'Average Monthly Orders',
  'modal.settings.companyInfo.aboutCompany.averageMonthlyOrders.placeholder': 'Select Average Monthly Orders',
  'modal.settings.companyInfo.aboutCompany.howDidYouHear': 'How Did You Hear?',
  'modal.settings.companyInfo.aboutCompany.howDidYouHear.placeholder': 'Select source of reaching  us',

  'modal.settings.companyInfo.bankInfo.bankName': 'Bank Name',
  'modal.settings.companyInfo.bankInfo.accountNumber': 'Account Number',
  'modal.settings.companyInfo.bankInfo.ifsc': 'IFSC/SWIFT/BIC',
  'modal.settings.companyInfo.bankInfo.newAccount': 'Add New Bank Account',

  'modal.settings.companyInfo.company.companyName': 'Organization Name',
  'modal.settings.companyInfo.company.phoneNumber': 'Phone Number',
  'modal.settings.companyInfo.company.faxNumber': 'Fax Number',
  'modal.settings.companyInfo.company.website': 'Website',
  'modal.settings.companyInfo.company.decimalDigitsLength': 'Decimal Digits Length',
  'modal.settings.companyInfo.company.updateInventoryOnSalesInvoice': 'Update Inventory On Sales Invoice',
  'modal.settings.companyInfo.company.updateInventoryOnPurchaseInvoice': 'Update Inventory On Purchase Invoice',

  'modal.settings.companyInfo.costCenter.costCenterName': 'Cost Center Name',
  'modal.settings.companyInfo.costCenter.costCenterNumber': 'Cost Center Number',
  'modal.settings.companyInfo.costCenter.department': 'Department',
  'modal.settings.companyInfo.costCenter.description': 'Description',
  'modal.settings.companyInfo.costCenter.newCostCenter': 'Add New Cost center',

  'modal.settings.companyInfo.taxInfo.taxType': 'Tax Type',
  'modal.settings.companyInfo.taxInfo.number': 'Number',
  'modal.settings.companyInfo.taxInfo.populateOnTransaction': 'Populate on Transaction',
  'modal.settings.companyInfo.taxInfo.populateOnTransaction.tooltip': 'If selected then tax identification will be displayed on pdf document',
  'modal.settings.companyInfo.taxInfo.addLine': 'Add Line',

  'modal.settings.documentApproval.transaction': 'Transaction',

  'modal.settings.quilityCheck.qualityChecklistRequired': 'Quality Checklist Required',
  'modal.settings.quilityCheck.generateShipmentWithoutQuilityCheck': 'Generate Shipment Without Quility Check',

  'modal.settings.documentnumber.transaction': 'Transaction',
  'modal.settings.documentnumber.numberExample': 'Sample',
  'modal.settings.documentnumber.numberInfo': 'Number Info',
  'modal.settings.documentnumber.nextSequence': 'Next Sequence #',
  'modal.settings.documentnumber.nextSequence.tooltip': 'Enter the Sequence number you want to start with',
  'modal.settings.documentnumber.action': 'Action',

  'modal.settings.manageUser.permissions.view': 'View',
  'modal.settings.manageUser.permissions.create': 'Create',
  'modal.settings.manageUser.permissions.update': 'Update',
  'modal.settings.manageUser.permissions.delete': 'Delete',
  'modal.settings.manageUser.permissions.fullControl': 'Full Control',
  'modal.settings.manageUser.employee': 'Employee',
  'modal.settings.manageUser.email': 'Email',
  'modal.settings.manageUser.email.placeholder': 'Required and activation email will be sent',
  'modal.settings.manageUser.notifyUser': 'Notify User',
  'modal.settings.manageUser.password': 'Password',
  'modal.settings.manageUser.password.placeholder': 'Password',
  'modal.settings.manageUser.password.validationMessage': 'Password is required',
  'modal.settings.manageUser.confirmPassword': 'Confirm Password',
  'modal.settings.manageUser.confirmPassword.placeholder': 'Repeat Password',
  'modal.settings.manageUser.employee.requiredMessage': 'Employee is required',
  'modal.settings.manageUser.employee.validationMessage': 'Incorrect Email',

  'modal.supplier.applyPurchaseCredit.debitMemoNumber': 'Debit Memo #',
  'modal.supplier.applyPurchaseCredit.debitMemoDate': 'Debit Memo Date',
  'modal.supplier.applyPurchaseCredit.debitAmount': 'Debit Amount',
  'modal.supplier.applyPurchaseCredit.debitsAvailable': 'Debits Available',
  'modal.supplier.applyPurchaseCredit.amountToDebit': 'Amount To Debit',
  'modal.supplier.applyPurchaseCredit.remarks': 'Remarks',
  'modal.supplier.applyPurchaseCredit.invoiceNumber': 'Invoice #',
  'modal.supplier.applyPurchaseCredit.invoiceDate': 'Invoice Date',
  'modal.supplier.applyPurchaseCredit.dueDate': 'Due Date',
  'modal.supplier.applyPurchaseCredit.invoiceAmount': 'Invoice Amount',
  'modal.supplier.applyPurchaseCredit.totalAmountToDebit': 'Amount to Debit',
  'modal.supplier.applyPurchaseCredit.invoiceDue': 'Invoice Due',
  'modal.supplier.applyPurchaseCredit.applyCredits': 'Apply Credits',
  'modal.txDetails.rfq.convertToPO': 'Convert to PO',
  'modal.txDetails.rfq.approveRFQ': 'Approve RFQ',
  'modal.txDetails.rfq.rfqDate': 'RFQ Date',
  'modal.txDetails.rfq.bidClosingDate': 'Bid Closing Date',
  'modal.txDetails.rfq.customerRFQNumber': 'Customer RFQ #',


  'modal.txDetails.creditMemo.creditMemoNumber': 'Credit Memo #',
  'modal.txDetails.creditMemo.creditMemoDate': 'Credit Memo Date',
  'modal.txDetails.creditMemo.creditMemoExp': 'Credit Memo Exp. Date',

  'modal.txDetails.common.billTo': 'Bill To:',
  'modal.txDetails.common.shipTo': 'Ship To:',
  'modal.txDetails.common.shippingAddress': 'Shipping Address',
  'modal.txDetails.common.placeOfSupply': 'Place of Supply',
  'modal.txDetails.common.foreignCurrency': 'Foreign Currency',
  'modal.txDetails.common.exchangeRate': 'Exchange Rate',
  'modal.txDetails.common.project': 'Project',
  'modal.txDetails.common.dropshipOrder': 'Dropship Order?',
  'modal.txDetails.common.refNumber': 'Ref #',
  'modal.txDetails.common.paymentTerm': 'Payment Term',
  'modal.txDetails.common.priority': 'Priority',
  'modal.txDetails.common.product': 'Product',
  'modal.txDetails.common.description': 'Description',
  'modal.txDetails.common.qty': 'Qty',
  'modal.txDetails.common.basePrice': 'Base Price',
  'modal.txDetails.common.specialDiscount': 'Special Discount',
  'modal.txDetails.common.rate': 'Rate',
  'modal.txDetails.common.hsn': 'HSN',
  'modal.txDetails.common.discount': 'Discount',
  'modal.txDetails.common.tax': 'Tax',
  'modal.txDetails.common.netAmount': 'Net Amount',
  'modal.txDetails.common.attachments': 'Attachments',
  'modal.txDetails.common.internalRemarks': 'Internal Remarks',
  'modal.txDetails.common.supplierRemarks': 'Supplier Remarks',
  'modal.txDetails.common.customerRemarks': 'Customer Remarks',
  'modal.txDetails.common.remarks': 'Remarks',
  'modal.txDetails.common.employeeRemarks': 'Employee Remarks',
  'modal.txDetails.common.shippingInstructions': 'Shipping Instructions',
  'modal.txDetails.common.subTotal': 'Sub Total',
  'modal.txDetails.common.expense': 'Expense',
  'modal.txDetails.common.grandTotal': 'Grand Total',
  'modal.txDetails.common.deleteStamp': 'Delete Stamp',
  'modal.txDetails.common.edit': 'Edit',
  'modal.txDetails.common.clone': 'Clone',
  'modal.txDetails.common.delete': 'Delete',
  'modal.txDetails.common.void': 'Void',
  'modal.txDetails.common.void.confirmation': 'Are you sure you want to void this transaction?',
  'modal.txDetails.common.delete.confirmation': 'Are you sure you want to delete this transaction?',
  'modal.txDetails.common.email': 'Email',
  'modal.txDetails.common.downloadPDF': 'Download PDF',
  'modal.txDetails.common.download': 'Download',
  'modal.txDetails.common.print': 'Print',
  'modal.txDetails.common.auditTrail': 'Audit Trail',
  'modal.txDetails.common.updateStatus': 'Update Status',
  'modal.txDetails.common.delegateApprover': 'Delegate Approver',
  'modal.txDetails.common.reject': 'Reject',

  'modal.txDetails.package.labelDownload.itemName': 'Item Name',
  'modal.txDetails.package.labelDownload.poNumber': 'PO #',
  'modal.txDetails.package.labelDownload.poLineNumber': 'PO Line #',
  'modal.txDetails.package.labelDownload.stockNumber': 'Stock #',
  'modal.txDetails.package.labelDownload.qty': 'Quantity',
  'modal.txDetails.package.qtyYetToPack': 'Qty yet to pack',
  'modal.txDetails.package.soStatus': 'SO Status',
  'modal.txDetails.package.createPackage': 'Create Package',

  'modal.txDetails.purchaseOrder.approvePO': 'Approve PO',
  'modal.txDetails.purchaseOrder.convertToID': 'Convert to GRN',
  'modal.txDetails.purchaseOrder.convertToInboundDelivery': 'Convert to GRN',
  'modal.txDetails.purchaseOrder.poNumber': 'PO #',
  'modal.txDetails.purchaseOrder.poDate': 'PO Date',
  'modal.txDetails.purchaseOrder.poDueDate': 'PO Due Date',
  'modal.txDetails.purchaseOrder.expectedDelivery': 'Expected Delivery',
  'modal.txDetails.purchaseOrder.rfqNumber': 'RFQ #',
  'modal.txDetails.purchaseOrder.supplierQuoteNumber': 'Supplier Quote #',

  'modal.txDetails.purchaseInvoice.payInvoice': 'Pay Invoice',
  'modal.txDetails.purchaseInvoice.approveInvoice': 'Approve Invoice',
  'modal.txDetails.purchaseInvoice.availableDebits': 'Available Debits',
  'modal.txDetails.purchaseInvoice.applyDebits': 'Apply Debits',
  'modal.txDetails.purchaseInvoice.invoiceNumber': 'Invoice #',
  'modal.txDetails.purchaseOrder.invoiceDate': 'Invoice Date',
  'modal.txDetails.purchaseOrder.dueDate': 'Due Date',

  'modal.txDetails.purchaseRequest.approvePR': 'Approve PR',
  'modal.txDetails.purchaseRequest.requestor': 'Requestor',
  'modal.txDetails.purchaseRequest.owner': 'Owner',
  'modal.txDetails.purchaseRequest.purchaseRequestNumber': 'Purchase Request #',
  'modal.txDetails.purchaseRequest.prDate': 'PR date',
  'modal.txDetails.purchaseRequest.expireDate': 'Expire Date',
  'modal.txDetails.purchaseRequest.reason': 'Reason',
  'modal.txDetails.purchaseRequest.convertTo.rfq': 'RFQ',
  'modal.txDetails.purchaseRequest.convertTo.po': 'Purchase Order',

  'modal.txDetails.debitMemo.debitMemoNumber': 'Debit Memo #',
  'modal.txDetails.debitMemo.debitMemoDate': 'Debit Memo Date',

  'modal.txDetails.inboundDelivery.inboundDeliveryNumber': 'GRN #',
  'modal.txDetails.inboundDelivery.poNumber': 'PO #',
  'modal.txDetails.inboundDelivery.inboundDeliveryDate': 'GRN Date',
  'modal.txDetails.inboundDelivery.rfqNumber': 'RFQ #',
  'modal.txDetails.inboundDelivery.supplierQuoteNumber': 'Supplier Quote #',
  'modal.txDetails.inboundDelivery.convertToInvoice': 'Convert to Invoice',

  'modal.txDetails.payment.supplierName': 'Supplier Name',
  'modal.txDetails.payment.paymentNumber': 'Payment Number',
  'modal.txDetails.payment.invoiceNumber': 'Invoice Number',
  'modal.txDetails.payment.paymentDate': 'Payment Date',
  'modal.txDetails.payment.paymentMode': 'Payment Mode',
  'modal.txDetails.payment.paymentAmount': 'Payment Amount',
  'modal.txDetails.payment.invoiceAmount': 'Invoice Amount',
  'modal.txDetails.payment.refNumber': 'Ref #',
  'modal.txDetails.payment.remarks': 'Remarks',
  'modal.txDetails.payment.project': 'Project',

  'modal.txDetails.inquiry.inquiryNumber': 'Inquiry #',
  'modal.txDetails.inquiry.inquiryDate': 'Inquiry Date',
  'modal.txDetails.inquiry.inquiryClosingDate': 'Inquiry Closing Date',
  'modal.txDetails.inquiry.customerInquiryNumber': 'Customer Inquiry #',
  'modal.txDetails.inquiry.convertTo.salesQuote': 'Sales Quote',
  'modal.txDetails.inquiry.convertTo.purchaseRequest': 'Purchase Request',

  'modal.customer.applySalesCredit.creditMemoNumber': 'Credit Memo #',
  'modal.customer.applySalesCredit.creditMemoDate': 'Credit Memo Date',
  'modal.customer.applySalesCredit.creditAmount': 'Credit Amount',
  'modal.customer.applySalesCredit.creditsAvailable': 'Credits Available',
  'modal.customer.applySalesCredit.amountToCredit': 'Amount To Credit',
  'modal.customer.applySalesCredit.remarks': 'Remarks',
  'modal.customer.applySalesCredit.invoiceNumber': 'Invoice #',
  'modal.customer.applySalesCredit.invoiceDate': 'Invoice Date',
  'modal.customer.applySalesCredit.dueDate': 'Due Date',
  'modal.customer.applySalesCredit.invoiceAmount': 'Invoice Amount',
  'modal.customer.applySalesCredit.totalAmountToCredit': 'Amount to Credit',
  'modal.customer.applySalesCredit.invoiceDue': 'Invoice Due',
  'modal.customer.applySalesCredit.applyCredits': 'Apply Credits',

  'reports.agedDebtor.header.pastDue.Last30Days': 'Past Due 1 – 30 Days',
  'reports.agedDebtor.header.pastDue.30To60Days': 'Past Due 31 – 60 Days',
  'reports.agedDebtor.header.pastDue.60To90Days': 'Past Due 61 – 90 Days',
  'reports.agedDebtor.header.pastDue.above90Days': 'Past Due 91+ Days',

  'modal.reports.agedDebtors.customerName': 'Customer Name',
  'modal.reports.agedDebtors.invoiceNumber': 'Invoice Number',
  'modal.reports.agedDebtors.invoiceDate': 'Invoice Date',
  'modal.reports.agedDebtors.invoiceAmount': 'Invoice Amount',
  // 'modal.reports.agedDebtors.status' : 'Status',

  'reports.agedDebtor.header.companyName': 'Organization Name',
  'reports.agedDebtor.header.totalReceivable': 'Total Receivable',

  'modal.reports.partyLedger.gridHeader.date': 'Date',
  'modal.reports.partyLedger.gridHeader.transaction': 'Transaction',
  'modal.reports.partyLedger.gridHeader.transactionNumber': 'Transaction #',
  'modal.reports.partyLedger.gridHeader.debit': 'Debit',
  'modal.reports.partyLedger.gridHeader.credit': "Credit",
  'modal.reports.partyLedger.startDate.label': "Start Date",
  'modal.reports.partyLedger.endDate.label': "End Date",
  'modal.reports.partyLedger.submit.label': "Submit",
  'modal.reports.partyLedger.total.label': "Total",
  'modal.reports.partyLedger.closingAmount.label': 'Closing Amount (Dr)',
  'modal.reports.partyLedger.download.label': 'Download',

  'customer.salesOrder.listing.header.draftNo': 'Draft #',

  'modal.txDetails.package.label': 'Package',
  'modal.txDetails.package.length': 'Length',
  'modal.txDetails.package.width': 'Width',
  'modal.txDetails.package.height': 'Height',
  'modal.txDetails.package.weight': 'Weight',
  'modal.txDetails.package.packageNumber': 'Package Number',
  'modal.txDetails.package.packageDate': 'Package Date',
  'modal.txDetails.package.soNumber': 'SO #',
  'modal.txDetails.package.soDate': 'SO Date',
  'modal.txDetails.package.poNumber': 'PO #',
  // 'modal.txDetails.package.status': 'Status',
  'modal.txDetails.package.deliveryDate': 'Delivery Date',
  'modal.txDetails.package.qtyOrdered': 'Qty Ordered',
  'modal.txDetails.package.qtyPacked': 'Qty Packed',
  'modal.txDetails.package.packageLabel': 'Package Label',
  'modal.txDetails.package.convertToShipment': 'Convert to Shipment',
  'modal.txDetails.package.qualityCheck': 'Quality Check',

  'forgot.btn.addQualityChecklist': 'Add Quality Check',
  'showAllList.text': 'Show All',
  'modal.certificateOfConformity.title': 'Certificate of Conformity',
  'modal.shippingInvoice.title': 'Shipping Invoice',
  'modal.txDetails.shipment.markAsShipped': 'Mark As Shipped',
  'modal.txDetails.shipment.markAsDelivered': 'Mark As Delivered',
  'modal.txDetails.shipment.shippingInvoice': 'Shipping Invoice',
  'modal.txDetails.shipment.updateDeliveryDate': 'Update Delivery Date',
  'modal.txDetails.shipment.shipmentNumber': 'Shipment #',
  'modal.txDetails.shipment.carrier': 'Carrier',
  'modal.txDetails.shipment.trackingNumber': 'Tracking #',
  'modal.txDetails.shipment.freightType': 'Freight Type',
  'modal.txDetails.shipment.consignee': 'Consignee',
  'modal.txDetails.shipment.shipmentDate': 'Shipment Date',
  'modal.txDetails.shipment.expectedDelivery': 'Expected Delivery',
  'modal.txDetails.shipment.poNumber': 'PO #',
  'modal.txDetails.shipment.soNumber': 'SO #',
  'modal.txDetails.shipment.packageNumber': 'Package #',
  'modal.txDetails.shipment.qtyOrdered': 'Qty Ordered',
  'modal.txDetails.shipment.qtyPacked': 'Qty Packed',
  'modal.txDetails.shipment.qtyShipped': 'Qty Shipped',
  'supplier.purchaseOrder.form.gridHeader.qtyReceived': 'Qty Received',
  'supplier.purchaseOrder.form.gridHeader.qtyToReceive': 'Qty to Receive',

  'modal.txDetails.salesOrder.soNumber': 'SO #',
  'modal.txDetails.salesOrder.soDate': 'SO Date',
  'modal.txDetails.salesOrder.deliveryDate': 'Delivery Date',
  'modal.txDetails.salesOrder.userStatus': 'User Status',
  'modal.txDetails.salesOrder.convertTo.purchaseRequest': 'Purchase Request',
  'modal.txDetails.salesOrder.convertTo.salesInvoice': 'Sales Invoice',
  'modal.txDetails.salesOrder.convertTo.proformaInvoice': 'Proforma Invoice',
  'modal.txDetails.salesOrder.convertTo.package': 'Package',
  'modal.txDetails.salesOrder.approveSO': 'Approve SO',
  'modal.txDetails.salesOrder.qualityCheck': 'Quality Check',
  'modal.txDetails.salesOrder.soSource': 'SO Source',
  'modal.txDetails.salesOrder.customerPO': 'Customer PO',
  'modal.txDetails.salesInvoice.payInvoice': 'Pay Invoice',

  'modal.common.project.projectName': 'Project Name',
  'modal.common.project.description': 'Description',
  'modal.common.project.departmentName': 'Department Name',
  'modal.common.project.projectOwner': 'Project Owner',
  'modal.common.project.startDate': 'Start Date',
  'modal.common.project.endDate': 'End Date',

  'modal.common.priceList.name': 'Name',
  'modal.common.priceList.description': 'Description',
  'modal.common.priceList.percentage': 'Percentage',
  'modal.common.priceList.startDate': 'Start Date',
  'modal.common.priceList.endDate': 'End Date',
  'modal.common.priceList.startDate.tooltip': 'Price list start  date',
  'modal.common.priceList.endDate.tooltip': 'Price list end date',

  'modal.txDetails.salesQuote.convertToSO': 'Convert to SO',
  'modal.txDetails.salesQuote.approveQuote': 'Approve Quote',
  'modal.txDetails.salesQuote.quoteNumber': 'Quote #',
  'modal.txDetails.salesQuote.quoteDate': 'Quote Date',
  'modal.txDetails.salesQuote.quoteExpiryDate': 'Quote Expiry Date',
  'modal.txDetails.salesQuote.rfqNumber': 'RFQ #',
  'modal.txDetails.salesQuote.customerRFQ': 'Customer RFQ',
  'modal.txDetails.salesQuote.customerRFQNumber': 'Customer RFQ #',
  'modal.txDetails.salesQuote.deliveryMethod': 'Delivery Method',
  'so.trackingNumber': 'Enter Tracking Number',
  'so.consignee': 'Shipment Receiver',
  'customer.salesComplaint.listing.header.salesComplaintNumber': 'Complaint #',
  'customer.salesComplaint.listing.header.assignedTo': 'Assigned To',
  'customer.salesComplaint.form.gridHeader.complaintDetails': 'Complaint Details',
  'customer.salesComplaint.listing.header.complaintType': 'Complaint Type',
  'customer.salesComplaint.listing.header.complaintClosedDate': 'Complaint Closed Date',
  'reason.placeholder': 'Enter Reason',
  'forgot.btn.addTransportData': 'Add Transport Data',

  'salesQuote.sqExpiryDate': 'Quote Expiry Date',

  'package.packageDetail.length': 'Length',
  'package.packageDetail.width': 'Width',
  'package.packageDetail.height': 'Height',
  'package.packageDetail.weight': 'Weight',

  'confirmation.staySignedIn': 'Stay Signed In',
  'confirmation.signout': 'Log Off ',

  // common  number
  'common.paymentNumber': 'Payment #',
  'common.invoiceNumber': 'Invoice #',
  'common.customer.poNumber': 'Customer PO #',
  'common.customer.inquiryNumber': 'Customer Inquiry #',
  'common.inquiryNumber': 'Inquiry #',
  'common.soNumber': 'SO #',
  'common.rfqNumber': 'RFQ #',
  'common.poNumber': 'PO #',

  'common.ref': 'Ref #',
  'modal.txDetails.common.uom': 'UOM',
  'modal.txDetails.common.sku': 'SKU',

  'invoiceAmount.text': 'Invoice Amount',
  'paymentAmount.text': 'Payment Amount',
  'common.dueAmount': 'Due Amount',
  'paymentTerm.text': 'Payment Term',
  'totalOrder.text': 'Total Orders',
  'openSalesOrder.text': 'Open Sales Orders',
  'salesRevenue.text': 'Sales Revenue',
  'paidInvoices.text': 'Paid Invoices',
  'communicationType.text': 'Communication Type',

  'common.salesPerson': 'Sales Person',
  'common.status': 'Status',
  'common.description': 'Description',
  'common.priority': 'Priority',
  'common.invoiceDate': 'Invoice Date',
  'common.dueDate': 'Due Date',
  'common.project': 'Project',
  'common.cancel': 'Cancel',
  'common.middleName': 'Middle Name',
  'common.phone': 'Phone',
  'common.customer': 'Customer',
  'common.supplier': 'Supplier',
  'common.suppliers': 'Suppliers(s)',

  'common.shipping.address': 'Shipping Address',
  'common.billing.address': 'Billing Address',

  'common.materialNumber': 'Material #',

  'partially.fulfilled': 'Partially Fulfilled',
  'fulfilled': 'Fulfilled',
  'shipped': 'Shipped',
  'not.shipped': 'Not Shipped',
  'completed': 'Completed',
  'not.started': 'Not Started',
  'not.required': 'Not Required',

  'incorrect.password.entered': 'An incorrect password is entered. Please enter the correct password and try again.',
  'insufficient.company.access': 'You don`t have access to the company, please contact the administrator.',
  'account.suspended': 'The account is currently suspended. Please contact the administrator.',
  'trial.license.expired': 'Your trial license has expired. Please contact support.',
  'email.exist': 'Email already exists. Please try different Email id.',
  'delieverd.package.cant.delete': 'Package can`t be deleted because it has been shipped and delivered.',
  'shipped.package.cant.delete': 'Package can`t be deleted because it has been shipped.',
  'delievered.shipment.cant.delete': 'Shipment can`t be deleted because it has been delivered',
  'empty.brand.name': 'The brand name is empty. Please enter the brand name and save it again.',
  'empty.category.name': 'The category name is empty. Please enter the category name and save it again.',
  'empty.manufacturer': 'The manufacturer`s name is empty. Please enter the manufacturer`s name and try again.',
  'empty.uom.name': 'UOM is empty. Please enter the UOM name and save it again.',
  'verification.code.does.not.match': 'The verification code does not match. Please enter the correct verification code as sent in the invite email.',
  'missing.relationsip.id': 'Relationship id is missing',
  'misisng.contact': 'Contact does not exist. Please refresh your page.',
  'primary.contact.cant.delete': 'Primary contact can`t be deleted.',
  'missing.address': 'The address does not exist. Please refresh your page.',
  'cant.delete.project': 'The project can`t be deleted because it has associated with one more transaction.',
  'cant.delete.inquiry.so.converted': 'Inquiry can`t be deleted because it has been converted to Sales Quote.',
  'cant.delete.inquiry.pr.converted': 'Inquiry can`t be deleted because it has been converted to Purchase Request.',
  'cant.update.void.invoice': 'Invoice can`t be updated because its voided',
  'cant.delete.delivered.shipment': 'Delivered shipment cant be deleted',
  'qty.to.invoice.error': 'Qty to invoice should not be more than qty ordered',
  'invoice.date.blank': 'Invoice date is blank. Please resubmit the request.',
  'invoice.due.date.blank': 'Invoice datedue  is blank. Please resubmit the request.',
  'sequence.id.used': 'This sequence Id is already used. Try different id',
  'cant.void.cash.invoice': 'Cash invoice can`t be voided.',
  'proforma.invoice.exist': 'Proforma Invoice already exists for this Sales Order.',
  'sequence.id.saved': 'Sequence id saved successfully.',
  'payment.cant.save.on.void.invoice': 'Payment can`t be applied on void invoice.',
  'credit.cant.save.on.void.invoice': 'Credit can`t be applied on void invoice.',
  'cant.update.cancelled.so': 'Sales order can`t be updated because it`s canceled.',
  'cant.update.fulfilled.so': 'Sales order can`t be updated because it has been fulfilled.',
  'cant.update.partially.fulfilled.so': 'Sales order can`t be updated because it has been partially fulfilled.',
  'insufficient.access.to.approve.so': 'You don`t have permission to approve this sales order',
  'so.already.approved': 'Sales Order has already been approved.',
  'cant.delete.so.invoice.created': 'Sales Order can`t be deleted because invoicing is done.',
  'cant.delete.so.partially.packed': 'Sales Order can`t be deleted because it`s partially packed.',
  'cant.delete.so.packed': 'Sales Order can`t be deleted because the packaging is completed.',
  'cant.delete.so.partially.shipped': 'Sales Order can`t be deleted because it`s partially shipped',
  'cant.delete.so.shipped': 'Sales Order can`t be deleted because shipment is completed.',
  'cant.cancel.so.partially.invoiced': 'Sales Order can`t be canceled because it`s partially invoiced.',
  'cant.cancel.so.invoiced': 'Sales Order can`t be canceled because invoicing is completed.',
  'cant.cancel.so.partially.packed': 'Sales Order can`t be canceled because it`s partially packed.',
  'cant.cancel.so.packed': 'Sales Order can`t be canceled because the packaging is completed.',
  'cant.cancel.so.partially.shipped': 'Sales Order can`t be canceled because it`s partially shipped.',
  'cant.cancel.so.shipped': 'Sales Order can`t be canceled because shipment is completed.',
  'no.permission.reject.so': 'You don`t have permission to reject the Sales Order.',
  'quote.already.approved': 'The quotation has already been approved. Check audit trail for details.',
  'cant.delete.quote.converted.to.so': 'Quote can`t be deleted because it has been converted to Sales Order',
  'customer.incorrect.upload.template': 'It appears incorrect customer template is used. You can download current template and try again.',
  'customer.incorrect.country.template': 'You are uploading incorrect country template. You can download template and try again to uplood.',
  'customer.upload.gst.15.char.required': 'GST Number must be 15 characters',
  'customer.upload.pan.10.char.required': 'PAN Number must be 10 characters.',
  'customer.upload.invalid.relatioship.date': 'Relationship date is not valid. Please enter valid date in Relationship Date column and try again.',
  'customer.upload.invalid.date.format': 'Incorrect date for Relationship Since Date must be in DD/MM/YYYY format',
  'customer.upload.website.length': 'Website name must not be more than 70 characters',
  'customer.upload.company.name.length': 'Company name must not be more than 75 characters',
  'customer.upload.email.length': 'Email address can`t be more than 75 characters',
  'customer.upload.fax.length': 'Fax number can`t be more than 15 characters',
  'customer.upload.first.name.length': 'First Name can`t be more than 20 characters',
  'customer.upload.job.title': 'Job Title can`t be more than 20 characters.',
  'customer.upload.last.name.length': 'Last name can`t be more than 20 characters.',
  'customer.upload.middle.name': 'Middle Name can`t be more than 20 characters',
  'customer.upload.department.name.length': 'Department name can`t be more than 30 characters',
  'customer.upload.call.name.length': 'Prefer call name can`t be more than 20 characters.',
  'customer.upload.work.phone.length': 'Work Phone can`t be more than 15 characters.',
  'customer.upload.city.length': 'City name can`t be more than 45 characters',
  'customer.upload.street1.length': 'Street Address1 can`t be more than 75 characters',
  'customer.upload.street2.length': 'Street address2 can`t be more than 75 characters',
  'customer.upload.postal.length': 'Postal/Zip code can`t be more than 25 characters',
  'Customer uploaded successfully': 'Customer uploaded successfully',
  'upload.fail.empty.file': 'Data not uploaded. The file may be empty. Please check the file and try again.',
  'cant.delete.customer': 'Customer can`t be deleted because one or more transactions are associated with the customer(s)',
  'customer.upload.cellphone.length': 'Phone # does have have the sufficient numbers. It must be in this format +19999999999',
  'stock.already.updated': 'Stock has already been updated for this Shipment',
  'new.inventory.warehouse.required': 'Please select a warehouse',
  'new.inventory.purchase.price.required.for.current.stock': 'Purchase price is required for the current stock for inventory valuation.',
  'new.inventory.invalid.variant': 'Invalid character entered for variant',
  'new.inventory.sku.required': 'Please provide SKU',
  'new.inventory.invalid.tax': 'The invalid tax provided. Please select the correct tax from the uplaod Excel file',
  'new.inventory.invalid.hsn': 'Invalid HSN Code provided. Please select HSN Code from HSN Code Column from Excel file',
  'new.inventory.missing.variant': 'Missing variant value. Please variant value and try again.',
  'inventory.upload.incorrect.template': 'It appears incorrect inventory template is used. You can download current template from import inventory popup and try again to upload.',
  'inventory.upload.duplicate.sku': 'Duplicate SKU found',
  'inventory.upload.incorrect.variant.attribut1': 'Incorrect Variant Attribute1 selected from the dropdown. Please select the correct value from the Variant Attribute1 column and try again to upload.',
  'inventory.upload.incorrect.variant.attribut2': 'Incorrect Variant Attribute2 selected from the dropdown. Please select the correct value from Variant Attribute2 column and try again to upload.',
  'inventory.upload.incorrect.variant.attribut3': 'Incorrect Variant Attribute3 selected from the dropdown. Please select the correct value from Variant Attribute3 column and try again to upload.',
  'inventory.upload.purchase.price.missing': 'The purchase price is required for the current stock for inventory valuation.',
  'inventory.upload.invalid.char.variant1': 'Invalid character entered for variant1. Correct variant1 and try again',
  'inventory.upload.invalid.char.variant2': 'Invalid character entered for variant1. Correct variant2 and try again',
  'inventory.upload.invalid.char.variant3': 'Invalid character entered for variant1. Correct variant3 and try again',
  'inventory.upload.part.length': 'Part Number can`t be more than 20 characters.',
  'inventory.upload.stocknumber.length': 'Stock Number can`t be more than 20 characters',
  'inventory.upload.upc.length': 'UPC can`t be more than 12 characters',
  'inventory.upload.ean.length': 'EAN can`t be more than 13 characters',
  'inventory.upload.isbn.length': 'ISBN can`t be more than 13 characters',
  'inventory.upload.description': 'Description can`t be more than 10000 characters',
  'inventory.upload.item.name': 'Item Name  can`t be more than 75 characters',
  'inventory.upload.variant value': 'Variant value1 name can`t be more than 20 characters',
  'inventory.upload.variant.value2': 'Variant value2 name can`t be more than 20 characters',
  'inventory.upload.variant.value3': 'Variant value3 can`t be more than 20 characters',
  'inventory.upload.sku.length': 'SKU must not be more than 20 characters',
  'inventory.upload.purchase.price.required.current.stock': 'For inventory valudation purchase price is required for current stock',
  'inventory.upload.first.record.missing': 'First record found without Product Name in Excel. Please provide product name.',
  'no.product.imported': 'No product imported. The file may be empty. Please check the file and upload again',
  'can`t.delete.item.po': 'Item can`t be deleted because it`s been used in the Purchase Order. Remove the item that is used in the Purchase Order and submit the request again.',
  'can`t.delete.item.pi': 'Item can`t be deleted because it`s been used in Purchase Invoice. Remove the item that is used in the Purchase Invoice and submit the request again.',
  'can`t.delete.item.dm': 'Item can`t be deleted because it`s been used in the Debit Memo. Remove the item that is used in the Debit Memo and submit the request again.',
  'can`t.delete.item.so': 'The item can`t be deleted because it`s been used in the Sales Order. Remove the item that is used in the Sales Order and submit the request again.',
  'can`t.delete.item.si': 'The item can`t be deleted because it`s been used in the Sales Invoice. Remove the item that is used in the Sales Invoice and submit the request again.',
  'can`t.delete.item.cm': 'The item can`t be deleted because it`s been used in Credit Memo. Remove the item that is used in the Credit Memo and submit the request again.',
  'insufficient.access.to.approve.qty.change': 'You dont have permisison to approve qty change request.',
  'insufficient.access.to.delegate.qty.change': 'You dont have permisison to delegate qty change request',
  'insufficient.access.to.delegate.price.change': 'You dont have permission to delegate price change request.',
  'insufficient.access.to.reject.price.change': 'You dont have permisison to reject this price change request',
  'insufficient.access.to.reject.qty.change': 'You dont have permisison to reject price change request',
  'insufficient.access.to.approve.price.change': 'You dont have permisison to approve this price change request',
  'cant.delete.warehouse.stock.exist': 'The warehouse can`t be deleted because there are inventory item(s) assigned to this warehouse',
  'warehouse.deleted': 'Warehouse deleted successfully.',
  'cant.delete.system.doc': 'You can`t delete system owned document name.',
  'email.not available': 'Email already exists. Please use a different email address.',
  'cant.delete.primary.user': 'The primary user can`t be deleted.',
  'cant.delete.employee': 'The user has access to Alpide. Please revoke user access before deleting. To revoke, go to Setting / User Management.',
  'cant.delete.manager': 'can`t delete employee because the employee is a manager and other employees report to the manager.',
  'cant.delete.rfq.converted.to.po': 'RFQ cannot be deleted because it has been converted to Purchase Order.',
  'pr.converted.to.rfq': 'RFQ has alraedy been converted for Purchase Request',
  'cant.update.rfq.converted.to.po': 'RFQ cannot be updated because it has been converted to Purchage Order',
  'not.enough.stock.to.update': 'Not enough stock to update inventory. You can uncheck Update Inventory field and resubmit the request',
  'cant.delete.id.invoice.done': 'Goods Receipt Note can`t be deleted because invoicing is completed.',
  'qtytoinvoice.more.than.qty.ordered': 'Qty to invoice should not be more than the quantity ordered.',
  'invoice.id.saved': 'Invoice sequence id saved successfully',
  'payment.sequence.id.saved': 'Payment sequence id saved successfully',
  'cant.apply.payment.void.invoice': 'Payment can`t be applied on void invoice',
  'cant.update.cancel.po': 'Purchase order can`t be updated because it`s cancelled.',
  'cant.update.fulfilled.po': 'Purchase order can`t be updated because it has been fulfilled.',
  'cant.update.partially.fulfilled.po': 'Purchase order can`t be updated because it has been partially fulfilled.',
  'insufficient.access.to.approve.po': 'You dont have permisison to approve this purchase order',
  'cant.canceled.partially.invoiced.po': 'Purchase Order can`t be cancelled because it`s partially invoiced',
  'cant.canceled.invoiced.po': 'Purchase Order can`t be cancelled because invoicing is completed',
  'cant.delete.pr.convertedto.rfq': 'Purchase Request can`t be deleted because it has been converted to RFQ',
  'cant.delete.pr.convertedto.po': 'Purchase Request can`t be deleted because it has been converted to Purchase Order',
  'upload.supplier.incorrect.template': 'It appears incorrect supplier template is used. You can download current template and try again to upload.',
  'supplier.upload.future.relationship.date.error': 'Relationship Since date can`t be the future date. Please correct the date and upload again.',
  'supplier.upload.relationship.date.incorrect.format': 'Incorrect date format for Relationship Since Date. It must be in DD/MM/YYYY format.',
  'supplier.upload.website.length': 'Website name must not be more than 70 characters',
  'supplier.upload.supplier.name.length': 'Supplier name  can`t be more than 75 characters',
  'one.supplier.imported': 'Supplier imported successfully',
  'suppliers.imported': 'Suppliers imported successfully',
  'supplier.not.imported': 'No supplier uploaded. File may be empty. Please check the file and try again.',
  'cant.delete.supplier.tx.exist': 'Supplier can`t be deleted because one or more transactions are associated with the supplier(s).',
  'invalid.date.format': 'Incorrect date provided. Date must be in DD/MM/YYYY format.',
  'missing.day': 'Day is empty for Relationship Since (date). Please provide value for day',
  'missing.month': 'Month is empty for Relationship Since (date). Please provide value for month',
  'missing.year': 'year is empty for Relationship Since (date). Please provide value for year',
  'invalid.day': 'Incorrect day for Relationship Since (date). Day can`t be more than 31',
  'invalid.month': 'Incorrect month for Relationship Since (date). Month can`t be more than 12',
  'invalid.login.email': 'Account with this email does not exist. Please enter correct email address or sign up.',
  'signup.email.unavailable': 'Email address is not available for sign up. Please use something else.',
  'email.not.available': 'Email is not available. Please use a different email address',
  'father.email.not.available': 'Father email is not available. Please use a different email address',
  'mother.email.not.available': 'Mother email is not available. Please use a different email address',
  'root.hierarchy.exist': 'Root hierarchy already exists. There can be only one root hierarchy. Correct the error and try again',
  'sequence.id.rest.success': 'Credit memo id reset succeeded.',
  'sequence.rest.success': 'Sequence reset succeeded.',
  'sales.invoice.already.approved': 'Sales Invoice has already been approved. Check audit trail for details.',
  'cancelled': 'Cancelled',
  'delivered': 'Delivered',
  'partially.paid': 'Partially Paid',
  'paid': 'Paid',
  'void': 'Void',
  'unpaid': 'Unpaid',
  'new': 'New',
  'open': 'Open',
  'in.process': 'In Process',
  'approved': 'Approved',
  'rejected': 'Rejected',
  'pending.approval': 'Pending Approval',
  'not.applicable': 'Not Applicable',
  'partially.received': 'Partially Received',
  'received': 'Received',
  'over.tolerance': 'Over Tolerance',
  'pending': 'Pending',
  'converted.partially': 'Converted Partially',
  'partially.cConverted.to.pr': 'Partially Converted to PR',
  'converted': 'Converted',
  'invoiced': 'Invoiced',
  'not.invoiced': 'Not Invoiced',
  'not.received': 'Not Received',
  'converted.to.lead': 'Converted to Lead',

  'package.doesnot.exist': 'Package does not exist',
  'shipment.doesnot.exist': 'Shipment does not exist. Shipment can be created for Package(s).',
  'sales.invoice.doesnot.exist': 'Invoice has not been created for this Sales Order.',

  'package.doesnot.exist2': 'to create a new package.',
  'shipment.doesnot.exist2': 'to create a new Shipment.',
  'sales.invoice.doesnot.exist2': 'to create a new invoice.',

  'supplier.businessExpense.listing.header.expenseNo': 'Expense #',
  'supplier.businessExpense.listing.header.refNumber': 'Ref #',
  'supplier.businessExpense.listing.header.expenseDate': 'Expense Date',
  'supplier.businessExpense.listing.header.expenseAmount': 'Amount',

  'modal.common.newTax.taxName': 'Tax Name',
  'modal.common.newTax.taxRate': 'Tax Rate',
  'showAllPackages.text': 'Show all Packages',

  'clickhere': 'Click here',
  'certifcateOfConformity.text': 'Certifcate of Conformity',
  'salesOrderDetails.text': 'Sales Order Details',
  'shipmentDetails.text': 'Shipment Details',
  'invoiceStatus.text': 'Invoice Status',
  'paymentStatus.text': 'Payment Status',

  'customer.proformaInvoice.form.confirmation': 'Are you sure you want to create proforma Invoice?',

  'location.type.missing': 'Location type is missing',
  'email.doesnot.exist': 'System could not find the email address you have entered. Please enter the correct email address and try again.',
  'account.already.activated': 'Account has already been activated. Please continue to sign in.',
  'user.saved': 'User saved successfully',

  'category.group.exist': 'Category group already exist',
  'category.exist': 'Category already exist',
  'start.end.account.number.rule.error': 'Starting account number should not be greater than ending account number',
  'start.account.zero.error': 'Starting account number should be not less than zero',
  'start.end.account.cant.be.same': 'Starting account number should be not same as ending account number',
  'account.range': 'Account range ',
  'is.reserved.for': ' is reserved for',
  'stock.not.found': 'Stock not found',
  'product.name.min.4.char': 'Product name must be minimum 2 chracters',
  'invalid.purchased.price': 'Invalid purchased price entered for one of the products',
  'invalid.retail.price': 'Invalid retail price entered for one of the products',
  'invalid.wholesale.price': 'Invalid wholesale price entered for one of the products',
  'invalid.current.stock': 'Invalid current stock entered. Please enter correct value',
  'low.stock.must.be.number': 'Low Stock Qty Alert must be number',
  'reorder.stock.must.be.number': 'Reorder Stock Qty Alert must be number',
  'product.impoorted': 'Product impoorted successfully',

  'package.not.selected': 'Package could not found. Please select package and try again',
  'customer.salesInvoice.listing.header.invoiceConverted': 'Invoice Converted',
  'salesComplaint.create.validation.message': 'Please select SO number and complaint detail',

  'finance.JournalDetail.journal.label': 'Journal',
  'finance.JournalDetail.journalDate': 'Journal Date',
  'finance.JournalDetail.journalNumber': 'Journal Number',
  'finance.JournalDetail.ledgerAccount': 'Ledger Account',
  'finance.JournalDetail.description': 'Description',
  'finance.JournalDetail.debit': 'Debit',
  'finance.JournalDetail.credit': 'Credit',
  'finance.JournalDetail.total': 'Total',
  'finance.JournalDetail.narration': 'Narration',
  'finance.journal.form.confirmation': 'Are you sure you want to create journal?',
  'address.type': 'Address Type',
  'address.type.tooltip': 'Select Address Type',

  'drawer.customer.address.note.text': 'Note',
  'drawer.customer.address.note.message1': 'You can add and manage additional addresses from customer details section.',
  'drawer.customer.address.note.message2': 'View and edit the address format of your transactions under Settings > Preferences > Customers and Vendors.',
  // 'so.shipmentName' : 'Enter Shipment Name',
  'so.shipmentName': 'Enter Shipment Number', // changed from name to number
  'popup.recurringInvoice.title': 'Recurring Invoice Setup',
  'in.progress': 'In Progress',
  'supplier.rfq.form.draft.confirmation': 'Are you sure you want to save as draft?',
  'modal.reports.partyLedger.closingAmount.dr.label': 'Closing Amount (Dr)',
  'modal.reports.partyLedger.closingAmount.cr.label': 'Closing Amount (Cr)',

  'recurringInvoice.text': 'Recurring Invoice',
  'recurringInvoice.setup.neverExpires': 'Never Expires',
  'recurringInvoice.setup.daily': 'Daily',
  'recurringInvoice.setup.weekly': 'Weekly',
  'recurringInvoice.setup.monthly': 'Monthly',
  'recurringInvoice.setup.yearly': 'Yearly',
  'recurringInvoice.setup.generatedOn.message': 'Invoice will be generated on',
  'recurringInvoice.setup.daily.message': 'Invoice will be generated daily',
  'recurringInvoice.setup.weekly.message': 'of every week',
  'recurringInvoice.setup.monthly.message': 'of every month',
  'recurringInvoice.setup.yearly.message': 'of every year',
  'recurringInvoice.setup.emailCustomer': 'Notify Customer',
  'recurringInvoiceNumber.text': 'Recurring Invoice #',
  'common.record.notSelected': 'No record selected. Please select a record before uploading.',
  'common.inactive': 'Inactive',
  'recurringInvoice.inactive.confirmation': 'Are you sure you want to inactive recurring invoice(s)?',
  'recurringInvoice.active.confirmation': 'Are you sure you want to active recurring invoice(s)?',
  'recurringInvoice.setup.period': 'Period',
  'dayOfWeek': 'Day of Week',
  'dayOfMonth': 'Day of Month',
  'dayOfYear': 'Day of Year',
  'monthOfYear': 'Month of Year',
  'sunday': 'Sunday',
  'monday': 'Monday',
  'tuesday': 'Tuesday',
  'wednusday': 'Wednesday',
  'thursday': 'Thursday',
  'friday': 'Friday',
  'saturday': 'Saturday',
  'january': 'January',
  'february': 'February',
  'march': 'March',
  'april': 'April',
  'may': 'May',
  'june': 'June',
  'july': 'July',
  'august': 'August',
  'september': 'September',
  'october': 'October',
  'november': 'November',
  'december': 'December',
  'splitRFQ.text': 'Split RFQ',
  'Special.Discount.(%)': 'Special Discount (%)',
  'Base-Rate': 'Base Rate',
  'Variants': 'Variants',
  'QTY.Current.Stock': 'QTY Current Stock',
  'Job.Title.Name': 'Job Title Name',
  'Manager.Name': 'Manager Name',
  'Quantity.Packed': 'Quantity Packed',
  'save.confirmation': 'Are you sure you want to save?',
  'continue.confirmation': 'Are you sure you want to continue?',
  'authorize.net.logo': 'Authorize.Net',
  'stripe.logo': 'Stripe',
  'paypal.logo': 'PayPal',
  'doDirect.text': 'Direct Payment',
  'neonPay.logo': 'NeonPay',
  'bank.Integration': 'Square',
  'amazon.logo': 'Amazon',
  'shopify.logo': 'Shopify',
  'eabay.logo': 'ebay',
  'walmart.logo': 'Walmart',
  'quickBook.logo': 'QuickBook',
  'sage.logo': 'Sage',
  'expense.Transaction.number': 'Transaction Number',
  'card.details': 'CM-443-150120-12',
  'card.details.no': 'Wed, 15 Jan 2020',
  'common.active': 'Active',
  'common.gst.mechanism': 'GST Mechanism',
  'common.Located.in.SEZ': 'Located in SEZ',
  'common.Relationship.since': 'Relationship Since',
  'common.Total.balance.due': 'Total Balance Due',
  'common.available.credit': 'Available Credit',
  'common.CGST': 'CGST @',
  'common.SGST': 'SGST @',
  'common.To.do': 'To-do',
  'common.Completed.Task': 'Completed Task',
  // 'common.Shipto' : 'Ship to',
  'common.InvoiceNo': 'Invoice No.',
  'common.SONo': 'SO No.',
  // 'common.foreign.currency' : 'Foreign Currency',
  // 'common.Exchange.Rate' : 'Exchange Rate',
  // 'common.Place.supply' : 'Place Of Supply',
  // 'common.Customer.pO' : 'Customer PO',
  'common.Ref.no': 'Ref No',
  // 'common.QTY' : 'QTY',
  // 'common.Base.price' : 'Base Price',
  // 'common.Special.discount' : 'Special Discount',
  // 'common.Rate' : 'Rate',
  'common.HSN': 'HSN',
  // 'commmon.Discount' : 'Discount',
  // 'common.Tax' : 'Tax',
  // 'common.Net.amount' : 'Net Amount',
  // 'common.Attachments' : 'Attachments',
  // 'common.Internal.remarks': 'Internal Remarks',
  // 'common.Customer.remarks' : 'Customer Remarks',
  // 'common.Sub.total' : 'Sub Total',
  // 'common.Grand.total' : 'Grand Total',
  // 'common.Delete.stamp' : 'Delete Stamp',
  // 'common.Material' : 'Material # :',
  // 'common.Stock' : 'Stock # :',
  // 'common.Part' :'Part # :',
  // 'common.Origin' : 'Origin :',
  // 'common.HS.code' : 'HS Code :',
  // 'common.Warehouse' : 'Warehouse Name :',
  'common.PO': 'PO',
  'common.Line': 'Line',
  'disconnect.confirmation': 'Are you sure you want to disconnect',
  'reports.partyLedger.currentDue': 'Current Due',
  'common.RFQ.Number': 'RFQ Number',
  'common.PR.Number': 'PR Number',
  'tooltip.system.gnerated': 'System Generated RFQ Number',
  'common.standard.template': 'Standard PO Template',
  'common.Multi.currency.template': 'Multi Currency PO Template',
  'common.supplier.contact': 'Supplier Contact',
  'common.supplier.address': 'Supplier Address',
  'common.System.generated.purchase.order.umber': 'System Generated Purchase Order Number',
  'common.Currency': 'Currency',
  'common.IGST': 'IGST @',
  'common.edit.employee': 'Edit Employee',
  'common.delete.employee': 'Delete Employee',
  'common.edit.hierarchy': 'Edit Hierarchy',
  'common.delete.hierarchy': 'Delete Hierarchy',
  'common.About.your.business': 'About your business',
  'ommon.discript.Please.select': 'Please select Customer, Product/Description, Quantity and Rate',
  'common.Audit.trail.required.': 'Audit trail is required.',
  'tooltip.generateDebitMemo': 'System Generated Debit Memo Number',
  'description.Please.select.Supplier': 'Please select Supplier, Product/Description, Quantity and Rate',
  'common.Mark.complete': 'Mark task as complete',
  'common.Task.marked.successfully': 'Task marked as created successfully',
  'common.titleEdit.task': 'Edit task',
  'common.Delete.task': 'Delete task',
  'common.Task.marked.todo.successfully': 'Task marked as todo successfully',
  'common.Add.task': 'Add Task',
  'common.Task.updated.successfully': 'Task updated successfully',
  'common.Task.created.successfully': 'Task created successfully',
  'tabs.Invoice': 'Invoice',
  'common.Open.Purchase.Orders': 'Open Purchase Orders',
  'common.Unpaid.Balance': 'Unpaid Balance',
  'common.Total.Purchases': 'Total Purchases',
  'common.Transactions': 'Transactions',
  'common.Contacts': 'Contacts',
  'communications': 'Communications',
  'activities': 'Activities',
  'title.Supplier.Tax.Identification': 'title.Supplier.Tax.Identification',
  'title.System.Generated.Inbound.Delivery.Number': 'System Generated GRN Number',
  'title.common.Create.Inbound.Delivery': 'Create GRN',
  'common.Enter.internal.remarks': 'Enter internal remarks',
  'common.System.Generated.Purchase.Invoice.Number': 'System Generated Purchase Invoice Number',
  'common.You.can.enter.Supplier.Invoice.Number': 'You can enter the Supplier Invoice Number that you might have received',
  'common.Exchange.Rate.required': 'Exchange Rate is required',
  'common.Currency.required': 'Currency is required',
  'common.VAT': 'VAT @',
  'common.MultiCurrency.Purchase.Invoice': 'MultiCurrency Purchase Invoice',
  'body.Are.you.create.activity': 'Are you sure you want to create activity ?',
  'Address.does.not.exist': 'Address does not exist.',
  'to.create.address': 'to create an address',
  'Are.you.sure.communication': 'Are you sure you want to create communication ?',
  'to.create.contact': 'to create a contact.',
  'does.exist': 'does not exist.',
  'clone.Supplier': 'Clone Supplier',
  'open.Orders': 'Open Orders',
  'no.supplierselected': 'No supplier selected. Please select supplier before downloading.',
  'upload.invoice.xero': 'Upload invoice to xero',
  'please.Select.Supplier': 'Please Select Supplier',
  'multiCurrency.Purchase': 'MultiCurrency Purchase Order',
  'enter.supplier.remarks': 'Enter supplier remarks',
  'please.Product.Description.Quantity': 'Please Product/Description and  Quantity',
  'system.generatedPurchase.RequestNumber': 'System Generated Purchase Request Number',
  'standard.Purchase.Request': 'Standard Purchase Request',
  'split.RFQ.merge.existing': 'Split new RFQ or merge to an existing one',
  'transaciton.required': 'Transaciton is required',
  'purchase.Order.Details': 'Purchase Order Details',
  'pO.status': 'PO Status',
  'delivery.Status': 'Delivery Status',
  'inbound.Deliver.created.PurchaseOrder': 'Goods Receipt Note has not been created for this Purchase Order.',
  'to.create.Inbound.Delivery': 'to create Goods Receipt Note.',
  'areyou.sure.you.want.approve': 'Are you sure you want to approve?',
  'delete.messaget': 'This will not delete the Invoice. Only Recurring Invoice settings will be removed from the Invoice.',
  'make.Active': 'Make Active',
  'in.Active': 'Make Inactive',
  'common.record.notSelected.proceeding': 'No record selected. Please select the record(s) before proceeding.',
  'payment.gateway': 'Receive Payment Online',
  'tooltip.onlinePaymnet': 'When selected, in email user will see Pay Online link. When user makes online payment, payment will be credited to the selected payment gateway.',
  'tooltip.onlinePaymnet.help': 'To add payment gateway, visit Integration -> Payment Page',

  'sales-inquiry': 'Inquiry',
  'customer-party-ledger': 'Customer Party Ledger',
  'sales-quote': 'Sales Quote',
  'sales-order': 'Sales Order',
  'packaging': 'Packaging',
  'outbound-delivery': 'Outbound Delivery',
  'sales-invoice': 'Sales Invoice',
  'sales-payment': 'Sales Payment',
  'sales': 'Sales',
  'credit-memo': 'Credit Memo',
  'sales-discount': 'Sales Discount',
  'sales-output-tax': 'Sales Output Tax',
  'sales-expense': 'Sales Expense',
  'cess-payable': 'Cess Payable',
  'shipment': 'Shipment',

  'stock-qty-update': 'Stock Qty Update',
  'stock-price-update': 'Stock Price Update',

  'purchase-request': 'Purchase Request',
  'rfq': 'RFQ',
  'purchase-order': 'Purchase Order',
  'inbound-delivery': 'Goods Receipt Note',
  'purchase-invoice': 'Purchase Invoice',
  'purchase-payment': 'Purchase Payment',
  'purchase': 'Purchase',
  'debit-memo': 'Debit Memo',
  'cess-receivable': 'Cess Receivable',
  'purchase-discount': 'Purchase Discount',
  'purchase-expense': 'Purchase Expense',
  'purchase-input-tax': 'Purchase Input Tax',
  'supplier-party-ledger': 'Supplier Party Ledger',

  'incorrect.paymentTerm': 'Payment Term is not valid. Please select Payment Term from the Excel Dropdown',

  'validation.day.requiredMessage': 'Please select a day',
  'validation.month.requiredMessage': 'Please select a month',
  'invalid.dateRange.message': 'Please select valid date range',
  'attachInvoicePdf.text': 'Attach Invoice PDF',

  'drawer.customer.contact.note.message1': 'You can add and manage additional contacts from customer details page.',
  'drawer.supplier.contact.note.message1': 'You can add and manage additional contacts from supplier details page.',
  'customer.upload.success': 'Customer uploaded successfully',

  'incorrrect.brand.name': 'Incorrect brand name selected from the dropdown.',
  'incorrect.uom.name': 'Incorrect uom selected from the dropdown.',
  'incorrect.category.name': 'Incorrect category selected from the dropdown.',
  'incorrect.manufacturer.name': 'Incorrect manufacturer selected from the dropdown.',
  'incorrect.variant1.name': 'Incorrect variant name 1 selected from the dropdown.',
  'incorrect.variant2.name': 'Incorrect variant name 2 selected from the dropdown.',
  'incorrect.variant3.name': 'Incorrect variant name 3 selected from the dropdown.',
  'supplier.uploaded.successfully': 'Supplier uploaded successfully',

  'missing.customer.id': 'Customer id is missing',
  'missing.supplier.id': 'Supplier id is missing',
  'missing.so.id': 'Sales Order id is missing',
  'missing.createdby.id': 'Created by id is missing',
  'missing.invoiceMasterId': 'Invoice id is missing',
  'missing.product.name': 'Product name is misisng in one of the rows',
  'common.saved.success': 'Saved succesfully',
  'common.delete.success': 'Deleted succesfully',
  'sidebar.menuItem.banking': 'Banking',
  'banking.deposit': 'Deposit',
  'banking.withdrawal': 'Withdrawal',
  'banking.institutionName': 'Institution Name',
  'banking.paymentChannel': 'Payment Channel',
  'banking.merchantName': 'Merchant Name',
  'banking.match': 'Match',
  'banking.findAndMatch': 'Find and Match',
  'banking.search.minAmount': 'Min Amount',
  'banking.search.maxAmount': 'Max Amount',
  'taxRate.validation': 'Tax Rate should be between 1 and 100',
  'address.validation.street': 'Street Address is required.',
  'address.validation.city': 'City is required.',
  'address.validation.state': 'State/Province/Region is required.',
  'amountFrom.text': 'Amount from',
  'amountFromStatement.text': 'Amount from statement',
  'importStatement.text': 'Import Statement',
  'fetchStatement.text': 'Fetch Statement',
  'addAccount.text': 'Add Account',
  'banking.tab.transacitonForReview': 'Transaciton For Review',
  'banking.tab.allocatedInAlpide': 'Reconciled Transaction',
  'banking.tab.auditTrail': 'Audit Trail',
  'missing.public.token': 'Public token is missing',
  'banking.auditTrail.transacitonCount': 'No. of Transactions',
  'banking.auditTrail.file': 'File',
  'banking.txInAlpide.party': 'Party',
  'banking.findMatch.validation': 'Amount difference should be zero',
  'bankReco.createInvoice.validation': 'Invoice amount must be same as bank transaction amount {value}',
  'bankReco.createExpense.validation': 'Expense amount must be same as bank transaction amount',
  'banking.institution.exist': 'Finanical institution already added',
  'downloadTemplate.text': "Download Template",
  'importStatement.message': "This allow manually bank statement to alpide using CSV (Comma Separate Value) file format",
  'importBankStatement.text': 'Import Bank Statement',
  'browse.text': 'Browse',
  'banking.upload.statement': 'Upload Statement',
  'nothingToAssociate.text': 'Nothing to Associate',
  'associate.text': 'Associate',
  'missing.payment.amount': 'Payment amount is missing',
  'missing.supplier.or.expense.id': 'Either Supplier Id or Expense Master Id is misisng',
  'review.text': 'Review',
  'ledgerAccountCredit.text': 'Ledger (Credit)',
  'ledgerAccountDebit.text': 'Ledger (Debit)',
  'diposit': 'Bank Deposit',
  'withdrawal': 'Bank Withdrawal',
  'totalTransactions.text': 'Total Transactions',
  'totalDeposit.text': 'Total Deposit',
  'totalWithdrawal.text': 'Total Withdrawal',
  'partyName.text': 'Party Name',
  'instrumentNumber.text': 'Instrument Number',
  'instrumentDate.text': 'Instrument Date',
  'instrumentNumber.tooltip': 'This is Instrument Number',
  'instrumentDate.tooltip': 'This is Instrument Date',
  'csv.amount.validation': 'It appers either deposit/withdrawal is empty or deposit and withdawral both have amount.',
  'package.shipped': 'Shipment can not be generated/updated for the shipped package(s)',
  'selecteFile.text': 'Select the file to upload',
  'uploadFile.placeholder': 'No file selected',
  'banking.match.tooltip': 'If match is found then Alpide found unpaid transaction',
  'banking.noMatch.tooltip': 'Alpide could not find any match transaction',
  'banking.associateMatch.tooltip': 'Alpide found a manual payment. Click to assciate this payment',
  'banking.noAssociateMatch.tooltip': 'Alpide could not find any match transaction',
  'ledger.info.missing': 'Ledger info missing',
  'transactionType.text': 'Transaction Type',
  'banking.ledgerAccount.validation': 'Ledger Account can not be empty.',
  'banking.createAndMatch.salesInvoice': 'Create and Match (Sales Invoice)',
  'banking.createAndMatch.purchaseInvoice': 'Create and Match (Purchase Invoice)',
  'banking.createAndMatch.expense': 'Create and Match (Expense)',
  'csv.fileType.validation': 'Only CSV (Comma Separate Value) file allowed.',
  'expense.delete.success': 'Expense deleted successfully',
  'businessType.text': 'Business Type',
  'business': 'Business',
  'individual': 'Individual',
  'primary.address.cant.delete': 'Primary address can`t be deleted.',
  'missing.customer.type': 'Customer type is missing.',
  'missing.product.name.for.description': 'Product name is missing for Description Column',
  'missing.product.name.for.amazon.asin': 'Product name is missing for Amazon ASIN #',
  'missing.product.name.for.sku': 'Product name is missing for SKU Column',
  'missing.product.name.for.variant': 'Product name is missing for Variant Column',
  'missing.product.name.for.uom': 'Product name is missing for UOM Column',
  'missing.product.name.for.purchase.price': 'Product name is missing for Purchase Price Column',
  'missing.product.name.for.retail.price': 'Product name is missing for Retail Price Column',
  'missing.product.name.for.wholesale.price': 'Product name is missing for Wholesale Price Column',
  'missing.product.name.for.current.stock': 'Product name is missing for Current Stock Column',
  'missing.product.name.for.tax.on.current.stock': 'Product name is missing for Tax on Current Stock Column',
  'missing.product.name.for.expense.on.current.stock': 'Product name is missing for Expense on Current Stock Column',
  'missing.product.name.for.category.name': 'Product name is missing for Category Name Column',
  'missing.product.name.for.brand.name': 'Product name is missing for Brand Name Column',
  'missing.product.name.for.article.number': 'Product name is missing for Article Number',
  'missing.product.name.for.manufacturer.name': 'Product name is missing for Manufacturer Name Column',
  'missing.product.name.for.stock.number': 'Product name is missing for Stock Number Column',
  'missing.product.name.for.part.number': 'Product name is missing for Part Number Column',
  'missing.product.name.for.upc': 'Product name is missing for UPC Column',
  'missing.product.name.for.ean': 'Product name is missing for EAN Column',
  'missing.product.name.for.isbn': 'Product name is missing for ISBN Column',
  'missing.product.name.for.low.stock.level.alert': 'Product name is missing for Low Stock Alert Column',
  'missing.product.name.for.reorder.stock.level.alert': 'Product name is missing for Reorder Stock Level Alert Column',
  'missing.product.name.for.warehouse.name': 'Product name is missing for Warehouse Name Column',
  'reminder.text': 'Setup Reminder',
  'addReminder.text': 'Add Reminder',
  '2000.rows.returned': 'More than 2000 records found. Please narrow your search and generate multiple pdf with 2000 records.',
  'payExpense.label': 'Pay Expense',
  'selectFile.text': 'Please select a file',
  'common.selectFile.empty': 'Selected file is empty, Please select another file',
  'sameLadger.validation': 'Credit and Debit Ledger Account cant be same',
  'sampleFile': 'Sample File',
  'compare.sampleFile.message': 'and compare it to your import file',
  'cant.apply.payment.unapproved.status': 'Payment can`t be applied on unapproved status',
  'invoiceIs.text': 'Send if an invoice is',
  'dueIn.text': 'due in',
  'pastDueIn.text': 'past due in',
  'after.text': 'after',
  'before.text': 'before',
  'notify.text': 'Notify',
  'noActive.reminder': 'There is no active reminder',
  'reminder.validation.requiredMessage': 'Past due/due in and days are required',
  'resend.activation.mail.text': 'Resend Activation Email',
  'reminder.validation.maxActive': 'Active reminder can not be more than 4',
  'activation.panding.tile': 'Activation Pending',
  'activation.panding.message': 'Your account has not been activated yet. Check your email to your activate your account. If you dont find the email, please check your spam folder.',
  'modal.common.project.projectNumber': 'Project #',
  'newProject.text': 'New Project',
  'common.delete.project': 'Delete Project',
  'timezone.required': 'Timezone is required',
  'signup.validation.timezone': 'Timezone is required',
  'default.paymentGateway': 'Default Payment Gateway',
  'clientId.text': 'API Username',
  'secretKey.text': 'API Password',
  'clientId.text.placeholder': 'ex:sb-nvq4475860828_api1.xyz.example.com',
  'secretKey.text.placeholder': 'ex:AHDR683KG3N5F78M',
  'paypalDirect.logo': 'PayPal Payment Kiosk',
  'apiSignature.text': 'Api Signature',
  'apiSignature.text.placeholder': 'ex:D7jjkfUWtrDb-jjl8aCM3kJqCS0bAmOwmkbZkxnYFCwQM41SfXKNIUqu',
  'stripe': 'Stripe',
  'paypal': 'PayPal',
  'expensePayment.text': 'Expense Payment',
  'cashEquivalents.ledgers': 'Ledgers from Cash & Cash Equivalents are displayed',
  'cashAccount.text': 'Cash Account',
  'filter.text': 'Filter',
  'reset.text': 'Reset',
  'csv.amount.NAN.validation': 'It appers either deposit/withdrawal is not a numeric value or contains any special character.',
  'modal.payInvoice.paymentModeValidation': 'Payment mode is required',
  'modal.payInvoice.ledgerValidation': 'Bank Account is required',
  'success.upload.message': 'Email notification will be sent when upload is done',
  'success.uploaded.message': 'Bank statement uploaded successfully',
  'associateTransaction.text': 'Associate Transaction',
  'importStatement.notUploaded.message': 'The transactions have not yet uploaded. Please select transactions from the first column and click on Upload Statement button.',
  'modal.settings.manageUser.notifyUser.tooltip': 'Activation email will be sent',
  'banking.tab.uploadStatementDraft': 'Upload Statement Draft',
  'tooltip.generateExpenseNumber': 'System Generated Expense Number',
  'deleteActivity.confirmation': 'Are you sure you want to delete this Activity?',
  'communication.text': 'Communication',
  'from.text': 'From',
  'to.text': 'To',
  'cc.text': 'CC',
  'bcc.text': 'BCC',
  'subject.text': 'Subject',
  'message.text': 'Message',
  'customer.import.firstname.required': 'First name is required for individual customer type',
  'cant.delete.paid.expense': 'Expense cant be deleted because it has been paid',
  'missing.shipment.master.id': 'Shipment master id is missing',

  'financialYear.text': 'Financial Year',
  'signup.validation.financialYear': 'Financial year is required',
  'uploadSignature.tooltip': 'Upload Signature / Stamp',
  'activity.validation.activityType': 'Activity Type is required',
  'activity.validation.description': 'Description is required',
  'supplierPONumber.text': 'Supplier PO #',
  'modal.common.priceList.percentage.validation': 'Percentage should be between 1 and 100',
  'reminder.notifyCustomer.validation.message': 'Notify customer and email are required',
  'makeRecurring.text': 'Make Recurring',
  'selectSalesOrder.message': 'Please select Sales Order',
  'modal.settings.companyInfo.bankInfo.payeeName': 'Payee Name',
  'modal.settings.companyInfo.bankInfo.routingNumber': 'Routing Number',
  'modal.settings.companyInfo.bankInfo.payeeName.validation': 'Payee Name is required',
  'modal.settings.companyInfo.bankInfo.name.validation': 'Bank Name is required',
  'modal.settings.companyInfo.bankInfo.accountNumber.validation': ' Account Number is required',
  'modal.settings.companyInfo.bankInfo.ifsc.validation': 'IFSC/SWIFT/BIC is required',
  'reminder.cash.invoice.error': 'Can not setup reminder on the cash invoice',
  'payInvoice.cash.invoice.error': 'No payment requier for the cash invoice',
  'financial.year.required': 'Financial year is required',
  'bucket.name.empty': 'Email sent but document is not uploaded because AWS Bucket is empty',
  'cant.delete.ledger': 'Can not delete ledger account because it is used in the transaction',
  'ledger.text': 'Ledger',
  'modal.txDetails.shipment.convertToInvoice': 'Convert To Invoice',
  'tooltip.supplierPayment.txNumber': 'This is Purchase Invoice or Expense Number',
  'missing.packgae.master.id': 'Package Master Id is missing on shipmentDetailListUI',
  // 'modal.common.project.ledger': 'Ledger'
  'transaction.name.is.required': 'Transaction name is required',
  'modal.common.project.ledger': 'Ledger',
  'productUpload.inprogress.messsage': 'Product upload in progress you will be notified once the upload is done',
  'dueDays.text': 'Past Due',
  'notDue.text': 'Not yet due',
  'missing.inboundDeliveryMasterId': 'GRN master id is missing',
  'saved.successfully': 'Saved successfully',
  'email.template.validation': 'Transaction and Message are required',
  'paymentTermId.missing': 'Payment term  id is required',
  'paymentTermDays.missing': 'Payment term days is required',
  'cant.delete.system.term': 'Can not delete system owned term',
  'cant.void.paid.invoice': 'Can not void invoice as payment is received',
  'cant.convert.to.si.shiment.invoice.done': 'Invoice can not be generated because Invoice is generted from Shipment. Convert remaining invoice from Shipment.',
  'cant.convert.to.si.proforma.invoice.done': 'Invoice can not be generated because Invoice is generted from Proforma. Convert remaining invoice from Proforma.',
  'cant.convert.to.si.so.invoice.done': 'Invoice can not be generated because Invoice has been converted from SO. Convert remaining invoice from SO.',
  'invoice.markAsPaid.confirmation': 'The invoice is either Cash on Delivery or Payment in Advance, do you want to apply payment and mark the invoice as paid?',
  'product.listing.validation.failed.message.amazon': 'No item selected. Please select item before uploading to amazon.',
  'product.listing.amazon': 'Amazon Upload',
  'product.listing.amazon.asin': 'ASIN #',
  'product.listing.amazon.sku': 'SKU',
  'product.listing.amazon.price': 'Price',
  'product.listing.amazon.upload': 'Upload to Amazon',
  'select.gateway': 'Select Payment Gateway',
  'invalid.accont.number.range': "Starting or ending account number is not available.",
  'register.fatherFirstName': "Father's First Name",
  'register.fatherLastName': "Father's Last Name",
  'setting.student.registration.form.label': 'Registration Form Setting',
  'setting.student.registration.form.message': 'You can override registration form setting here.',
  'setting.student.registration.form.fatherDetail': 'Father\'s Detail',
  'setting.student.registration.form.motherDetail': 'Mother\'s Detail',
  'setting.student.registration.form.emergencyContactName': 'Emergency Contact Name',
  'setting.student.registration.form.emergencyPhone': 'Emergency Ph. #',
  'setting.student.registration.form.defaultContact': 'Ask Default Phone Number',
  'questionText.placeholder': 'Enter Question Text',
  'optionText.placeholder': 'Enter Option Text',
  'answerType.text': 'Answer Type',
  'required.text': 'Required',
  'addQuestion.text': 'Add Question',
  'addAnswer.text': 'Add Answer',
  'removeAnswer.text': 'Remove Answer',
  'father.fName': 'Father\'s First Name',
  'father.lName': 'Father\'s Last Name',
  'father.name': 'Father Name',
  'father.mobile': 'Father\'s Mobile',
  'father.email': 'Father\'s Email',
  'mother.fName': 'Mother\'s First Name',
  'mother.name': 'Mother Name',
  'mother.lName': 'Mother\'s Last Name',
  'mother.mobile': 'Mother\'s Mobile',
  'mother.email': 'Mother\'s Email',
  'add.student': 'Add Student',
  'studentDetail.text': 'Child/Student Details',
  'student.text': 'Student',
  'fatherName.text': 'Father Name',
  'fatherMobile.text': 'Father Mobile',
  'fatherEmail.text': 'Father Email',
  'motherName.text': 'Mother Name',
  'motherMobile.text': 'Mother Mobile',
  'motherEmail.text': 'Mother Email',
  'allergies.header.text': 'Allergies (Y/N)',
  'gender.text': 'Gender',
  'dob.text': 'DOB',
  'grade.text': 'Grade',
  'formHeading.text': 'Form Heading',
  'studentRegistration.text': 'Registered Members',
  'selectFatherName.text': 'Select Father Name',
  'creditApplied.text': 'Credit Applied',
  'debitApplied.text': 'Debit Applied',
  'parentRemarks.text': 'Parent Remarks',
  'fee.text': 'Fee',
  'deleteQuestion.text': 'Delete Question',
  'student.missing.details.message': 'Student required field is missing',
  'no.student.message': 'Please add student',
  'required.question.missing': 'Complete all required question(s)',
  'preview.text': 'Preview',
  'address.missing.message': 'Address required field is missing',
  'registration.paymentTerm.helpMessage': 'This will not be displayed on the form',
  'registration.paymentTerm.required': 'Payment Term (Required for Invoicing)',
  'registration.formHeading.required': 'Form heading is required',
  'balance.text': 'Balance',
  'formHeading.textColor': 'Header Font Color',
  'formHeading.bgColor': 'Header Backgraund Color',
  'sameColor.validation': 'Font color backgraund color should not be same',
  'cant.split.multiple.item.found': 'This RFQ has can not be splitted because it has more than one items',
  'setting.student.registration.collectStudentInfo': 'Student or Child Detail',
  'setting.student.registration.schoolLevelValidation.helpMessage': 'Max number of students enrolled in the school. After this number, enrollment will be closed.',
  'setting.student.registration.schoolLevelValidation': 'School Level Validation',
  'setting.student.registration.maxStudent.missing': 'Max student missing for form level validation',
  'setting.student.registration.maxStudent': 'Max Student',
  'setting.student.registration.gradeLevelValidation': 'Grade Level Validation',
  'setting.student.registration.gradeLevelValidation.helpMessage': 'Max number of studens enrolled for the given grade',
  'cant.delete.default.doc': 'You can not delete the default document name',
  'no.more.seat.available.message': 'Registration has closed. Please contact the support team.',
  'no.more.seat.available.inGrade.message': 'Seats are full for the selected grade. Your student will be on the waitlist. Please fill out the form.',
  'invalid.date': 'Invalid Date',
  'paymentTermName.missing': 'Payment term name is required',
  'project.name.require': 'Project name is required',
  'cannot.delete.student.invoicing.done': 'Can not delete student. Invoicing is done for this student.',
  'studentRegistrationId.missing': 'studentRegistrationId is missing.',
  'cant.delete.systemGenerated': 'Can not delete system generated resource',
  'cant.delete.default': 'Can not delete default resource',
  'cannot.find.another.default.doc': 'System can not find another default document',
  'missing.warehouseMasterId.id': 'warehouseMasterId is missing',
  'invoice.generated.for.shipment': 'Invoice has already been generated for this Shipment.',
  'setting.student.registration.collectOnlinePayment': 'Collect Online Payment',
  'setting.student.registration.collectOnlinePayment.helpMessage': 'When this is active, user has to pay at the time of registration',
  'setting.student.registration.feeAmount': 'Amount',
  'proceedToPay.text': 'Proceed to Pay {value}',
  'setting.student.registration.list.heading': 'Registration Form',
  'setting.student.registration.list.formName': 'Form Name',
  'setting.student.registration.list.date': 'Date Created',
  'incorrect.current.password': "Password does not match. Please enter your correct current password.",
  'button.registerForm.createNewForm': 'New Form',
  'registrationForm.date': 'Program Date',
  'registration.form.delete.confirmation': 'Are you sure you want to delete this form?',
  'subdomain.does.not.exist': 'Invalid request. Please contact the admin for support.',
  'father.email.used': 'Father name is already used',
  'mother.email.used': 'Mother name is already used',
  'form.heading.exist': 'Form Heading name alraedy used. Duplicate is not allowed.',
  'registration.form.email.alreadyExists': 'Email exists. Do you want to use this email?',
  'registration.form.maxgrade.missing': 'Missing max student number. Please make sure you have entered max student for all grades.',
  'registration.form.school.level.student.missing': 'You have turned on school level validation but have not provided max student number.',
  'registration.completion.success': 'Thank you for completing the registation.',
  'formShortName.text': 'Form Short Name',
  'registration.formShortName.required': "Form short name is required",
  'gradefee.header.text': 'Fee ($)',
  'gradefeeYearly.header.text': 'Yearly Fee ($)',
  'paymentGateway.available': 'Payment Gateway',
  'paymentGateway.notavailable': 'Please integrate payment gateway',
  'common.name.required': 'Name is required',
  'taxRate.required': 'Tax Rate is required.',
  'paymentGateway.notAvailable.message': 'Payment gateway is not selected',
  'registration.isSendEmail': 'Send Email After Registration',
  'registration.successMessage': 'Success Message',
  'registration.successMessage.helpMessage': 'This will be displayed after internet form is submitted',
  'totalParents.text': 'Total Parents',
  'totalStudent.text': 'Total Students',
  'feesDue.text': 'Fees Due',
  'feesPaid.text': 'Fees Paid',
  'enrollmentDate.text': 'Registration Date',
  'formid.missing': 'Form id is missing',
  'registration.completion.failure': 'Your payment failed',
  'already.registered': 'You have already registered for this program',
  'formSubHeading.text': 'Sub Heading',
  'inquiry.number.missing': 'Inquiry number is missing',
  'quote.number.missing': 'Quote number is missing',
  'so.number.missing': 'SO number is missing',
  'invoice.number.missing': 'Invoice number is missing',
  'package.number.missing': 'Package number is missing',
  'shipment.number.missing': 'Shipment number is missing',
  'cm.number.missing': 'Credit Memo number is missing',
  'payment.number.missing': 'Payment number is missing',
  'pr.number.missing': 'Purchase Request number is missing',
  'rfq.number.missing': 'RFQ number is missing',
  'po.number.missing': 'PO number is missing',
  'id.number.missing': 'GRN Number is missing',
  'dm.number.missing': 'Debit Memo number is missing',
  'client.secret.missing': 'Client secret is missing',
  'clientid.missing': 'Client id is missing',
  'refresh.token.missing': 'Refresh token is missing',
  'access.token.missing': 'Access token is missing',
  'shareInvoice.text': 'Share Invoice',
  'copyToClipboard.text': 'Copied to clipboard',
  'shareInvoice.link.description': 'You can use the below link to share it with your customer. Most important, anyone who has access to this link can view and make the payment online.',
  'missing.payment.gateway.name': 'Payment gateway name is missing.',
  'payment.confirmation.text': 'Are you sure you want to make the below payment?',
  'oneTimePayment.text': 'One Time Payment',
  'paymentStartDate.text': 'Next Payment Date',
  'paymentStartDate.helpMessage': 'This is your next payment date. Your credit card will be charged automatically on this date.',
  'noOfPayment.text': 'No. of Payment(s)',
  'pay.text': 'Pay',
  'select.period.validation': 'Please select payment Recurrence',
  'wallmart.clientId.text': 'Client Id',
  'wallmart.secretKey.text': 'Secret Key',
  'payOnline.success': 'Your payment has been processed successfully',
  'payOnline.failed': 'Payment failed',
  'missing.marketplace': 'Marketplace name is missing',
  'paymentMethod.text': 'Payment Method',
  'creditCard.text': 'Card Payment',
  'email.confirmation.sent.message': 'Note: An email confirmation has been emailed to you',
  'programName.text': 'Program',
  'program.text': 'Program',
  'programName.required.validation': 'Program Name is required',
  'registration.project.helpMessage': 'This will not be displayed on the form',
  'projectName.required.validation': 'Project Name is required',
  'product.listing.amazon.itemType': 'Item Type/Category',
  'product.listing.amazon.taxCode': 'Tax Code',
  'drawer.inventory.asin': 'Amazon ASIN #',
  'tooltip.asin': 'ASIN is an Amazon Standard Identification Number. It is a 10-character alphanumeric unique identifier assigned by Amazon.com and its partners for product identification within the Amazon organization.',
  'drawer.inventory.asin.placeholder': 'Amazon Standard Identification Number',
  'product.listing.eBay': 'Upload eBay',
  'product.listing.eBay.upload': 'Upload to eBay',
  'product.listing.validation.failed.message.eBay': 'No item selected. Please select the item(s) before uploading to the eBay.',
  'product.listing.walmart': 'Upload Walmart',
  'product.listing.walmart.upload': 'Upload to Walmart',
  'product.listing.validation.failed.message.walmart': 'No item selected. Please select the item(s) before uploading to the Walmart.',
  'programName.notSelected.validation': 'Program Name is not selected',
  'programName.notStudent.available': 'Student is not enrolled  for the selected program',
  'incorrect.billing.address': 'Incorrect address selected from the dropdown',
  'cancelSubscription.text': 'Cancel Subscription',
  'transaciton.canceled.message': 'Transaciton canceled',
  'cant.delete.form': 'The form can not be deleted because member is enrolled on this form.',
  'onlinePayment.not.available.message': 'Online payment is not enabled for this invoice. Please contact the support team.',
  'setting.donation.heading': 'Web Donation Configurations',
  'setting.donation.form.message': 'You can override donation setting here.',
  'displayName.text': 'Display Name',
  'listedForDonation.text': 'Show?',
  'listedForDonation.help.message': 'When checked, this project will be displayed.',
  'displayName.missing.validation': 'Display Name is missing',
  'formActive.text': 'Form Active',
  'inactiveMessage.text': 'Inactive Message',
  'registration.inactiveMessage.helpMessage': 'This will be displayed on internet form if form is inactive',
  'company.b2b.url': 'Online Store URL',
  'company.b2b.url.helpMessage': 'This is the URL you can share with your customer or Supplier (for RFQ Responses Submission online)',
  'url.not available': 'URL already exists. Please use a different URL',
  'drawer.ledger.purchaseDiscount': 'Discount (Dr)',
  'drawer.ledger.purchaseOutput': 'Tax (Dr)',
  'drawer.ledger.generalExpense': 'Expense (Dr)',
  'cant.reminder.void.invoice': 'Can not setup reminder on the void invoice',
  'invoiceReminder.inactive.confirmation': 'Are you sure you want to make this payment reminder inactive?',
  'invoiceReminder.active.confirmation': 'Are you sure you want to make this payment reminder active?',
  'recurringInvoice.setup.copyReminder': 'Copy Payment Reminder',
  'recurringInvoice.setup.copyReminder.helpMessage': 'If checked, payment reminders will be copied from this invoice to the new invoice to be genearated.',
  'modal.txDetails.soStatus': 'Update SO Status',
  'modal.txDetails.inquiryStatus': 'Update Inquiry Status',
  'prStatus.text': 'PR Status',
  'response.text': 'Response',
  'shipStation.logo': 'Ship Station',
  'shipStation.line1': 'Browse Rates',
  'shipStation.line2': 'Generate Shipping Label',
  'shipStation.line3': 'Track Shipment',
  'send.email.to.default.contact.message': 'Email will be sent to the default contact. If there is no default contact then email will not be sent.',
  'shareRFQ.text': 'Share RFQ',
  'shareRFQ.link.description': 'You can use the below link to share it with your supplier. Most important, anyone who has access to this link can view and submit response online.',
  'shareRegistrationForm.text': 'Share Registration Form',
  'shareRegistrationForm.link.description': 'You can use the below link to share it with anyone. Most important, anyone who has access to this link can register themselves',
  'ecommerce.text': 'Onine Store',
  'publishedStock.text': 'Published Stock',
  'b2bPrice.text': 'B2B Price',
  'published.text': 'Published',
  'publish.text': 'Publish',
  'unpublish.text': 'Unpublish',
  'clearance.text': 'Clearance',
  'dealOfTheDay.text': 'Deal of the Day',
  'newArrival.text': 'New Arrival',
  'bestSelling.text': 'Best Selling',
  'variantDetails.text': 'Variant Details',
  'invoice.voided.message': 'Invoice has been voided successfully',
  'authorize.net.statement': 'Authorize.Net Statement',
  'webRfq.comment.placeholder': 'Enter your comment here',
  'banking.transaction.not.found': 'No transaction found. Date should not be more than a month',
  'importAuthrizeStatement.message': 'This allow bank statement to alpide',
  'onlinePayment.text': 'Online Payment',
  'invoiceReminder.text': 'Invoice Reminder',
  'amount.is.required': 'Amount is required',
  'missing.hideBankTransactionId': 'HideBankTransactionId is missing',
  'missing.isActive': 'isActive is missing',
  'missing.transactionId': 'transactionId is missing',
  'hideTransaction.text': 'Hidden Transaction',
  'subscriptionName.text': 'Subscription Name',
  'selectVariant.text': 'Please select a variant',
  'menuSetting.text': 'Menu Settings',
  'menuSetting.helpMessage': 'Selected menu will be displayed',
  'projectSetting.text': 'Project Settings',
  'projectSetting.helpMessage': 'You can configure text to display, what project to display, ledger account to record the payment and payment gateway to process the payment for each project.',
  'feePayment.text': 'Fee Payment',
  'openEvent.text': 'Events',
  'registration.text': 'Registration',
  'fundRaising.text': 'Fundraising',
  'apiKey.text': 'API Key',
  'donate.text': 'Donate',
  'generateShippingLabel.text': 'Generate Shipping Label',
  'availableCarriars.text': 'Available Carriars',
  'carriarsPrice.text': 'Label Price',
  'downloadShippingLabel.text': 'Download Shipping Label',
  'customName.text': 'Account Name',
  'missing.projectMasterId': 'projectMasterId is missing',
  'missing.ledgerAccountId': 'chartOfAccountDetailsId is missing',
  'transactionId.text': 'Transaction Id',
  'missing.distributionGroupId': 'DistributionGroupId is missing',
  'missing.pageSize': 'pageSize is missing',
  'missing.pageNumber': 'DistributionGroupId is missing',
  'dimensions.text': 'Dimensions (L x W x H)',
  'lastPurchasedOn.text': 'Last Purchased On',

  'sidebar.menuItem.membership': 'Membership',
  'sidebar.menuItem.member': 'Members',
  'sidebar.menuItem.termAndFee': 'Term and Fee',
  'sidebar.menuItem.membershipOption': 'Membership Level',

  'membership.termAndFee.listing.header.type': 'Type',
  'membership.termAndFee.listing.header.fee': 'Fee',
  'membership.termAndFee.listing.header.frequency': 'Frequency',
  'membership.termAndFee.listing.header.duration': 'Duration',
  'membership.termAndFee.listing.header.period': 'Period',
  'membership.termAndFee.listing.header.lifetime': 'Lifetime',

  'getRate.text': 'Get Rate',
  'buyLabel.text': 'Buy Label',

  'sidebar.menuItem.fundraising': 'Fundraising',
  'sidebar.menuItem.fundraisingCampaign': 'Fundraising Campaign',
  'sidebar.menuItem.donations': 'Donations',
  'sidebar.menuItem.pledges': 'Pledges',
  'sidebar.menuItem.recurringDonationSchedules': 'Recurring Donation Schedules',
  'sidebar.menuItem.peerToPeer': 'Peer to Peer',
  'sidebar.menuItem.formBuilder': 'Form Builder',
  'sidebar.menuItem.communication': 'Communications',
  'sidebar.menuItem.account': 'Account',
  'sidebar.menuItem.distributionGroup': 'Distribution Group',
  'sidebar.menuItem.newsletter': 'Newsletter',
  'sidebar.menuItem.events': 'Events',
  'sidebar.menuItem.onlineStores': 'Online Stores',
  'sidebar.menuItem.kiosk': 'Kiosk',

  'fromEmail.text': 'From Email',
  'fromEmail.helpMessage': 'This email will be used fromn sending emails',

  'membership.termAndFee.addDrawer.drawerName': 'New Term & Fee',
  'membership.termAndFee.addDrawer.type': 'Type',
  'membership.termAndFee.addDrawer.type.new': 'New',
  'membership.termAndFee.addDrawer.type.renew': 'Renew',
  'membership.termAndFee.addDrawer.fee': 'Fee',
  'membership.termAndFee.addDrawer.frequency': 'Frequency',
  'membership.termAndFee.addDrawer.frequency.daily': 'Daily',
  'membership.termAndFee.addDrawer.frequency.weekly': 'Weekly',
  'membership.termAndFee.addDrawer.frequency.monthly': 'Daily',
  'membership.termAndFee.addDrawer.frequency.yearly': 'Yearly',
  'membership.termAndFee.addDrawer.duration': 'Duration',
  'membership.termAndFee.addDrawer.period': 'Period',
  'membership.termAndFee.addDrawer.period.week': 'Week',
  'membership.termAndFee.addDrawer.period.month': 'Month',
  'membership.termAndFee.addDrawer.period.year': 'Year',
  'membership.termAndFee.addDrawer.lifetime': 'Lifetime',
  'membership.termAndFee.addDrawer.lifetime.on': 'On',
  'membership.termAndFee.addDrawer.lifetime.off': 'Off',
  'membership.termAndFee.addDrawer.confitmation': 'Are you sure you want to create term and fee?',

  'requiredField.missing.msg': 'This field is required',

  'invalid.brodcast.to.field': 'Invalid Recipient data.',

  'membership.membershipOption.listing.header.name': 'Name',
  'membership.membershipOption.listing.header.shortName': 'Short Name',
  'membership.membershipOption.listing.header.type': 'Type',
  'membership.membershipOption.listing.header.active': 'Active',
  'membership.membershipOption.listing.header.maxFamilyMembers': 'Max Family Members',
  'membership.membershipOption.listing.header.fee': 'Fee',
  'membership.membershipOption.listing.header.totalMembers': 'Total Members',
  'membership.membershipOption.listing.header.totalAmount': 'Total Amount',

  'membership.membershipOption.addDrawer.drawerName': 'New Membership Level',
  'membership.membershipOption.addDrawer.name': 'Name',
  'membership.membershipOption.addDrawer.shortName': 'Short Name',
  'membership.membershipOption.addDrawer.type': 'Type',
  'membership.membershipOption.addDrawer.active': 'Active',
  'membership.membershipOption.addDrawer.maxFamilyMembers': 'Max Family Members',
  'membership.membershipOption.addDrawer.fee': 'Term & Fee',
  'membership.membershipOption.addDrawer.autoRenew': 'Auto Renew',

  'fundraising.campaign.listing.header.campaign': 'Campaign',
  'fundraising.campaign.listing.header.goal': 'Goal',
  'fundraising.campaign.listing.header.totalRecieved': 'Total Received',
  'fundraising.campaign.listing.header.totalDonars': 'Total Donors',
  'fundraising.campaign.listing.header.pledgeCount': 'Pledge Count',
  'fundraising.campaign.listing.header.startsOn': 'Starts On',
  'fundraising.campaign.listing.header.endsOn': 'Ends On',
  'fundraising.campaign.listing.header.status': 'Status',

  'fundraising.pledge.listing.header.donar': 'Donor',
  'fundraising.pledge.listing.header.amount': 'Amount',
  'fundraising.pledge.listing.header.startDate': 'Start Date',
  'fundraising.pledge.listing.header.status': 'Status',
  'fundraising.pledge.listing.header.campaign': 'Campaign',
  'fundraising.pledge.listing.header.recurring': 'Recurring',
  'fundraising.pledge.listing.header.type': 'Type',

  'fundraising.campaign.addDrawer.drawerName': 'New Fundraising Campaign',
  'fundraising.campaign.addDrawer.field.campaignName': 'Campaign Name',
  'fundraising.campaign.addDrawer.field.goal': 'Goal',
  'fundraising.campaign.addDrawer.field.startDate': 'Start Date',
  'fundraising.campaign.addDrawer.field.endDate': 'End Date',
  'fundraising.campaign.addDrawer.field.form': 'Form',
  'fundraising.campaign.addDrawer.field.status': 'Status',
  'fundraising.campaign.addDrawer.confitmation': 'Are you sure you want to create campaign?',
  'fundraising.campaign.addDrawer.field.campaignCode': 'Campaign Code',

  'fundraising.pledge.addDrawer.drawerName': 'New Pledge',
  'fundraising.pledge.addDrawer.field.donar': 'Donor',
  'fundraising.pledge.addDrawer.field.amount': 'Amount',
  'fundraising.pledge.addDrawer.field.paymentMode': 'Payment Mode',
  'fundraising.pledge.addDrawer.field.paymentModeDetails': 'Payment Mode Details',
  'fundraising.pledge.addDrawer.field.status': 'Status',
  'fundraising.pledge.addDrawer.field.fundraisingCampaign': 'Fundraising Campaign',
  'fundraising.pledge.addDrawer.field.recurring': 'Recurring',
  'fundraising.pledge.addDrawer.field.every': 'Every',
  'fundraising.pledge.addDrawer.field.startDate': 'Start Date',
  'fundraising.pledge.addDrawer.field.endDate': 'End Date',
  'fundraising.pledge.addDrawer.confitmation': 'Are you sure you want to create pledge?',

  'communication.dgGroup.listing.header.groupName': 'Group Name',
  'communication.dgGroup.listing.header.recipients': 'Recipients',

  'communication.dgGroup.addDrawer.drawerName': 'New Distribution Group',
  'communication.dgGroup.addDrawer.field.name': 'Distribution Group Name',
  'communication.dgGroup.addDrawer.field.newContact': 'New Contact',
  'communication.dgGroup.addDrawer.field.import': 'Import',
  'communication.dgGroup.addDrawer.field.recepients': 'Recepients',
  'communication.dgGroup.addDrawer.listing.header.firstName': 'First Name',
  'communication.dgGroup.addDrawer.listing.header.lastName': 'Last Name',
  'communication.dgGroup.addDrawer.listing.header.phone': 'Phone',
  'communication.dgGroup.addDrawer.listing.header.email': 'Email',

  'communication.account.brodcastSMS.drawer.drawerName': 'Brodcast SMS',
  'communication.account.brodcastSMS.drawer.form.from': 'From',
  'communication.account.brodcastSMS.drawer.form.to': 'To',
  'communication.account.brodcastSMS.drawer.form.message': 'Message',
  'communication.account.brodcastSMS.drawer.form.cost': 'Cost',
  'communication.account.popup.brodcast.confimation.msg': 'Are you sure you want to Brodcast this message now?',
  'communication.account.brodcastEmail.drawer.drawerName': 'Brodcast Email',

  'common.listing.header.action': 'Action',
  'commons.listing.edit.tooltip.msg': 'Edit',
  'commons.listing.delete.tooltip.msg': 'Delete',
  'commons.create.popup.confimation.msg': 'Are you sure you want to create?',
  'commons.edit.popup.confimation.msg': 'Are you sure you want to update?',
  'commons.delete.popup.confimation.msg': 'Are you sure you want to delete?',

  'sidebar.menuItem.easyPost': 'EasyPost',
  'sidebar.menuItem.shipStation': 'ShipStation',
  'easyPost.logo': 'EasyPost',
  'setting.kioskDonation.heading': 'Kiosk Configurations',
  'missing.country.id': 'Country id is required',
  'missing.state.id': 'State id is required',
  'missing.tax.rate': 'Tax rate is required',
  'buttonName.text': 'Button Name',
  'buttonAction.text': 'Button Action',
  'buttonSize.text': 'Button Size',
  'buttonCorner.text': 'Corner Style',
  'buttonPosition.text': 'Position',
  'buttonTextStyle.text': 'Text Style',
  'buttonBackgraound.text': 'Background',
  'buttonTextColor.text': 'Text Color',
  'shipping.weight.number': 'Shipping weight must be number',
  'buttonTextSize.text': 'Text Size',
  'buttonHeight.height': 'Height',
  'tooltip.shippingWeight': 'Shipping weight is used to calculate shipping rates at checkout',
  'shippingWeight.text': 'Shipping Weight',
  'measurementUnit.text': 'Measurement Unit',
  'missing.marketplace.shipping.country.id': 'marketplaceShippingCountryId is missing',
  'missing.marketplace.shipping.choice.id': 'marketplaceShippingChoiceId is missing',
  'missing.marketplace.shipping.choice.name': 'marketplace shipping choiceName is missing',
  'missing.marketplace.shipping.choiceDescription': 'marketplace shipping choiceDescription is missing',
  'altText.text': 'Alt Text',
  'imageSize.text': 'Image Size',
  'imgPosition.text': 'Image Position',
  'imgAction.text': 'Image Action',
  'missing.weight.price.range': 'Price range or Weight range is missing',
  'missing.shipping.name': 'Shipping name is missing',
  'missing.marketplacesipping.rule.id': 'marketplaceShippingRuleId is missing',
  'buttonCorners.text': 'Corner',
  'serialNumber.text': 'Serial Number',
  'formItem.text': 'Form Item',
  'account.exists': 'Account exists. Request can not be process.',
  'missing.message': 'Message body is missing',
  'missing.dg.id': 'distributionGroupId is missing. Please contact the tech support.',
  'formType.text': 'Form Type',
  'primaryContactForInvoicing.text': 'Primary Contact',
  'fatherDOB.text': 'Father\'s DOB',
  'motherDOB.text': 'Mother\'s DOB',
  'shippingChoiceId': 'shippingChoiceId is missing. This is a technical issue. Please contact the tech support.',
  'sidebar.menuItem.membershipForm': 'Membership Form',
  'formDescription.text': 'Form Description',
  'bordercolor.text': 'Border Color',
  'footerMessage.text': 'Footer Message',
  'fundraisingSetting.text': 'Fundraising Setting',
  'fundraisingName.text': 'Fundraising Name',
  'flashMessage.text': 'Flash Message',

  'linkName.text': 'Link Name',
  'linkAction.text': 'Link Action',
  'event.startDate.and.time': 'Event Start Date & Time',
  'event.hostedBy': 'Event Hosted By',
  'buttonPosition.vertical.text': 'Position Vertical',
  'templateType.changed.confirmation': 'Are you sure you want to change the template type? Any unsaved changes will be lost.',
  'backgraund.opacity.text': 'Opacity',
  'invalid.phone.number.format': 'Phone number format is not valid. Please make sure ph number 10 digits and in this format +15555555555 (+1 us the country code)',
  'fundraisingCampaign.required': 'Fundraising Campaign required',
  'accountSetup.text': 'Account Setup',
  'missing.source': 'Source is missing',
  'missing.start.date': 'Start date is missing',
  'missing.end.date': 'End date is missing',
  'pagesize.cant.zero': 'Page size can not be 0',
  'guestName.text': 'Guest Name',
  'numberOfAdult.text': 'No of Adults (12+)',
  'numberOfChild.text': 'No of Child (Under 12)',
  'missing.sequence.id': 'Sequence id is missing.',
  'missing.transaction.id': 'Transaction id is missing.',
  'close.confirmation': 'Are you sure you want to close? Any unsaved changes will be lost.',
  'sidebar.menuItem.school': 'School',
  'sidebar.menuItem.advances': 'Advances',
  'account.displayName.helpMessage': 'This will be used to display on the text message',
  'account.displayName.validationMessage': 'Display Name is required',
  'missing.tx.number': 'Transaction Name is required',
  'cell.must.be.10.chars': 'Phone must be number and 10 characters',
  'countryCallingCode.text': 'Country Code',
  'countryCallingCode.validationMessage': 'Country Code Required',
  'contact.importContact.modal.title': 'Import Contact',
  'contact.buttonMenu.importContact': 'Import Contact',
  'phone.invalid.country.code': 'Invalid Country code. Phone # must start with +(country code)',
  'templateType.share.confirmation': 'Are you sure you want to share the template? All changes will be saved.',
  'subscribe.text': 'Subscribe',
  'eventName.text': 'Event Name',
  'schoolRegistration.text': 'School Registration',
  'MemberRegistration.text': 'Member Registration',
  'attendingStatus.text': 'Are you attending?',
  'attending.text': 'Attending',
  'tentative.text': 'Tentative',
  'declined.text': 'Declined',
  'common.missing.requiredField.message': 'Required fields are missing',
  'missing.campaign.id': 'Campaign id is missing',
  'missing.campaign.code': 'Fundraising campaign code is missing',
  'missing.contact.id': 'Contact id is missing',
  'missing.contavct.type': 'Contact type is missing',
  'facebookLink.text': 'Facebook Link',
  'instagramLink.text': 'Instagram Link',
  'twitterLink.text': 'Twitter Link',
  'linkedInLink.text': 'LinkedIn Link',
  'youtubeLink.text': 'Youtube Link',
  'link.text': 'Link',
  'eventTitle.text': 'Event Title',
  'first.name.required': 'First Name is required',
  'dgNames.text': 'DG Names',
  'subscribeHeaderMessage.text': 'Subscribe Header Message',
  'shareMembershipForm.text': 'Share Membership Form',
  'maritalStatus.text': 'Marital Status',
  'spouse.fName': 'Spouse\'s First Name',
  'spouse.lName': 'Spouse\'s Last Name',
  'spouse.mobile': 'Spouse\'s Mobile',
  'spouse.email': 'Spouse\'s Email',
  'spouseName.text': 'Spouse Name',
  'spouseMobile.text': 'Spouse Mobile',
  'spouseEmail.text': 'Spouse Email',
  'spouseDOB.text': 'Spouse DOB',
  'familyDoctorName.text': 'Family Doctor Name',
  'familyDoctorPhone.text': 'Family Doctor Ph. #',
  'nationality.text': 'Nationality',
  'membershipType.text': 'Membership Type',
  'paymentStartDate.text2': 'Payment Start Date',
  'campaignCode.exist.message': 'Fundraising Campaign Code exists. Please use a different code',
  'primaryContactForInvoicing.validation.message': 'Primary Contact is required',
  'fundraising.campaign.addTeam.drawerName': 'Add Team to Campaign',
  'fundraising.campaign.teamDrawer.field.teamName': 'Team Name',
  'fundraising.campaign.teamDrawer.field.goal': 'Goal',
  'fundraising.campaign.teamDrawer.field.teamLead': 'Team Lead',
  'fundraising.campaign.addFundraiserToTeam.drawerName': "Add Fundraiser to Team",
  'fundraising.campaign.fundraiserrawer.field.searchFundrasier': 'Search Fundraiser',
  'button.createFundraiser': 'Create Fundraiser',
  'fundraiser.cellPhone': 'Cell Phone',
  'fundraiser.name': 'Name',
  'addItem.text.createFundraiser': 'Create Fundraiser',
  'addFundraiser.confirmation': 'Are you sure you want to add {value} ',
  'dashboard.cashFlow': 'Cash Flow',
  'dashboard.recentPayment': 'Recent Payment',
  'dashboard.fundraisingCampaing': 'Fundraising Campaing',
  'dashboard.accountsReceivable': 'Accounts Receivable',
  'dashboard.member': 'Members',
  'dashboard.event': 'Events',
  'dashboard.RecentExpenses': 'Recent Expenses',
  'dashboard.expense': 'Expense',
  'missing.pledgeId': 'Pledge id is missing',
  'cant.delete.pledge': 'Pledge can not be deleted because payment has been receieved.',
  'team.confirmation': 'Are you sure you want to add the team?',
  'commmon.email.invalidEmail': 'Invalid Email',
  'attendance.text': 'Attendance',
  'openRegistration.text': 'Register Now',
  'current.password.incorrect': 'Current password is incorrect. Please enter the correct current password and try again.',
  'password.rule.invalid': 'Please enter 8 characters password.',
  'editProfile.text': 'Edit Profile',
  'viewProfile.text': 'View Profile',
  'parent.paymentPlan.text': 'Setup fees payment plan', 
  'addPaymentPlan.text': 'Add Payment Plan',
  'paymentPlan.text': 'Select Fee',
  'select.paymentPlan': 'Select Payment Plan',
  'registration.parentPaymentPlan.required': 'Please provide the payment plan',
  'createAccount.text': 'Create Account',
  'contact.is.misisng':'Contact is missing',
  'enrolled.text': 'Currently Enrolled',
  'profile.data.missing': 'Profile data (ERR-CUST OBJ NULL) is missing',
  'registration.orgMember.required': 'Please select org member',
  'registration.financialAssistance.required': 'Please select financial assistance',
  'financialAssistance.message': 'Are you in financial crisis and you need financial assistance?',
  'mother.email.found.for.father': 'Email not available - The father email you have entered exists as mother email in the system.',
  'father.email.found.for.mother' : 'Email not available - the mother email you have entered exists as father email in the system.',
  'mother.email.exist': 'Email not available - Mother email already exists in the system (may be in different account). Try with different mother email.',
  'father.email.exist': 'Email not available - Father email already exists in the system (may be in different account). Try with different father email.',
  'missing.clientOnlineUserAccountId': 'clientOnlineUserAccountId is missing',
  'fathre.mother.email.cant.same': 'Father and mother email can not be same',
  'addTeacher.level': 'Are you teacher?',
  'addVolunteer.level': 'Do you volunteer?',
  'registration.addTeacher.required': 'Please provide, Are you teacher?',
  'registration.addVolunteer.required': 'Please provide, Are you volunteer?',
  'student.firstName.missing': 'First Name is missing',
  'student.lastName.missing': 'Last Name is missing',
  'student.grade.missing': 'Class is missing',
  'student.dob.missing': 'DOB is missing',
  'student.allergies.missing': 'Allergies is missing',
  'student.gender.missing': 'Gender is missing',
  'invalid.token': 'Invalid accont activation request. Please signup again.',
  'missing.mother.firstName.primary.contact': 'You have selected mother as the primary contact but mother first name is missing.',
  'missing.mother.lastName.primary.contact': 'You have selected mother as the primary contact but mother last name is missing.',
  'missing.mother.email.primary.contact': 'You have selected mother as the primary contact but mother email is missing.',
  'missing.mother.mobile.primary.contact': 'You have selected mother as the primary contact but mother mobile number is missing.',
  'missing.father.firstName.primary.contact': 'You have selected father as the primary contact but father first name is missing.',
  'missing.father.lastName.primary.contact': 'You have selected father as the primary contact but father last name is missing.',
  'missing.father.email.primary.contact': 'You have selected father as the primary contact but father email is missing.',
  'missing.father.mobile.primary.contact': 'You have selected father as the Primary Contact but father mobile number is missing.',
  'max.attempts': 'Assignemnt can not be resubmitted because you have reached the maximum allowable attempts.',
  "max.attempt.zero": "Max attempts can not be zero",
  "no.assignment.for.classId":"No assignment found for the selected class",
  'address.street.missing.message': 'Street Name is missing',
  'address.city.missing.message': 'City Name is missing',
  'address.zipCode.missing.message': 'Zip/Postal code field is missing',
  'missing.contact.data': 'Contact data is missing',
  'communication.emails.txt' : 'Emails',
  'communication.broadcast.text' : 'Broadcast',
  'spouse.email.exist': 'Spouse email exists.',
  'missing.class.id': 'Class info is missing. Please select a class and submit again.',
  'phoneNumber.incorrect.message': 'Phone number must be number and 10 digit without space',
  'technical.issue': 'There is an issue creating the account. Please contact support.',
  'contact.relationship.message': 'Relationship is required',
  'allergies.label': 'Allergies (Y/N)',
  'myProfile.text': 'My Profile',
  'education.text': 'Education',
  'employment.text': 'Employment',
  'online.account.already.enabled': 'Online account has alraedy been enabled for this account.',
  'facebook.text': 'Facebook',
  'instagram.text': 'Instagram',
  'twitter.text': 'Twitter',
  'linkedIn.text': 'LinkedIn',
  'email.required.for.portal.access':'Email address is requierd for portal access. Please edit contact to provide email address',
  'addButton.familyMember.text': 'Add Family Member',
  'registration.membershipOption.required': 'Please select membership level',
  'sidebar.menuItem.registration' : 'Registration',
  'sidebar.menuItem.registerNow' : 'Register Now',
  'sidebar.menuItem.enrolled' : 'Currently Enrolled',
  'sidebar.menuItem.fees' : 'Fees',
  'sidebar.menuItem.attendance' : 'Attendance',
  'sidebar.menuItem.assignment' : 'Assignment',
  'sidebar.menuItem.communication' : 'Communication',
  'sidebar.menuItem.email' : 'Email',
  'sidebar.menuItem.broadcast' : 'Broadcasts',
  'allergies.missing.message': 'Allergies required',
  'student.gradeFee.missing': 'Class fee missing for some student',
  'age.text': 'Age',
  'contact.mobileNumber.missing': 'Incorrect mobile number is missing',
  'status.text': 'Status',
  'phone.number.shortLength': 'Phone number must be of 10 digits',
  'student.age.missing': 'Please enter student age',
  'heading.feesManagement.text': 'Fees',
  'table.heading.fees': 'Fees List',
  'contact.fullName': 'Full Name',
  'contact.mobileNumber.message': 'Mobile Number is Missing',
  'contact.relationship': 'Relationship',
  'password.verification': 'Password must contain 1 capital letter, 1 number, 1 special character and if of 8 character length',
  'registrationForms.page.name': 'Registration Forms',
  'registrationForms.list.name': 'Registration Forms List',
  'formName.text': 'Form Name',
  'common.serial.no': 'S.No.',
  'table.heading.preInvoiceList': 'Pre Invoice List',
  'modal.education.educationType' : 'Education Type is required',
  'modal.education.ScoreGPA' : 'Score/GPA Type is required',
  'modal.education.employerName' : "Employer Name is required",
  'modal.education.employerPhone' : "Employer Phone is required",
  'modal.education.employerEmail' : "Employer Email is required ",
  'modal.addFamilyMember.gender' : "Gender is required",
  'modal.addFamilyMember.familyRelationship' : 'Relationship is required',
  'modal.addFamilyMember.cellPhone' : "Phone is required",
  'modal.addFamilyMember.firstName': 'First Name is required',
  'modal.addFamilyMember.lastName': 'Last Name is required',
  'modal.addFamilyMember.emailAddress' : 'Email is required',
  'modal.addFamilyMember.allergies' : 'Allergies is required',
  'modal.addFamilyMember.allergiesDesc' : 'Specify Allergy is required',
  'modal.addFamilyMember.dateOfBirth' : 'DOB is required',
  'modal.addFamilyMember.bloodGroup' : 'Blood Group is required',
  'Log In':'Log In',
  'modal.education.School': "School is required",
  'modal.education.CollegeUniversity': "College/University is required",
  'modal.education.startDate': "Start Date is required",
  'modal.education.forms': 'Forms',
  'modal.education.present': 'Present',
  'modal.education.absent': 'Absent',
  'modal.education.holiday': 'Holiday',
  'modal.education.sunday': 'Sunday',
  'modal.education.students': 'Students',
  'sidebar.menuItem.timeTable': 'Time Table',
  'table.heading.timeTableList': "Time Table List",
};

export const en = lang;