import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DashboardComponent from '../../components/dashboard/index';
import { getFeesList, getInvoiceProforma } from '../FeeList/action';
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions';
import moment from "moment";
import {getUnpaidAmtByCustomerId, getPaidAmtByCustomerId, getTotalFees, getStudentClassByStudentId, 
    getTotalInvoiceAmtByCustomerId, getPaymentSubscription, getBroadcastMessageByCustomerId, getStudentsByCustomerIdAndCourseId, 
    getProformaByCustomerIdAndCourseId} from './action';
import { getSchoolAssignment } from '../AssignmentList/action';
import { getSchoolAttendanceByStudentId } from '../Attendance/action';
import { COMMON_ACTIONS, CONSTANTS } from '../../static/constants';
import {getAllStudentForm } from '../OpenRegistration/action';
import { fetchFromLocalStorage, getCurrentDateForBackend } from '../../utils';
import { getCustomerCommunication } from '../Communication/action';


class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 1,
            pageSize: 200,
            salesSelectedDate: moment().startOf('month').format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) + "-" + moment().endOf('month').format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            purchaseSelectedDate: moment().startOf('month').format(CONSTANTS.DISPLAY_DATE_FORMAT_FY) + "-" + moment().endOf('month').format(CONSTANTS.DISPLAY_DATE_FORMAT_FY),
            isDataLoaded: false,
            isLoaded:false
        }
    }
    

    componentDidMount() {
        this.props.updateHeaderState({headerHeading: 'Dashboard'})
        const companyInfo = this.props.companyInfo || {};
        const customerInfo = this.props.customerInfo || {};

        const payload = { 
            relationshipId: companyInfo.relationshipId, 
            customerId: customerInfo.customerId,
            courseId: JSON.parse(fetchFromLocalStorage(COMMON_ACTIONS.DEFAULT_COURSE) || '0'),
            schoolStudentId: 0,
            pageNumber: 1, 
            pageSize: 100,
        };
        this.props.getStudentsByCustomerIdAndCourseId(payload);
        this.props.getUnpaidAmtByCustomerId(payload);
        // this.props.getPaidAmtByCustomerId(payload);
        this.props.getTotalFees(payload);
        this.props.getStudentClassByStudentId(payload);
        this.props.getAllStudentForm(payload);
        this.props.getTotalInvoiceAmtByCustomerId(payload);
        // this.props.getPaymentSubscription(payload);
        this.props.getBroadcastMessageByCustomerId(payload);
        this.props.getCustomerCommunication(payload);
        this.props.getProformaByCustomerIdAndCourseId(payload);
        // this.props.getSchoolAssignment(payload)
        // this.props.getFeesList(payload);
    }

    componentWillReceiveProps(props){
      const currentDate = new Date();

      if(!this.state.isDataLoaded && (props.allStudentList || []).length ){
          let payload = {
              relationshipId: props.companyInfo.relationshipId,
              classId : props.allStudentList[0].classId,
              studentId : props.allStudentList[0].schoolStudentId,
              startDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
              endDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)),
              customerId: (this.props.customerInfo || {}).customerId,
              pageNumber: 1,
              pageSize: 100,
            }
            this.props.getPaidAmtByCustomerId(payload);
            props.getSchoolAssignment(payload); 
            props.getSchoolAttendanceByStudentId(payload);
        
            this.props.getInvoiceProforma({
                relationshipId: props.companyInfo.relationshipId,
                customerId: props.allStudentList[0].customerId,
                orgRegistrationId: props.allStudentList[0].orgRegistrationId
      
              });
            this.setState({isDataLoaded: true, activeTabKey: props.allStudentList[0].schoolStudentId.toString(),selectedStudent: props.allStudentList[0]})
        }
    
       
    }
    

    render() {
         return <DashboardComponent {...this.state} {...this.props} updateState={(data) => { this.setState(data) }} />
    }

}

const mapStateToProps = (state) => {
    return {
        common: state.common,
        companyInfo: state.common.companyInfo,
        customerInfo: state.common.customerInfo,
        unpaidCustomerAmount: state.dashboard.unpaidCustomerAmount,
        paidCustomerAmount: state.dashboard.paidCustomerAmount,
        totalInvoiceCustomerAmount: state.dashboard.totalInvoiceCustomerAmount,
        studentClassList: state.dashboard.studentClassList,
        totalFeeAmount: state.dashboard.totalFeeAmount,
        paymentSubscription: state.dashboard.paymentSubscription,
        broadcastMessageList: state.dashboard.broadcastMessageList,
        feeList: state.school.feeList[1],
        allStudentList: state.dashboard.allStudentList,
        assignmentList: state.school.assignmentList,
        // studentAttendance: state.dashboard.studentAttendance,
        studentAttendance: state.school.studentAttendance,
        defaultCourse: state.common.defaultCourse,
        invoiceProformaList: state.school.invoiceProformaList,
        communicationList: state.school.communicationList,
        customerProformaData:state.dashboard.customerProformaData

    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    showModal,
    hideModal,
    pushModalToStack,
    popModalFromStack,
    getUnpaidAmtByCustomerId, getPaidAmtByCustomerId, getTotalFees,
    getStudentClassByStudentId,
    getAllStudentForm,
    getTotalInvoiceAmtByCustomerId,
    getPaymentSubscription,
    getBroadcastMessageByCustomerId,
    getFeesList,
    getSchoolAssignment,
    getSchoolAttendanceByStudentId,
    getStudentsByCustomerIdAndCourseId,
    getInvoiceProforma,
    getCustomerCommunication,
    getProformaByCustomerIdAndCourseId
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
