import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ForgotPassword from '../../components/forgotpassword/Forgotpassword';
import { sendResetMail } from './action';
class Forgot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailClass: '',
      emailInvalidClass: '',
    }
  }

  render() {
    return <ForgotPassword {...this.props} {...this.state} updateState={(data) => { this.setState(data) }} />
  }

}
const mapStateToProps = (state) => {
  return {
    relationshipId: state.common.companyInfo.relationshipId,
    companyInfo: state.common.companyInfo,
    
  }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ 
  sendResetMail, 
  // fetchRelationshipByDomainName, fetchRelationshipDataByRID  
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);
