import axios from "axios";
import config from "../../config/environmentConfig";
import { SCHOOL_ACTIONS, COMMON_ACTIONS } from "../../static/constants";
import { showToasterMessage } from "../../utils";
const { lang } = require("../../translations/" + (sessionStorage.getItem("language") || "en") + ".js");

export const getClassSectionList = (payload) => {
    return (dispatch) => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/classSection/getSection?relationshipId=${payload.relationshipId}&pageSize=${payload.pageSize || 100}&pageNumber=${payload.pageNumber || 0}&empId=0`)
        .then((res) => {
        //   const data = {
        //     pageNo: payload.pageNumber,
        //     list: res.data
        //   }
          dispatch({
            type: SCHOOL_ACTIONS.CLASS_SECTION_LIST,
            res,
          });
        })
        .catch((err) => {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    };
  };

  export const getTimeTableByClassSection = (payload) => {
    return (dispatch) => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .get(`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS}/timetable/getTimeTableByClass?relationshipId=${payload.relationshipId}&classId=${payload.classId}&sectionId=${payload.sectionId}`)
        .then((res) => {
          dispatch({
            type: SCHOOL_ACTIONS.TIME_TABLE_LIST,
            data: res.data,
          });
        })
        .catch((err) => {
            showToasterMessage({
              messageType: "error",
              description: lang[((err.response || {}).data || {}).message] || "Some error occurred",
            });
        })
        .finally(()=>{
          dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
        })
    };
  };
  export const getClassList = (payload) => {
    return (dispatch) => {
      return axios
        .get(
          `${config.API_BASE_URL}${config.API_VERSION}/${config.rootContextSMS
          }/class/getClassList?relationshipId=${payload.relationshipId}&empId=${payload.relationshipEmployeeId || 0}`
        )
        .then((res) => {
         
          dispatch({
            type: SCHOOL_ACTIONS.CLASS_LISTING,
            data:res.data,
          });
        })
        .catch((err) => {
          showToasterMessage({
            messageType: "error",
            description:
              lang[((err.response || {}).data || {}).message] ||
              "Some error occurred",
          });
       
        })
    };
  };