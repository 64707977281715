import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Tile from '../tiles/tiles';
import './dashboard.scss';
import { Col, Row } from "antd";
import { MODAL_TYPE } from '../../static/constants';
import { Tabs } from 'antd';
import GreenRing from '../../assets/images/ringProgress.svg';
import RedRing from '../../assets/images/redRing.svg';
import PageBreadcrumb from "../PageBreadcrumb";
// import img1 from '../../assets/images/User (1).png'
import img2 from '../../assets/images/User (2).png'
import img3 from '../../assets/images/User (3).png'
import img4 from '../../assets/images/User4.png';
import img5 from '../../assets/images/User9.png';
// import FeesDetails from '../../assets/images/fee-details.png'
// import PaymentIcon from '../../assets/images/payment-pic.jpg'
import { DASHBOARD_PAGE_CONSTANTS, CONSTANTS } from '../../static/constants';
import UnpaidInvoices from '../../containers/Dashboard/UnpaidInvoices';
import { fixedDecimalNumber, getCurrentDateForBackend, getMomentDateForUIReadOnly, getUserDefinedName } from '../../utils';
import config from '../../config/environmentConfig';
import ReactApexChart from "react-apexcharts";
import EmailInfo from '../modal/modalBody/communication/EmailInfo';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;

const { TabPane } = Tabs;

class DashboardComponent extends React.Component {

  getTotalCustomerDue = () => {
    let total = 0;
    if (this.props.customerUnpaidInvoices && this.props.customerUnpaidInvoices[0]) {
      this.props.customerUnpaidInvoices[0].forEach(function (d) {
        total = total + d.invoiceDueAmount;
      });
    }
    return total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  getAmountByLedgerGroup = (groupName, category, type, toReduceType) => {
    let randomTotalAmount = 0;

    for (var i = 0; i < this.props.allLedgerAccounts.length; i++) {
      if (this.props.allLedgerAccounts[i].categoryGroupName === groupName && this.props.allLedgerAccounts[i].categoryName === category && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) !== toReduceType) {
        randomTotalAmount = randomTotalAmount + this.props.allLedgerAccounts[i].amountDifference;

      } else if (this.props.allLedgerAccounts[i].categoryGroupName === groupName && this.props.allLedgerAccounts[i].categoryName === category && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === toReduceType) {
        randomTotalAmount = randomTotalAmount - this.props.allLedgerAccounts[i].amountDifference;
      }
    }

    return parseFloat((randomTotalAmount || 0).toFixed(2));

  }

  openUnpaidInvoicesModal = (context, customer, title) => {
    this.props.showModal(
      {
        title: <Fragment>
          <div className='ant-modal-title'>{title || 'Total Invoices'}</div>
        </Fragment>,
        modalBody: <UnpaidInvoices {...this.props} context={context} customerData={customer} />,
        width: 1100,
        hideFooter: true,
        wrapClassName: 'tiles-modal'
      }
    )
  }

  getTotalSupplierDue = () => {
    let total = 0;
    if (this.props.supplierUnpaidInvoices && this.props.supplierUnpaidInvoices[0]) {
      this.props.supplierUnpaidInvoices[0].forEach(function (d) {
        total = total + d.invoiceDueAmount;
      });
    }
    return total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  getTotal = (type) => {
    let totalAmount = 0;
    if (!this.props.allLedgerAccounts && !this.props.allLedgerAccounts.length) {
      return;
    }
    let randomTotalAmount = 0;
    switch (type) {
      case "Equity and Liabilities": {
        for (let i = 0; i < this.props.allLedgerAccounts.length; i++) {
          if ((this.props.allLedgerAccounts[i].categoryName === "Shareholder's Funds" || this.props.allLedgerAccounts[i].categoryName === "Non Current Liabilities" || this.props.allLedgerAccounts[i].categoryName === "Current Liabilities") && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
            totalAmount = totalAmount + this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if ((this.props.allLedgerAccounts[i].categoryName === "Shareholder's Funds" || this.props.allLedgerAccounts[i].categoryName === "Non Current Liabilities" || this.props.allLedgerAccounts[i].categoryName === "Current Liabilities") && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
            totalAmount = totalAmount - this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Assets": {
        for (let i = 0; i < this.props.allLedgerAccounts.length; i++) {
          if ((this.props.allLedgerAccounts[i].categoryName === "Non - Current Assets" || this.props.allLedgerAccounts[i].categoryName === "Current Assets") && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
            totalAmount = totalAmount + this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if ((this.props.allLedgerAccounts[i].categoryName === "Non - Current Assets" || this.props.allLedgerAccounts[i].categoryName === "Current Assets") && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
            totalAmount = totalAmount - this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Expenses": {
        for (let i = 0; i < this.props.allLedgerAccounts.length; i++) {
          if ((this.props.allLedgerAccounts[i].categoryName === "Cost of Materials" || this.props.allLedgerAccounts[i].categoryName === "Employee Benefit Expense" || this.props.allLedgerAccounts[i].categoryName === "Other Expenses" || this.props.allLedgerAccounts[i].categoryName === "Taxes" || this.props.allLedgerAccounts[i].categoryName === "Finance Cost") && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
            totalAmount = totalAmount + this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if ((this.props.allLedgerAccounts[i].categoryName === "Cost of Materials" || this.props.allLedgerAccounts[i].categoryName === "Employee Benefit Expense" || this.props.allLedgerAccounts[i].categoryName === "Other Expenses" || this.props.allLedgerAccounts[i].categoryName === "Taxes" || this.props.allLedgerAccounts[i].categoryName === "Finance Cost") && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
            totalAmount = totalAmount - this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      case "Income": {
        for (let i = 0; i < this.props.allLedgerAccounts.length; i++) {
          if ((this.props.allLedgerAccounts[i].categoryName === "Revenue from Operations" || this.props.allLedgerAccounts[i].categoryName === "Other Income") && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === "CREDIT") {
            totalAmount = totalAmount + this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          } else if ((this.props.allLedgerAccounts[i].categoryName === "Revenue from Operations" || this.props.allLedgerAccounts[i].categoryName === "Other Income") && ((this.props.allLedgerAccounts[i].nature && this.props.allLedgerAccounts[i].nature.toUpperCase()) || this.props.allLedgerAccounts[i].accountingEntry.toUpperCase()) === "DEBIT") {
            totalAmount = totalAmount - this.props.allLedgerAccounts[i].amountDifference;
            randomTotalAmount = totalAmount.toFixed(2);
          }
        }
        break;
      }
      default:
        break;
    }

    return randomTotalAmount;
  };

  openModalOfSelectedTile = (data) => {
    switch (data.type) {
      case DASHBOARD_PAGE_CONSTANTS.CUSTOMER_DUE:
        // this.props.showModal(
        //     {
        //         title: <Fragment>
        //             <div className='ant-modal-title'>Customer Due</div>
        //         </Fragment>,
        //         modalBody: <UnpaidInvoices  {...this.props} />,
        //         width: 1000,
        //         hideFooter: true,
        //         wrapClassName: 'tiles-modal'
        //     }
        // )
        break;
      case DASHBOARD_PAGE_CONSTANTS.SUPPLIER_DUE:
        // this.props.showModal(
        //     {
        //         title: <Fragment>
        //             <div className='ant-modal-title'>Supplier Due</div>
        //         </Fragment>,
        //         modalBody: <SupplierUnpaidInvoices  {...this.props} />,
        //         width: 1000,
        //         hideFooter: true,
        //         wrapClassName: 'tiles-modal'
        //     }
        // )
        break;
      case DASHBOARD_PAGE_CONSTANTS.BALANCE_SHEET: {
        this.props.history.push({
          pathname: '/admin/balance-sheet',
        })
        break;
      }
      case DASHBOARD_PAGE_CONSTANTS.PROFIT_AND_LOSS: {
        this.props.history.push({
          pathname: '/admin/profit-loss',
        })
        break;
      }
      default:
        break;
    }
  }

  getCashFlow = (data) => {
    let total = 0;
    Object.keys(data || {}).map((e, i) => {
      total = total + Number(e);
    });
    return total;
  }

  render() {
    const openUrlPopup = (props, payload) => {
      const formData = payload.formData || {};
      const { pushModalToStack, companyInfo = {} } = props;
      formData.relationshipId = companyInfo.relationshipId;
      const data = {
        title: <div></div>,
        formData,
        hideFooter: true,
        hideTitle: true,
        // hideCloseIcon: true,
        modalData: {
          modalType: MODAL_TYPE.URL_POPUP,
          data: payload,
        },
        width: "90%",
        keyboard: true,
        wrapClassName: "modal-custom-detail",
        style: { marginTop: '-20px' },
        handleSubmit: (formData = {}) => {
          loadPageData(props.pageNumber, props.pageSize);
          props.popModalFromStack(data);
        }
      };
      pushModalToStack(data);
    }

    const breadCrumbList = [
      {
        name: <FormattedMessage id='breadcrum.dashboard' defaultMessage='' />,
      }
    ];
    const { customerInfo = {}, companyInfo, feeList = [] } = this.props;

    const loadPageData = (pageNumber, pageSize) => {

      const payload = {
        companyInfo,
        relationshipId: companyInfo.relationshipId,
        pageNumber: pageNumber || 1,
        pageSize: pageSize || 25,
        customerId: customerInfo.customerId,
        formId: this.props.defaultSchoolForm
      };
      this.props.getFeesList(payload);
      this.props.getFeesCount(payload);
    };


    const tilesData = [
      {
        footer: 'TOTAL STUDENT',
        count: (this.props.allStudentList || []).length || 0,
        type: DASHBOARD_PAGE_CONSTANTS.TOTAL_STUDENT,
        color: 'tile2',
        antIcon: <img src={img4} alt="" width={"48px"} height={"48px"} />
      },
      {
        footer: 'TOTAL FEES',
        count:  Number(this.props.customerProformaData?.totalAmount || 0).toFixed(2),
        // leble: 'Assets',
        currencyIcon: (this.props.companyInfo || {}).currencyIcon,
        type: DASHBOARD_PAGE_CONSTANTS.INVOICE_GENERATED,
        color: 'tile1',
        antIcon: <img src={img5} alt="" width={"48px"} height={"48px"} />
      },
      {
        footer: 'PAID FEES',
        count: Number(this.props.customerProformaData?.paidAmount || 0).toFixed(2),
        currencyIcon: (this.props.companyInfo || {}).currencyIcon,
        type: DASHBOARD_PAGE_CONSTANTS.FEES_PAID,
        color: 'tile4',
        antIcon: <img src={img2} alt="" />
      },
      {
        footer: 'UNPAID FEES',
        count: Number(this.props.customerProformaData?.unpaidAmount || 0).toFixed(2),
        type: DASHBOARD_PAGE_CONSTANTS.FEES_DUE,
        currencyIcon: (this.props.companyInfo || {}).currencyIcon,
        color: 'tile3',
        antIcon: <img src={img3} alt="" />,
      },
    ];



    const handleTabChange = (studentId) => {
      const currentDate = new Date();
      const payload = {
        relationshipId: this.props.companyInfo.relationshipId,
        studentId: studentId,
        startDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)),
        endDate: getCurrentDateForBackend(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)),
        pageNumber: 1,
        pageSize: 25,
        classId: (this.props.allStudentList || []).find(obj => obj.schoolStudentId === Number(studentId)).classId
      }
      this.props.getSchoolAttendanceByStudentId(payload);
      this.props.getSchoolAssignment(payload);
      const student = this.props.allStudentList.find(student => student.schoolStudentId === Number(studentId));
      this.props.getInvoiceProforma({
        relationshipId: student.relationshipId,
        customerId: student.customerId,
        orgRegistrationId: student.orgRegistrationId
        
      });
      this.props.updateState({ activeTabKey: studentId,selectedStudent:student})

    };
    const { studentAttendance = {} } = this.props;


    const attendenceCount = (attendanceType) => {
      const statusList = Object.values(studentAttendance || {}) || [];
      let count = 0;
      if (statusList.length) {

        statusList.forEach(status => {
          switch (status) {
            case attendanceType:
              count++;
              break;
            default:
              break;
          }
        });
      }
      return count;
    }

    const presentCount = parseInt(attendenceCount("Present"));
    const absentCount = parseInt(attendenceCount("Absent"));
    const state = {
      series: [absentCount, presentCount],
      options: {
        chart: {
          width: 380,
          type: 'pie',
        },
        labels: ['Absent', 'Present'],
        colors: ['#F85353', '#1CB980'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },


    };
    return (
      <>
        <div className="view-top-bar">
          <div className="top-view-left">
            <div className="page-heading">Menu</div>
            <div className="vertical-sep" />
            <PageBreadcrumb breadCrumbList={breadCrumbList} />
          </div>
        </div>

        <div className="tile-data">
          <Tile
            {...this.props}
            tilesData={tilesData}
            getSelectedTile={(data) => {
              this.openModalOfSelectedTile(data);
            }}
          />
        </div>

        <div className="view-container">
          <Tabs
            // activeKey={this.props.activeTabKey}
            activeKey={this.props.activeTabKey}
            onChange={handleTabChange}
          >
            {(this.props.allStudentList || []).map((item, i) => {
              return (
                <TabPane
                  key={item.schoolStudentId.toString()}
                  tab={`${item.firstName} ${item.lastName}`}
                >
                  <div className="dashboard-wrapper">
                    <Row gutter={[16, 16]}>
                      <Col md={13} xs={24}>
                        <div className="inner-view">
                          <div className="table-caption">Fees</div>

                          <div className="fees-progress">
                            <div className="progress-bar">
                              <img src={GreenRing} />
                            </div>
                            <div className="fees1">
                              <b style={{ fontSize: "16px" }}>
                                {/* {Number(
                                      this.props.paidCustomerAmount || 0
                                    ).toFixed(2)} */}
                                {
                                  (fixedDecimalNumber(this.props.invoiceProformaList
                                    .filter(invoice => invoice.isInvoiceConverted === 1)
                                    .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0), 0)))
                                }
                              </b>

                              <div>Total Paid</div>
                            </div>

                            <div className="progress-bar">
                              <img src={RedRing} />
                            </div>
                            <div className="fees1">
                              <b style={{ fontSize: "16px" }}>
                                {/* {(
                                      Number(
                                        this.props.totalInvoiceCustomerAmount ||
                                          0
                                      ) -
                                      Number(this.props.paidCustomerAmount || 0)
                                    ).toFixed(2)} */}
                                {
                                  (fixedDecimalNumber(this.props.invoiceProformaList
                                    .filter(invoice => invoice.isInvoiceConverted === 0)
                                    .reduce((sum, invoice) => sum + (invoice.invoiceTotalAmount || 0), 0)))
                                }
                              </b>

                              <div>Total Unpaid</div>
                            </div>
                          </div>
                          <div className="data-box">
                            <div className="table-container">
                              <table>
                                <thead>
                                  <tr>
                                    {/* <th>S.No.</th> */}
                                    {/* <th>Form</th> */}
                                    {/* <th>Invoice No</th> */}
                                    <th>Fees</th>
                                    <th>Due Date</th>
                                    {/* <th>Due Amount</th> */}
                                    <th>Status</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.props.invoiceProformaList && this.props.invoiceProformaList.length
                                    ? this.props.invoiceProformaList.map((rowData, i) => {
                                      return (
                                        <tr key={i}>
                                          {/* <td>{i+1}</td> */}
                                          {/* <td>{rowData.formName || '-'}</td> */}
                                          {/* <td>{rowData.invoiceNumber || '-'}</td> */}
                                          <td className="one-line-view">
                                            {this.props.companyInfo
                                              .currencyIcon ? (
                                              <i
                                                className={
                                                  this.props.companyInfo
                                                    .currencyIcon
                                                }
                                              ></i>
                                            ) : (
                                              this.props.companyInfo
                                                .currencyCode + " "
                                            )}
                                            {fixedDecimalNumber(rowData.invoiceTotalAmount)}
                                          </td>
                                          <td className="one-line-view">
                                            {rowData.expiryDate
                                              ? getMomentDateForUIReadOnly(
                                                {
                                                  date: new Date(
                                                    rowData.expiryDate
                                                  ),
                                                  format:
                                                    CONSTANTS.TABLE_DATE_FORMAT,
                                                }
                                              )
                                              : "-"}
                                          </td>
                                          {/* <td>
                                                  {this.props.companyInfo
                                                    .currencyIcon ? (
                                                    <i
                                                      className={
                                                        this.props.companyInfo
                                                          .currencyIcon
                                                      }
                                                    ></i>
                                                  ) : (
                                                    this.props.companyInfo
                                                      .currencyCode + " "
                                                  )}
                                                  {(
                                                    Number(
                                                      rowData.invoiceDueAmount ||
                                                        0
                                                    ) -
                                                    Number(
                                                      rowData.paymentAmount || 0
                                                    )
                                                  )
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </td> */}
                                          <td>
                                            <span
                                              style={{
                                                backgroundColor:
                                                  rowData.statusColor ||
                                                  "unpaid",
                                              }}
                                              className="status-label open"
                                            >
                                              <FormattedMessage
                                                id={
                                                  rowData.paymentStatus ||
                                                  "unpaid"
                                                }
                                              />
                                            </span>
                                          </td>
                                          <td>
                                            {/* <DropdownAction overlay={actionMenu(rowData)} trigger={['click']}>
                                                                    <i className="fi fi-br-menu-dots-vertical"></i>
                                                                </DropdownAction> */}
                                            {/* <a href='/'>Pay</a> */}
                                            {rowData.paymentStatus !== "paid" &&
                                              rowData.paymentStatus !== "void" ? (
                                              <span
                                                className="cursor-pointer"
                                                key="1"
                                                value="Pay"
                                                // onClick={() => {
                                                //   let url =
                                                //     config.UI_BASE_URL +
                                                //     "invoice?webInvoiceId=" +
                                                //     rowData.webInvoiceId;
                                                //   openUrlPopup(
                                                //     this.props,
                                                //     {
                                                //       formData: {
                                                //         urlToOpen: url,
                                                //       },
                                                //     }
                                                //   );
                                                // }}
                                                onClick={() => {
                                                  window.open(`${config.UI_BASE_URL}/proforma-payment?${encodeURIComponent(`rid=${rowData.relationshipId}&proformaId=${rowData.invoiceProformaId || 0}&customerId=${this.props.customerInfo?.customerId || 0}`)}`, '_blank');
                                                }}
                                              >
                                                Pay
                                              </span>
                                            ) : (
                                              "-"
                                            )}{" "}
                                          </td>
                                        </tr>
                                      );
                                    })
                                    : ""}
                                  {/* <tr>
                                                        <td>Projected Fees</td>
                                                        <td>{(this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " ")}{Number(this.props.totalFeeAmount || 0).toFixed(2)}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Invoice Raised</td>
                                                        <td>{(this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " ")}{Number(this.props.totalInvoiceCustomerAmount || 0).toFixed(2)}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Payment Received</td>
                                                        <td>{(this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " ")}{Number(this.props.paidCustomerAmount || 0).toFixed(2)}</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>Current Balance</td>
                                                        <td>{(this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " ")}{Number((this.props.totalInvoiceCustomerAmount - this.props.paidCustomerAmount) || 0).toFixed(2)}</td>
                                                        <td> {(Number(this.props.totalInvoiceCustomerAmount || 0)) > (Number(this.props.paidCustomerAmount || 0)) ? <span onClick={() => { this.openUnpaidInvoicesModal('unpaidInvoices', this.props.customerInfo, 'Current Balance'); }} className='cursor-pointer'>Pay </span> : ''}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Total Balance</td>
                                                        <td>{(this.props.companyInfo.currencyIcon ? <i className={this.props.companyInfo.currencyIcon}></i> : this.props.companyInfo.currencyCode + " ")}{Number((this.props.totalFeeAmount - this.props.paidCustomerAmount) || 0).toFixed(2)}</td>
                                                        
                                                    </tr> */}
                                </tbody>
                              </table>

                              {/* <table>
                                                            <thead>
                                                                <tr>
                                                                    <th width='6%'>S.No.</th>
                                                                    <th>Student</th>
                                                                    <th>Gender</th>
                                                                    <th>Status</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(this.props.allStudentList || []).map((e, i) => {
                                                                    return <tr key={'std' + i}>
                                                                        <td>{i + 1}</td>
                                                                        <td>
                                                                            <div>{e.firstName + (e.lastName ? ' ' + e.lastName : '')}</div>
                                                                            {e.grade ? <div>Grade {e.grade}</div> : ''}
                                                                        </td>
                                                                        <td>{e.gender || '-'}</td>
                                                                        <td className='one-line-view'>{e.studentStatus ? <span className='status-label no-width' style={{ background: e.studentStatusColor || '#78C1ED', padding: '5px', color: 'white' }}>{e.studentStatus || ''}</span> : <span style={{ background: '#e7e7e7', padding: '5px' }}>Not Enrolled</span>}</td>
                                                                    </tr>
                                                                })
                                                                }
                                                            </tbody>
                                                        </table> */}
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col md={11} xs={24}>
                        <div className="inner-view">
                          <div className="table-caption">Attendance</div>

                          <div>
                            <ul className="list">
                              <>
                                <li>
                                  Total
                                  <div
                                    className="list-font"
                                    style={{ color: "#0075FF" }}
                                  >
                                    {parseInt(attendenceCount("Present")) +
                                      parseInt(attendenceCount("Absent"))}
                                  </div>
                                </li>
                                <li>
                                  Present
                                  <div
                                    className="list-font"
                                    style={{ color: "#1CB980" }}
                                  >
                                    {attendenceCount("Present")}
                                  </div>
                                </li>
                                <li>
                                  Absent
                                  <div
                                    className="list-font"
                                    style={{ color: "#F85353" }}
                                  >
                                    {attendenceCount("Absent")}
                                  </div>
                                </li>
                              </>
                            </ul>
                          </div>

                          <div className="progress-chart">
                            <ReactApexChart
                              options={state.options}
                              series={state.series}
                              type="pie"
                              height={180}
                            />
                          </div>
                        </div>
                      </Col>

                      <Col md={12} xs={24}>
                        <div className="inner-view" >
                          <div className="table-caption">Assignments</div>


                          <Row gutter={[8, 8]} style={{ margin: "10px" }} className='table-card'>

                          {(this.props.assignmentList[this.props.pageNumber] || []).length ? 
                              this.props.assignmentList[this.props.pageNumber || 1]
                                .filter(obj => obj.classSectionId === (this.props.selectedStudent?.schoolStudentClassRefList[0] || {}).classSectionId)
                                .map((item, i) => {
                                  const studentResponse = (item.assignmentResponseList || []).find(val => val.studentId === this.props.selectedStudent?.schoolStudentId)
                                  return (
                                    <Col
                                      className="inner-view1"
                                      span={11}
                                      key={`as` + i}

                                    >
                                      <div className="table-caption">
                                        Assignment for {item.assignmentName}
                                      </div>

                                      <div className="asm-card-body">

                                        {/* <Row className="asm-card-list" gutter={[8, 8]}>

                                          <Col
                                            span={10}
                                            className="asm-card-label"
                                          >
                                            Class
                                          </Col>
                                          <Col
                                            span={10}
                                            className="leftt"
                                          >
                                            {item.assignmentClassName}
                                          </Col>
                                        </Row> */}

                                        <Row className="asm-card-list">
                                          <Col
                                            span={10}
                                            className="asm-card-label"
                                          >
                                            Group
                                          </Col>
                                          <Col
                                            span={14}
                                            className="leftt"
                                          >
                                            {item.assignmentGroupName}
                                          </Col>
                                        </Row>

                                        <Row className="asm-card-list">
                                          <Col
                                            span={10}
                                            className="asm-card-label"
                                          >
                                            {getUserDefinedName(
                                              "Class",
                                              this.props
                                            )}
                                          </Col>
                                          <Col
                                            span={10}
                                            className="leftt"
                                          >
                                            {item.className}
                                          </Col>
                                        </Row>

                                        <Row className="asm-card-list">
                                          <Col
                                            span={10}
                                            className="asm-card-label"
                                          >
                                            Questions
                                          </Col>
                                          <Col
                                            span={10}
                                            className="leftt"
                                          >
                                            {JSON.parse(
                                              item.questionList || "[]"
                                            ).length || 0}
                                          </Col>
                                        </Row>

                                        <Row className="asm-card-list">
                                          <Col
                                            span={10}
                                            className="asm-card-label"
                                          >
                                            Due Date
                                          </Col>
                                          <Col
                                            span={10}
                                            className="leftt"
                                          >
                                            {item.dueDate
                                              ? getMomentDateForUIReadOnly(
                                                {
                                                  date: item.dueDate,
                                                }
                                              )
                                              : "-"}
                                          </Col>
                                        </Row>

                                        <Row className="asm-card-list">
                                          <Col
                                            span={10}
                                            className="asm-card-label"
                                          >
                                            Submission Date
                                          </Col>
                                          <Col
                                            span={10}
                                            className="leftt"
                                          >
                                             {(studentResponse || {}).dateSubmitted
                                              ? getMomentDateForUIReadOnly({
                                                date: (studentResponse || {})
                                                  .dateSubmitted,
                                                format: format,
                                              })
                                              : "-"}
                                          </Col>
                                        </Row>
                                        <Row className="asm-card-list">
                                          <Col
                                            span={10}
                                            className="asm-card-label"
                                          >
                                            Status
                                          </Col>
                                          <Col
                                            span={10}
                                            className="leftt"
                                            style={{
                                              color: "#F85353",
                                            }}
                                          >
                                              {
                                              
                                              (studentResponse) ? (
                                                <span className="submit-status">Submitted</span>
                                              ) : (
                                                new Date(item.dueDate) < new Date() ? (
                                                  <span className="expired-status">Expired</span>
                                                ) : (
                                                  <span className="not-submitted-status">Not Submitted</span>
                                                )
                                              )
                                            }
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                  );
                                }
                              )
                              : "No Assignements"}
                          </Row>

                        </div>

                      </Col>

                      <Col md={12} xs={24}>
                        <div className="inner-view">
                          <div className="table-caption">
                            Communications
                          </div>

                          <div className="data-box">
                            <div className="table-container">
                              <table>
                                <thead>
                                  <tr>
                                    <th>S.No</th>
                                    <th>Type</th>
                                    <th>Subject</th>
                                    <th>Communicator</th>
                                    <th>Date</th>
                                    {/* <th>Status</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {(this.props.communicationList[this.props.pageNumber || 1] || [])?.map((e, i) => {
                                    return <tr key={'std' + i}>
                                      <td>{i + 1}</td>
                                      <td>
                                        <span className="cursor-pointer"
                                          onClick={() => {
                                            this.props.showModal({
                                              title: <div>Communication</div>,
                                              modalBody: <EmailInfo {...this.props} communicationDetail={e} />,
                                              width: 700,
                                              hideFooter: true,
                                            })
                                          }}>
                                          {e.communicationMode || '-'}</span>

                                      </td>
                                      <td>{e.emailSubject || '-'}</td>
                                      <td>{e.relationshipEmployeeName === 'System Generated' ? 'System' : e.relationshipEmployeeName || '-'}</td>
                                      <td>{e.communicationDate ? getMomentDateForUIReadOnly({ date: new Date(e.communicationDate), format }) : '-'}</td>
                                    </tr>
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </TabPane>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  }
}

export default injectIntl(DashboardComponent);
