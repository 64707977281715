import axios from 'axios';
import config from '../config/environmentConfig';
import { COMMON_ACTIONS } from '../static/constants';
import { showToasterMessage } from './../utils';
import download from 'downloadjs';
const { lang } = require('../translations/' + (sessionStorage.getItem('language') || 'en') + '.js');




export const downloadFileFromBucket = (payload) => {
  return dispatch => {
    dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
    return axios
      .get(`${config.AWS_BASE_URL}${config.API_VERSION}alpide-aws/boBucketOperations/getFile?objectKey=${payload.awsKeyName}&bucketName=${payload.bucketName}`,{responseType: 'arraybuffer'})
      .then(res => {
        const content = res.headers['content-type'];
        download(res.data, (payload.fileName || 'Document.pdf'), content);
        showToasterMessage({
          description: (res || {}).message || 'Download successfully.',
          messageType: 'success'
        })
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
      .catch(err => {
        //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
      })
  }
}

export const generatePDF = (payload , cb) => {
    return dispatch => {
      // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PDF_API_BASE_URL}${config.PDF_API_VERSION}/pdf/generate`, payload , { responseType: 'blob', })
        .then(res => {
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(payload.fileDataReqeired){
            cb(res.data);
          }else{
            //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            const content = res.headers['content-type'];
            download(res.data, (payload.fileName || 'Document.pdf'), content);
              showToasterMessage({
                description: (res || {}).message || 'Download successfully.',
                messageType: 'success'
              })
          }
        })
        .catch((err = {}) => {
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({
                description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
              })
          } 
        })
    }
  }

  export const printPDF = (payload , cb) => {
    return dispatch => {
      // dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PDF_API_BASE_URL}${config.PDF_API_VERSION}/pdf/generate`, payload , { responseType: 'blob', })
        .then(res => {
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(payload.fileDataReqeired){
            cb(res.data);
          }else{
            //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            var file = new Blob([res.data], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank");
          }
        })
        .catch((err = {}) => {
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({
                description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
              })
          } 
        })
    }
  }



  export const generateLabelPdf = (payload , cb) => {
    return dispatch => {
      dispatch({ type: COMMON_ACTIONS.SHOW_LOADER });
      return axios
        .post(`${config.PDF_API_BASE_URL}${config.PDF_API_VERSION}/pdf/generatePackage`, payload , { responseType: 'blob', })
        .then(res => {
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if(payload.fileDataReqeired){
            cb(res.data);
          }else{
            //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
            const content = res.headers['content-type'];
            download(res.data, (payload.fileName || 'Label.pdf'), content);
              showToasterMessage({
                description: (res || {}).message || 'Download successfully.',
                messageType: 'success'
              })
          }
        })
        .catch((err = {}) => {
          //dispatch({ type: COMMON_ACTIONS.HIDE_LOADER });
          if (!err.__isRetryRequest) {
            showToasterMessage({
                description: lang[((err.response || {}).data || {}).message] || 'Some error occurred'
              })
          } 
        })
    }
  }