import { COMMON_ACTIONS, LOCAL_STORAGE_KEYS } from '../static/constants';
import { fetchFromLocalStorage, addToLocalStorage } from '../utils';
const initialState = {
  isLoading: 0,
  companyInfo: {},
  userInfo: {},
  bankInfo: {},
  companyEmpList: [],
  countries: [],
  states: [],
  relationshipId: 0,
  customerInfo: {},
  subdomain: {},
  paymentTerms: [],
  adminToken: {},
  loggedInEmail: '',
  contactEducationList: [],
  contactEmploymentList: [],
  isFlashVisible: false,
  studentContact: [],
  defaultCourse: 0,
}

const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case COMMON_ACTIONS.SHOW_LOADER:
      return { ...state, isLoading: state.isLoading + 1 };
    case COMMON_ACTIONS.HIDE_LOADER:
      return { ...state, isLoading: state.isLoading === 0 ? 0 : state.isLoading - 1};
    case COMMON_ACTIONS.LOGGEDIN_COMPANY_INFO:
      return { ...state, companyInfo: action.data };
    case COMMON_ACTIONS.LOGGEDIN_USER_INFO:
      return { ...state, userInfo: action.data };
    case COMMON_ACTIONS.LOGGEDIN_USER_BANK_INFO:
      return { ...state, bankInfo: action.data };
    case COMMON_ACTIONS.LOGGEDIN_COMPANY_EMP_LIST:
      return { ...state, companyEmpList: action.data };
    case COMMON_ACTIONS.ADMIN_TOKEN:
      return { ...state, adminToken: action.data };

    case COMMON_ACTIONS.COUNTRIES_LIST:
      return { ...state, countries: action.data };
    case COMMON_ACTIONS.STATES_LIST:
      return { ...state, states: action.data };
    case COMMON_ACTIONS.RELATIONSHIP_ID:
      return { ...state, relationshipId: action.data };

    case COMMON_ACTIONS.LOGGEDIN_CUSTOMER_INFO:
      return { ...state, customerInfo: action.data };

    case COMMON_ACTIONS.SUBDOMAIN:
      return { ...state, subdomain: action.data }

    case COMMON_ACTIONS.PAYMENT_LIST:
      return { ...state, paymentTerms: action.data };
    case COMMON_ACTIONS.PAYMENT_LIST_ADDITION:
      return { ...state, paymentTerms: [action.data, ...state.paymentTerms] }
    case COMMON_ACTIONS.PAYMENT_LIST_DELETION:
      return { ...state, paymentTerms: state.paymentTerms.filter(x => x.paymentTermId !== action.data.id) }
    case COMMON_ACTIONS.DEFAULT_SCHOOL_FORM: {
      const entityData = fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA) ? JSON.parse(fetchFromLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA)) : {};
      entityData.formId = action.data
      addToLocalStorage(LOCAL_STORAGE_KEYS.LOGGEDIN_ENTITY_DATA, JSON.stringify(entityData));
      addToLocalStorage(LOCAL_STORAGE_KEYS.DEFAULT_SCHOOL_FORM, action.data);
      return { ...state, defaultSchoolForm: action.data }
    }
    case COMMON_ACTIONS.LOGGEDIN_EMAIL: {
      return { ...state, loggedInEmail: action.data };
    }
    case COMMON_ACTIONS.CONTACT_EDUCATION_LIST: {
      return { ...state, contactEducationList: action.data };
    }  
    case COMMON_ACTIONS.CONTACT_EMPLOYMENT_LIST: {
      return { ...state, contactEmploymentList: action.data };
    }
    case COMMON_ACTIONS.IS_FLASH_VISIBLE:{
      return {...state, isFlashVisible: action.data};
    }
    case COMMON_ACTIONS.DEFAULT_COURSE:{
      return {...state, defaultCourse: action.data};
    }
    default:
      return state;
  }
};

export default CommonReducer;