import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { TextBox } from '../general/TextBox';
import { CustomButton } from '../general/CustomButton';
import Avatar from '../../assets/images/avatar.svg';
import { validateEmail, getAWSFileURL, showToasterMessage } from '../../utils';
import config from '../../config/environmentConfig';
import SuccessIcon from "../../assets/images/success.svg";

import './forgotpassword.scss';

function ForgotPassword(props) {
  const [sentDone, setSentDone] = useState(false);
  const handleSubmit = (payload) => {
    if(!payload.email){
      showToasterMessage({ messageType: 'error', description: "Please enter your email address" })
    }
    if (payload.email && validateEmail(payload.email)) {
      payload.sendResetMail(payload.email, payload.history ,(e)=>setSentDone(e));
    } else {
      if (!validateEmail(payload.email)) {
        payload.updateState({
          emailClass: 'forgot-form-input-error input-error',
          emailInvalidClass: 'forgot-form-input-error input-error'
        });
      }
    }
  }
  
  const { email, emailClass, emailInvalidClass, updateState, intl, history } = props;
  return (
    <div className='login-container'>
       {
      !sentDone ?(
       <><div className='panel1'>
            <div className="login-container-body">
              <div>
                <div className='login-content'>
                  “Teaching is not about answering questions but about raising questions –
                  opening doors for them in places that they could not imagine.”
                </div>

                <div className='avatar'>
                  <img src={Avatar} />
                  <div className='avatar-name'>Adam James
                    <div style={{ fontSize: "14px", paddingTop: "5px" }}>Writer</div>
                  </div>
                </div>

              </div>

            </div>
          </div><div className='panel2'>
              <div className='login-container-body'>
                <div className='body-1'>
                  <div className='title-page'>

                    <div>
                      {(props.companyInfo || {}).logoAwsObjectKey ? (
                        <img
                          src={getAWSFileURL(
                            (props.companyInfo || {}).logoAwsObjectKey,
                            config.BUCKET_NAME.BO_RELATIONSHIP
                          )}
                          style={{ height: "40px", width: "50px" }}
                          alt="a" />
                      ) : (
                        ""
                      )}
                    </div>

                    <div className='parent-portal'>
                      {(props.companyInfo || {}).storeName ? (
                        (props.companyInfo || {}).storeName
                      ) : (
                        <div>
                          Parent Portal
                        </div>
                      )}
                    </div>


                  </div>

                  <div className='form-details'>

                    <h4 className="heading-title-login">
                      <FormattedMessage id="forgot.headingText" defaultMessage="" />
                    </h4>
                    <div className="login-subheading-text">
                      Enter your email address that you used to register your account. We will send you an email with your username and password.              </div>
                    <div className="login-form-container">
                      <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(props); } }>
                        <Row className='pb15'>

                          <Col span={24}>
                            <TextBox
                              placeholder={intl.formatMessage(
                                { id: 'forgot.email.placeholder', defaultMessage: '' }
                              )}
                              type='text'
                              value={email}
                              // className={emailClass || 'forgot-form-input'}
                              className="login-form-input"

                              // invalidClassName={emailInvalidClass || 'forgot-form-input-error'}
                              validator={(val) => { return validateEmail(val); } }
                              onChange={(e) => {
                                updateState({
                                  email: e.target.value,
                                  // emailClass: 'forgot-form-input'
                                });
                              } } />
                          </Col>
                        </Row>
                        <Form.Item>
                          <Row>
                            <Col span={24} align="end">
                              <CustomButton
                                type='primary'
                                htmlType='submit'
                                className="login-create-account"
                                intlId='forgot.btn.submit'
                                defaultMessage='' />
                            </Col>
                          </Row>
                          <Row>
                            <Col span={24}>
                              <CustomButton
                                type='default'
                                htmlType='button'
                                className='forgot-goback'
                                intlId='forgot.btn.back'
                                defaultMessage=''
                                onClick={(e) => { e.preventDefault(); history.push('/'); } } />
                            </Col>

                          </Row>

                        </Form.Item>
                      </Form>
                    </div>
                  </div>

                </div>


              </div>
            </div></>
      ):(<div>
        <div className="text-center">
           <img src={SuccessIcon} alt='success' width={100}/>
         </div>
         <div className="text-center"><h2>Email send</h2></div>
         <div className="text-center"><p>Check your registered mail id</p></div>
     </div>)}
  </div>

  );
}


export default injectIntl(ForgotPassword);

