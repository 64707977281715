import React, { useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Upload, Row, Col, Tabs, Checkbox, Radio } from 'antd';
import { injectIntl } from 'react-intl';
import './index.scss';
import { getAWSFileURL, getCurrentDateForBackend, getMomentDateForUIReadOnly, showToasterMessage } from '../../utils';
import { CUSTOM_CONFIGURATION_FORM, CUSTOM_CONFIGURATION } from '../../static/ckEditorConfigConstants';
import CKEditor from '../general/CustomCKEditor/ckeditor';
import ReactHtmlParser from 'react-html-parser';
import { CONSTANTS } from '../../static/constants';
import FileFig from "../../assets/images/file-fig.svg";
import UploadIcon from "../../assets/images/upload-icon.svg";
import { TextArea } from '../general/TextAreaComponent';

const format = CONSTANTS.DISPLAY_DATE_FORMAT;
const { Dragger } = Upload;
const { TabPane } = Tabs;

function AssignmentAttemp(props) {
    const { companyInfo, asmData = {}, questionList = [] } = props;
    console.log(props.docDetailList, "docDetailList")
    const [tabKey, setTabKey] = useState("1");
    const [instVisible, setInstVisible] = useState(true);

    const [questions, setQuestions] = useState(questionList);
    const [currentIndex, setCurrentIndex] = useState(0);
    const totalQuestions = questionList.length;

    const imageVal = asmData?.docDetailList?.map((val) => {

        return getAWSFileURL(val.fileName, props.companyInfo.bucketName + "/image")
    })
    const handleQNext = () => {
        if (currentIndex === totalQuestions - 1) {
            console.log("filrtrt")
            handleNext();
        }
        if (currentIndex < questionList.length - 1) {
            setCurrentIndex(currentIndex + 1);

        }
    };

    const handleQPrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const handleSkip = () => {
        if (currentIndex < questionList.length - 1) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const currentQuestion = questions[currentIndex];
    const currentQuestionArray = [];
    currentQuestionArray.push(currentQuestion);

    const toggleDescriptionText = () => {
        props.updateState({ isSeeMore: !props.isSeeMore });
    }
    const handleNext = () => {
        if (tabKey === '1') {
            setTabKey("2");
        }
        else {
            setTabKey("3");
        }
    }
    const getQuestionCount = (assignment) => {
        let questionList = JSON.parse(assignment.questionList || "[]");
        return questionList.length;
    };
    const handleUpload = (file) => {
        const newFile = {
            uid: file.uid,
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
        };

        const updatedDocDetailList = [
            ...props.docDetailList,
            newFile
        ].filter((item, index, self) =>
            index === self.findIndex((t) => t.fileName === item.fileName)
        );

        props.updateState({ docDetailList: updatedDocDetailList });
        return false;
    };
    const handleRemove = (file) => {
        const id = file.docDetailsId || file.uid;
        const updatedList = props.docDetailList.filter(item => item.docDetailsId !== id && item.uid !== id);
        props.updateState({ docDetailList: updatedList });
    };
    return (
        <div className="view-container">
            {instVisible ?
                <>
                    <div className='inst-wrapper'>
                        <Row>
                            <Col xs={24} lg={12} className='section-right-border'>
                                {/* <div style={{ paddingTop: '10px ' }}>
                                <Dropdown
                                    style={{ width: '300px', padding: '0px' }}
                                    items={props.classStudentList || []}
                                    optionKeyName='schoolStudentId'
                                    valueKeyName='firstName'
                                    lastNameKey='lastName'
                                    onSelect={(selectedValue, option) => {
                                        props.updateState({
                                            schoolStudentId: selectedValue
                                        });
                                    }}
                                />
                            </div> */}
                                <div className='section-header' >
                                    <p>Assignment Details</p>
                                </div>
                                <div className='assignment-wrapper'>
                                    <Row gutter={[16, 20]}>
                                        <Col span={8} className='assignment-lable'>Name</Col>
                                        <Col span={16} className='assignment-value'>
                                            {asmData.assignmentName || ''}
                                        </Col>

                                        <Col span={8} className='assignment-lable'>Class</Col>
                                        <Col span={16} className='assignment-value'>
                                            {asmData.className || ''}
                                        </Col>

                                        <Col span={8} className='assignment-lable' >Group</Col>
                                        <Col span={16} className='assignment-value'>
                                            {asmData.assignmentGroupName}
                                        </Col>

                                        <Col span={8} className='assignment-lable' >Grade</Col>
                                        <Col span={16} className='assignment-value'>
                                            {props.responseData && (props.responseData || {}).grade || "-"}
                                        </Col>

                                        <Col span={8} className='assignment-lable' >Questions</Col>
                                        <Col span={16} className='assignment-value'>
                                            {getQuestionCount(asmData) > 1
                                                ? getQuestionCount(asmData)
                                                : getQuestionCount(asmData)}
                                        </Col>

                                        <Col span={8} className='assignment-lable'>Attemps</Col>
                                        <Col span={16} className='assignment-value'>
                                            {asmData.numberOfAttempts}
                                        </Col>

                                        {asmData.dueDate ? <>
                                            <Col span={8} className='assignment-lable'>Due Date</Col>
                                            <Col span={16} className='assignment-value'>
                                                {getMomentDateForUIReadOnly({ date: asmData.dueDate, format: format })}
                                            </Col>
                                        </>
                                            : ''
                                        }

                                        <Col span={8} className='assignment-lable'>Submission Date</Col>
                                        <Col span={16} className='assignment-value'>
                                            {props.responseData && (props.responseData || {}).dateSubmitted
                                                ? getMomentDateForUIReadOnly({
                                                    date: (props.responseData || {})
                                                        .dateSubmitted,
                                                    format: format,
                                                })
                                                : "-"
                                            }
                                        </Col>

                                        <Col span={8} className='assignment-lable'>Status</Col>
                                        <Col span={16} className='assignment-value'>
                                            {
                                                props.responseData ? (
                                                    <span className="submit-status"> Submitted</span>
                                                ) : (
                                                    <span className="not-submitted-status">
                                                        Not Submitted
                                                    </span>
                                                )}
                                        </Col>
                                        <Col span={24} className='assignment-lable' style={{ fontWeight: "600" }}>Assignment Description</Col>
                                        <Col span={24} className='assignment-value'>
                                            <h5 style={{ height: props.isSeeMore ? 'auto' : '5em', overflow: 'hidden', fontSize: "12px" }}>{ReactHtmlParser(asmData.assignmentDescription)}</h5>
                                            {asmData.assignmentDescription && <span style={{ color: '#0096ff', fontWeight: "600", textDecoration: 'none', cursor: 'pointer', padding: '0px 0px', float: 'left', color: '#333', marginTop: '-5px' }} onClick={() => { toggleDescriptionText() }}>{props.isSeeMore ? 'Show Less' : 'Show More'}   </span>}
                                        </Col>

                                        <Col span={24}>
                                            <div className='action-btn-new'>
                                                {/* <DropdownAction overlay={()=>{}} trigger={['click']}>
                                <span><DownloadOutlined /> &nbsp; Download </span>
                                    </DropdownAction> */}

                                                <div className='left'>
                                                    {/* <img src={FileFig} alt=""/> */}

                                                    {imageVal && imageVal.length > 0 ? (
                                                        imageVal.map((imageUrl, index) => (
                                                            <img key={index} src={imageUrl} alt="" height={50} width={50} />
                                                        ))
                                                    ) : (
                                                        ''
                                                    )}


                                                </div>
                                                <div className='right' style={{ fontSize: "24px" }}>
                                                    <DownloadOutlined />
                                                </div>
                                            </div>
                                        </Col>

                                    </Row>

                                </div>


                                {/* <Dragger {...uploadProps}>
                                    <p className="ant-upload-drag-icon">
                                        <UploadOutlined style={{ fontSize: '34px', color: '#7883b0' }} />
                                    </p>
                                    <p className="ant-upload-text" style={{ color: '#363b51' }}> Drag and Drop file </p>

                                    <p className="ant-upload-text">or</p>
                                    <p className="ant-upload-hint">
                                        <div className='brawse-but'>  <Button type="primary" style={{ borderRadius: '0px', padding: '0px 50px', color: '#3157c2', border: 'solid 0px #578dc8' }}  >
                                            Browse files
                                        </Button>
                                        </div>
                                    </p>
                                </Dragger> */}
                                {/* <div style={{ paddingTop:'10px '}}>
                                    <p className="ant-upload-drag-icon" style={{ paddingTop:'10px'}}>
                            3- Browse or drag & drop excel file to upload.
                                </p></div> */}
                            </Col>

                            {/* <Col span={1} >
                            <div style={{ background: '#e4e4e4', width: '2px', height: '490px', float: 'right' }}></div>
                        </Col> */}
                            {/* <Col span={1} ></Col> */}
                            <Col xs={24} lg={12}>
                                <div className='section-header'>
                                    <p>Introduction</p>
                                </div>
                                <div className='introduction-main-wrapper'>
                                    <Row>
                                        <div className='introduction-wrapper'>
                                            <ul className='ul-style'>
                                                <li>Click the best answer for each of the questions</li>
                                                <li>If you want to change an answer, simply click another box for your new choice</li>
                                                <li>When the time of assessment finished, it will automatically move to result page</li>
                                                <li>Every question has equal mark and no negative marking for wrong answer</li>
                                            </ul>
                                        </div>
                                    </Row>
                                </div>

                                {/* <div style={{ textAlign: 'center', height: 'initial', background: 'initial' }} className='rem-bgs'> */}
                                {/* <div style={{ paddingTop: '10px ', }}>
                                    <Row>

                                        <Col span={24}>

                                            <CKEditor
                                                // editor={ClassicEditor}
                                                data={props.assignmentResponse || ''}
                                                key={`${"emailBody"}`}
                                                // onInit={editor => {
                                                //     this.editor = editor;
                                                // }}
                                                onChange={(event) => {
                                                    const data = event.editor.getData();
                                                    props.updateState({ assignmentResponse: data });
                                                }}
                                                config={CUSTOM_CONFIGURATION_FORM}
                                            />
                                        </Col>
                                    </Row>
                                </div> */}

                                {/*   <input accept='.xlsx, .xls, .csv' type="file" className="" onChange={(e) => {
                                props.updateState({
                                fileToUpload: e.target.files[0]
                                })
                                }} /> {(props.fileToUpload || {}).name} */}
                                {/* <br /> */}


                                {/* </div> */}


                                {/* <div style={{ textAlign: 'center', paddingTop:'10px' }}>
                        <div >
                        <a href={`${config.API_BASE_URL}${config.API_VERSION}/${config.rootContext}/customers/downloadContactTemplate?relationshipId=${companyInfo.relationshipId}&countryName=${companyInfo.countryName}`}>
                        <DownloadOutlined style={{ color:'#000', fontSize:'20px', float:'left' }} />
                        <span className="ml5" style={{color:'#4e16ff', fontSize:'14px', float:'left'}}>Download Template</span> <span style={{color: 'rgba(0, 0, 0, 0.65)',float:'right'}}>Accepted file type Excel only</span>
                        </a>
                                </div>
                            </div> */}
                            </Col>
                        </Row>
                    </div>
                    <div className="fot-xer" style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            className='cutom-button'
                            type='primary'
                            onClick={() => {
                                setInstVisible(false)
                            }}
                        >
                            Continue
                        </Button>
                    </div>
                </>

                :

                <div className='team-and-free auto'>
                    <div>
                        <div className='section-header'>
                            <p>Assignment {asmData.subjectName ? `for ${asmData.subjectName}` : ""}</p>
                        </div>

                        <Tabs type="line" activeKey={tabKey} onChange={(key) => { setTabKey(key) }}>
                            {/* <TabPane tab='Assignment' key="1"> */}

                            {/* <Row> */}
                            {/* <div style={{borderTop:'solid 1px #e4e4e4', padding:'10px 0px 20px', width:'100%', marginTop:'20px'}}></div> */}
                            {/* <Col span={12} style={{padding:'0px 15px 0px 0px'}}>
                                    <div className='proucty'>
                                        {props.isLoadData ?
                                            <CKEditor
                                                // editor={ClassicEditor}
                                                data={props.assignmentResponse || ''}
                                                key={`${"emailBody"}`}
                                                // onInit={editor => {
                                                //     this.editor = editor;
                                                // }}
                                                onChange={(event) => {
                                                    const data = event.editor.getData();
                                                    props.updateState({ assignmentResponse: data });
                                                }}
                                                config={{ ...CUSTOM_CONFIGURATION_FORM, placeholder: 'Student Comments' }}
                                            /> : ''}
                                    </div>
                                </Col> */}

                            {/* <Col span={12} style={{padding:'0px 0px 0px 15px'}}> 
                                <div className='rem-bgs'>
                                 <Dragger {...uploadProps}>
                                            <p className="ant-upload-drag-icon">
                                                <UploadOutlined style={{ fontSize: '34px', color: '#7883b0' }} />
                                            </p>
                                            <p className="ant-upload-text" style={{ color: '#363b51' }}> Drag and Drop file </p>

                                            <p className="ant-upload-text">or</p>
                                            <p className="ant-upload-hint">
                                                <div className='brawse-but'>  <Button type="primary" style={{ borderRadius: '0px', padding: '0px 50px', color: '#3157c2', border: 'solid 0px #578dc8' }}  >
                                                    Browse files
                                                </Button>
                                                </div>
                                            </p>
                                        </Dragger>
                                        </div>
                                        </Col> */}
                            {/* </Row> */}



                            <TabPane tab='Q&A' key="1" className='rtsd'>
                                <div className='qa-container'>
                                    <div className='height-crtoqa'>
                                        <Row>
                                            <Col span={24} className='question-counter'>
                                                <div className='left'>Questions</div>
                                                <div className='right'>
                                                    <div>Questions <span>{currentIndex + 1} / {totalQuestions}</span></div>
                                                </div>
                                            </Col>
                                            <Col span={24} className='indicater-wrapper'>
                                                {(questionList || []).map((queObj, i) => {
                                                    return (<span
                                                        style={{ background: currentIndex === i ? "#0075FF" : "#F1F1F1" }} className='tab-indicater' key={i}
                                                    ></span>)
                                                })}
                                            </Col>
                                        </Row>
                                        <div>
                                            {
                                                (questionList || []).map((queObj, i) => {
                                                    return (
                                                        currentIndex == i ? <div style={{ marginBottom: '20px', padding: '20px 10px 30px 10px' }} key={'que' + i}>
                                                            <Row style={{ marginBottom: '10px', display: "flex", flexDirection: "column" }} >
                                                                <Col span={20}><label className='question-label'> <span>{`${currentIndex + 1}.`}</span> {queObj.questionText} {queObj.isRequired ? <span className="required">*</span> : ''}</label></Col>
                                                            </Row>
                                                            <Row>
                                                                <Col span={24} className='pt10'>
                                                                    {(queObj.optionList || []).length ?
                                                                        <div className="nertr">
                                                                            {queObj.answerType === 'multiChoice' ?
                                                                                <Checkbox.Group value={queObj.answers} onChange={(answers) => {
                                                                                    queObj.answers = answers;
                                                                                    let questionList = props.questionList;
                                                                                    questionList.splice(i, 1, queObj);
                                                                                    props.updateState({ questionList: questionList });
                                                                                }}
                                                                                >
                                                                                    {(queObj.optionList || []).map((optObj, j) => {
                                                                                        return (<div key={'opt' + j} ><Checkbox value={optObj.optText}>{optObj.optText}</Checkbox></div>)
                                                                                    })
                                                                                    }

                                                                                </Checkbox.Group> :
                                                                                <Radio.Group value={queObj.answers} onChange={(e) => {
                                                                                    queObj.answers = e.target.value;
                                                                                    let questionList = props.questionList;
                                                                                    questionList.splice(i, 1, queObj);
                                                                                    props.updateState({ questionList: questionList });
                                                                                }} >
                                                                                    {(queObj.optionList || []).map((optObj, k) => {
                                                                                        return (optObj.optText ? <div key={'optA' + k} ><Radio value={optObj.optText}>{optObj.optText}</Radio></div> : '')
                                                                                    })
                                                                                    }

                                                                                </Radio.Group>
                                                                            }
                                                                        </div> : ''
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            {queObj.answerType === 'shortAnswer' ?
                                                                <Row>
                                                                    <Col span={20}>
                                                                        <TextArea
                                                                            className='input_textarea'
                                                                            placeholder='Enter here'
                                                                            type='text'
                                                                            value={queObj.answers}
                                                                            onChange={(e) => {
                                                                                queObj.answers = e.target.value;
                                                                                let questionList = props.questionList;
                                                                                questionList.splice(i, 1, queObj);
                                                                                props.updateState({ questionList: questionList });
                                                                            }}
                                                                        />
                                                                        {/* <CKEditor
                                                                        type="inline"
                                                                        className="description-input"
                                                                        key={`${"queAns_" + i}`}
                                                                        data={queObj.answers}
                                                                        editor={props.editor}
                                                                        onInit={editor => {
                                                                            props.updateState({
                                                                                editor
                                                                            })
                                                                        }}
                                                                        onChange={(event) => {
                                                                            const data = event.editor.getData();
                                                                            queObj.answers = data;
                                                                            let questionList = props.questionList;
                                                                            questionList.splice(i, 1, queObj);
                                                                            props.updateState({questionList:questionList});
                                                                            // updateState({
                                                                            //     remarks: data
                                                                            // });
                                                                        }}
                                                                        config={CUSTOM_CONFIGURATION}
                                                                    /> */}
                                                                        {/* <TextArea 
                                                                    value={queObj.answers}
                                                                    onChange={(e) => {
                                                                     
                                                                        queObj.answers = e.target.value;
                                                                        let questionList = props.questionList;
                                                                        questionList.splice(i, 1, queObj);
                                                                        props.updateState({questionList:questionList});
                                                                       
                                                                    }}
                                                                    /> */}
                                                                    </Col>
                                                                </Row> : ''
                                                            }
                                                        </div> : ''
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </TabPane>

                            <TabPane tab='Upload' key="2">
                                <div className='upload-wrapper'>
                                    <Row gutter={[40, 20]}>
                                        <Col xs={24} lg={12} >
                                            <div className='rem-bgs'>
                                                <Dragger
                                                    multiple={true}
                                                    showUploadList={false}
                                                    beforeUpload={handleUpload}
                                                    //   fileList={props.docDetailList}
                                                    onRemove={handleRemove}

                                                >
                                                    <p className="ant-upload-drag-icon">
                                                        <img src={UploadIcon} alt='' />
                                                    </p>
                                                    <p className="ant-upload-text" style={{ marginTop: "20px" }}> <span>Click to upload</span> Drag and Drop file </p>
                                                    <p className="ant-upload-hint">
                                                        <div className='brawse-but'> SVG, PNG, JPG or GIF (max. 800x400px)
                                                        </div>
                                                    </p>
                                                </Dragger>
                                                <div >
                                                    {props.docDetailList.map((doc, i) => {
                                                        return (

                                                            <Row className='image-list'>

                                                                <Col> {doc.fileName}</Col>
                                                                <Col> <i
                                                                    className="fa fa-trash pointer-cu"
                                                                    onClick={() => handleRemove(doc)}
                                                                    style={{ width: "20px" }}
                                                                ></i></Col>
                                                            </Row>
                                                        )
                                                    })}
                                                </div>

                                            </div>
                                        </Col>
                                        <Col xs={24} lg={12} >
                                            <div className='proucty'>
                                                {props.isLoadData ?
                                                    <CKEditor
                                                        // editor={ClassicEditor}
                                                        data={props.assignmentResponse || ''}
                                                        key={`${"emailBody"}`}
                                                        // onInit={editor => {
                                                        //     this.editor = editor;
                                                        // }}
                                                        onChange={(event) => {
                                                            const data = event.editor.getData();
                                                            props.updateState({ assignmentResponse: data });
                                                        }}
                                                        config={{ ...CUSTOM_CONFIGURATION_FORM, placeholder: 'Student Comments' }}
                                                    /> : ''}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </TabPane>
                        </Tabs>

                        <div className="fot-xer" style={{ justifyContent: `${tabKey === "2" ? "flex-end" : "space-between"}` }}>
                            {/* {props.current < steps.length - 1 && (

                    <Button type="primary" onClick={() => next(props)}>
                        Next
                        </Button>
                )}

                {
                    props.current > 0 && (
                        <Button style={{ marginRight: 8 }} onClick={() => prev()}>
                            Previous
                            </Button>
                    )}
                {props.current === steps.length - 1 && (

                    <Button type="primary" onClick={() => {
                        if (props.fileToUpload) {
                            props.uploadSchoolDoc({ relationshipId: companyInfo.relationshipId, fileToUpload: props.fileToUpload }, props);
                        } else {
                            showToasterMessage({
                                messageType: 'error', description: 'Please select a file before importing'
                            });
                        }
                    }}>
                        Done
    </Button>
                )} */}
                            {/* <Button type="default" onClick={() => {
                            props.history.push({
                              pathname: '/admin/assignment-list',
                              state: {}
                            });
                        }}>
                            Cancel
                        </Button> */}
                            {tabKey === "1" ?
                                <Button className='cutom-button' type='primary' onClick={handleQPrevious} disabled={currentIndex === 0}>
                                    Previous
                                </Button> : ""}

                            {!(totalQuestions - 1 == currentIndex) &&
                                <div className='next-btn-group'>
                                    <Button className='cutom-button' type='primary' onClick={handleSkip} disabled={currentIndex === questions.length - 1}>
                                        Skip
                                    </Button>
                                    <Button className='cutom-button' type='primary' onClick={handleQNext} disabled={currentIndex === questions.length - 1}>
                                        Next
                                    </Button>
                                </div>
                            }


                            {(totalQuestions - 1 == currentIndex) &&
                                <Button className='cutom-button' type="primary" onClick={() => {
                                    let isRequiredQuestionMissing = false;
                                    (props.questionList || []).forEach((ques) => {
                                        if (ques.isRequired && (!ques.answers || !ques.answers.length)) {
                                            isRequiredQuestionMissing = true;
                                        }
                                    })

                                    if (isRequiredQuestionMissing) {
                                        return showToasterMessage({
                                            messageType: 'error', description: props.intl.formatMessage({ id: 'required.question.missing' })
                                        });
                                    }
                                    if (asmData.isAttachementRequiredInResponse && !props.docDetailList) {
                                        return showToasterMessage({ messageType: 'error', description: 'Please attach your assignemnt.' });
                                    }
                                    // if (!props.assignmentName) {
                                    //     return showToasterMessage({ messageType: 'error', description: 'Assignment name is required' });
                                    // }
                                    // if (!props.dueDate) {
                                    //     return showToasterMessage({ messageType: 'error', description: 'Assignment due date is required' });
                                    // }
                                    // if (!props.assignmentGroupId) {
                                    //     return showToasterMessage({ messageType: 'error', description: 'Assignment group is required' });
                                    // }
                                    // if (!props.numberOfAttempts) {
                                    //     return showToasterMessage({ messageType: 'error', description: 'Number of attemps is required' });
                                    // }
                                    // if (props.numberOfAttempts && props.numberOfAttempts === 'Multiple' && !props.maxAttempts) {
                                    //     return showToasterMessage({ messageType: 'error', description: 'Max attemp is required' });
                                    // }
                                    // if ((props.scheduling && props.scheduling === 'Schedule for Later' && (!props.startDate || !props.endDate))) {
                                    //     return showToasterMessage({ messageType: 'error', description: 'Assignment scheduling date is required' });
                                    // }

                                    const modalData = {
                                        modalBody: 'Are you sure you want to submit?',
                                        handleSubmit: () => {
                                            let payload = {
                                                relationshipId: companyInfo.relationshipId,
                                                assignmentId: asmData.assignmentId,
                                                assignmentName: asmData.assignmentName,
                                                response: JSON.stringify(props.assignmentResponse),
                                                dueDate: getCurrentDateForBackend(asmData.dueDate || new Date()),
                                                startDate: asmData.startDate ? getCurrentDateForBackend(asmData.startDate || new Date()) : null,
                                                endDate: asmData.endDate ? getCurrentDateForBackend(asmData.endDate || new Date()) : null,
                                                assignmentGroupId: asmData.assignmentGroupId,
                                                numberOfAttempts: asmData.numberOfAttempts,
                                                questionAnswer: JSON.stringify(props.questionList || '[]'),
                                                studentId: props.studentId,
                                                classId: asmData.classId,
                                                className: asmData.className,
                                                teacherComment: props.teacherComment,
                                                docDetailList: props.docDetailList || [],


                                            }
                                            props.submitAssignment(payload, props);
                                        }
                                    };
                                    props.pushModalToStack(modalData);

                                    // } else { 
                                    //     showToasterMessage({
                                    //         messageType: 'error', description: 'Please select a file before importing'
                                    //     });
                                    // }
                                }}>
                                    Submit
                                </Button>
                            }


                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default injectIntl(AssignmentAttemp);
