import React, { Fragment, useState } from "react";
import { Row, Col, Tabs, Menu, Dropdown, Button } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
// import ThumPic from "../../assets/images/newletter-main-pic.jpg";
// import PinPic from "../../assets/images/pin-icon.png";
// import ContactIfoPic from "../../assets/images/contacts-info.png";
// import StudentPic from "../../assets/images/student-pic.png";
// import EducationPic from "../../assets/images/fee-details.png";
// import IncomeEx from "../../assets/images/empl.png";
// import DefultPic from "../../assets/images/avatar.png";
// import DefultsPic from "../../assets/images/avatar1.png";
import * as filter from 'lodash.filter';
import * as find from 'lodash.find';
import { getMomentDateForUIReadOnly, showToasterMessage, getAWSFileURL, getInitialsFromString, getUserDefinedName, clearLocalStorage } from '../../utils';
import { CONSTANTS, MODAL_TYPE, FAMILY_RELATIONSHIP_NAME_BY_KEY } from '../../static/constants';
import ReactHtmlParser from 'react-html-parser';
// import { pushModalToStack } from "../../actions/commonActions";
import DropdownAction from "antd/lib/dropdown";
import { MoreOutlined, CalendarOutlined, UserOutlined, RightOutlined } from "@ant-design/icons";
import EditIcon from '../../../src/assets/images/edit-icon.svg';
import DeleteIcon from '../../../src/assets/images/delete-icon.svg';
import SquareUnlockIcon from '../../../src/assets/images/square-unlock-icon.svg';
import SecurityPasswordIcon from '../../../src/assets/images/security-password-icon.svg';
import UserIcon from '../../../src/assets/images/users-icon.svg';
import BellIcon from '../../../src/assets/images/bell-icon.svg';
import SettingsIcon from '../../../src/assets/images/settings-icon.svg';
import PortalIcon from '../../../src/assets/images/portal-icon.svg';
import CalendarIcon from '../../../src/assets/images/calender-icon.svg';
import allergiesIcon from '../../../src/assets/images/allergies-icon.svg';
import LocationIcon from '../../../src/assets/images/location-icon.svg';
import ChangePassword from "../../containers/Profile/ChangePassword/index";
import NotFound from "../Common/NotFound";


const { TabPane } = Tabs;

const Profile = (props) => {
  const { primaryContact, customerInfo  } = props;
  const [componentsToDisplay , setComponentsToDisplay ] = useState("Portal Users");
  const [activeComponent, setActiveComponent] = useState("Portal Users");

  const handleItemClick = (component) => {
    setComponentsToDisplay(component);
    setActiveComponent(component);
};

    const handleLogout = () =>{
      clearLocalStorage();
      window.location.reload();
    }

   const handleNewContactAddition = (props, payload) => {
   
    const formData = payload.formData;
    const { pushModalToStack, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    const data = {
      title: <div>
        Add Family Member
        <div style={{ 'fontSize': '12px' }}> {formData.customerName || ''}</div>
      </div>,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.CONTACT,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.popModalFromStack(data);
      }
    };
    pushModalToStack(data);
  }

  const handleNewEducationAddition = (props, payload, contact) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
      }
    }
    const data = {
      title: <>
        <FormattedMessage id='education.text' defaultMessage='' />
        <div style={{ 'fontSize': '12px' }}> {contact.firstName + (contact.lastName ? ' ' + contact.lastName : '')}</div>
      </>,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EDUCATION,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }
  const handleNewEmploymentAddition = (props, payload, contact) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
      }
    }
    const data = {
      title: <>
        <FormattedMessage id='employment.text' defaultMessage='' />
        <div style={{ 'fontSize': '12px' }}> {contact.firstName + (contact.lastName ? ' ' + contact.lastName : '')}</div>
      </>,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.EMPLOYMENT,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  const handleAddressAddition = (props, payload) => {
    const formData = payload.formData;
    const { showModal, companyInfo } = props;
    formData.relationshipId = companyInfo.relationshipId;
    formData.callback = (contact) => {
      props.hideModal(data);
      if (contact.customerId) {
        // props.fetchAllContacts({
        //     customerId: contact.customerId,
        //     relationshipId: (props.companyInfo || {}).relationshipId,
        // });
      }
    }
    const data = {
      title: <>
        <FormattedMessage id='signup.tab.address' defaultMessage='' />
        <div style={{ 'fontSize': '12px' }}> {formData.customerName || ''}</div>
      </>,
      formData,
      hideFooter: true,
      modalData: {
        modalType: MODAL_TYPE.BILLING_ADDRESS,
        data: payload,

      },
      handleSubmit: (formData = {}) => {
        data.formData.submittedOnce = true;
        props.hideModal(data);
      }
    };
    showModal(data);
  }

  if (primaryContact && primaryContact.contactId && !primaryContact.familyRelationship && !props.isProfileSetupOpen) {
    let formData = { customerId: customerInfo.customerId, customerName: customerInfo.companyName, contactData: primaryContact };
    props.updateState({ isProfileSetupOpen: true });
    handleNewContactAddition(props, { formData: formData });
  }
  const actionMenuEd = (e, contact) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="1"
          value="Edit"
          // onClick={() => {
          //   let formData = {  ...e,customerId: props.customerInfo
          //         .customerId,
          //     customerName:
          //       props.customerInfo
          //         .companyName,
          //     contactData: contact,
          //     contactId:
          //       contact.contactId,
          //   };
          //   handleNewEducationAddition(
          //     props,
          //     {
          //       formData: formData,
          //     },
          //     contact
          //   );
          // }}
          onClick={() => {
            props.updateHeaderState({
            drawer :  {
            ...props.drawer,
            educationDrawerVisible: true},
            education : e
                 })}}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="2"
          value="Delete"
          onClick={() => {
            props.showModal({
              modalBody:
                "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteContactEducation(
                  e,
                  props
                );
              },
            });
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };


  const actionMenuEB = (e, contact) => {
    return (
      <Menu className="row-action-dropdown">
        <Menu.Item key="0" value="title">
          Actions
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="1"
          value="Edit"
          // onClick={() => {
          //   let formData = {  ...e,customerId: props.customerInfo
          //         .customerId,
          //     customerName:
          //       props.customerInfo
          //         .companyName,
          //     contactData: contact,
          //     contactId:
          //       contact.contactId,
          //   };
          //   handleNewEducationAddition(
          //     props,
          //     {
          //       formData: formData,
          //     },
          //     contact
          //   );
          // }}
          onClick={() => {
            props.updateHeaderState({
            drawer :  {
            ...props.drawer,
             employmentDrawerVisible: true},
                employer : e
                 })}}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          key="2"
          value="Delete"
          onClick={() => {
            props.showModal({
              modalBody: "Are you sure you want to delete?",
              handleSubmit: () => {
                props.deleteContactEmployment(e, props);
              }
            })
          }}
        >
          Delete
        </Menu.Item>
      </Menu>
    );
  };

  const operations = <button className="ant-btn  ant-btn ant-btn-default cutom-button-green" 
  onClick={() => {
    props.updateHeaderState({
    drawer :  {
      ...props.drawer,
      addFamilyMemberDrawerVisible: true,
    },
    addFamilyMember: {
      allergies  : "Yes"
    }
  })}}>Add Member</button>

  return (
    <Fragment>
      <Row>
        <Col xs={24} lg={6} xl={5} className="left-profile-container">
          <div className="left-profile">
          <div className="profile-detail p20">       
           <Row>
            <Col xs={5} sm={2} lg={5}>
            <div className="thum">
              {(primaryContact.docDetailList && primaryContact.docDetailList[0]) ?
                <img src={getAWSFileURL(primaryContact.docDetailList[0].awsKeyName, primaryContact.docDetailList[0].bucketName)} alt="Avatar" />
                : 
                <div className='user-icon-profile-parent'>
                  {customerInfo ? getInitialsFromString(customerInfo.companyName || 'XX') : ''}
                </div>
              }
            </div> 
            </Col>
            <Col xs={19} sm={22} lg={19} >
            <div className="heading1">{primaryContact.firstName?(primaryContact.firstName  +" "+ primaryContact.lastName) : ''}
             {primaryContact.cellPhone && <span onClick={() => {
                              props.updateHeaderState({
                              drawer :  {
                              ...props.drawer,
                              addFamilyMemberDrawerVisible: true},
                              addFamilyMember : primaryContact
                              })}}> <i className="fa fa-pencil" style={{ width: '20px', float: 'right', cursor: 'pointer' }}></i></span>}
            </div>

            <div className="description">
              <ul>
                <li>{primaryContact.cellPhone || <Col style={{ cursor: 'pointer', color: '#676ED3' }} onClick={() => {
                  let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: primaryContact }
                  handleNewContactAddition(props, { formData: formData });
                }}> Enter your phone #</Col>}
                </li>
                <li>{primaryContact.emailAddress || '-'}</li>
              </ul>
            </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div className="profile-btn-group" {...primaryContact ===''}>
              <Button  icon={<UserOutlined />} className="ant-btn ant-btn-primary custom-button">{primaryContact?.gender}</Button>
              {primaryContact?.dateOfBirth 
              ? <Button icon={<CalendarOutlined />}  className="ant-btn ant-btn-primary custom-button">{primaryContact?.dateOfBirth}</Button> : ""}
              </div>
            </Col>
          </Row>    
            
            {/* <div className="heading">
              Membership ID 1234
              <span>Edit Profile</span>
            </div> */}

           {false && <div className="heading1" style={{textAlign: 'left',fontSize:'14px', fontWeight:'700'}}>Welcome {primaryContact.firstName?(primaryContact.firstName  +" "+ primaryContact.lastName) : ''}
            {primaryContact.cellPhone && <span onClick={() => {
               let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: primaryContact }
               handleNewContactAddition(props, { formData: formData });
              }}> <i className="fa fa-pencil" style={{ width: '20px', float: 'right', cursor: 'pointer' }}></i></span>}
            </div> }
            {false && <div className="thum">
              {(primaryContact.docDetailList && primaryContact.docDetailList[0]) ?
                <img src={getAWSFileURL(primaryContact.docDetailList[0].awsKeyName, primaryContact.docDetailList[0].bucketName)} alt="Avatar" />
                : <div className='user-icon-profile-parent'>
                {customerInfo ? getInitialsFromString(customerInfo.companyName || 'XX') : ''}
                    </div>
              }
              {/* <img src={ThumPic} alt="pic" /> */}
            </div> }
            {/* <div className="title">
              {props.customerInfo.companyName}
            </div> */}
            {false && <div className="description">
              <ul>
                <li><span><i className="fa fa-phone" ></i></span> {primaryContact.cellPhone || <Col style={{ cursor: 'pointer', color: '#676ED3' }} onClick={() => {
                  let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: primaryContact }
                  handleNewContactAddition(props, { formData: formData });
                }}> Enter your phone #</Col>}
                </li>
                <li><span><i className="fa fa-envelope-o"></i></span> {primaryContact.emailAddress || '-'}</li>
              </ul>
              {/* <button className="ant-btn ant-btn-primary">Renew Membership</button> */}
            </div>}      
            </div>

            <div className="profile-nav-wrapper">
              <ul className="ul-list">
                <li onClick={()=>handleItemClick("Portal Users")} className={activeComponent === "Portal Users" ? 'active-link' : ''}> <span><img alt=""  src={UserIcon}/>&nbsp;Portal Users</span> <span>{<RightOutlined />}</span></li>
                <li  onClick={()=>handleItemClick("Notifications")} className={activeComponent === "Notifications" ? 'active-link' : ''}> <span><img alt=""  src={BellIcon}/>&nbsp;Notifications</span> <span>{<RightOutlined />}</span></li>
                <li  onClick={()=>handleItemClick("Settings")} className={activeComponent === "Settings" ? 'active-link' : ''}> <span><img alt=""  src={SettingsIcon}/>&nbsp;Settings</span> <span>{<RightOutlined />}</span></li>
                <li  onClick={()=>handleItemClick("Change Password")} className={activeComponent === "Change Password" ? 'active-link' : ''}> <span><img alt=""  src={SecurityPasswordIcon}/>&nbsp;Change Password</span> <span>{<RightOutlined />}</span></li>
                <li onClick={()=>handleLogout("Logout")} style={{color : "#FF1604"}}> <span><img alt=""  src={SquareUnlockIcon}/>&nbsp;Logout</span></li>
              </ul>
            </div>


            <div className="profile-nav-wrapper-mobile ">
              <ul className="ul-list">
              <li onClick={()=>handleItemClick("Portal Users")} className={activeComponent === "Portal Users" ? 'active-link' : ''}> <span><img src={UserIcon}/>&nbsp;Portal Users</span> <span>{<RightOutlined />}</span></li>
                <li  onClick={()=>handleItemClick("Notifications")} className={activeComponent === "Notifications" ? 'active-link' : ''}> <span><img src={BellIcon}/>&nbsp;Notifications</span> <span>{<RightOutlined />}</span></li>
                <li  onClick={()=>handleItemClick("Settings")} className={activeComponent === "Settings" ? 'active-link' : ''}> <span><img src={SettingsIcon}/>&nbsp;Settings</span> <span>{<RightOutlined />}</span></li>
                <li  onClick={()=>handleItemClick("Change Password")} style={{display  : "flex", minWidth  :"160px"}}  className={activeComponent === "Change Password" ? 'active-link' : ''}> <span><img src={SecurityPasswordIcon}/>&nbsp;Change&nbsp;Password</span> <span>{<RightOutlined />}</span></li>
                <li onClick={()=>handleLogout("Logout")} style={{color : "#FF1604"}}> <span><img src={SquareUnlockIcon}/>&nbsp;Logout</span></li>
              </ul>
            </div>
          </div>
          {/* { (address && address.streetAddress1) ?
            <div className="left-profile">
              <div className="heading1">Address <span onClick={() => {
                let formData = { isUpdate: true, ...address, locationType: CONSTANTS.BILLING_ADDRESS, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName };
                handleAddressAddition(props, { formData: formData });
              }}> <i className="fa fa-pencil" style={{ width: '20px', float: 'right', cursor: 'pointer' }}></i></span></div>
              <div className="headings">
                <Fragment>
                  {
                    address.streetAddress1
                      ? <div className='billing-address'>{address.streetAddress1}
                      </div>
                      : ''
                  }
                  {
                    address.streetAddress2
                      ? <div className='billing-address'>{address.streetAddress2}
                      </div>
                      : ''
                  }
                  {
                    address.cityName
                      ? <div className='billing-address'>
                        {
                          `${address.cityName || ''}${address.stateName ? ', ' + address.stateName : ''}${address.zipCode ? ', ' + address.zipCode : ''}`
                        }
                      </div>
                      : ''
                  }
                </Fragment>

              </div>
            </div>
            :
            <div className="left-profile">
              <div className="heading1">Address</div>
              <div className="pl10 pr20 pb20">
                <Row>
                  <Col span={8}><img src={locationPic} alt="" className="pt20"/> </Col>
                  <Col span={16} className="text-center pt25">
                Address does not exist. <span style={{ cursor: 'pointer', color: '#676ED3' }} onClick={() => {
                  let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName }
                  handleAddressAddition(props, { formData: formData });
                }}>Click Here </span> to add address.
                </Col>
                </Row>
              </div>
            </div>
          } */}

          {/* {(membershipInfo && membershipInfo.orgMembershipId) ?
            <div className="left-profile">
              <div className="heading1" >Membership Info</div>
              <div className="headings">
              
                <Row>
                  <Col span={12} style={{ textAlign: 'right' }}>Membership Id</Col>
                  <Col span={12} className="pl10">{membershipInfo.orgMembershipId}</Col>
                </Row>
                <Row>
                  <Col span={12} style={{ textAlign: 'right' }}>Status</Col>
                  <Col span={12} className="pl10">{membershipInfo.status}</Col>
                </Row>
                <Row>
                  <Col span={12} style={{ textAlign: 'right' }}>Membership Level</Col>
                  <Col span={12} className="pl10">{membershipInfo.membershipLevel}</Col>
                </Row>
                <Row>
                  <Col span={12} style={{ textAlign: 'right' }}>Member Since</Col>
                  <Col span={12} className="pl10">{membershipInfo.dateCreated ? getMomentDateForUIReadOnly({ date: new Date(membershipInfo.dateCreated), format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</Col>
                </Row>
                <Row>
                  <Col span={12} style={{ textAlign: 'right' }}>Renewal Date</Col>
                  <Col span={12} className="pl10">{membershipInfo.renewalDate ? getMomentDateForUIReadOnly({ date: new Date(membershipInfo.renewalDate), format: CONSTANTS.DISPLAY_DATE_FORMAT }) : ''}</Col>
                </Row>
              </div>
            </div> :
            <div className="left-profile">
              <div className="heading1">Membership Info</div>
              <div className="pl10 pr50 pb20">
                You are not member of the organization. <span style={{ cursor: 'pointer', color: '#676ED3' }} onClick={() => {
                  let membershipForm = (props.membershipFormList || [])[0] || {}
                  props.history.push({
                    pathname: '/admin/register-member',
                    state: {
                      formId: membershipForm.registrationFormSettingId,
                    }
                  })
                }}>Click Here </span> to become the member.
              </div>
            </div>
          } */}


        </Col>

       {componentsToDisplay  === "Portal Users" && <Col  xs={24} lg={18} xl={19}>
          {/* <div className="right-profile">
            <div className="main-title">
              <img src={PinPic} alt="pic" />  Address
              <span><button className="ant-btn grey-button ant-btn ant-btn-default">Edit </button></span>
            </div>
            <div className="description">Lipsum generator: Lorem Ipsum - All the facts
              https://www.lipsum.com
              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make </div>
          </div> */}
          <div className="right-profile">
           {false && <div className="main-title">
             <div className="page-heading">
              Contact Information
              </div>
              <span><button className="ant-btn  ant-btn ant-btn-default" style={{backgroundColor: '#1CB961',border: '1px solid #1CB961',color: '#ffffff'}}onClick={() => {
                let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName }
                handleNewContactAddition(props, { formData: formData });
              }}>Add Member</button></span>
            </div>}



            <div className="main-title-wrapper">
              <Row>
               <Col span={24}>
             <Tabs tabBarExtraContent={operations} onChange={(key) => {
            props.resetContactEducation();
            props.resetContactEmployment();
            let conatctObj = find(customerInfo.boContactList || [], { contactId: Number(key) }) || {};
            props.updateHeaderState({
              conatctObj : conatctObj
            })
            props.getContactEducation(conatctObj); 
            props.getContactEmployment(conatctObj);
             }} className="prl15">

            {(customerInfo.boContactList || []).map((contact, i) => {
               console.log("contact", primaryContact)
              return <TabPane tab={contact.firstName + (contact.lastName ? ' ' + contact.lastName : '')} key={contact.contactId}>
                <div>
                  <Row>
                    <Col span="24">
                    <div className="user-details-card">
                      <Row gutter={[0, 20]}>
                    <Col xs={22} lg={8}>
                         <div className="right-section">
                          <div className="thum">
                       {(primaryContact.docDetailList && primaryContact.docDetailList[0]) ?
                      <img  src={getAWSFileURL(primaryContact.docDetailList[0].awsKeyName, primaryContact.docDetailList[0].bucketName)} alt="Avatar" />
                       : <div className='user-icon-profile-parent'>
                       {customerInfo ? getInitialsFromString( props?.conatctObj ? (props?.conatctObj.firstName  +" "+ props?.conatctObj.lastName) : (primaryContact.firstName  +" "+ primaryContact.lastName) || 'XX') : ''}
                       </div>
                      }
                       </div>
                      <div className="">
                      <div className="heading1">{ props?.conatctObj ? (props?.conatctObj.firstName  +" "+ props?.conatctObj.lastName) : (primaryContact.firstName  +" "+ primaryContact.lastName) }
                     {/* {primaryContact.cellPhone && <span onClick={() => {
                     let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: primaryContact }
                     handleNewContactAddition(props, { formData: formData });
                     }}> <i className="fa fa-pencil" style={{ width: '20px', float: 'right', cursor: 'pointer' }}></i></span>} */}
                     </div>

                     <div className="description">
                     <ul>
                      <li>{props?.conatctObj ? props.conatctObj.emailAddress : primaryContact.emailAddress}</li>
                      </ul>
                     <div style={{display  :"flex", gap  :"10px"}}>
                     {props.primaryContact?.isPrimaryContact  ?  <span className="custom-label label-yellow">Primary</span> : ""}
                     <span className="custom-label label-green">Enrolled</span>
                      <span className="custom-label label-blue">{<UserOutlined/>} {props?.conatctObj ? props.conatctObj.gender : primaryContact.gender}</span>
           
                       </div>
                       </div>
                          </div>
                         </div>
                    </Col>
                     <div className="edit-mobile">
                     <Col span={2}>
                            <div className="actopn-circle dotes" >
                             <Dropdown overlay={<Menu>

                            <Menu.Item key="2"  onClick={() => {
                              props.updateHeaderState({
                              drawer :  {
                              ...props.drawer,
                              addFamilyMemberDrawerVisible: true},
                              addFamilyMember : contact
                              })}}><span > <i className="fa fa-pencil" style={{ width: '25px' }}></i>Edit
                              </span> </Menu.Item>
                            <Menu.Item key="3" className="disabled" disabled={props?.conatctObj ? props.conatctObj.isPrimaryContact : primaryContact.isPrimaryContact} onClick={() => {
                              if (primaryContact.isPrimaryContact) {
                                showToasterMessage({ messageType: 'error', description: 'Primary Contact can not be deleted' });
                              } else {
                                props.pushModalToStack({
                                  modalBody: "Are you sure you want to delete?",
                                  handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.hideModal();
                                    props.deleteContact(primaryContact, props);
                                  }
                                })
                              }
                              }}><span > <i className="fa fa-trash" style={{ width: '25px' }} ></i>Delete
                              </span></Menu.Item>

                            <Menu.Item key="4" className="disabled" disabled={props?.conatctObj ? props.conatctObj.isPrimaryContact : primaryContact.isPrimaryContact} onClick={() => {
                              props.pushModalToStack({
                                modalBody: "Are you sure you want to make this contact as primary contact?",
                                handleSubmit: () => {
                                  props.popModalFromStack();
                                  props.hideModal();
                                  props.makePrimary({ ...primaryContact, isPrimaryContact: 1, isEnablePortalAccess: 1 }, null, props);
                                }
                              })
                            }}><span> <i className="fa fa-check" style={{ width: '25px' }} ></i>Make Primary Contact
                              </span></Menu.Item>
                            <Menu.Item key="5" onClick={() => {
                              if (primaryContact.isEnablePortalAccess) {
                                showToasterMessage({ messageType: 'error', description: 'Already enabled' });
                              } else if (!primaryContact.emailAddress) {
                                showToasterMessage({ messageType: 'error', description: 'Email address is requierd for portal access. Please edit contact to provide email address' });
                              } else {
                                props.pushModalToStack({
                                  modalBody: "Are you sure you want to enable portal access for this contact?",
                                  handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.hideModal();
                                    props.enablePortalAccess({ ...primaryContact, isEnablePortalAccess: 1 }, null, props);
                                  }
                                })
                              }
                            }}><span > <i className="fa fa-solid fa-desktop" style={{ width: '25px' }} ></i>Enable Portal Access
                              </span></Menu.Item>
                          </Menu>} trigger={['click']}>
                            <div>
                                <i className="fi fi-br-menu-dots-vertical" style={{fontSize: '16px'}}></i>
                              </div>
                          </Dropdown>
                        </div>
                            </Col>
                            </div>


                        <Col xs={24} lg={16}>
                          <Row gutter={[0 , 20]}>
                            <Col xs={24} lg={9}>
                              <div className="address-wrapper" >
                                <span><img src={LocationIcon}/></span>
                                 <p>
                                 &nbsp;{customerInfo.boLocationList[0]?.streetAddress1}, 
                                 {customerInfo.boLocationList[0]?.cityName}, {customerInfo.boLocationList[0]?.stateName}, <br/>{customerInfo.boLocationList[0]?.zipCode}
                                 </p>
                              </div>
                            </Col>
                            <Col xs={24} lg={7}>
                              <div className="contact-wrapper">
                               <span><img src={allergiesIcon}/> &nbsp;{props?.conatctObj ? props.conatctObj.cellPhone : primaryContact.cellPhone}</span>
                                <span><img src={CalendarIcon}/> &nbsp;{props?.conatctObj ? props.conatctObj.allergies : primaryContact.allergies || "No Allergies"}</span>
                              </div>
                            </Col>
                            <Col  xs={24} lg={7}>
                              <div className="contact-wrapper">
                            <span> <img src={CalendarIcon}/> &nbsp;{props?.conatctObj ? props.conatctObj.dateOfBirth : primaryContact.dateOfBirth || "-"}</span>
                            <span> <img src={PortalIcon}/> &nbsp; {props.conatctObj?.isEnablePortalAccess === 0 ? "No Portal Access" : "Portal Access"}</span>
                              </div>
                            </Col>

                            <Col span={1} style={{textAlign  :"end"}}>
                            <div className="edit-desktop">
                            <div className="actopn-circle dotes" >
                             <Dropdown overlay={<Menu>

                            <Menu.Item key="2"  onClick={() => {
                              props.updateHeaderState({
                              drawer :  {
                              ...props.drawer,
                              addFamilyMemberDrawerVisible: true},
                              addFamilyMember : contact
                              })}}><span > <i className="fa fa-pencil" style={{ width: '25px' }}></i>Edit
                              </span> </Menu.Item>
                            <Menu.Item key="3" className="disabled" disabled={props?.conatctObj ? props.conatctObj.isPrimaryContact : primaryContact.isPrimaryContact} onClick={() => {
                              if (primaryContact.isPrimaryContact) {
                                showToasterMessage({ messageType: 'error', description: 'Primary Contact can not be deleted' });
                              } else {
                                props.pushModalToStack({
                                  modalBody: "Are you sure you want to delete?",
                                  handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.hideModal();
                                    props.deleteContact(primaryContact, props);
                                  }
                                })
                              }
                            }}><span > <i className="fa fa-trash" style={{ width: '25px' }} ></i>Delete
                              </span></Menu.Item>

                            <Menu.Item key="4" className="disabled" disabled={props?.conatctObj ? props.conatctObj.isPrimaryContact : primaryContact.isPrimaryContact} onClick={() => {
                              props.pushModalToStack({
                                modalBody: "Are you sure you want to make this contact as primary contact?",
                                handleSubmit: () => {
                                  props.popModalFromStack();
                                  props.hideModal();
                                  props.makePrimary({ ...primaryContact, isPrimaryContact: 1, isEnablePortalAccess: 1 }, null, props);
                                }
                              })
                            }}><span  > <i className="fa fa-check" style={{ width: '25px' }} ></i>Make Primary Contact
                              </span></Menu.Item>
                            <Menu.Item key="5" onClick={() => {
                              if (primaryContact.isEnablePortalAccess) {
                                showToasterMessage({ messageType: 'error', description: 'Already enabled' });
                              } else if (!primaryContact.emailAddress) {
                                showToasterMessage({ messageType: 'error', description: 'Email address is requierd for portal access. Please edit contact to provide email address' });
                              } else {
                                props.pushModalToStack({
                                  modalBody: "Are you sure you want to enable portal access for this contact?",
                                  handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.hideModal();
                                    props.enablePortalAccess({ ...primaryContact, isEnablePortalAccess: 1 }, null, props);
                                  }
                                })
                              }
                            }}><span > <i className="fa fa-solid fa-desktop" style={{ width: '25px' }} ></i>Enable Portal Access
                              </span></Menu.Item>
                          </Menu>} trigger={['click']}>
                            <div>
                                <i className="fi fi-br-menu-dots-vertical" style={{fontSize: '16px'}}></i>
                              </div>
                          </Dropdown>
                        </div>
                            </div>
                            </Col>
                          </Row>
                        </Col>

                      </Row>
                    </div>
                    </Col>
                   </Row>

                   <Row>
                    <Col span="24">
                    <div className="right-profile">
                  <div className="main-title">
                    <div className="page-heading">
                      Education
                      </div> 
                    {/* <span><button className="ant-btn  ant-btn ant-btn-default cutom-button"  onClick={()=>{
                      let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                      handleNewEducationAddition(props, { formData: formData }, contact);
                    }}>Add Education</button></span> */}

                    <span  onClick={() => {
                    props.updateHeaderState({
                    drawer :  {
                      ...props.drawer,
                      educationDrawerVisible: true,
                    }
                  })}}><button 
                   className="ant-btn  ant-btn ant-btn-default cutom-button-green"  
                   >
                    <i className="fi fi-br-plus mr5"></i>
                    Add</button></span>
                  </div>
                  {/* <div className="description"> */}
                  <div className='table-container'>
                    <table onMouseLeave={() => { props.updateState({ editableIndex: -1 }) }}>
                        <thead>

                        <tr>
                          <th style={{ textAlign: 'center' }}>S.No.</th>
                          <th>Institution Type</th>
                          <th>Institution Name</th>
                          <th>{getUserDefinedName("Course", props)}</th>                     
                          <th>Major</th>
                          <th>Score</th>
                          <th><span>Start Date</span></th>
                          <th><span>End Date</span></th>
                          <th><FormattedMessage id='customer.listing.header.action' /></th>
                        </tr>
                        </thead>
                      <tbody>
                        {(props.contactEducationList || []).map((e, i) => {
                          return <tr key={'std' + i}>

                            <td style={{ textAlign: 'center' }}>{i + 1}</td>
                            <td>{e.institutionType || '-'}</td>
                            <td>{e.institutionName || '-'}</td>
                            <td>{e.courseName || '-'}</td>
                            <td>{e.major || '-'}</td>
                            <td>{e.score || '-'}</td>
                            <td><span>{e.startDate ? getMomentDateForUIReadOnly({ date: e.startDate, format: CONSTANTS.TABLE_DATE_FORMAT }) : '-'}</span></td>
                            <td><span>{e.endDate ? getMomentDateForUIReadOnly({ date: e.endDate, format: CONSTANTS.TABLE_DATE_FORMAT }) : '-'}</span></td>
                            <td style={{paddingLeft: '50px'}}>
                              <DropdownAction overlay={actionMenuEd(e,contact)} trigger={['click']}>
                                <MoreOutlined />
                              </DropdownAction>
                              {/* <div className="delrt-sticky action-cell">
                                <i className='fa fa-edit' onClick={() => {
                                   let formData = {...e, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                                   handleNewEducationAddition(props, { formData: formData }, contact);
                                }} type="edit" />
                                <i className='fa fa-trash' onClick={() => {
                                  props.showModal({
                                    modalBody: "Are you sure you want to delete?",
                                    handleSubmit: () => {
                                      props.deleteContactEducation(e, props);
                                    }
                                  })
                                }} type="delete" />
                              </div> */}
                            </td>
                          </tr>
                        })
                        }
                      </tbody>
                    </table>
                    </div>
                     </div>
                {/* </div> */}
                    </Col>
                    <Col span={24}>
                    <div className="right-profile">
                  <div className="main-title">
                    <div className="page-heading">
                      Employment Background
                      </div> 
                    {/* <span><button className="ant-btn  ant-btn ant-btn-default cutom-button" onClick={()=>{
                      let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                      handleNewEmploymentAddition(props, { formData: formData }, contact);
                    }}>Add Employment</button></span> */}

                      
                    {/* <span className="top-view-right">

                      <Button className="ant-btn ant-btn-default cutom-button-green"
                      onClick={() => {
                    props.updateHeaderState({
                    drawer :  {
                      ...props.drawer,
                      employmentDrawerVisible: true,
                    }
                  })}}>
                 <i className="fi fi-br-plus mr5"></i>
                        Add
                      </Button>
</span> */}
                    <span>

                      <button className="ant-btn  ant-btn ant-btn-default cutom-button-green"  onClick={() => {
                    props.updateHeaderState({
                    drawer :  {
                      ...props.drawer,
                      employmentDrawerVisible: true,
                    }
                  })}}>
                 <i className="fi fi-br-plus mr5"></i>

                    Add</button>
                    
                    </span>
                  </div>
                  {/* <div className="description"> */}
                  <div className='table-container'>
                    <table  onMouseLeave={() => { props.updateState({ editableIndex: -1 }) }}>
                        <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }}>S.No.</th>
                          <th><span>Employer Name</span></th>
                          <th><span>Job Title</span></th>
                          <th><span>Reason For Leaving</span></th>
                          {/* <th><span>Job Duties</span></th> */}
                          <th><span>Employer Phone</span></th>
                          <th><span>Employer Email</span></th>
                          <th><span>Start Date</span></th>
                          <th><span>End Date</span></th>
                          <th className="emps"><FormattedMessage id='customer.listing.header.action' /></th>
                        </tr>
                        </thead>
                      <tbody>
                        {(props.contactEmploymentList || []).map((e, i) => {
                          return <tr key={'std' + i}>

                            <td style={{ textAlign: 'center' }}>{i + 1}</td>
                            <td>{e.employerName || '-'}</td>
                            <td>{e.jobTitle || '-'}</td>
                            <td>{e.reasonForLeaving || '-'}</td>
                            {/* <td>{e.jobDuties ? ReactHtmlParser(e.jobDuties) : '-'}</td> */}
                            <td>{e.employerPhone || '-'}</td>
                            <td>{e.employerEmail || '-'}</td>
                            <td><span>{e.startDate ? getMomentDateForUIReadOnly({ date: e.startDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : '-'}</span></td>
                            <td><span>{e.endDate ? getMomentDateForUIReadOnly({ date: e.endDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : '-'}</span></td>
                            <td style={{paddingLeft: '50px'}}>
                              <DropdownAction overlay={actionMenuEB(e,contact)} trigger={['click']}>
                                <MoreOutlined />
                              </DropdownAction>
                              </td>
                          
                           { false && <td className="emps">
                              <div className="delrt-sticky action-cell new-action-cell">
                                {/* <i className='fa fa-edit' onClick={() => {
                                  let formData = {...e, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                                  handleNewEmploymentAddition(props, { formData: formData }, contact);
                                }} type="edit" /> */}
                                {/* <span onClick={() => {
                                  let formData = {...e, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                                  handleNewEmploymentAddition(props, { formData: formData }, contact);
                                }} > */}

                                  <span  onClick={() => {
                                   props.updateHeaderState({
                                   drawer :  {
                                   ...props.drawer,
                                    employmentDrawerVisible: true},
                                       employer : e
                                        })}}>
                                  <img src={EditIcon}/></span>

                                {/* <i className='fa fa-trash' onClick={() => {
                                  props.showModal({
                                    modalBody: "Are you sure you want to delete?",
                                    handleSubmit: () => {
                                      props.deleteContactEmployment(e, props);
                                    }
                                  })
                                }} type="delete" /> */}

                                <span onClick={() => {
                                  props.showModal({
                                    modalBody: "Are you sure you want to delete?",
                                    handleSubmit: () => {
                                      props.deleteContactEmployment(e, props);
                                    }
                                  })
                                }}><img src={DeleteIcon}/></span>

                              </div>
                            </td> }
                          </tr>
                        })
                        }
                      </tbody>
                    </table>
                    </div>
                     {/* </div> */}
                </div>
                    </Col>
                   </Row>
                  </div>  
              </TabPane>
            })
            }
           </Tabs>
                </Col>
                {/* <Col span={4} style={{display : "flex", justifyContent : "flex-end"}}>
                <button className="ant-btn  ant-btn ant-btn-default cutom-button-green"  onClick={() => {
                let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName }
                handleNewContactAddition(props, { formData: formData });
              }}>Add Family Member</button>
                </Col> */}

              </Row>
            </div>


            <div className="description" style={{display : "none"}}>
              <Row>
                {(primaryContact && primaryContact.contactId) ?
                  <Col xs={24} md={8} className='mat mab pl5 pr5 pt10 pb10' key={'std-' + primaryContact.contactId} >
                    <div className="add-adrsboxnel add-boxtop-bot">
                      <div className="hed-studs">
                        <Row >
                          {/* <Col span={8} className="textright15"> <b>First Name</b></Col> */}
                          <Col span={24}  >
                            <div className="tiles">
                            <div className="left-pic1">
                              <div className="left-pic">
                                {(primaryContact.docDetailList && primaryContact.docDetailList[0]) ?
                                  <img src={getAWSFileURL(primaryContact.docDetailList[0].awsKeyName, primaryContact.docDetailList[0].bucketName)} alt="Avatar" />
                                  : <div className='user-icon-profile-family'>
                                  {customerInfo ? getInitialsFromString(customerInfo.companyName || 'XX') : ''}
                                      </div>
                                }
                                {/* <img src={DefultPic} alt="pic" /> */}
                              </div>
                              {/* {primaryContact.isPrimaryContact ? <div className="primary"><span> Primary </span></div> : ''} */}
                              </div>
                              <div className="titles"><span> {primaryContact.firstName + (primaryContact.lastName ? ' ' + primaryContact.lastName : '')}</span></div>
                              <div className="emails">{primaryContact.emailAddress || ''}</div>
                              <div className="socialm"> {primaryContact.isPrimaryContact ? <div className="primary"><span> Primary </span></div> : ''}<div style={{float:'right', lineHeight:'17px'}}> 
                              {/* <i className="fa fa-facebook" onClick={() => {
                                if (!primaryContact.facebookLink) { return showToasterMessage({ messageType: 'error', description: 'Facebook link does not exist. Please edit profile to add facebook link.' }); }
                                let url = primaryContact.facebookLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                              }}></i> */}
                                {/* <i className="fa fa-twitter" onClick={() => {
                                  if (!primaryContact.instagramLink) { return showToasterMessage({ messageType: 'error', description: 'Twitter link does not exist. Please edit profile to add twitter link.' }); }
                                  let url = primaryContact.instagramLink;
                                  url = url.match(/^https?:/) ? url : '//' + url;
                                  window.open(url, '_blank');
                                }}></i> */}
                                {/* <i className="fa fa-linkedin" onClick={() => {
                                  if (!primaryContact.linkedIn) { return showToasterMessage({ messageType: 'error', description: 'LinkedIn link does not exist. Please edit profile to add linkedIn link.' }); }
                                  let url = primaryContact.linkedIn;
                                  url = url.match(/^https?:/) ? url : '//' + url;
                                  window.open(url, '_blank');
                                }}></i> */}
                                {/* <i className="fa fa-instagram" onClick={() => {
                                  if (!primaryContact.instagramLink) { return showToasterMessage({ messageType: 'error', description: 'Instagram link link does not exist. Please edit profile to add instagram link.' }); }
                                  let url = primaryContact.instagramLink;
                                  url = url.match(/^https?:/) ? url : '//' + url;
                                  window.open(url, '_blank');
                                }}></i> */}
                        </div>
                              </div>
                            </div>
                            {/* <span><img src={StudentPic} alt="" /></span> */}
                          </Col>
                        </Row>
                        <div className="actopn-circle dotes" >
                          <Dropdown overlay={<Menu>

                            <Menu.Item key="2" onClick={() => {
                              let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: primaryContact }
                              // 
                              // props.updateState({
                              //   inboundDeliveryFilterDrawerVisible:true,
                              //   formData:formData
                              // })
                              handleNewContactAddition(props, { formData: formData });
                              ;
                            }}><span > <i className="fa fa-pencil" style={{ width: '25px' }}></i>Edit
                              </span> </Menu.Item>
                            <Menu.Item key="3" className="disabled" disabled={primaryContact.isPrimaryContact} onClick={() => {
                              if (primaryContact.isPrimaryContact) {
                                showToasterMessage({ messageType: 'error', description: 'Primary Contact can not be deleted' });
                              } else {
                                props.pushModalToStack({
                                  modalBody: "Are you sure you want to delete?",
                                  handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.hideModal();
                                    props.deleteContact(primaryContact, props);
                                  }
                                })
                              }
                            }}><span > <i className="fa fa-trash" style={{ width: '25px' }} ></i>Delete
                              </span></Menu.Item>

                            <Menu.Item key="4" className="disabled" disabled={primaryContact.isPrimaryContact} onClick={() => {
                              props.pushModalToStack({
                                modalBody: "Are you sure you want to make this contact as primary contact?",
                                handleSubmit: () => {
                                  props.popModalFromStack();
                                  props.hideModal();
                                  props.makePrimary({ ...primaryContact, isPrimaryContact: 1, isEnablePortalAccess: 1 }, null, props);
                                }
                              })
                            }}><span  > <i className="fa fa-check" style={{ width: '25px' }} ></i>Make Primary Contact
                              </span></Menu.Item>
                            <Menu.Item key="5" onClick={() => {
                              if (primaryContact.isEnablePortalAccess) {
                                showToasterMessage({ messageType: 'error', description: 'Already enabled' });
                              } else if (!primaryContact.emailAddress) {
                                showToasterMessage({ messageType: 'error', description: 'Email address is requierd for portal access. Please edit contact to provide email address' });
                              } else {
                                props.pushModalToStack({
                                  modalBody: "Are you sure you want to enable portal access for this contact?",
                                  handleSubmit: () => {
                                    props.popModalFromStack();
                                    props.hideModal();
                                    props.enablePortalAccess({ ...primaryContact, isEnablePortalAccess: 1 }, null, props);
                                  }
                                })
                              }
                            }}><span > <i className="fa fa-solid fa-desktop" style={{ width: '25px' }} ></i>Enable Portal Access
                              </span></Menu.Item>
                          </Menu>} trigger={['click']}>
                            <div>
                                <i className="fi fi-br-menu-dots-vertical" style={{fontSize: '16px'}}></i>
                                {" "}
                              </div>
                          </Dropdown>
                        </div>
                      </div>
                      {/* {student.isDefault ? <div className="add-top-hed">Default</div> : ''} */}
                      <div className="plus-icon">
                        <Row className="lins"><Col span={12} className="textright15">Relationship</Col>
                        <Col span={12}>
                        {primaryContact.familyRelationship ? <span className="primary p5" style={{borderRadius: '5px'}}> {FAMILY_RELATIONSHIP_NAME_BY_KEY[primaryContact.familyRelationship.toLowerCase()]}</span> : '-'}
                        </Col></Row>
                        <Row className="lins"><Col span={12} className="textright15">Gender</Col><Col span={12}>
                          {primaryContact.gender || '-'}
                        </Col></Row>
                        <Row className="lins"><Col span={12} className="textright15">Mobile</Col>
                          <Col span={12}>
                            {primaryContact.cellPhone || '-'}
                          </Col></Row>
                        <Row className="lins"><Col span={12} className="textright15">DOB</Col>
                          <Col span={12}>
                            {primaryContact.dateOfBirth ? getMomentDateForUIReadOnly({ date: new Date(primaryContact.dateOfBirth), format: CONSTANTS.TABLE_DATE_FORMAT }) : '-'}
                          </Col></Row>
                        <Row className="lins"><Col span={12} className="textright15">Allerges</Col>
                          <Col span={12}>
                            {primaryContact.allergies || '-'}
                          </Col></Row>
                        <Row className="lins1"><Col span={12} className="textright15">Has Portal Acces</Col>
                          <Col span={12}>
                            {primaryContact.isEnablePortalAccess ? 'Yes' : 'No'}
                          </Col></Row>

                        {/* <Row className="lins1"><Col span={8} className="textright15"><b>Allergies:</b></Col>
                          <Col span={14}>
                            {student.allergies || '-'} 
                          </Col></Row> */}
                      </div>
                    </div>
                  </Col>
                  : ''}
                  {/* {(customerInfo.boContactList || []).map((contact, i) => {   */}
                {(filter(customerInfo.boContactList || [], (cnt) => { return cnt.isPrimaryContact !== 1; })).map((contact, i) => {
                
                  return (
                    <Col
                      xs={24}
                      md={8}
                      className="mat mab pl5 pr5 pt10 pb10"
                      key={"std-" + contact.contactId}
                    >
                      <div className="add-adrsboxnel add-boxtop-bot">
                        <div className="hed-studs">
                          <Row>
                            {/* <Col span={8} className="textright15"> <b>First Name</b></Col> */}
                            <Col span={24}>
                              <div className="tiles">
                                <div className="left-pic1">
                                  <div className="left-pic">
                                    {contact.docDetailList &&
                                    contact.docDetailList[0] ? (
                                      <img
                                        src={getAWSFileURL(
                                          contact.docDetailList[0].awsKeyName,
                                          contact.docDetailList[0].bucketName
                                        )}
                                        alt="Avatar"
                                      />
                                    ) : (
                                      <div className="user-icon-profile-family">
                                        {customerInfo
                                          ? getInitialsFromString(
                                              contact.firstName +
                                                (contact.lastName
                                                  ? " " + contact.lastName
                                                  : "") || "XX"
                                            )
                                          : ""}
                                      </div>
                                    )}
                                    {/* <img src={DefultPic} alt="pic" /> */}
                                  </div>
                                  {/* {contact.isPrimaryContact ? <div className="primary"><span> Primary </span></div> : ''} */}
                                </div>
                                <div className="titles">
                                  <span>
                                    {" "}
                                    {contact.firstName +
                                      (contact.lastName
                                        ? " " + contact.lastName
                                        : "")}
                                  </span>
                                </div>
                                <div className="emails">
                                  {contact.emailAddress || ""}
                                </div>
                                <div className="socialm">
                                  {" "}
                                  {contact.isPrimaryContact ? (
                                    <div className="primary">
                                      <span> Primary </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div
                                    style={{
                                      float: "right",
                                      lineHeight: "17px",
                                    }}
                                  >
                                    {/* <i className="fa fa-facebook" onClick={() => {
                                if (!contact.facebookLink) { return showToasterMessage({ messageType: 'error', description: 'Facebook link does not exist. Please edit profile to add facebook link.' }); }
                                let url = contact.facebookLink;
                                url = url.match(/^https?:/) ? url : '//' + url;
                                window.open(url, '_blank');
                              }}></i>
                                <i className="fa fa-twitter" onClick={() => {
                                  if (!contact.instagramLink) { return showToasterMessage({ messageType: 'error', description: 'Twitter link does not exist. Please edit profile to add twitter link.' }); }
                                  let url = contact.instagramLink;
                                  url = url.match(/^https?:/) ? url : '//' + url;
                                  window.open(url, '_blank');
                                }}></i>
                                <i className="fa fa-linkedin" onClick={() => {
                                  if (!contact.linkedIn) { return showToasterMessage({ messageType: 'error', description: 'LinkedIn link does not exist. Please edit profile to add linkedIn link.' }); }
                                  let url = contact.linkedIn;
                                  url = url.match(/^https?:/) ? url : '//' + url;
                                  window.open(url, '_blank');
                                }}></i>
                                <i className="fa fa-instagram" onClick={() => {
                                  if (!contact.instagramLink) { return showToasterMessage({ messageType: 'error', description: 'Instagram link link does not exist. Please edit profile to add instagram link.' }); }
                                  let url = contact.instagramLink;
                                  url = url.match(/^https?:/) ? url : '//' + url;
                                  window.open(url, '_blank');
                                }}></i> */}
                                  </div>
                                </div>
                              </div>
                              {/* <span><img src={StudentPic} alt="" /></span> */}
                            </Col>
                          </Row>
                          <div className="actopn-circle dotes">
                            <Dropdown
                              overlay={
                                <Menu>
                                  <Menu.Item
                                    key="2"
                                    onClick={() => {
                                      let formData = {
                                        customerId:
                                          props.customerInfo.customerId,
                                        customerName:
                                          props.customerInfo.companyName,
                                        contactData: contact,
                                      };
                                      handleNewContactAddition(props, {
                                        formData: formData,
                                      });
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#888",
                                        fontSize: "14px",
                                        padding: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className="fa fa-pencil"
                                        style={{ width: "25px" }}
                                      ></i>
                                      Edit
                                    </span>{" "}
                                  </Menu.Item>
                                  <Menu.Item
                                    key="3"
                                    className={
                                      contact.isPrimaryContact ? "disabled" : ""
                                    }
                                    disabled={contact.isPrimaryContact}
                                    onClick={() => {
                                      if (contact.isPrimaryContact) {
                                        showToasterMessage({
                                          messageType: "error",
                                          description:
                                            "Primary Contact can not be deleted",
                                        });
                                      } else {
                                        props.pushModalToStack({
                                          modalBody:
                                            "Are you sure you want to delete?",
                                          handleSubmit: () => {
                                            props.popModalFromStack();
                                            props.hideModal();
                                            props.deleteContact(contact, props);
                                          },
                                        });
                                      }
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#888",
                                        fontSize: "14px",
                                        padding: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className="fa fa-trash"
                                        style={{ width: "25px" }}
                                      ></i>
                                      Delete
                                    </span>
                                  </Menu.Item>

                                  <Menu.Item
                                    key="4"
                                    className={
                                      contact.isPrimaryContact ? "disabled" : ""
                                    }
                                    disabled={contact.isPrimaryContact}
                                    onClick={() => {
                                      props.pushModalToStack({
                                        modalBody:
                                          "Are you sure you want to make this contact as primary contact?",
                                        handleSubmit: () => {
                                          props.popModalFromStack();
                                          props.hideModal();
                                          props.makePrimary(
                                            {
                                              ...contact,
                                              isPrimaryContact: 1,
                                              isEnablePortalAccess: 1,
                                            },
                                            null,
                                            props
                                          );
                                        },
                                      });
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#888",
                                        fontSize: "14px",
                                        padding: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className="fa fa-check"
                                        style={{ width: "25px" }}
                                      ></i>
                                      Make Primary Contact
                                    </span>
                                  </Menu.Item>
                                  <Menu.Item
                                    key="5"
                                    onClick={() => {
                                      if (contact.isEnablePortalAccess) {
                                        showToasterMessage({
                                          messageType: "error",
                                          description: "Already enabled",
                                        });
                                      } else if (!contact.emailAddress) {
                                        showToasterMessage({
                                          messageType: "error",
                                          description:
                                            "Email address is requierd for portal access. Please edit contact to provide email address",
                                        });
                                      } else {
                                        props.pushModalToStack({
                                          modalBody:
                                            "Are you sure you want to enable portal access for this contact?",
                                          handleSubmit: () => {
                                            props.popModalFromStack();
                                            props.hideModal();
                                            props.enablePortalAccess(
                                              {
                                                ...contact,
                                                isEnablePortalAccess: 1,
                                              },
                                              null,
                                              props
                                            );
                                          },
                                        });
                                      }
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#888",
                                        fontSize: "14px",
                                        padding: "10px 0px",
                                      }}
                                    >
                                      {" "}
                                      <i
                                        className="fa fa-solid fa-desktop"
                                        style={{ width: "25px" }}
                                      ></i>
                                      Enable Portal Access
                                    </span>
                                  </Menu.Item>
                                </Menu>
                              }
                              trigger={["click"]}
                            >
                              <div>
                                <i className="fi fi-br-menu-dots-vertical" style={{fontSize: '16px'}}></i>
                                {" "}
                              </div>
                            </Dropdown>
                          </div>
                        </div>
                        {/* {student.isDefault ? <div className="add-top-hed">Default</div> : ''} */}
                        <div className="plus-icon">
                          <Row className="lins">
                            <Col span={12} className="textright15">
                              Relationship
                            </Col>
                            <Col span={12}>
                              {contact.familyRelationship ? (
                                <span
                                  className="primary p5"
                                  style={{ borderRadius: "5px" }}
                                >
                                  {" "}
                                  {
                                    FAMILY_RELATIONSHIP_NAME_BY_KEY[
                                      contact.familyRelationship
                                    ]
                                  }
                                </span>
                              ) : (
                                "-"
                              )}
                            </Col>
                          </Row>
                          <Row className="lins">
                            <Col span={12} className="textright15">
                              Gender
                            </Col>
                            <Col span={12}>{contact.gender || "-"}</Col>
                          </Row>
                          <Row className="lins">
                            <Col span={12} className="textright15">
                              Mobile
                            </Col>
                            <Col span={12}>{contact.cellPhone || "-"}</Col>
                          </Row>
                          <Row className="lins">
                            <Col span={12} className="textright15">
                              DOB
                            </Col>
                            <Col span={12}>
                              {contact.dateOfBirth
                                ? getMomentDateForUIReadOnly({
                                    date: new Date(contact.dateOfBirth),
                                    format: CONSTANTS.DISPLAY_DATE_FORMAT,
                                  })
                                : ""}
                            </Col>
                          </Row>
                          <Row className="lins">
                            <Col span={12} className="textright15">
                              Allerges
                            </Col>
                            <Col span={12}>{contact.allergies || "-"}</Col>
                          </Row>
                          <Row className="lins1">
                            <Col span={12} className="textright15">
                              Has Portal Acces
                            </Col>
                            <Col span={12}>
                              {contact.isEnablePortalAccess ? "Yes" : "No"}
                            </Col>
                          </Row>

                          {/* <Row className="lins1"><Col span={8} className="textright15"><b>Allergies:</b></Col>
                          <Col span={14}>
                            {student.allergies || '-'} 
                          </Col></Row> */}
                        </div>
                      </div>
                    </Col>
                  );
                })
                }
              </Row>
            </div>
          </div>
          <div className="right-profile profile-tabs" style={{display : "none"}}>
            {/* <div className="table-heading-education">Education and Employement Background</div> */}
           <Tabs onChange={(key) => {
            props.resetContactEducation();
            props.resetContactEmployment();
            let conatctObj = find(customerInfo.boContactList || [], { contactId: Number(key) }) || {};
            props.getContactEducation(conatctObj); 
            props.getContactEmployment(conatctObj);
           }} className="prl15" >
            {/* {(filter(customerInfo.boContactList || [], (cnt) => { return cnt.isPrimaryContact !== 1; })).map((contact, i) => { */}
            {(customerInfo.boContactList || []).map((contact, i) => {
              return <TabPane tab={contact.firstName + (contact.lastName ? ' ' + contact.lastName : '')} key={contact.contactId} >
                <div className="right-profile">
                  <div className="main-title">
                    <div className="page-heading">
                     Education
                      </div> 
                    <span><button className="ant-btn  ant-btn ant-btn-default" style={{backgroundColor: '#1CB961',border: '1px solid #1CB961',color: '#ffffff'}} onClick={()=>{
                      let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                      handleNewEducationAddition(props, { formData: formData }, contact);
                    }}>Add Education</button></span>
                  </div>
                  <div className="description">
                  <div className='table-container '>
                    <table onMouseLeave={() => { props.updateState({ editableIndex: -1 }) }}>
                        <thead>

                        <tr>
                          <th style={{ textAlign: 'center' }}>#</th>
                          <th><span>Institution Name</span></th>
                          <th><span>{getUserDefinedName('Class', props)} / Degree Type (Major)</span></th>
                          {/* <th><span>Major</span></th> */}
                          {/* <th><span>Grade</span></th> */}
                          {/* <th><span>Score/GPA</span></th> */}
                          <th><span>Start Date</span></th>
                          <th><span>End Date</span></th>
                          <th><FormattedMessage id='customer.listing.header.action' /></th>
                        </tr>
                        </thead>
                      <tbody>
                        {(props.contactEducationList || []).map((e, i) => {
                          return <tr key={'std' + i}>

                            <td style={{ textAlign: 'center' }}>{i + 1}</td>
                            <td>{e.institutionName || '-'}</td>
                            <td>{(e.schoolType === 'college' ? (`${e.degreeType} - (${e.major})`|| '-') : (e.grade))}</td>
                            {/* <td>{(e.schoolType === 'college' ? (e.major || '-') : 'NA')}</td> */}
                            {/* <td>{(e.schoolType === 'college' ? 'NA' :(e.grade || '-'))}</td> */}
                            {/* <td>{e.score || '-'}</td> */}
                            <td><span>{e.startDate ? getMomentDateForUIReadOnly({ date: e.startDate, format: CONSTANTS.TABLE_DATE_FORMAT }) : '-'}</span></td>
                            <td><span>{e.endDate ? getMomentDateForUIReadOnly({ date: e.endDate, format: CONSTANTS.TABLE_DATE_FORMAT }) : '-'}</span></td>
                            <td style={{paddingLeft: '50px'}}>
                              <DropdownAction overlay={actionMenuEd(e,contact)} trigger={['click']}>
                                <MoreOutlined />
                              </DropdownAction>
                              {/* <div className="delrt-sticky action-cell">
                                <i className='fa fa-edit' onClick={() => {
                                   let formData = {...e, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                                   handleNewEducationAddition(props, { formData: formData }, contact);
                                }} type="edit" />
                                <i className='fa fa-trash' onClick={() => {
                                  props.showModal({
                                    modalBody: "Are you sure you want to delete?",
                                    handleSubmit: () => {
                                      props.deleteContactEducation(e, props);
                                    }
                                  })
                                }} type="delete" />
                              </div> */}
                            </td>
                          </tr>
                        })
                        }
                      </tbody>
                    </table>
                    </div>
                     </div>
                </div>
                <div className="right-profile">
                  <div className="main-title">
                    <div className="page-heading">
                      Employment Background
                      </div> 
                    <span><button className="ant-btn  ant-btn ant-btn-default" style={{backgroundColor: '#1CB961',border: '1px solid #1CB961',color: '#ffffff'}} onClick={()=>{
                      let formData = { customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                      handleNewEmploymentAddition(props, { formData: formData }, contact);
                    }}>Add Employment</button></span>
                  </div>
                  <div className="description">
                  <div className='table-container'>
                    <table  onMouseLeave={() => { props.updateState({ editableIndex: -1 }) }}>
                        <thead>

                        <tr>
                          <th style={{ textAlign: 'center' }}>#</th>
                          <th><span>Employer Name</span></th>
                          <th><span>Job Title</span></th>
                          <th><span>Reason For Leaving</span></th>
                          <th><span>Job Duties</span></th>
                          <th><span>Employer Phone</span></th>
                          <th><span>Employer Email</span></th>
                          <th><span>Start Date</span></th>
                          <th><span>End Date</span></th>
                          <th className="emps"><FormattedMessage id='customer.listing.header.action' /></th>
                        </tr>
                        </thead>
                      <tbody>
                        {(props.contactEmploymentList || []).map((e, i) => {
                          return <tr key={'std' + i}>

                            <td style={{ textAlign: 'center' }}>{i + 1}</td>
                            <td>{e.employerName || '-'}</td>
                            <td>{e.jobTitle || '-'}</td>
                            <td>{e.reasonForLeaving || '-'}</td>
                            <td>{e.jobDuties ? ReactHtmlParser(e.jobDuties) : '-'}</td>
                            <td>{e.employerPhone || '-'}</td>
                            <td>{e.employerEmail || '-'}</td>
                            <td><span>{e.startDate ? getMomentDateForUIReadOnly({ date: e.startDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : '-'}</span></td>
                            <td><span>{e.endDate ? getMomentDateForUIReadOnly({ date: e.endDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }) : '-'}</span></td>
                            <td className="emps">
                              <div className="delrt-sticky action-cell">
                                <i className='fa fa-edit' onClick={() => {
                                  let formData = {...e, customerId: props.customerInfo.customerId, customerName: props.customerInfo.companyName, contactData: contact, contactId: contact.contactId }
                                  handleNewEmploymentAddition(props, { formData: formData }, contact);
                                }} type="edit" />
                                <i className='fa fa-trash' onClick={() => {
                                  props.showModal({
                                    modalBody: "Are you sure you want to delete?",
                                    handleSubmit: () => {
                                      props.deleteContactEmployment(e, props);
                                    }
                                  })
                                }} type="delete" />
                              </div>
                            </td>
                          </tr>
                        })
                        }
                      </tbody>
                    </table>
                    </div>
                     </div>
                </div>
              </TabPane>
            })
            }
          </Tabs>
          </div>
          </Col> }
          <Col>
        {componentsToDisplay === "Notifications" 
        &&  <Col
        >
          <NotFound/>
        </Col>}

        </Col> 

        <Col>
        {componentsToDisplay === "Settings" 
        &&  <Col
        >
          <NotFound/>
        </Col>}

        </Col> 

        {componentsToDisplay === "Change Password" &&  <Col xs={24} xl={19} >
        <div className="right-profile">
          <ChangePassword/>
        </div>
        </Col>}
      
      </Row>
    </Fragment>
  );
};

  export default injectIntl(Profile);
