import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TimeTableComp from "../../components/TimeTable";
import { showModal, hideModal, pushModalToStack, popModalFromStack } from '../../actions/commonActions';
import { getClassSectionList, getTimeTableByClassSection,getClassList } from "./action";


class TimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNumber: 1,
      pageSize: 200,
      activeTab:props.activeTab,
      isTimeTableLoaded:false,
      // combinedList:(this.props.classListing || []).map(item => item.classSectionList)?.flat()
    };
  }
    
  componentDidMount() {
    const companyInfo = this.props.companyInfo || {};
    const payload = {
      relationshipId: companyInfo.relationshipId
    };
    this.props.getClassSectionList(payload);
    this.props.getClassList(payload);
  }

  componentWillReceiveProps(props) {
  
    if(!this.state.isTimeTableLoaded && (props.allStudentList || []).length && (props.classListing || []).length){
      let payload = {
        relationshipId: props.companyInfo.relationshipId,
        classId: props.allStudentList[0]?.classId,
        sectionId: (props.allStudentList[0]?.schoolStudentClassRefList[0] || []).classSectionId
      }
      
      this.props.getTimeTableByClassSection(payload);
      this.setState({
        isTimeTableLoaded: true,
        activeTab: props.allStudentList[0]?.schoolStudentId.toString(),
      })
      
    }    
  }

  render() {
    return (
      <TimeTableComp
        {...this.props}
        {...this.state}
        updateState={(data) => {
          this.setState(data);
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    companyInfo: state.common.companyInfo,
    userInfo: state.common.userInfo,
    customerInfo: state.common.customerInfo,
    studentClassList: state.dashboard.studentClassList,
    allStudentList: state.dashboard.allStudentList,
    classSectionList: state.school.classSectionList,
    timeTableList: state.school.timeTableList,
    classListing:state.school.classListing
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showModal,
      hideModal,
      pushModalToStack,
      popModalFromStack,
      getClassSectionList,
      getTimeTableByClassSection,
      getClassList
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TimeTable);
