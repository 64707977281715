import { DASHBOARD_ACTION_LIST } from '../static/constants';
const initialState = {
  unpaidCustomerAmount: 0,
  paidCustomerAmount: 0,
  totalInvoiceCustomerAmount: 0,
  allStudentList: [],
  studentClassList: [],
  totalFeeAmount: 0,
  unpaidInvoices: {
    1: []
  },
  unpaidInvoiceCount: 0,
  paymentSubscription: {},
  broadcastMessageList: []
}

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_ACTION_LIST.UNPAID_CUSTOMER_AMOUNT:
      return { ...state, unpaidCustomerAmount: action.data };
    case DASHBOARD_ACTION_LIST.PAID_CUSTOMER_AMOUNT:
      return { ...state, paidCustomerAmount: action.data };
    case DASHBOARD_ACTION_LIST.TOTAL_FEE_AMOUNT:
      return { ...state, totalFeeAmount: action.data };
    case DASHBOARD_ACTION_LIST.ALL_STUDENT_LIST:
      return { ...state, allStudentList: action.data };
    case DASHBOARD_ACTION_LIST.STUDENT_CLASS_LIST:
      return { ...state, studentClassList: action.data };
    case DASHBOARD_ACTION_LIST.TOTAL_INVOICE_CUSTOMER_AMOUNT:
      return { ...state, totalInvoiceCustomerAmount: action.data };
     
      case DASHBOARD_ACTION_LIST.UNPAID_INVOICES_BY_FORM_ID:
        return {
          ...state,
          unpaidInvoices: {
            ...state.unpaidInvoices,
            [action.data.pageNo || 1]: action.data.list
          }
        };
      case DASHBOARD_ACTION_LIST.UNPAID_INVOICES_COUNT_BY_FORM_ID:
        return { ...state, unpaidInvoiceCount: action.data };
    case DASHBOARD_ACTION_LIST.UNPAID_INVOICES_BY_FORM_ID_RESET:
      return { ...state, unpaidInvoices: initialState.unpaidInvoices };
    case DASHBOARD_ACTION_LIST.PAYMENT_SUBSCRIPTION:
      return { ...state, paymentSubscription: action.data };
    case DASHBOARD_ACTION_LIST.BROADCAST_MESSAGE_LIST:
      return { ...state, broadcastMessageList: action.data };  
      case DASHBOARD_ACTION_LIST.CUSTOMER_PROFORMA_DATA:
      return { ...state, customerProformaData: action.data };  
    
    default:
      return state;
  }
};

export default DashboardReducer;