import React from 'react';
import { Tooltip, Pagination, Button } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CONSTANTS } from '../../../static/constants';
import { fetchPaginationDataIfNeeded, fixedDecimalNumber, getMomentDateForUIReadOnly } from '../../../utils';
import config from "../../../config/environmentConfig";
//import SalesInvoiceDetail from '../../../../../containers/modal/modalBody/customer/SalesInvoiceDetail';


const UnpaidInvoicesComponent = (props) => {
    const { unpaidInvoiceList, unpaidInvoiceCount, companyInfo, updateState, pageNumber } = props;

    const handlePageSizeChange = (pageNumber, pageSize, props) => {
        const payload = {
            relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize,
            formId: props.defaultSchoolForm || props.studentFormList[0].registrationFormSettingId
        };

        props.resetPaginatedData();
        props.getUnpaidInvoicesByFormId(payload);
        props.updateState({ pageSize: pageSize, pageNumber: pageNumber });
    }


    const showTotal = (total) => {
        return props.intl.formatMessage({ id: 'pagination.text.total', defaultMessage: '' }) + ` ${total} ` + props.intl.formatMessage({ id: 'pagination.text.items', defaultMessage: '' });
    };

    const loadPageData = (pageNumber, pageSize) => {
        const payload = { relationshipId: companyInfo.relationshipId, pageNumber: pageNumber, pageSize: pageSize, formId: props.defaultSchoolForm || props.studentFormList[0].registrationFormSettingId };
        fetchPaginationDataIfNeeded('getUnpaidInvoicesByFormId', 'unpaidInvoices', props, payload, true);
    }

    return (
        <div className='agGridWrapper liyutnty'>

            <div className='new-table-attend'>
                <table style={{ width: '100%' }} className='ntybuis'>
                    <tbody className='therts'>
                        <tr>
                            <th style={{ textAlign: 'center' }}>#</th>
                            <th>Parent Name </th>
                            <th>Porgram</th>
                            {/* <th>School Session</th> */}
                            <th>Invoice #</th>
                            <th>Invoice Date</th>
                            <th>Amount</th>
                            <th>Paid</th>
                            <th>Balance</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                        {(unpaidInvoiceList[pageNumber] || []).map((e, i) => {
                            return <tr key={'std' + i}>
                                <th style={{ textAlign: 'center' }}>{i + 1}</th>
                                <td> <div>{e.customerName || ''}</div></td>
                                <td> <div>{e.formName || ''}</div>
                                    {e.startDateSchoolSession ? <div><span style={{ fontSize: '10px', background: '#e5dede', padding: '5px' }}> {(e.startDateSchoolSession && getMomentDateForUIReadOnly({ date: e.startDateSchoolSession, format: CONSTANTS.DISPLAY_DATE_FORMAT }))} - {(e.endDateSchoolSession && getMomentDateForUIReadOnly({ date: e.endDateSchoolSession, format: CONSTANTS.DISPLAY_DATE_FORMAT }))} </span></div> : ''}
                                </td>
                                {/* <td> <div>
                                    <Tooltip placement="topLeft" title={(e.startDateSchoolSession && getMomentDateForUIReadOnly({ date: e.startDateSchoolSession, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                        {(e.startDateSchoolSession && getMomentDateForUIReadOnly({ date: e.startDateSchoolSession, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                    </Tooltip>-<Tooltip placement="topLeft" title={(e.endDateSchoolSession && getMomentDateForUIReadOnly({ date: e.endDateSchoolSession, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                        {(e.endDateSchoolSession && getMomentDateForUIReadOnly({ date: e.endDateSchoolSession, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                    </Tooltip>
                                </div></td> */}
                                <td>
                                    <div className="cursor-pointer" onClick={() => {
                                        let url = config.UI_BASE_URL + '/invoice?webInvoiceId=' + e.webInvoiceId;
                                        Object.assign(document.createElement('a'), {
                                            target: '_blank',
                                            href: url,
                                        }).click();
                                    }}>{e.invoiceNumber || ''}</div>
                                </td>
                                <td><Tooltip placement="topLeft" title={(e.invoiceDate && getMomentDateForUIReadOnly({ date: e.invoiceDate, format: CONSTANTS.DISPLAY_TOOLTIP_DATE_FORMAT }))}>
                                    {(e.invoiceDate && getMomentDateForUIReadOnly({ date: e.invoiceDate, format: CONSTANTS.DISPLAY_DATE_FORMAT }))}
                                </Tooltip></td>
                                <td style={{ textAlign: 'right' }}><span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(e.invoiceTotalAmount)}</span></td>
                                <td style={{ textAlign: 'right' }}><span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(e.totalPaymentReceived)}</span></td>
                                <td style={{ textAlign: 'right' }}><span>{props.companyInfo.currencyIcon ? <i className={props.companyInfo.currencyIcon}></i> : props.companyInfo.currencyCode + " "}{fixedDecimalNumber(e.invoiceTotalAmount - e.totalPaymentReceived)}</span></td>
                                <td>
                                    {
                                        (e.status === 'Rejected' && e.rejectionReason) ?
                                            <div>
                                                <Tooltip placement="topLeft" title={e.rejectionReason}>
                                                    <span className={["status-code", (e.status || "").toLowerCase()].join(' ')}><FormattedMessage id={e.status || "open"} /></span>
                                                </Tooltip>
                                            </div> :
                                            <div> <span className={["status-code", (e.status || "").toLowerCase()].join(' ')}><FormattedMessage id={e.status || "open"} /></span> </div>
                                    }
                                </td>
                                <td>{(e.status !== "paid" && e.status !== 'void') ?
                                    <Button type="primary" className='cursor-pointer' onClick={() => {
                                        let url = config.UI_BASE_URL + '/invoice?webInvoiceId=' + e.webInvoiceId;
                                        Object.assign(document.createElement('a'), {
                                            target: '_blank',
                                            href: url,
                                        }).click();
                                    }}>Pay</Button> :
                                    '-'
                                } </td>
                            </tr>
                        })
                        }
                    </tbody>

                </table>
            </div>
            <Pagination
                size="small"
                total={unpaidInvoiceCount}
                showTotal={showTotal}
                defaultPageSize={100} showSizeChanger pageSizeOptions={[10, 25, 50, 100, 200]}
                showQuickJumper
                onChange={(pageNumber, pageSize) => {
                    loadPageData(pageNumber, pageSize);
                    updateState({ pageSize: pageSize, pageNumber: pageNumber })
                }}
                onShowSizeChange={(pageNumber, pageSize) => {
                    handlePageSizeChange(pageNumber, pageSize, props)
                }}
            />
        </div>
    );
};

export default injectIntl(UnpaidInvoicesComponent);
